export const subscriptionType = {
    Basic: 0,
    Lifetime :5,
    Trial :6
}
export const allSubscriptionTypes = [
    {
        "id": 0,
        "systemName": "basic",
        "displayName" : "پایه"
    },
    {
        "id": 1,
        "systemName": "premium",
        "displayName" : "پرمیوم"
    },
    {
        "id": 2,
        "systemName": "family",
        "displayName" : "خانواده"
    },
    {
        "id": 3,
        "systemName": "student",
        "displayName" : "دانش آموز"
    },
    {
        "id": 4,
        "systemName": "business",
        "displayName" : "کسب و کار"
    },
    {
        "id": 5,
        "systemName": "lifetime",
        "displayName" : "دائمی"
    }
];

export const byId = (id)=>{
    return allSubscriptionTypes.find(st=>st.id===id) || {}
}

export const titleById = (id)=>{
    return byId(id).displayName || "";
}