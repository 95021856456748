import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';

import ListPage from './List'
import CreatePage from './Create'
import EditPage from './Edit'
import { Route, Switch, withRouter } from "react-router-dom";
import { Paper } from '@material-ui/core';

 class SubscriptionManage extends React.Component {
    render() {
        const { match, classes } = this.props;
        // const isCreatePage = location.pathname === `${match.path}/create`;
        console.log(match.path+'/create');
        console.log(window.location);
        
        const isCreatePage = window.location.hash.includes(`${match.path}/create`);

        return <Paper className={`${classes.root} ${isCreatePage ? classes.create : ''}`}>
    <Switch>
        <Route path={`${match.path}/create/reseller`} component={CreatePage} key="reseller" />
        <Route path={`${match.path}/create/trial`} component={CreatePage} key="trial" />
        <Route path={`${match.path}/create`} component={CreatePage} key="basic" />
        
        <Route path={`${match.path}/:id`} component={EditPage} />
        <Route path={`${match.path}`} exact component={ListPage} />

    </Switch>
</Paper>
    }
}

const styles = {
    root: {
        padding: 20,
        margin: 'auto',
        
    },
    create: {
        maxWidth: 600,
    },
};


export default withStyles(styles)(SubscriptionManage);