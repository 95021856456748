import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateResellerSetting } from '../../api'
import {hasValue} from '../../../../../utils/string'

import Switch from '../../../../../Component/Switch'

export const IsUpPaymentEnabled =  () => {
    const { t } = useTranslation('resellerSetting');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('isUpPaymentEnabled.title')}
                   name={"isUpPaymentEnabled"}
                   component={Switch}
                   type="text" />
        </FormControl>
    </Grid>
    </>
}

export const validate = (values, errors, t)=> {
    if ((values.isUpPaymentEnabled !== true && values.isUpPaymentEnabled !==false)) {
        errors.isUpPaymentEnabled = t('isUpPaymentEnabled.required');
    }
    return errors;
}


export function IsUpPaymentEnabledView({ values, onRefresh }) {
    const { t } = useTranslation('resellerSetting');

    const update = async (values) =>{
        const {id, isUpPaymentEnabled=t("common:notEntered") }=values;
        return await updateResellerSetting({id, isUpPaymentEnabled });
    }

    const successMessage  = (values) => t('updated')


    const { isUpPaymentEnabled } = values;

    return <ViewField successMessage={successMessage}
                        title={ t('isUpPaymentEnabled.title')}
                        headline={ isUpPaymentEnabled?t('common:yes'):t('common:no') }
                        update={update}
                        changeTitle= {t('common:enable')}

                        validate={validate}
                        values={values}
                        onRefresh={onRefresh}
                        editComponent={ IsUpPaymentEnabled }
                        />

}


