import {t} from '../i18n';

export const durationUnits = [
  {
    displayName: t('subscription:durationUnits.hourly'),
    id: 1,
  },
  {
    displayName: t('subscription:durationUnits.daily'),
    id: 2,
  },
  {
    displayName: t('subscription:durationUnits.monthly'),
    id: 3,
  },
];

export const durationValues = [
  {
    displayName: 1,//t('subscription:durationValues.one'),
    id: 1,
  },
  {
    displayName: 2,//t('subscription:durationValues.two'),
    id: 2,
  },
  {
    displayName: 3,//t('subscription:durationValues.three'),
    id: 3,
  },
  {
    displayName:  6,//t('subscription:durationValues.six'),
    id: 6,
  },
  {
    displayName:  12,//t('subscription:durationValues.twelve'),
    id: 12,
  },
];


  
  export const toHours = (value, unitId) => {
    let hours;
    
    switch(unitId) {
      case 1: // Hours
        hours = value;
        break;
      case 2: // Days
        hours = value * 24;
        break;
      case 3: // Months
        // using the average number of hours in a month (~730)
        hours = value * 730;
        break;
      default:
        throw new Error("Invalid unit ID");
    }
    
    return hours;
  };
  
  export const fromHours = (hours) => {
    let valueId;
    let unitId;
    // hours = Math.abs(hours);
    // Convert to the most appropriate unit
    if (hours < 24) {
      unitId = 1; // Hours
      valueId = hours;
    } else if (hours < 730) {
      unitId = 2; // Days
      valueId = hours / 24;
    } else {
      unitId = 3; // Months
      valueId = hours / 730;
    }
    
    // Round the value to the nearest integer for simplicity
    valueId = Math.round(valueId);
    return {
      valueId,
      unitId
    };
  };
  
 

  export const toText = (hours) => {
    const sign = hours <0 ? "-" :"";

    const { valueId, unitId } = fromHours(hours); // Assuming fromHours is defined elsewhere
  
    const value = durationValues.find(item => item.id === valueId);
    const unit = durationUnits.find(item => item.id === unitId);
  
    if (value && unit) {
      return sign+value.displayName + " " + unit.displayName;
    }
  
    return "Invalid hours";
  };

  export const toHumanText = (hours) => {
    const org = hours;
    const sign = hours <0 ? " "+t('subscription:units.ago') :"";

    // if(hours <=0 ) return "";

    const {valueId, unitId} = fromHours(hours);
    let text = "";
    if (unitId === 1) { // Hours
      text = `${valueId} ${t('subscription:units.hour')}`;
    } else if (unitId === 2) { // Days
      const days = Math.floor(valueId);
      const remainingHours = Math.round((valueId - days) * 24);
      text = `${days} ${t('subscription:units.day')}`;
      if (remainingHours > 0) {
        text += ` و ${remainingHours} ${t('subscription:units.hour')}`;
      }
    } else if (unitId === 3) { // Months
      const months = Math.floor(valueId);
      const remainingHours = Math.round((valueId - months) * 730);
      const {valueId: remainingDays, unitId: remainingUnitId} = fromHours(remainingHours);
      text = `${months} ${t('subscription:units.month')}`;
    
      if (remainingUnitId === 2) {
        text += ` ${t('subscription:conjunctions.and')} ${remainingDays} ${t('subscription:units.day')}`;
      } else if (remainingUnitId === 1 && remainingDays > 0) {
        text += ` ${t('subscription:conjunctions.and')} ${remainingDays} ${t('subscription:units.hour')}`;
      }
    }
    if(text) text = text + sign;
    return text || `${t('subscription:units.now')}`;  
  };
  
  