import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateServer } from '../../api'
import {hasValue} from '../../../../../utils/string'

import {ListDialog as RegionsDialog} from "../../../../Admin/Region/List"
import Selector from '../../../../../Component/Selector'


export const Region =  ({values={ region:{} }}) =>{
    const { t } = useTranslation('server');

    return <>

    <Grid sm={ 6 } xs={12}  item  >
        <FormControl fullWidth >
            <Field
                component={Selector}
                label={t('region.title')}
                name="regionId,region"
                options = { RegionsDialog}
                optionTitle= "name"
            />
        </FormControl>
    </Grid>
    </>

}


export const validate = (values, errors, t)=> {
    if (!hasValue(values.leaderId) && !hasValue(values.regionId)) {
        errors.regionId = t('region.required');
    }
    return errors;
}


class View extends React.Component {
    
    update = async (values) =>{
        const {id,regionId}=values;
        return await updateServer({id, regionId});
    }

    headline(values){
        const { region = {} } = values;
        const headline = region?(region.name):'-';
        return headline;
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
 
        return <ViewField
            successMessage={this.successMessage}
            title= { t('region.title')}
            headline  = { this.headline(values) }
            update ={this.update} 
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            editComponent={ Region } />
        
    }
}


export const RegionView = withTranslation('server')(View);