import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateResellerSetting } from '../../api'
import {hasValue} from '../../../../../utils/string'
import {EditablePriceTable, ViewPriceTable}  from '../../Component/PriceList'

import TextInput from '../../../../../Component/TextInput'

export const Prices =  () => {
    const { t } = useTranslation('resellerSetting');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('prices.title')}
                   name={"prices"}
                   component={    EditablePriceTable                }
                   />
        </FormControl>
    </Grid>
    </>
}
export const validate = (values, errors, t)=> {
    return errors;
}


export function PricesView({ values, onRefresh }) {
    const { t } = useTranslation('resellerSetting');

    const update = async (values) =>{

        const {id, prices }=values;
        return await updateResellerSetting({id, prices });
    }

    const successMessage  = (values) => t('updated')
    const headline  = () => {
        return <ViewPriceTable data= {JSON.parse(values.prices||'[]')} />
    }

    const { prices=t("common:notEntered") } = values;
    // return

    return <ViewField successMessage={successMessage}
                        title={ t('prices.title')}
                        showTitle={false}
                        headline={  headline }
                        update={update}
                        validate={validate}
                        values={values}
                        onRefresh={onRefresh}
                        editComponent={ Prices } />

}