import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateTrafficTransaction } from '../../api'
import {hasValue} from '../../../../../utils/string'

import {ListDialog as SubscriptionsDialog} from "../../../../Admin/Subscription/List"
import Selector from '../../../../../Component/Selector'


export const Receiver =  ({values={ receiver:{} }}) =>{
    const { t } = useTranslation('trafficTransaction');

    return <>

    <Grid sm={ 12 } xs={12}  item  >
        <FormControl fullWidth >
            <Field
                component={Selector}
                label={t('receiver.title')}
                name="receiverId,receiver"
                options = { SubscriptionsDialog}
                optionTitle= "customerName"
            />
        </FormControl>
    </Grid>
    </>

}


export const validate = (values, errors, t)=> {
    if (!hasValue(values.receiverId)) {
        errors.receiverId = t('receiver.required');
    }
    return errors;
}


export function ReceiverView({ values, onRefresh }) {
    const { t } = useTranslation('trafficTransaction');
    
    const update = async (values) =>{
        const {id,receiverId}=values;
        return await updateTrafficTransaction({id, receiverId});
    }

    const headline = (values) => {
        const { receiver = {} } = values;
        const headline = receiver?(receiver.customerName):'-';
        return headline;
    }

    const successMessage  = (values) => t('updated')

    return <ViewField
        successMessage={successMessage}
        title= { t('receiver.title')}
        headline  = { headline(values) }
        update ={update} 
        validate={validate}
        values={values}
        onRefresh={onRefresh}
        editComponent={ Receiver } />

}


