import React, { PureComponent } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Form, Field } from 'react-final-form'
import TextInput from '../../../../Component/TextInput'
import FormControl from '@material-ui/core/FormControl'
import { addCustomer } from '../api'
import {signIn} from '../../../../modules/Auth'
export default class Register extends PureComponent {

    constructor(props){
        super(props)

        this.state = {
            busyUI: false,
            showMessage: false,
            message: ''
        }
    }

    getReturnUrl = () => this.props.location.state ? this.props.location.state.from : '/home'
    onSubmit = async (values) => {

        const res = await addCustomer(values)

        if (res) {
            const loggedIn = await signIn( values.username, values.password )
            if (loggedIn)
                this.props.history.push(this.getReturnUrl());
        }
    }

    validateEmail = (email) => {
        var re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    }

    validate = (values) => {

        const errors = {}

        if (!values.username) {
            errors.code = 'نام کاربری را وارد نمایید'
        }

        if (!values.password) {
            errors.password = 'کلمه عبور را وارد نمایید'
        }

        if ( values.password && values.password.length < 6 ) {
            errors.password = 'کلمه عبور نباید کمتر از 6 کاراکتر باشد'
        }

        if (!values.email) {
            errors.email = 'ایمیل را وارد نمایید'
        }

        if (values.email && !this.validateEmail(values.email)) {
            errors.email = 'ایمیل اشتباه است'
        }

        if (!values.phone) {
            errors.phone = 'تلفن را وارد نمایید'
        }

        if (!values.repeatPass) {
            errors.repeatPass = 'تکرار کلمه عبور را وارد نمایید'
        }

        if (values.password && values.repeatPass 
            && (values.password !== values.repeatPass)){
                errors.repeatPass = 'تکرار کلمه عبور مطابقت ندارد'
        }

        if (!values.firstName) {
            errors.firstName = 'نام را وارد نمایید'
        }

        if (!values.lastName) {
            errors.lastName = 'نام خانوادگی را وارد نمایید'
        }
        
        return errors

    }

    render() {

        const {  
            username, 
            password, 
            repeatPass,
            firstName, 
            lastName, 
            email, 
            phone
        } = this.state
        return (
            
                
                <Form

                    subscription={{ submitting: true, pristine: true }}
                    onSubmit={this.onSubmit}
                    initialValues={{
                        username: username,
                        password: password,
                        repeatPass: repeatPass,
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        phone: phone
                    }}
                    validate={this.validate}
                    render={({ handleSubmit, reset, submitting, pristine, values }) => {
                        this.resetForm = reset
                        return (
                            <form
                                onSubmit={handleSubmit}
                                noValidate
                                autoComplete="off">

                                <Grid container spacing={0} direction="row" justify="center" alignItems="center">
                                    <Grid item xs={10} sm={4} style={{ maxWidth: 400 }}>
                                        <Grid container direction="column" justify="center" >
                                            <Grid item style={styles.row}>
                                                <FormControl fullWidth >
                                                    <Field
                                                        label={'نام کاربری'}
                                                        name="username"
                                                        component={TextInput}
                                                        type="text"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item style={styles.row}>
                                                <FormControl fullWidth>
                                                    <Field
                                                        label={'کلمه عبور'}
                                                        name="password"
                                                        component={TextInput}
                                                        type="password"
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item style={styles.row}>
                                                <FormControl fullWidth>
                                                    <Field
                                                        label={'تکرار کلمه عبور'}
                                                        name="repeatPass"
                                                        component={TextInput}
                                                        type="password"
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item style={styles.row}>
                                                <FormControl fullWidth>
                                                    <Field
                                                        label={'ایمیل'}
                                                        name="email"
                                                        component={TextInput}
                                                        type="email"
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item style={styles.row}>
                                                <FormControl fullWidth>
                                                    <Field
                                                        label={'نام'}
                                                        name="firstName"
                                                        component={TextInput}
                                                        type="text"
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item style={styles.row}>
                                                <FormControl fullWidth>
                                                    <Field
                                                        label={'نام خانوادگی'}
                                                        name="lastName"
                                                        component={TextInput}
                                                        type="text"
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item style={styles.row}>
                                                <FormControl fullWidth>
                                                    <Field
                                                        label={'تلفن'}
                                                        name="phone"
                                                        component={TextInput}
                                                        type="number"
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item style={styles.row}>
                                                <Button className='big-button' disabled={submitting} variant="contained" type="submit" fullWidth>
                                                    تایید
                                                </Button>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>)
                    }}
                />
            
        )
    }
}

const styles = {
    row: {
        paddingTop: 10,
        paddingBottom: 10
    }
}
