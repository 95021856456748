import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateOccasion } from '../../api'
import {allRecurrentTypes, titleById} from '../../../../../domain/recurrentType'
import Selector from '../../../../../Component/Selector'

export const RecurrentType =  ({t=useTranslation('occasion').t }) =>{

    return <Grid item sm={ 6 } xs={12}>
    <FormControl fullWidth>
        <Field component={Selector}
               label={t('recurrentType.title')}
               name="recurrentType"
               options={allRecurrentTypes }
               // keyName="id"
               optionTitle="displayName" />
    </FormControl>
</Grid>
}

export const validate = (values, errors, t)=> {
    if (values.recurrentType === undefined) {
        errors.recurrentType = t('recurrentType.required');
    }
    return errors;
}

class View extends React.Component {

    update = async (values) =>{
        const { id, recurrentType }=values;
        return await updateOccasion({id, recurrentType });
    }

    headline(values){
        const { recurrentType } = values;
        return titleById(recurrentType);
    }

    successMessage  = () => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;

        return <ViewField 
            successMessage={this.successMessage}
            title={ t('recurrentType.title')}
            headline={ this.headline(values) }
            update={this.update}
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            editComponent={ RecurrentType } />

    }
}

export const RecurrentTypeView = withTranslation('occasion')(View);