import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('directories');

export const insertCity = async (city) => {

    const resultEntity = await post(`city`,city,
    {
        errorMessage : t('common:insertError') + t('city:city')
    });

    if(!resultEntity) return null;

    return {...city, id:resultEntity.id};
}


export const updateCity = async city => {

    const result = await putFormData(`city/${ city.id }`,
        city,
        {
            errorMessage :  t('common:updateError') + t('city:city')
        });

    return result;
}

export const deleteCity = async (id) => {

    const result = await del(`city/${id}`,
        {
            errorMessage : t('common:deleteError') + t('city:city')
        });

    return result;
}

export const getCities = async (params) => {

    const result = await get(`city`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('city:cities')

    });

    return result;
}



export const getCity = async (id) => {

    const result = await get(`city/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('city:city')
    });

    return result;
}



