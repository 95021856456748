import axios from 'axios'
import { getToken } from '../modules/Auth'
import { onApiError } from '../modules/ErrorHandling'
import { emitEvent } from '../modules/EventEmitter'
import config from './config';



function toFormData(obj, form, namespace) {
    let fd = form || new FormData();
    let formKey;
    console.log(obj);
    for (let property in obj) {
        
        if (obj.hasOwnProperty(property) && (obj[property] || obj[property] === false || obj[property] === 0)) {
            if (namespace) {
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }

            // if the property is an object, but not a File, use recursivity.
            if (obj[property] instanceof Date) {
                fd.append(formKey, obj[property].toISOString());
            }
            else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                toFormData(obj[property], fd, formKey);
            } else { // if it's a string or a File object
   
                fd.append(formKey, obj[property]);
            }
        }
    }

    return fd;
}

export const generateIdempotencyKey= ()=>{
    const idempotencyKey = `${new Date().toISOString()}-${Math.random()}`;
    return idempotencyKey;
}

const getDefaultOptions = (t) => {

    let options = {
        'Accept': t || 'application/json',
        headers: {
            'Idempotency-Key': generateIdempotencyKey(),
            'Content-Type': t || 'application/json',
            'Access-Control-Allow-Origin': '*' // http://localhost:3000
        }
    };

    const token = getToken()
    if (token) {
        options['headers']['Authorization'] = "Bearer " + token
    }
    return options;
}
// function s2ab(s) {
//     var buf = new ArrayBuffer(s.length);
//     var view = new Uint8Array(buf);
//     for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
//     return buf;
//   }

function check(result) {
    return checkError(checkStatus(result));
}

function checkStatus(response) {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
        return response;
    } else {
        let error = new Error(response.statusText);
        error.response = response;

        throw error;
    }
}

function checkError(data) {
    if (data.errorMessage) {
        let error = new Error(data.errorMessage);
        error.response = data;

        throw error;
    }

    return data.data;
}


const instances = {}

class Ajax {
    constructor(serviceName) {
        // this.apiUrl = `${config.serverOrigin}/${serviceName}/api/`;
        this.apiUrl = config[serviceName+'Url'];
        this.cache = {};
        this.cacheClearedOn = new Date();
        if (!instances[serviceName]) {
            instances[serviceName] = axios.create({
                baseURL: this.apiUrl,
                timeout: 15000
            });
        }
     
        this.instance = instances[serviceName];


        this.instance.interceptors.request.use(function (config) {
            emitEvent('API_REQUEST')
            return config;
        }, function (error) {
            return Promise.reject(error);
        });

        this.instance.interceptors.response.use(function (response) {
            emitEvent('API_REQUEST_SUCCESS')
            return response;
        }, function (error) {
            emitEvent('API_REQUEST_FAILURE')
            return Promise.reject(error);
        });


    }
    resetCache () {
        for (var member in this.cache) delete this.cache[member];
        this.cache = {};
    }
    isCacheExpired(cacheItem)
    {
        var mins = ((new Date().getTime()- cacheItem.time)/1000)/60;
        return mins > 1;
    }
    getCache(cacheKey)
    {   
        const cacheItem = this.cache[cacheKey];
        if(cacheItem && this.isCacheExpired(cacheItem)) {
            delete this.cache[cacheKey];
            return undefined;
        }
        if(cacheItem)
            return cacheItem.data;
    }
    setCache(cacheKey, data){
        this.cache[cacheKey] = {
            data,
            time: new Date().getTime()
        }
    }
    get = async (url, params = null, options = {}) => {

        const errorMessage = options.errorMessage;
        const download = options.download;
        const useCache = options.useCache;
        delete options.errorMessage;
        delete options.download;
        delete options.useCache;
       
        if (params) {
            options = { ...options, ...{ params } };
        }

        try {

            // const response = await this.instance.get(url, { ...getDefaultOptions(), ...options}, download&&{responseType: 'blob'} );
            let response = null;


        //if(useCache) {
            const cacheKey = url+ (params?JSON.stringify(params):'');
            const cacheData = useCache&&this.getCache(cacheKey);
            if(cacheData)
                response= cacheData;
            else
            {
                response = await this.instance.get(url, { ...getDefaultOptions(), ...options, responseType: download && 'blob', exposedHeaders: ['Content-Disposition'] });
                useCache&&this.setCache(cacheKey, response);
            }
            if (download) {


                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'unknown';
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                    console.log(fileNameMatch)
                    if (fileNameMatch.length === 2)
                        fileName = fileNameMatch[1];
                }

                // const type = response.headers['content-type']

                var blob = new Blob([response.data], {
                    type: ''
                });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
            return check(response);
        } catch (e) {
            onApiError(e, errorMessage);
            return null;
        }
    }



    post = async (url, params, options = {}) => {
        this.resetCache();
        
        const errorMessage = options.errorMessage;
        delete options.errorMessage;
        try {
            const defaultOptions = getDefaultOptions();
            const mergedHeaders = { ...defaultOptions.headers, ...(options.headers || {}) };


            const result = await this.instance.post(url, params, 
                {
                    ...defaultOptions,
                    ...options,
                    headers: mergedHeaders
                  })
      
            return check(result);
        } catch (e) {
            onApiError(e, errorMessage);
            return null;
        }
    };

    postFormData = async (url, params, options = {}) => {
        return await this.post(url, toFormData(params), options)
    };

    putFormData = async (url, params, options = {}) => {
        return await this.put(url, toFormData(params), options)
    };

    put = async (url, params, options = {}) => {
        this.resetCache();
        
        const errorMessage = options.errorMessage;
        delete options.errorMessage;
        try {
            const result = await this.instance.put(url, params, { ...getDefaultOptions(), ...options })
            return check(result);
        } catch (e) {
            onApiError(e, errorMessage);
            return null;
        }
    };


    del = async (url, params, options) => {
        this.resetCache();
        
        if (!options && params.errorMessage)
            options = params;

        const errorMessage = options.errorMessage;
        delete options.errorMessage;

        // if (params) {
        //     options = { ...options, ...{ params } };
        // }

        try {
            const result = await this.instance.delete(url, { ...getDefaultOptions(), ...options });
            return check(result);
        } catch (e) {
            onApiError(e, errorMessage);
            return null;
        }
    };


}

export const createApi = (serviceName) => {
    return new Ajax(serviceName);
};
