import React, { PureComponent } from 'react'
import ActiveBundleList from '../../Bundle/List/ActiveBundleList'
export {default as Icon} from '@material-ui/icons/MonetizationOnOutlined';

// export const Name ='پرداخت‌های من'
// export const getName=({recurrentDonation:{donationSubject:{name=""}={}}}) =>'پرداخت‌های '+name;

export const getName=() =>'بسته‌ها'

export class Extension extends PureComponent
{
   render()
   {
      const {subscriber, newBundleMode} = this.props;

      if(newBundleMode) {console.log('newBundleMode'); console.log(newBundleMode)}
      return <ActiveBundleList subscriber={subscriber} newBundleMode={newBundleMode}/>

   }
   
}