import React, { PureComponent } from 'react'
import { withRouter } from "react-router-dom"
// import 'react-table/react-table.css'
import { getServers, deleteServer, setMasterServer, updateNodeConfig, updateNodeSoftware, sendCallback } from '../api'
import { getServer, updateServer, insertServer } from '../api'

import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import UpdateConfigIcon from "@material-ui/icons/PermDataSetting";
import UpdateSoftwareIcon from "@material-ui/icons/Autorenew";
import FilterableGrid from '../../../../Component/FilterableGrid';
import DeleteIcon from "@material-ui/icons/Delete";
import DialogWindow from '../../../../Component/DialogWindow'
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import { Link as RouterLink } from 'react-router-dom';
import {withLogin} from '../../../../Contexts/withLogin'
import EmptyList from '../../../../Component/EmptyList'
import StarIcon from '@material-ui/icons/Star';
import FlashAutoIcon from '@material-ui/icons/FlashAuto';


const ddlColumns = 
    {
        'name': {
            title: 'نام سرور',
            remote: true
        },
        'region.name': {
            editLink : true,
            // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
            title: 'منطقه',
            remote: true
        },
        'ip': {
            editLink : true,
            // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
            title: 'IP',
            remote: true
        }
        // 'version': {
        //     editLink : true,
        //     // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
        //     title: 'ورژن',
        //     remote: true
        // },
        // 'region.code2': {
        //     editLink : true,
        //     // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
        //     title: 'منطقه',
        //     remote: true
        //},
        // 'ip': {
        //     editLink : true,
        //     // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
        //     title: 'منطقه',
        //     remote: true
        // },
        // 'serverProvider.name': {
        //     editLink : true,
        //     // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
        //     title: 'منطقه',
        //     remote: true
        // },
        // 'island.id': {
        //     editLink : true,
        //     // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
        //     title: 'منطقه',
        //     remote: true
        // }
    }
;


class DefaultList extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            servers: [],
            selection: [],
            selectAll: false,
            // serverId: props.match.params.serverId,
            openDeleteDialog:false

        }
    }
    area= this.props.match.path.split('/')[1]
    columns = {
        'name': {
            editLink : true,
            format: (row) => (row.leaderId===row.id?"" : row.leader.name + "> ")+ row.name,
            // format: (row) => <RouterLink style={{color:"red"}} to={this.props.match.path+`/${row.id}`}>{row.name}</RouterLink>,
            title: 'نام سرور',
            remote: true
        },
        'enabled': {
            title: 'Enabled',
            format: (row) => row.enabled?"Yes":"",
            remote: true
        }
        ,
            'version': {
                // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
                title: 'ورژن',
                remote: true
            },
            'scriptVersion': {
                // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
                title: 'Script',
                remote: true
            },
            'region.name': {
                // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
                title: 'منطقه',
                remote: true
            },
            'ip': {
                // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
                title: 'IP',
                remote: true
            },
            'serverProvider.name': {
                editLink : true,
                // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
                title: 'Provider',
                remote: true
            },
            'islandId': {
                // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
                title: 'Island',
                remote: true
            },
            'dailyAverageTraffic': {
                // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
                title: 'Traffic',
                remote: true
            },
            'yesterdayTraffic': {
                
                // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
                title: 'Traffic-1',
                remote: true
            },
            'yesterdayUserCount': {
                
                // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
                title: 'Count',
                remote: true
            },
            'subscriberCount': {
                
                // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
                title: 'TotalCount',
                remote: false
            },
            'healthResult': {
                format: (row) => row.healthResult=="1"? "OK":row.healthResult=="-1"?"Sent": (row.healthResult?row.healthResult.substring(0, 5):""),
                title: 'Health',
                remote: true
            }
        
    };

    async componentDidMount() {

        await this.loadServers()
    }

    refresh = async () => {
        await this.loadServers();
    }

    loadServers = async () => {
        var res = await getServers()
        if (res) {
            this.setState({ servers: res, selection :[], selectAll:false, openDeleteDialog:false })
        }
    }

    onFilter = async (params) => {
        var res = await getServers({...params})

        if (res) {
            const isFiltered = Object.keys(params).length;
            this.setState({ servers: res, openDeleteDialog:false, isFiltered })
        }
    }

  

    deleteServers = async () => {
        const res = await deleteServer(this.state.selection[0].id)
        if (res !== null){
            await this.loadServers()
        }

    }


    showDeleteDialog = (open, selected) => () => {
        this.setState({ selection : selected, openDeleteDialog: open });
    };

   
    setMaster = ({id}) => async () => {
        console.log('setMaster');
        console.log(id)
        var result = await setMasterServer(id);
        if(result) {
            await this.loadServers()
        }
    };

   


    


    onEditClick =(id)=>{
        const { match } = this.props;
        this.props.history.push(`${match.path}/${id}`);
    }
    onDeleteClick = (selected) => {
        this.setState({ selection : selected, openDeleteDialog: true });

    }

    onUpdateNodeConfigClick = ({name,id=""}={}) => async () => {
        if (window.confirm(`Update node configs in ${name||'All'}?`)) {
            if(!name) {
                const userResponse = window.prompt("Please type 'CONFIRM' to proceed with the update.");
                if(userResponse !== "CONFIRM")
                {
                    alert("Operation canceled!");
                    return;
                } 
            }
            
            var server = await updateNodeConfig(id);
        }
    };
    onUpdateNodeSoftwareClick = ({name,id=""}={}) => async () => {
        if (window.confirm(`Update node softwares in ${name||'All'}?`)) {
            if(!name) {
                const userResponse = window.prompt("Please type 'CONFIRM' to proceed with the update.");
                if(userResponse !== "CONFIRM")
                {
                    alert("Operation canceled!");
                    return;
                } 
            }
            var server = await updateNodeSoftware(id);

        }
      
    };

    onNodeCallbackClick = ({name,id=""}={}) => async () => {
        const nodeCallback  = window.prompt("Please enter the node callback to proceed");

        if (window.confirm(`Update node softwares in ${name||'All'}?`)) {
            if(!name) {
                const userResponse = window.prompt("Please type 'CONFIRM' to proceed with the update.");
                if(userResponse !== "CONFIRM")
                {
                    alert("Operation canceled!");
                    return;
                } 
            }
            var server = await sendCallback(nodeCallback, id);

        }
      
    };

    selectionToolbar = (selected)=> {
        const {t}= this.props;
        return <>
<Tooltip title={t('common:delete')}>
    <IconButton aria-label={t('common:delete')}>
        <DeleteIcon onClick={this.showDeleteDialog(true, selected)} />
    </IconButton>
</Tooltip>
<Tooltip title={t('common:updateConfig')}>
    <IconButton aria-label={t('common:updateConfig')}>
        <UpdateConfigIcon onClick={this.onUpdateNodeConfigClick(selected[0])} />
    </IconButton>
</Tooltip>
<Tooltip title={t('common:updateSoftware')}>
    <IconButton aria-label={t('common:updateSoftware')}>
        <UpdateSoftwareIcon onClick={this.onUpdateNodeSoftwareClick(selected[0])} />
    </IconButton>
</Tooltip>
<Tooltip title={t('common:nodeCallback')}>
    <IconButton aria-label={t('common:nodeCallback')}>
        <FlashAutoIcon onClick={this.onNodeCallbackClick(selected[0])} />
    </IconButton>
</Tooltip>

<Tooltip title={'Master server'}>
    <IconButton aria-label={'Master server'}>
        <StarIcon onClick={this.setMaster(selected[0])} />
    </IconButton>
</Tooltip>


        </>
    }

    toolbar = (selected)=> {
        const { t, match }= this.props;
        return <>
<Tooltip title={t('common:new')}>
    <Button component={RouterLink} to={`/${this.area}/server/create`} color="primary">{t('new')}</Button>
</Tooltip>
<Tooltip title={t('common:updateConfig')}>
    <IconButton aria-label={t('common:updateConfig')}>
        <UpdateConfigIcon onClick={this.onUpdateNodeConfigClick()} />
    </IconButton>
</Tooltip>
<Tooltip title={t('common:updateSoftware')}>
    <IconButton aria-label={t('common:updateSoftware')}>
        <UpdateSoftwareIcon onClick={this.onUpdateNodeSoftwareClick()} />
    </IconButton>
</Tooltip>
<Tooltip title={t('common:nodeCallback')}>
    <IconButton aria-label={t('common:nodeCallback')}>
        <FlashAutoIcon onClick={this.onNodeCallbackClick()} />
    </IconButton>
</Tooltip>

<Tooltip title={t('common:filter')}>
    <IconButton aria-label={t('common:filter')}>
        <FilterListIcon />
    </IconButton>
</Tooltip>
        </>
    }


DeleteDialog = () => {
    const {t}= this.props;
    return <Dialog open={true}
                   onClose={this.handleCloseDeleteDialog}
                   aria-labelledby="alert-dialog-title"
                   aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title" style={{direction:'rtl'}}>{t('delete')}</DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {t('delete?')}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.showDeleteDialog(false)} color="primary">
            {t('common:cancel')}
        </Button>
        <Button onClick={this.deleteServers } color="primary" autoFocus>
            {t('common:ok')}
        </Button>
    </DialogActions>
</Dialog>
    }

    render() {
        const { servers, openDeleteDialog, openImportDialog, isFiltered } = this.state
        const {match, t} = this.props

        if (!isFiltered && !servers.length) {
                  return (<EmptyList title={t('listIsEmpty')}
           entityTitle={t('server')}
           to={`/${this.area}/server/create`} />)
        }
        return (
        <>
            {openDeleteDialog&& this.DeleteDialog()}

<FilterableGrid data={ servers }
                selectable={this.props.selectable || true}
                pagination={false}
                title={t('servers')}
                selectionToolbar={this.selectionToolbar}
                toolbar={this.toolbar}
                header={this.props.columns ||this.columns}
                onDelete={this.onDeleteClick}
                onEdit={this.onEditClick}
                onFilter={this.onFilter}
                onSelect={this.props.onSelect}></FilterableGrid>
        </>
        )
    }
}

export class ListDialog extends PureComponent {

    render() {
        return <DialogWindow open={this.props.open} onClose={this.props.onClose}>
    <List columns={ddlColumns}
          selectable={1}
          {...this.props} />
</DialogWindow>
    }
}


export const List = withTranslation('server')(withLogin(withRouter(DefaultList)));
export default List;
