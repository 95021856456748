import {t} from '../i18n';


export const bundleType = {
    Infinite:0,
    Reseller: 1,
    Consumer:2,
}

export const allBundleTypes = [
    {
        "id": 0,
        "systemName": "infinite",
        "displayName" : t('bundle:bundleType.infinite')
    },
    {
        "id": 1,
        "systemName": "reseller",
        "displayName" : t('bundle:bundleType.reseller')
    },
    {
        "id": 2,
        "systemName": "consumer",
        "displayName" : t('bundle:bundleType.consumer')
    }
];

export const byId = (id)=>{
    return allBundleTypes.find(bt=>bt.id==id) || {}
}

export const titleById = (id)=>{
    return byId(id).displayName || "";
}