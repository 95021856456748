
let storageSupport = false
if (typeof (Storage) !== "undefined") {
  storageSupport = true
}

const checkStorage = () => {
  if (!storageSupport) throw Error("storage not support.")
}

export const getItem = (key) => {
  checkStorage()
  return localStorage.getItem(key)
}

export const setItem = (key, value) => {
  checkStorage()
  return localStorage.setItem(key, value)
}

export const removeItem = (key) => {
  checkStorage()
  return localStorage.removeItem(key)
}