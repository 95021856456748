import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateConnectionConfig } from '../../api'
import {hasValue} from '../../../../../utils/string'

import Switch from '../../../../../Component/Switch'

export const IsDefault =  () => {
    const { t } = useTranslation('connectionConfig');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('isDefault.title')}
                   name={"isDefault"}
                   component={Switch}
                   type="text" />
        </FormControl>
    </Grid>
    </>
}

export const validate = (values, errors, t)=> {
    if ((values.isDefault !== true && values.isDefault !==false)) {
        errors.isDefault = t('isDefault.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, isDefault }=values;
        return await updateConnectionConfig({id, isDefault });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { isDefault } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('isDefault.title')}
                          headline={ isDefault?t('common:yes'):t('common:no') }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ IsDefault }
                          />

    }
}


export const IsDefaultView = withTranslation('connectionConfig')(View);
