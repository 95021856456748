import React from 'react'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import Grid from "@material-ui/core/Grid";
import AutoComplete from '../../../../Component/Autocomplete';
import { getSubscriptions, getSubscriptionsByKey } from '../api';

import { numToFa } from '../../../../utils/persianUtils';
import { makeStyles } from "@material-ui/core/styles";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import PhoneEnabledOutlinedIcon from '@material-ui/icons/PhoneEnabledOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';

import { Box, IconButton } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
// import Button from '@material-ui/core/Button';
// import MenuList from '@material-ui/core/MenuList';

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  tools: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),

  },
  bodyIcon: {
    color: theme.palette.text.secondary,
    width: 15, marginTop: 10, marginBottom: -5
  },
  root: {
    height: 100,
    '& .tools': {

      visibility: 'hidden'
    },
    '&:hover .tools': {
      visibility: 'visible'
    }
  },
  listPaper: {
    minWidth: 300
  }
}));

function isValidHex(hexString) {
  return /^(0x)?[0-9a-fA-F]+$/.test(hexString);
}

// function deinterleaveBits(interleaved) {
//   let interleavedBigInt = BigInt(interleaved);

//   let key1 = 0;
//   let key2 = 0;
//   for (let i = 0; i < 32; i++) {
//       let bitFromKey1 = (interleavedBigInt >>> (2 * i)) & 1;
//       let bitFromKey2 = (interleavedBigInt >>> (2 * i + 1)) & 1;
//       key1 |= (bitFromKey1 << i);
//       key2 |= (bitFromKey2 << i);
//   }
//   return [key1, key2];
// }
// function incrementHex(hexString) {
//   // Parse the hex string to a decimal number
//   let decimalNumber = parseInt(hexString, 16);

//   // Increment the decimal number by 1
//   decimalNumber += 1;

//   // Convert the decimal number back to a hex string
//   let newHexString = decimalNumber.toString(16);

//   return newHexString;
// }

async function handleSearch(text) {
  
  if(isValidHex(text)){
    var result =  await getSubscriptionsByKey(text);
    if(result&&result.length) {
      
      return result;
    }
  }

  var result= await getSubscriptions({ "customerName+customerPhone": text });
  return result.results||[];


}




export default (props) => {
  const classes = useStyles();

  return <>
    <AutoComplete  {...props} classes={{ ...props.classes, paper: classes.listPaper }} onSearch={handleSearch}>{
      (benefactor) =>
        <Grid
          className={classes.root} container alignItems="center"
          component={RouterLink}
          onClick ={()=>props.onSelect&&props.onSelect(benefactor.id, benefactor)}
          to={!props.onSelect&& {
            pathname: `/admin/subscription/${benefactor.id}`,
            // search: "?sort=name",
            // hash: "#the-hash",
            // state: { newDonation: true }
          }}
        >
          <Grid item color="textSecondary">
            <EmojiPeopleIcon className={classes.icon} />
          </Grid>
          <Grid item xs>
            <Box style={{ fontWeight: 700 }}>
              {(benefactor.customerName||'') + ' ' + (benefactor.customerPhone||'')}
            </Box>
            <Box display="flex" justifyContent="space-between" fontSize={"small"} >
              <Box><PersonOutlineIcon className={classes.bodyIcon} fontSize="small" /> {numToFa(benefactor.key) || '-'}  </Box>
              <Box><PhoneEnabledOutlinedIcon className={classes.bodyIcon} fontSize="small" /> {numToFa(benefactor.customerPhone) || '-'} </Box>
            </Box>
          </Grid>
          {/* <Grid item className={classes.tools}>
            <Box className={'tools'}>

              <Box>

                <IconButton color="primary" aria-controls="simple-menu" aria-haspopup="true"
                  onClick={(e) => props.onShortcutSelect&&props.onShortcutSelect(e, benefactor)} >
                    
                  <MonetizationOnOutlinedIcon fontSize="small" />
                </IconButton>


              </Box>

            </Box>
          </Grid> */}
        </Grid>
    }
    </AutoComplete>
  </>
};

