import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateSubscription } from '../../api'
import {hasValue} from '../../../../../utils/string'
import {ListDialog as ServersDialog} from "../../../../Admin/Server/List"
import Selector from '../../../../../Component/Selector'

import MultiSelect from '../../../../../Component/MultiSelect'
import { getServers } from "../../../../Admin/Server/api"
import Chip from "@material-ui/core/Chip";

// async function  getSelectedServers () {

// }
// async function updateSelectedServers(){

// }
let allSelectedServers=null;

export const SelectedServers =  (values) =>{
    const { t } = useTranslation('subscription');
    const [options, setOptions] = useState(allSelectedServers||[]);
    useEffect(()=> {loadOptions().then(res=>setOptions(res))}, options);

    return <Grid item sm={ 12 } xs={12}  >
        <FormControl fullWidth>
            <Field
                component={MultiSelect} // Selector
                
                label={t('selectedServers.title')}
                name="selectedServers"
                // selectable = {4}
                options = {options} // ServersDialog
                // keyName="id"
                optionTitle="name"
                
                // returnObjectKey= "selectedServerId"
            />
        </FormControl>
    </Grid>
}


export const validate = (values, errors, t)=> {
    return errors;
}


export const loadOptions = async () =>
{
    return (allSelectedServers=allSelectedServers|| await getServers())
}

class View extends React.Component {
    
    update = async (values) =>{
        const {id, selectedServers }=values;
        return await updateSubscription({id, selectedServers });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t , readonly} = this.props;
        const { selectedServers } = values;

        return <ViewField
            successMessage={this.successMessage}
            title= { t('selectedServers.title')}
            headline  = {(selectedServers||[]).map(ss => (
                <Chip
                  key={ ss.id }
                  label={ ss.name }
                />
              ))}
            update ={this.update} 
            validate={validate}
            values={values}
            readonly = {readonly}

            onRefresh={onRefresh}
            editComponent={ SelectedServers } />


    }
}


export const SelectedServersView = withTranslation('subscription')(View);