import { createApi } from '../utils/Ajax'
const { post, get } = createApi('authority');


export const signIn = async (username, password) => {

    const token = await post(`auth`,
        {
            username,
            password
        },
        {
            errorMessage : `خطا در ورود کاربر ${username}`
        });
    
    return token;
}



export const tokenById = async (userId) => {

    const token = await get(`account/tokenById/`+userId,
        {},
        {
            errorMessage : `خطا در دریافت توکن توسط فرا ادمین ${userId}`
        });
    
    return token;
}



export const signId = async (phone) => {

    const resoponse = await post(`auth/signid`,
        {
            username : phone,
            password : ''
        },
        {
            errorMessage : `خطایی رخ داده، لطفا مدتی بعد دوباره تلاش کنید`
        });
    
    return resoponse;
}


export const signByOtp = async (phone, password) => {

    const token = await post(`auth/signByOtp`,
        {
            username : phone,
            password
        },
        {
            errorMessage : `خطا در ورود با رمز یکبار مصرف`
        });
    
    return token;
}
