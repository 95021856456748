import React, { PureComponent } from 'react'
import RouterBreadcrumbs from '../../../../Component/RouterBreadcrumbs'
import { withTranslation } from 'react-i18next';
import "filepond/dist/filepond.min.css";
import { ViewForm as DefaultEditForm } from '../Form';

class EditableViewForm extends PureComponent {
    render() {
        const {values,onRefresh, t, form, onClose } = this.props;
        const ViewForm = form || DefaultEditForm;
        return (<>
            <RouterBreadcrumbs title={ t('bundle') } onBack={onClose} />
            <ViewForm onRefresh={onRefresh} values={values} t={t} />
        </>)

        
    }
}

export default withTranslation('bundle')(EditableViewForm);