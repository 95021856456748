import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('beshkan');

export const insertFile = async (file) => {

    const resultEntity = await post(`file`,file,
    {
        errorMessage : t('common:insertError') + t('file:file')
    });

    if(!resultEntity) return null;

    return {...file, id:resultEntity.id};
}


export const updateFile = async (file) => {

    const result = await putFormData(`file/${ file.id }`,
        file,
        {
            errorMessage :  t('common:updateError') + t('file:file')
        });

    return result;
}

export const deleteFile = async (id) => {

    const result = await del(`file/${id}`,
        {
            errorMessage : t('common:deleteError') + t('file:file')
        });

    return result;
}

export const getFiles = async (params) => {

    const result = await get(`file`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('file:files')

    });

    return result;
}



export const getFile = async (id) => {

    const result = await get(`file/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('file:file')
    });

    return result;
}



