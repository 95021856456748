import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import DialogContent from "@material-ui/core/DialogContent";
// import ColorPicker from "../Components/Inputs/ColorPicker/Index";
const styles = {
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
    root:{
        direction:"ltr"
    }
};
function Transition(props) {
    return <Slide direction="up" {...props} />;
  }
class DialogWindow extends React.PureComponent {
    state = {
        open: true,
    };
    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.onClose && this.props.onClose();
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ open: nextProps.open });
    }

    render() {
        const {classes} = this.props;
        return (
            <div >
                {/* <Button onClick={this.handleClickOpen}>Open full-screen dialog</Button> */}
                <Dialog
                className={classes.root}
                    // fullScreen
                    // scroll='paper'
                    scroll='paper'
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    {/* <DialogTitle id="scroll-dialog-title">
                        <AppBar >
                            <Toolbar>
                                <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant="h6" color="inherit" className={classes.flex}>
                                    {this.props.title} 
                                </Typography>
                                {this.props.toolbar }

                                
                            </Toolbar>
                        </AppBar>
                    </DialogTitle> */}
                    <DialogContent  style={{ overflow: this.props.scroll?"auto":"hidden", paddingTop: "30px" }}>
                        {this.props.children}
                    {/* <ColorPicker /> */}
                    </DialogContent>

                </Dialog>
            </div>
        );
    }
}


export default withStyles(styles)(DialogWindow);
