export const allKindOfDocs = [
    {
        "id": 0,
        "systemName": "picture",
        "displayName" : "تصویر"
    },
    {
        "id": 1,
        "systemName": "audio",
        "displayName" : "صدا"
    },
    {
        "id": 2,
        "systemName": "video",
        "displayName" : "فیلم"
    },
    {
        "id": 3,
        "systemName": "text",
        "displayName" : "متن"
    },
    {
        "id": 4,
        "systemName": "other",
        "displayName" : "سایر"
    }
];

export const byId = (id)=>{
    return allKindOfDocs.find(kod=>kod.id === id) || {}
}

export const titleById = (id)=>{
    return byId(id).displayName || "";
}