import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateSubscription } from '../../api'
import {hasValue} from '../../../../../utils/string'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

import Switch from '../../../../../Component/Switch'

export const IsGovernment =  () => {
    const { t } = useTranslation('subscription');
    const [open, setOpen] = useState(false);
    const handleTouch = () => {
        setOpen(!open);
    };
    return <>
    <Grid item sm={ 12 } xs={12} style={{whiteSpace:"nowrap"}}>
        <FormControl >
            <Field label={t('governmentCompliant.title')}
                   name={"isGovernment"}
                   component={Switch}
                   type="text" />
        
    
        </FormControl>
        <Tooltip  
            title={<span dir="rtl" text >{t('governmentCompliant.hint')}</span>}
            open={open}
            onMouseEnter= {handleTouch}
            onClose={() => setOpen(false)}
            
        >
        <IconButton  onClick={handleTouch} style={{marginLeft:-20, marginRight:-20,marginTop:-4  }} aria-label="info">    <InfoIcon /> </IconButton>
        </Tooltip>
    </Grid>
    </>
}

export const validate = (values, errors, t)=> {
    if ((values.isGovernment !== true && values.isGovernment !==false)) {
        errors.isGovernment = t('governmentCompliant.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, isGovernment }=values;
        return await updateSubscription({id, isGovernment });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values={}, onRefresh, t } = this.props;
        const { isGovernment } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('governmentCompliant.title')}
                          headline={ isGovernment?t('common:yes'):t('common:no') }
                          update={this.update}
                          validate={validate}
                          changeTitle= {t('common:enable')}

                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ IsGovernment }
                          />

    }
}


export const IsGovernmentView = withTranslation('subscription')(View);
