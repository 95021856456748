import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: 'rgba(228, 206, 249, 0.48)'//lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary,
    paddingRight:20,
    width:'100%',
  },
  title: {
    flex: "0 0 auto"
  }
});

class EnhancedTableToolbar extends React.PureComponent 
{
  render() {
    const { title, numSelected, classes, handleOk, selectionToolbar, toolbar, selected } = this.props;
    return (
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0
        })}
      >
      <div className={classes.actions}>
        {numSelected > 0 ? selectionToolbar(selected) : (
          <Typography noWrap  variant="h6" id="tableTitle">
          {title}
        </Typography>)}
      </div>
        <div className={classes.spacer} />
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Button onClick={handleOk} variant={handleOk?"outlined":undefined} style={{marginLeft:8}} size="medium" color="secondary">
             {/* <Typography color="inherit" variant="subtitle1"> */}
              {numSelected} مورد
            {/* </Typography> */}
          </Button>
           
          ) : (
            toolbar&&toolbar()
          )}
        </div>
  
      </Toolbar>
    );
  }
}

export default withStyles(toolbarStyles)(EnhancedTableToolbar);
