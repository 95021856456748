import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import EnhancedTableHead from "./FilterableGrid.TableHead"
import EnhancedTableToolbar from "./FilterableGrid.Toolbar"
import Link from '@material-ui/core/Link';
import {byPath} from '../utils/objectHelper';
import {numToFa} from '../utils/persianUtils'; 
import { currencyFormat } from '../utils/objectHelper';
import {getDateTitle, getTimeTitle} from '../utils/moment-jalali-utils'
import { hasRole } from "../modules/Auth";

// https://stackoverflow.com/questions/23989463/how-to-set-tbody-height-with-overflow-scroll/23989771#:~:text=if%20you%20want%20tbody%20to,table%20%2C%20turn%20tr%20into%20table%20.&text=Another%20approach%20is%20to%20wrap,to%20stick%20to%20the%20top.

export const columnTypes={
  money:1,
  time:2,
  date:3,
  dateTime:4,
  number:5
};
function makeHeaderList(headerProp) {
  if(!headerProp) return [];
  // if header info is an object, convert to array
  if(!Array.isArray(headerProp)){
    headerProp = Object.keys(headerProp).map(key=>({[key]:headerProp[key]}));
  }

  var props = [];
  headerProp.forEach(prop => {
      
      if(typeof prop === "string") {
          props.push({name:prop, title:prop})
      } else {
          var propName = Object.keys(prop)[0];
          prop = prop[propName];
          if(prop.hidden) return;
          if(typeof prop === "string") {
            prop = {
              title : prop
            }
          }
          prop.name=propName;
          props.push(prop);
      }

  });

  return props;
}

const styles = () => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3
  },
  table: {
    // minWidth: 350,
    // direction:"rtl",
  },
  tableWrapper: {
    // overflowX: "auto",
    overflow: 'auto',
    marginRight: -20,
    marginBottom: -20
  },
  filterControl :{
    display:"inline-flex",
    whiteSpace :"nowrap",
      '&>span>.ctrl' :{
        position: "absolute",
        display:"none",
        width:16,
        height:16,
        // left:0,
        opacity: 0.6
      },
      '&:hover>span>svg':{
        color:"transparent"
      },
      '&:hover>span>.ctrl':{
          display:"block"
      },
  

  },
  headerInput:{
    textAlign: 'left', 
    direction: 'ltr', 
    whiteSpace: "nowrap"
  },
  headerInputLabel:{
    margin: "0px",
    padding: "0px",
    width: "100%",
    textAlign: 'left',
    direction: 'ltr',
    whiteSpace: "nowrap",
    // right: "auto",
    right: "0",
    transformOrigin: "top left",
    overflow: "hidden",
  },
  sortLabel : {
    position:"relative",
    top:"20px",
  },
  noClick: {
    cursor: 'pointer',
  },
});




class EnhancedTable extends React.PureComponent {
  state = {
    order: this.props.order || "asc",
    orderBy: this.props.orderBy || "displatOrder",
    selected: this.props.selected || [],
    filterText : '',
    page: this.props.page || 0,
    rowsPerPage: this.props.rowsPerPage || 1000,
    header : makeHeaderList(this.props.header)
  };

  serverFilter(property) {
    let {header, orderBy, order} = this.state;
    const remoteItems = header.filter(h=>h.remote); // && (h.filterText || h.name === orderBy)
    // reseting filter text :
    const hasOrderBy = remoteItems.filter(h=>h.name === orderBy)[0];
    const hasFilter =  remoteItems.filter(h=>h.name === property)[0];
    if (!hasOrderBy) {
      orderBy = undefined;
      order = undefined;

      if (!hasOrderBy && !hasFilter)
        return;
    }

    if (remoteItems.length) {
      
      var params = {};
      remoteItems.forEach(item => {if(item.filterText) params[item.name] = item.filterText});
      if (this.props.onFilter) {
        this.props.onFilter({orderBy, order, ...params});
      }
      // this.props.call({orderBy, order, ...params});
    }
  }
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
// console.log('handleRequestSort');
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy }, ()=> this.serverFilter(property));
  };
  handleRequestFilter = (filterText, property) => {
// console.log(property);
    let {header} = this.state;

    var item = header.filter(h=>h.name === property)[0]
    item.filterText = filterText;

    header = [...header];

    // console.log(header);
    this.setState({header}, ()=> this.serverFilter(property));
    
  };

  handleSelectAllClick = event => {
    const {selectable} = this.props;

    if (event.target.checked) {
      if(parseInt(selectable) && selectable< this.props.data.length)
        return;

      this.setState(() => ({ selected: this.props.data.map(n => n) }));
      return;
    }
    this.setState({ selected: [] });
  };

  
  handleSelect = (e) => {
    if(this.props.selectable!==1) {
      return;
    }

    var id = parseInt(e.target.getAttribute('rowId') || e.target.parentNode.getAttribute('rowId') || e.target.parentNode.parentNode.getAttribute('rowId'));

    // alert('id'+id);
    var data = this.props.data.filter(n=>n.id === id)[0];
    this.props.onSelect && this.props.onSelect(id, data)
    
  }
  handleOk = () => {
    const values = this.state.selected;
    var ids = values.map(n=>n.id);
    this.props.onSelect && this.props.onSelect(ids, values);
  }

  /**
   * if the total select is limited we remove the first selected items
   */
  handleClick = (event, item) => {

    const {selectable} = this.props;
    let { selected } = this.state;
    const selectedIndex = selected.findIndex(s=>s.id === item.id);
    let newSelected = [];
    

    
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {

     
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    // if(parseInt(selectable) && newSelected.length > selectable)
    //   return;
    if(parseInt(selectable) && newSelected.length > selectable)
      newSelected = newSelected.slice(1)

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleDelete = () => {
    this.props.onDelete && this.props.onDelete(this.state.selected)
  };

  handleEdit  =(e) => {
    var rowId = e.target.getAttribute('rowId');
 

    this.props.onEdit && this.props.onEdit(rowId)

  }
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = o => this.state.selected.findIndex(s=>s.id===o.id) !== -1;

  cellValue (hi, row) {
    var value;
    if(!hi.format)  
      value = byPath(row,hi.name)

    if (hi.type === columnTypes.money){
      value= numToFa(currencyFormat(value));
    } 
    else if (hi.type === columnTypes.date){
      value= numToFa(getDateTitle(value));
    }
    else if (hi.type === columnTypes.dateTime){
      value= numToFa(getDateTitle(value)  +' ' + getTimeTitle(value));
    }
    else if (hi.type === columnTypes.time){
      value=  getTimeTitle(value);
    }
    else if(hi.format){
      value=hi.format(row);
    }
    else if(hi.fa!==false){
      value = numToFa(value);
    } 
  
    // value=hi.format?hi.format(row) : byPath(row,hi.name);
    if (hi.editLink && this.props.selectable !== 1) {
      return <Link component="button" rowId={row.id} onClick={this.handleEdit}>{value}</Link>
    }

    return value;
  }
  
  componentDidMount() {
    if( this.props.fixed !== false) {
      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
    }
  }
  
    
  resize() {
    // set the height of the table as the fixed hight of the content so it can be scrooled

    var wrapper = document.getElementsByClassName("table-wrapper")[0];
    if(wrapper && wrapper.style){
        wrapper.style.height = ((window.innerHeight-25) - wrapper.offsetTop)+"px"; 
    }
  }
  render() {
    const { classes , pagination, title, showHeader, onSelect} = this.props;
    let {data} = this.props;
    let { selectable } = this.props;
    selectable = !selectable?false : selectable;
    let {  order, orderBy, selected, rowsPerPage, page, header } = this.state;
    if(rowsPerPage> data.length)
    {
      rowsPerPage =data.length; 
    }
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
      
      // data = tableFilter(data, header);

      // data = tableSort(data, getSorting(order, orderBy))
      //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      const showCheckbox = selectable !== false && (selectable !==1);
      

    return (
      <div style={{whiteSpace: "nowrap"}}>
        {title&& <EnhancedTableToolbar 
          selected = {selected}
          handleOk={onSelect?this.handleOk:undefined}
          onDelete= {this.handleDelete}
          selectionToolbar = {this.props.selectionToolbar}
          toolbar = {this.props.toolbar}
          title={title} numSelected={selected.length} />
        }

        <div className={classes.tableWrapper + ' table-wrapper'}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            {showHeader!==false && <EnhancedTableHead
              classes = {classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              selectable={selectable}
              showCheckbox = {showCheckbox}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              onRequestFilter={this.handleRequestFilter}
              rowCount={data.length}
              
              header = {header}
            />
            }
            <TableBody>
              {data.map(n => {
                  const isSelected = this.isSelected(n);
                  return (
                    <TableRow
                      hover
                      rowId={n.id}
                      rowData={n}
                      onClick={this.handleSelect}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n}
                      selected={isSelected}
                      className={classNames(showCheckbox?'':classes.noClick)}
                    >
                      {showCheckbox&&
                        <TableCell  padding="checkbox"
                        style={{
                          borderLeft: '1px dotted #80808030',}}>
                        <Checkbox onClick={event => this.handleClick(event, n)} checked={isSelected} />
                      </TableCell>
                      }
                      {header.map((hi , i)=> <TableCell 
                        rowId={n.id}  /*numeric*/ 
                        rowData={n}
                        style={{
                          borderLeft: '1px dotted #80808030',
                          paddingRight:"30px"}}>{this.cellValue(hi, n)}</TableCell>)}
                      
                      
                      {/* <TableCell numeric style={{paddingRight:"30px"}}>hello</TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {!(pagination===false)&&<TablePagination
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />}
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EnhancedTable);
