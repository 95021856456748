import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '../../../../../Component/Divider'
import { Name, NameView, validate as validateName } from './Name'
import { RecurrentType, RecurrentTypeView, validate as validateRecurrentType } from './RecurrentType'
import { CalenderType, CalenderTypeView, validate as validateCalenderType } from './CalenderType'
import { Day, DayView, validate as validateDay } from './Day'
import { Month, MonthView, validate as validateMonth } from './Month'
import { ReminderMessageEnabled, ReminderMessageEnabledView, validate as validateReminderMessageEnabled } from './ReminderMessageEnabled'
import { ReminderMessage, ReminderMessageView, validate as validateReminderMessage } from './ReminderMessage'
import { DaysBeforeEvent, DaysBeforeEventView, validate as validateDaysBeforeEvent } from './DaysBeforeEvent'
import { DueMessageEnabled, DueMessageEnabledView, validate as validateDueMessageEnabled } from './DueMessageEnabled'
import { DueMessage, DueMessageView } from './DueMessage'
import { OverdueMessageEnabled, OverdueMessageEnabledView, validate as validateOverdueMessageEnabled } from './OverdueMessageEnabled'
import { OverdueMessage, OverdueMessageView } from './OverdueMessage'
import { DaysAfterEvent, DaysAfterEventView } from './DaysAfterEvent'
import { ReminderAt, ReminderAtView } from './ReminderAt'

export const validators={
    validateName, validateRecurrentType, validateCalenderType, validateDay, validateMonth, validateReminderMessageEnabled, validateReminderMessage, validateDaysBeforeEvent, validateDueMessageEnabled, validateOverdueMessageEnabled, 
};

export const validate =(values, errors, t) => {

    for (const [, func] of Object.entries(validators)) {
        func(values, errors, t);
      }
}

export const FormFields = (props) =>
{
    const { values} = props;
    return <>
<Grid container spacing={2} style={{padding:25, paddingTop:0}}>

        <Name />
        <RecurrentType />
        { (values.recurrentType === 0 || values.recurrentType === 2  || values.recurrentType === 4)&& <CalenderType /> } 
        { values.recurrentType === 0&& <Day /> } 
        { values.recurrentType === 0&& <Month /> } 
        <ReminderMessageEnabled />
        { values.reminderMessageEnabled&& <ReminderMessage /> } 
        { values.reminderMessageEnabled&& <DaysBeforeEvent /> } 
        <Divider />
        <DueMessageEnabled />
        { values.dueMessageEnabled&& <DueMessage /> } 
        <Divider />
        <OverdueMessageEnabled />
        { values.overdueMessageEnabled&& <OverdueMessage /> } 
        { values.overdueMessageEnabled&& <DaysAfterEvent /> } 
        <Divider />
        { (values.reminderMessageEnabled || values.overdueMessageEnabled || values.dueMessageEnabled)&& <ReminderAt />}
</Grid>
</>
}

export const EditableViewFields = (props) =>
{
    const {values} = props;
return <>

<NameView {...props} />
    <RecurrentTypeView {...props} />
    { (values.recurrentType === 0 || values.recurrentType === 2  || values.recurrentType === 4)&& <CalenderTypeView {...props} /> } 
    { values.recurrentType === 0&& <DayView {...props} /> } 
    { values.recurrentType === 0&& <MonthView {...props} /> } 
    <Divider view />
    <ReminderMessageEnabledView {...props} />
    { values.reminderMessageEnabled&& <ReminderMessageView {...props} /> } 
    { values.reminderMessageEnabled&& <DaysBeforeEventView {...props} /> } 
    <Divider view />
    <DueMessageEnabledView {...props} />
    { values.dueMessageEnabled&& <DueMessageView {...props} /> } 
    <Divider view />
    <OverdueMessageEnabledView {...props} />
    { values.overdueMessageEnabled&& <OverdueMessageView {...props} /> } 
    { values.overdueMessageEnabled&& <DaysAfterEventView {...props} /> } 
    <Divider view />
    { (values.reminderMessageEnabled || values.overdueMessageEnabled || values.dueMessageEnabled)&& <ReminderAtView {...props} /> } 


</>

}

