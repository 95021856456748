import React, { PureComponent } from 'react'
import { Route, Switch } from "react-router-dom";
import User from './User'
import Home from './Home'
import Role from '../Admin/Role'
import File from '../Admin/File'
import MimeType from '../Admin/MimeType'
import Setting from '../Admin/Setting'
import UpdateConfigIcon from "@material-ui/icons/PermDataSetting";

import Region from '../Admin/Region'
import ServerProvider from '../Admin/ServerProvider'
import ConnectionConfig from '../Admin/ConnectionConfig'

import City from '../Admin/City'
import Province from '../Admin/Province'
import Occasion from '../Admin/Occasion'
import Island from '../Admin/Island'
import Server from '../Admin/Server'
import AvailableBundle from '../Admin/AvailableBundle'

import SampleData from './SampleData/Create'
import { hasRole } from '../../modules/Auth'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CastConnectedIcon from '@material-ui/icons/CastConnected';
import HelpIcon from '@material-ui/icons/Help';
import EventIcon from '@material-ui/icons/Event';
import SettingsPhoneIcon from '@material-ui/icons/SettingsPhone';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PinDropIcon from '@material-ui/icons/PinDrop';

import IslandsIcon from '@material-ui/icons/Widgets';
import ServersIcon from '@material-ui/icons/LocationOn';
import BundleIcon from '@material-ui/icons/Layers';

import BusinessIcon from '@material-ui/icons/Business';
import SettingsIcon from '@material-ui/icons/Settings';

const drawerWidth = 300;
const styles = theme => ({
 
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  root: {
    display: 'flex',
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    //marginTop:24,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    //marginTop:24,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 7 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    //marginTop:24,
    //marginRight:5
    padding: theme.spacing.unit * 3,
  },

});

class ManagePanel extends PureComponent {

  state = {
    left: false,
    openSettingMenu: false
  }

  gotoPage = (path) => () => {
    const { match, history } = this.props
    history.push(`${match.path}/${path}`);
  }

  toggleDrawer = (side, open) => () => {
    const {theme} = this.props;
    theme.sideOpen(open);
    this.setState({
      [side]: open,
      isSmall: false

    })
  }

  toggleMenu = (name) => () => {
    this.setState({
      [name]: !this.state[name],
      isSmall: false
    })
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    let isSmall = (window.innerWidth <= 760);
    if(this.state.isSmall !== isSmall)  
      this.setState({isSmall:isSmall});
  }

  render() {
    const { match, classes, theme } = this.props
    const { isSmall } = this.state
    const { gotoPage, toggleDrawer } = this
    let {left} = this.state;
    left = left && !isSmall;

    return (
      <div className={classes.root}>
        <CssBaseline />

        <AppBar position="fixed" className={classNames(classes.appBar, {
          [classes.appBarShift]: left,
        })}>
          <Toolbar disableGutters={!left}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={toggleDrawer('left', true)}
              className={classNames(classes.menuButton, {
                [classes.hide]: left,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              پنل فرا مدیریت
            </Typography>
          
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: left,
            [classes.drawerClose]: !left,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: left,
              [classes.drawerClose]: !left,
            }),
          }}
          open={left}
          onClose={toggleDrawer('left', false)}
        >
          <div className={classes.toolbar}> 
            <IconButton onClick={toggleDrawer('left', false)}>
              {theme.direction === 'ltr' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
            
          </div>
          <Divider />
          <List >


<ListItem button key={'داشبود'} onClick={gotoPage('dashboard')}>
              <ListItemIcon><DashboardIcon /></ListItemIcon>
              <ListItemText
                className={classNames({
                  [classes.hide]: !left,
                })}
                primary={'داشبود'} />

            </ListItem>
            {hasRole('Administrator')&&

             

<ListItem button key={'نماینده ها'} onClick={gotoPage('user?allAdmins')}>
                <ListItemIcon><CastConnectedIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'نماینده ها'} />

            </ListItem>
            
 }
            <ListItem button key={'جزیره ها'} onClick={gotoPage('island')}>
                <ListItemIcon><IslandsIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'جزیره ها'} />

            </ListItem>

            
       
            <ListItem button key={'سرور ها'} onClick={gotoPage('server')}>
                <ListItemIcon><ServersIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'سرور ها'} />

            </ListItem>
            <ListItem button key={'بسته‌ها'} onClick={gotoPage('availableBundle')}>
                <ListItemIcon><BundleIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'بسته‌ها'} />

            </ListItem>

{/* <ListItem button key={'موضوع‌های نماینده ی'} onClick={gotoPage('donationSubject')}>
                <ListItemIcon><BookmarksIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'موضوع‌های نماینده ی'} />

            </ListItem> */}



            {/* <ListItem button key={'موضوع تماس'} onClick={gotoPage('callReason')}>
                <ListItemIcon><SettingsPhoneIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'موضوع تماس'} />

            </ListItem> */}

          <ListItem button key={'منطقه ها'} onClick={gotoPage('region')}>
                <ListItemIcon><LocationCityIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'منطقه ها'} />
            </ListItem>



            <ListItem button key={'Connection Configs'} onClick={gotoPage('connectionConfig')}>
                <ListItemIcon><UpdateConfigIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'Connection Configs'} />
            </ListItem>

            <ListItem button key={'پروایدر ها'} onClick={gotoPage('serverProvider')}>
                <ListItemIcon><BusinessIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'پروایدر ها'} />
            </ListItem>
            <ListItem button key={'دوره‌ها و مناسبت‌ها '} onClick={gotoPage('occasion')}>
                <ListItemIcon><EventIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'دوره‌ها و مناسبت‌ها '} />

            </ListItem>

            <ListItem button key={'تنظیمات'} onClick={gotoPage('setting')}>
                <ListItemIcon><SettingsIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'تنظیمات'} />

            </ListItem>

{/*             
            <ListItem button key={'استان‌ها'} onClick={gotoPage('province')}>
                <ListItemIcon><LocationCityIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'استان‌ها'} />

            </ListItem>
            <ListItem button key={'شهرها'} onClick={gotoPage('city')}>
                <ListItemIcon><PinDropIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'شهرها'} />

            </ListItem> */}

{/* 
            <ListItem button key={'نقش‌ها'} onClick={gotoPage('role')}>
                <ListItemIcon><FaceIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'نقش‌ها'} />

            </ListItem>
            <ListItem button key={'فایل‌ها'} onClick={gotoPage('file')}>
                <ListItemIcon><PermMediaIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'فایل‌ها'} />

            </ListItem>
            <ListItem button key={'ساختارهای فایل'} onClick={gotoPage('mimeType')}>
                <ListItemIcon><ChevronLeftIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'ساختارهای فایل'} />

            </ListItem>
            <ListItem button key={'تنظیمات'} onClick={gotoPage('setting')}>
                <ListItemIcon><SettingsIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'تنظیمات'} />

            </ListItem>


            <ListItem button key={'شهرها'} onClick={gotoPage('city')}>
                <ListItemIcon><LocationCityIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'شهرها'} />

            </ListItem>
            <ListItem button key={'استان‌ها'} onClick={gotoPage('province')}>
                <ListItemIcon><LocationCityIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'استان‌ها'} />

            </ListItem>
            <ListItem button key={'داده‌های نمونه'} onClick={gotoPage('sampleData')}>
                <ListItemIcon><SettingsIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'داده‌های نمونه'} />

            </ListItem> */}

            <ListItem button key={'راهنما'} onClick={()=>window.location=`http://${window.location.hostname}/docs/`}>
                <ListItemIcon><HelpIcon /></ListItemIcon>
                <ListItemText className={classNames({
                              [classes.hide]: !left,
                              })}
                              primary={'راهنما'} />

            </ListItem>

          </List>
        </Drawer>

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch >

           <Route path={`${match.path}/island`} component={ Island } />
            <Route path={`${match.path}/server`} component={ Server } />
            <Route path={`${match.path}/availableBundle`} component={ AvailableBundle } />


            <Route path={`${match.path}/user`} component={User} />
            <Route path={`${match.path}/occasion`} component={ Occasion } />

            <Route path={`${match.path}/serverProvider`} component={ServerProvider} />
            <Route path={`${match.path}/connectionConfig`} component={ConnectionConfig} />

            <Route path={`${match.path}/region`} component={Region} />

            <Route path={`${match.path}/province`} component={Province} />
            <Route path={`${match.path}/setting`} component={Setting} />
            <Route path={`${match.path}/role`} component={ Role } />
            <Route path={`${match.path}/file`} component={ File } />
            <Route path={`${match.path}/mimeType`} component={ MimeType } />
            <Route path={`${match.path}/setting`} component={ Setting } />
            <Route path={`${match.path}/city`} component={ City } />
            <Route path={`${match.path}/province`} component={ Province } />
            <Route path={`${match.path}/sampleData`} component={ SampleData } />
            <Route path={`${match.path}/`} component={Home} />
          </Switch>
        </main>
      </div>
    )
  }
}
export default withStyles(styles, { withTheme: true })(ManagePanel);
