import React, { PureComponent } from 'react'
import widthForm from '../../../../Contexts/withForm';
import { withTranslation } from 'react-i18next';
import {FormFields} from '../Components/Fields';
import FormActionBar from '../../../../Component/FormActionBar';
import RouterBreadcrumbs from '../../../../Component/RouterBreadcrumbs'

class CreateForm extends PureComponent{
    render() {
        const {values, reset, t} = this.props;
        return <form autocomplete="off">
    <RouterBreadcrumbs title={ t("new")  } />
    <FormFields values={values} reset={reset} t={t} />
    <FormActionBar {...this.props} submitLabel={t('register')} />
</form>
    }
}


export default withTranslation('occasion')(widthForm(CreateForm));