import React from 'react';
import { useTranslation, i18n } from 'react-i18next';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { makeStyles } from '@material-ui/core/styles';
import {ReactComponent as FlagVN} from '../icons/langs/vn.svg';
import {ReactComponent as FlagUK} from '../icons/langs/uk.svg';
import {ReactComponent as FlagIR} from '../icons/langs/ir.svg';
import {ReactComponent as FlagCN} from '../icons/langs/cn.svg';
import {ReactComponent as FlagKR} from '../icons/langs/kr.svg';
import {ReactComponent as FlagRU} from '../icons/langs/ru.svg';

import Backdrop from '@material-ui/core/Backdrop';


const useStyles = makeStyles((theme) => ({
  action: {
  
    '& .MuiFab-root' : {
        backgroundColor: 'white',
        fontSize: '24px'

    },
    '& .MuiFab-root:hover': {
      
        backgroundColor: 'gray',
    }
    

  },
  icon: {
    // backgroundColor: 'white',
    borderRadius: '50%',
    // padding: '0px',
    marginTop:10,
    marginBottom:-8,
    fontSize: '24px'
  
  },
  icon2:{
    position:'absolute', left:0,top:5, marginTop:0,width:'100%'
  },
  root:{
     zIndex: 10000,
    "white-space": "nowrap",
    
     position: 'fixed', bottom: 10, left: 10 
  }
}));

const LanguageSwitcher = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const languages = [
    { code: 'ko', label: '한국어', flag: <FlagKR className={classes.icon2}/> },
    { code: 'vi', label: 'Tiếng Việt', flag: <FlagVN className={classes.icon2}/> },
    { code: 'fa', label: 'فارسی', flag: <FlagIR className={classes.icon2}/> },
    { code: 'ru', label: 'Русский', flag: <FlagRU className={classes.icon2}/> },
    { code: 'cn', label: '中文', flag: <FlagCN className={classes.icon2}/> },
    { code: 'en', label: 'English', flag: <FlagUK className={classes.icon2}/> },
  ];

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    window.location.reload();
  };

  const currentLanguage = languages.find(lang => lang.code === i18n.language);
  const currentFlag = currentLanguage ? currentLanguage.flag : <FlagUK className={classes.icon2}/>;

  return (
    <div className={classes.root}>
      <Backdrop open={open} />

      <SpeedDial
        className={classes.action}
        ariaLabel="Language SpeedDial"
        // icon={<SpeedDialIcon icon={<span className={classes.icon}>{currentFlag}</span>} />}
        icon={<div style={{top:8, opacity: 0.7 }} className={classes.icon2 }>{currentFlag}</div>} 

        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
      >
        {languages.map((lang) => (
          <SpeedDialAction

            key={lang.code}
            className={classes.action}
            icon={<div style={{width:24  }}>{lang.flag}</div>} // Inline style as an alternative
            tooltipTitle={lang.label}
            tooltipOpen={true} // Always show the tooltip
            tooltipPlacement="right"

            onClick={() => changeLanguage(lang.code)}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default LanguageSwitcher;
