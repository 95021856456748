import React, { PureComponent } from 'react'
import { setApiErrorHandler } from './ErrorHandling'
import Snackbar from '@material-ui/core/Snackbar'
import { addListener } from './EventEmitter'
import LinearProgressBar from '../Component/LinearProgress'
import {t} from '../i18n';

export default class AjaxHandler extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            showMessage: false,
            message: ''
        }
        
    }

    componentDidMount(){
        setApiErrorHandler(this.errorHandler)
        this.apiRequestHandler = addListener('API_REQUEST', this.handleOnApiRequest)
        this.apiRequestCompleteHandler = addListener('API_REQUEST_SUCCESS', this.handleOnApiRequestComplete)
        this.apiRequestFailureHandler = addListener('API_REQUEST_FAILURE', this.handleOnApiRequestComplete)
    }

    componentWillUnmount() {
        if (this.apiRequestHandler.remove) this.apiRequestHandler.remove()
        if (this.apiRequestCompleteHandler.remove) this.apiRequestCompleteHandler.remove()
        if (this.apiRequestFailureHandler.remove) this.apiRequestFailureHandler.remove()
        setApiErrorHandler(null)
      }

    handleOnApiRequestComplete = () => {
        this.setState({busyUI: false})
    }

    handleOnApiRequest = () => {
        // if (process.env.NODE_ENV !== 'production'){
        //     console.log('api request...')
        // }
        this.setState({ busyUI: true })
    }

    errorHandler = (exception, customMessage) => {
        if (process.env.NODE_ENV !== 'production'){
            console.log(exception.message)
            console.log(exception)
            console.log(customMessage)
            console.log(exception.response)
        }

        // const message = process.env.NODE_ENV !== 'production' ? exception.message : customMessage
        var message = customMessage 
        if(exception.response.data) {
            message += " - " + t(exception.response.data);
        }
        this.setState({ showMessage: true, message:  message })

    }

    hideMessage = () => this.setState({ showMessage: false })

    render() {
        const { message, showMessage, busyUI } = this.state
        return (
            <div>
                {busyUI && <LinearProgressBar  />}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={this.hideMessage}
                    open={showMessage}
                    message={message}
                    autoHideDuration={5000}
                />
            </div>
        )
    }
}
