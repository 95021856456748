import React from 'react'
import Grid from '@material-ui/core/Grid'
import {VerticalTabs, Tab} from '../../../../Component/VerticalTabs'
import Divider from '../../../../Component/Divider'

import { useTranslation } from 'react-i18next';
import { Box, FormGroup, Typography } from '@material-ui/core'
import { IsEnabled, IsEnabledView, validate as validateIsEnabled } from './Fields/IsEnabled'

import { NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';
import { Subscription, SubscriptionView, validate as validateSubscription } from './Fields/Subscription'
import { Name, NameView, validate as validateName } from './Fields/Name'
import { Logo, LogoView } from './Fields/Logo'
import { SupportId, SupportIdView } from './Fields/SupportId'
import { BotToken, BotTokenView } from './Fields/BotToken'
import { IsUpPaymentEnabled, IsUpPaymentEnabledView, validate as validateIsUpPaymentEnabled } from './Fields/IsUpPaymentEnabled'
import { UpPhones, UpPhonesView } from './Fields/UpPhones'
import { IsCardPaymentEnabled, IsCardPaymentEnabledView, validate as validateIsCardPaymentEnabled } from './Fields/IsCardPaymentEnabled'
import { CardNumbers, CardNumbersView } from './Fields/CardNumbers'
import { Prices, PricesView } from './Fields/Prices'
import { ResellerPricePerGb, ResellerPricePerGbView } from './Fields/ResellerPricePerGb'
import { UnlimitedPricePerMonth, UnlimitedPricePerMonthView } from './Fields/UnlimitedPricePerMonth'
import { MonthlyDiscountRate, MonthlyDiscountRateView, validate as validateMonthlyDiscountRate } from './Fields/MonthlyDiscountRate'
import { AdditionalDevicePriceRate, AdditionalDevicePriceRateView, validate as validateAdditionalDevicePriceRate } from './Fields/AdditionalDevicePriceRate'
import { makeStyles } from "@material-ui/core/styles";



  
const useStyles = makeStyles(() => ({
    title:{
      margin:0,marginTop:40,marginBottom:15,paddingLeft:20, fontWeight:450, fontSize:"1.25rem", color:"rgba(0, 0, 0, 0.8)"
    }
  }));

export const validators={
    validateSubscription, validateName, validateIsUpPaymentEnabled, validateIsCardPaymentEnabled, 
};


export const validate =(values, errors, t) => {

    for (const [name, func] of Object.entries(validators)) {
        func(values, errors, t);
      }

}
export const FormFields = (props) =>
{

    const { values } = props;
    const { t } = useTranslation('resellerSetting');
    return <>
<Grid container spacing={2} style={{padding:25, paddingTop:0}}>

        {/* <Subscription /> */}
        <Name />
        <Logo />
        <Divider />
        <SupportId />
        <BotToken />
        <Divider view/>
        <IsUpPaymentEnabled />
        <UpPhones />
        <IsCardPaymentEnabled />
        <CardNumbers />
        <Divider view/>
        <Prices />
        <ResellerPricePerGb />
        <UnlimitedPricePerMonth />
</Grid>
</>
}

export const EditableViewFields = (props) =>
{
    const {values, onRefresh} = props;
    const { t } = useTranslation('resellerSetting');
    const classes = useStyles();

return <>

    <Typography variant="h6" className={classes.title}>{t('resellerSetting')}</Typography>  
    {/* <Typography variant="subtitle1" gutterBottom>
        subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
        blanditiis tenetur
      </Typography> */}
    {/* <SubscriptionView {...props} /> */}
    <NameView {...props} />
    {/* <LogoView {...props} /> */}
    <Divider view {...props} />
    <h6 className={classes.title}>{t('sections.telegram')}</h6>  

    <SupportIdView {...props} />
    <IsEnabledView {...props} />
    <BotTokenView {...props} /> 
    <Divider view {...props} />
    <h6 className={classes.title}>{t('sections.payment')}</h6>  

    <IsUpPaymentEnabledView {...props} />
    {props.values.isUpPaymentEnabled && <UpPhonesView {...props} /> }
    <Divider view {...props} />

    <IsCardPaymentEnabledView {...props} />
    {props.values.isCardPaymentEnabled && <CardNumbersView {...props} />}
    <Divider view {...props} />
    <h6 className={classes.title}>{t('sections.pricing')}</h6>  
    <PricesView {...props} />
    <MonthlyDiscountRateView {...props} />
    <AdditionalDevicePriceRateView {...props} />
    <UnlimitedPricePerMonthView {...props} />
    <ResellerPricePerGbView {...props} />





</>

}

