import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateUser, exists } from '../../api'
import TextInput from '../../../../../Component/TextInput'
import {isValidIranianNationalCode} from '../../../../../utils/validators'
import {userTitle} from '../../../../../domain/extensions/userExtensions'
import { Link } from 'react-router-dom';

export const NatId =  ({t=useTranslation('user').t}) =>
<>
<Grid item xs={12}>
    <FormControl fullWidth>
        <Field label={t('natId.title')}
               name={"natId"}
               component={TextInput}
               type="text" />
    </FormControl>
</Grid>
</>

export const validate = async (values, errors, t)=> {
    
    if(values.natId){
        if( !isValidIranianNationalCode(values.natId)){
            errors.natId = t('natId.invalid');
        } else {
            var found = await exists({excepId:values.id,natId:values.natId});
            if(found.id) errors.natId= <span>این کد ملی متعلق به <Link to={`/admin/user/${found.id}`}>  {userTitle(found)}  </Link> می‌باشد!</span>;
        }
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, natId }=values;
        return await updateUser({id, natId });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { natId } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('natId.title')}
                          headline={ natId }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ NatId } />
    }
}


export const NatIdView = withTranslation('user')(View);