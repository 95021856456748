import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme, fade } from '@material-ui/core/styles';
import {
  List, ListItem, ListItemIcon, ListItemText, IconButton,
  AppBar, Toolbar, Typography, Drawer, CssBaseline, Divider,
} from '@material-ui/core';
import {
  Menu as MenuIcon, 
  ChevronRight as ChevronRightIcon, 
  ChevronLeft as ChevronLeftIcon, 
  Search as SearchIcon,
  Help as HelpIcon, 
  WhatsApp as SupportIcon, 
  PeopleAlt as SubscriptionsIcon, 
  Info as MySubscriptionsIcon
} from '@material-ui/icons';
import classNames from 'classnames';
import { hasRole, getCurrentUser } from '../../modules/Auth';
import  User  from './User';
import {AdminHome, EmployeeHome } from './Home';
import Subscription from './Subscription'
import Bundle from './Bundle'
import BeshkanAutocomplete from './Subscription/Components/BeshkanAutocomplete';
import AppSpeedDial from './Components/AppSpeedDial';
import LanguageSwitcher from '../../Component/LanguageSwitcher';
import {getDashboardData} from './api';
import ResellerSetting from './ResellerSetting'
import SettingsIcon from '@material-ui/icons/Settings';
import HistoryIcon from '@material-ui/icons/History';

import TrafficTransaction from './TrafficTransaction'
import DashboardIcon from '@material-ui/icons/Dashboard';


const drawerWidth = 300;
const useStyles =makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  root: {
    display: 'flex',
    
  },
  
  input:{
    marginLeft: theme.spacing(5),
    flex: 1,
    maxWidth:200,
    minWidth:180, 

  },
  iconButton: {
    color:'white',
    padding: 10,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    //marginTop:24,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    //marginTop:24,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 7 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    //marginTop:24,
    //marginRight:5
    padding: theme.spacing.unit * 3,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 250,
      },
    },
    paddingRight:50,
    color:'white',
  },
}));





const ManagePanel = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation('panel');
  const [left, setLeft] = useState(true);
  const [isSmall, setIsSmall] = useState(false);

  const [dashboardData, setDashboardData] = useState(null);


  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = (window.innerWidth <= 760);
      setIsSmall(isSmallScreen);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };


  }, []);

  // useEffect(() => {
  //     const fetchData = async () => {

 
  //     const response = await getDashboardData();

  //     setDashboardData(response);

  //    };

  //   fetchData();

  // }, []);



  const gotoPage = (path) => () => {
    history.push(`${match.path}/${path}`);
  }

  const toggleDrawer = useCallback((side, open) => {
    return () => {
      setLeft(side === 'left' ? open : left);
      setIsSmall(false);
    };
  }, [left]);


  const open = left && !isSmall;

  const Home = hasRole('Administrator,SuperAdmin') ? AdminHome : EmployeeHome;
  return (
    <div className={classes.root}>
        <CssBaseline />

<AppBar position="fixed" className={classNames(classes.appBar, {
        [classes.appBarShift]: open,
        })}>
    <Toolbar disableGutters={!open}>
        <IconButton color="inherit"
                    aria-label="Open drawer"
                    onClick={toggleDrawer('left', true)}
                    className={classNames(classes.menuButton, {
                    [classes.hide]: open,
                    })}>
            <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" noWrap>
            iPanel
        </Typography>
        <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>

        <BeshkanAutocomplete classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          
          />
      </div>
    </Toolbar>
</AppBar>

<Drawer variant="permanent"
        className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
        })}
        classes={{
        paper: classNames({
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
        }),
        }}
        open={open}
        onClose={toggleDrawer('left', false)}>
    <div className={classes.toolbar}>
        <IconButton onClick={toggleDrawer('left', false)}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>

    </div>
    
    <ListItem button key={'dashboard'} onClick={gotoPage('')}>
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText className={classNames({
                          [classes.hide]: !open,
                          })}
                          primary={t('dashboard')} />

        </ListItem>    
    <Divider />
    <List>

    <ListItem button key={'my-subscription'} onClick={gotoPage('subscription/'+getCurrentUser().id)}>
            <ListItemIcon><MySubscriptionsIcon /></ListItemIcon>
            <ListItemText className={classNames({
                          [classes.hide]: !open,
                          })}
                          primary={t('my-subscription')} />

        </ListItem>    

       <ListItem button key={'my-subscribers'} onClick={gotoPage('subscription')}>
            <ListItemIcon><SubscriptionsIcon /></ListItemIcon>
            <ListItemText className={classNames({
                          [classes.hide]: !open,
                          })}
                          primary={t('my-subscribers')} />

        </ListItem>
        <Divider />

        <ListItem button key={'transaction-history'} onClick={gotoPage('trafficTransaction')}>
            <ListItemIcon><HistoryIcon /></ListItemIcon>
            <ListItemText className={classNames({
                          [classes.hide]: !open,
                          })}
                          primary={t('trafficTransaction:trafficTransactions')} />

        </ListItem>

        
        <ListItem button key={'reseller-settings'} onClick={gotoPage('resellerSetting/edit')}>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText className={classNames({
                          [classes.hide]: !open,
                          })}
                          primary={t('resellerSetting:resellerSettings')} />

        </ListItem>
{/* <Divider />

        <ListItem button key={'support'} onClick={()=>window.location=`http://${window.location.hostname}/support/`}>
            <ListItemIcon><SupportIcon /></ListItemIcon>
            <ListItemText className={classNames({
                          [classes.hide]: !open,
                          })}
                          primary={t('support')} />

        </ListItem>
        <ListItem button key={'help'} onClick={()=>window.location=`http://${window.location.hostname}/docs/`}>
            <ListItemIcon><HelpIcon /></ListItemIcon>
            <ListItemText className={classNames({
                          [classes.hide]: !open,
                          })}
                          primary={t('help')} />

        </ListItem>
    */}
 </List>

 <AppSpeedDial/>
 <LanguageSwitcher/>
</Drawer>

<main className={classes.content}>
    <div className={classes.toolbar} />
    <Switch>        
        <Route path={`${match.path}/user`} component={ User } />
        <Route path={`${match.path}/subscription`}    component={ Subscription } />
        <Route path={`${match.path}/resellerSetting`}    component={ ResellerSetting } />
        <Route path={`${match.path}/trafficTransaction`}    component={ TrafficTransaction } />

        <Route path={`${match.path}/bundle`} component={ Bundle } />
        <Route path={`${match.path}/`} component={Home} />
    </Switch>
</main>

    </div>
  );
};

export default ManagePanel;