import React, { PureComponent } from 'react'
import {validate as validateProvince } from '../Components/Fields'
import CreateForm from './CreateForm';
import { insertProvince } from '../api'
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';

 class CreatePage extends PureComponent {

    onSubmit = async (values) => {
        const { t, enqueueSnackbar  } = this.props;
        var res = await insertProvince(values)
        if(!res) {
            return false;
        }
        
        // this.props.history.push('/admin/province')
        this.props.history.goBack();


        if (res) {
            enqueueSnackbar( t('saved'), { variant:'success' });
        }
    }

    validate = ( values ) => {
        const {t}= this.props;
        const errors = {}

        validateProvince(values, errors, t);

        return errors
    }

    render() {
        const { onCancel, t } = this.props
        return <>
            <CreateForm submitLabel={t('register')}
                onCancel={onCancel}
                ref={this.form}
                validate={this.validate}
                onSubmit={this.onSubmit} />
        </>
    }
}

export default withSnackbar(withTranslation('province')(CreatePage));