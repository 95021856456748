import React, { PureComponent } from 'react'
import { getFile } from '../api'
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import EditableViewForm from './EditableViewForm';

 class EditPage extends PureComponent {

    state = {
        file: {},
        fileId: this.props.match.params.id
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData(){
        var file = await getFile(this.state.fileId)

        if (!file) return;

        this.setState({ file })
    }

    refresh = async ()=>{
        await this.loadData();
    }

    render() {
        return <EditableViewForm onRefresh={this.refresh}
                  values={this.state.file } />
    }
}


export default withSnackbar(withTranslation('file')(EditPage));