

import { getItem, removeItem, setItem } from '../utils/Storage'
import decode from 'jwt-decode'
import { tokenById, signIn as signInApi, signId as signIdApi, signByOtp as signByOtpApi } from './AuthApi';
import {UserManager} from 'oidc-client'
import config from '../utils/config'
import {t} from '../i18n';

const USER_TOKEN_KEY = "auth-token"
const USER_PROFILE_KEY = "auth-prof"
const USER_SWITCHED_KEY = "auth-switched"
const USER_RETURN_URL_KEY = "auth-return-url"
let _token = null,
    _profile = null,
    _claims = null

// const oidcMode = true;

var identityConfig = {
    authority: config.authorityUrl,
    client_id: "spa",
    redirect_uri: `${config.spaClientUrl}/#/auth-callback?`,
    response_type: "id_token token",
    
    scope:"openid roles administration operation email",//api1
    post_logout_redirect_uri : `${config.spaClientUrl}/#/auth-loggedout`,
   
};
console.log(identityConfig);

let mgr = null;

const getUserManager = () => {
    if (mgr === null) {
        mgr = new UserManager(identityConfig);
    }
    return mgr;
};

export const getToken = () => {
    if (_token) return _token

    _token = getItem(USER_TOKEN_KEY)

    // console.log('getToken');
    // console.log(USER_TOKEN_KEY);
    // console.log(_token);
    if(_token == 'undefined' || !_token)
        return null;
    return _token
}

export const getCurrentUser = () => {
    var p = getProfile();
        
    return {
        id: parseInt(p.sub),
        firstName : p.firstName || p.given_name,
        lastName : p.lastName || p.family_name,
        given_name : p.family_name || p.firstName,
        family_name : p.given_name || p.lastName,  
    } 
}

export const getProfile = () => {
    if (_profile !== null) return _profile

    var json = getItem(USER_PROFILE_KEY);
    if(json)
        _profile = JSON.parse(json)
    
        
    return _profile
}
export const hasRole = (roleName) => {

    const isLoggedIn = loggedIn();
    const _claims = isLoggedIn && getClaims();
    const roles = _claims['role']; //http://schemas.microsoft.com/ws/2008/06/identity/claims/
    
    if(!roles) return false;

    var names = roleName.split(/[ ,]+/);

    for(var i in names){
        if(roles.indexOf(names[i]) > -1)
            return true;  
    }
    // console.log(roleName + ':' + (roles && roles.indexOf(roleName) > -1))
    return false;
}

export const signIn = async (username, password) => {

    const response = await signInApi( username, password)
    if (response){
        setProfile(response.user)
        setToken(response.token)
        return true
    }
    return false

    
}

export const signId = async (phone) => {

    const response = await signIdApi( phone)
    alert('signId');
    if (response){
        return response.hasPassword;
        
    }
    return null;

    
}
export const signByOtp = async (phone, code) => {

    const response = await signByOtpApi( phone, code)
    if (response){
        if(response.token) {
            setProfile(response.user)
            setToken(response.token)
        }
        // responses for waiting for attempts or incorrect password  
        return response
    }
    return false

    
}
export const signByOidc = async (idToken) => {
    const localMgr = new UserManager({
        response_mode: 'query',
    });

    await localMgr.signinRedirectCallback();
    var user = await localMgr.getUser();
    if (user) {
        console.log(user);
        console.log("User logged in");
        // console.log(user.profile);
    }
    else {
        console.log("User not logged in");
    }

    if(idToken) {
        setProfile(user.profile)
        setToken(user.access_token);
        return true;
    }

    return false

    
}
export const redirectToOidcLogin = async (returnUrl) => {
    setReturnUrl(returnUrl);
    const mgr = getUserManager();
    await mgr.signinRedirect();  
}
export const getCurrentUserId = () => {
    var id = getClaims()["Id"];
    if(id)
    return parseInt(id);
}


export const getClaims = () => {
    // Using jwt-decode npm package to decode the token
    if (_claims !== null) return _claims
    
    _claims = decode(getToken())
    return _claims
}

export const signOut = async () => {

    removeItem(USER_TOKEN_KEY);

    // maybe its a bug! because we have to define end_session_endpoint manually and if we set it in initial config we can't sign in into the system anymore
    let signOutIdentityConfig = {...identityConfig,
        metadata : {
            end_session_endpoint: `${config.authorityUrl}/connect/endsession`,
            // post_logout_redirect_uri : config.spaClientUrl
        }
    }
    mgr = new UserManager(signOutIdentityConfig);
    await mgr.signoutRedirect();
    _token = null
    _profile = null
    _claims = null

}

export const loggedIn = () => {
    // Checks if there is a saved token and it's still valid
    const token = getToken() // GEtting token from localstorage
    var expired = isTokenExpired(token)
    var hasToken = !!token && token !== 'undefined';
    return hasToken && !expired// handwaiving here
}

const isTokenExpired = (token) => {
    try {
        const decoded = decode(token);
        if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
            console.log('token expired!')
            return true
        }
        else
            return false
    }
    catch (err) {
        return false
    }
}

const setToken = (token) => {
    // Saves user token to localStorage
    _token = token
    setItem(USER_TOKEN_KEY, token)
}

export const setProfile = (profile) => {
    // Saves user token to localStorage
    _profile = profile
    setItem(USER_PROFILE_KEY, JSON.stringify(profile))
}

const setSwitched = (switchedAuth) => {
    // Saves user token to localStorage
    setItem(USER_SWITCHED_KEY, JSON.stringify(switchedAuth))
  }
  const getSwitched = () => {
    var json = getItem(USER_SWITCHED_KEY);
    if(json)
        return JSON.parse(json);
  }


export const getCurrentArea= function (path) {
    const currentArea = path.split('/').filter(Boolean)[0];
    return currentArea;
  }
  
  export const panels = [{
      title: 'پنل فرا مدیریت',
      path:'superadmin',
      roles : 'SuperAdmin'
    },{
      title: 'پنل گپ',
      path:'admin',
      roles : 'SuperAdmin,Administrator,Subscriber'
    }
    // {
    //   title:  'پنل نماینده ی من',
    //   path:'benefactor',
    //   roles : 'SuperAdmin,Administrator,Subscriber'
    // }
  ]
  export const getDefaultPanel= function ()
  {
    const def = panels.filter(p=> hasRole(p.roles))[0];
    
    return def || panels[2];
  }
  export const getPanelTitle= function (p)
  {
    if(p.path === 'admin')
      return p.title + ' ' + (getProfile().province? getProfile().province.name:'');
    
    return p.title;
  
  }
  


  export const switchUser = async function (userId)
  {
    console.log("switching user id : "+ userId);
    const response = await tokenById(userId);

    const switchedAuth= {
        token : getToken(),
        profile: getProfile()
    };

    if (response){
        setProfile(response.user);
        setToken(response.token);
        setSwitched(switchedAuth);

        window.location="/";

    }

  }

  export const returnUser = function ()
  {
    const switchedAuth = getSwitched()
    if(switchedAuth) {
        setToken(switchedAuth.token);
        setProfile(switchedAuth.profile);
        removeItem(USER_SWITCHED_KEY);

        window.location="/";
    }
  }

export const isSwitched = function () {
    return getItem(USER_SWITCHED_KEY);
}

export const getUserTitle = function (user) {
    user = user || {};
    const given_name = user.given_name  || user.firstName || '';
    const family_name = user.family_name  || user.lastName || '';

    if(!given_name && !family_name){
        return user.phone || user.email || t('common:noName')
    }
    return given_name + ' ' + family_name;
}



export const setReturnUrl = (returnUrl) => {
    // Saves user token to localStorage
    setItem(USER_RETURN_URL_KEY, returnUrl)
}

export const getReturnUrl = () => {
    var json = getItem(USER_RETURN_URL_KEY);
    // console.log(json)
    return json;
    // if (json)
    //     return JSON.parse(json);
}


export const getLoggedinUser = () => {

    var p = getProfile();
    console.log(p)


    return {id: parseInt(p.sub),
         given_name : p.firstName,
         family_name : p.lastName,    
         ...p
    };
}

export const getLoggedinUserTitle = () => {
    return getUserTitle(getLoggedinUser());
}
