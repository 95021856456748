import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateResellerSetting } from '../../api'
import {hasValue} from '../../../../../utils/string'

import {ListDialog as SubscriptionsDialog} from "../../../../Admin/Subscription/List"
import Selector from '../../../../../Component/Selector'


export const Subscription =  ({values={ subscription:{} }}) =>{
    const { t } = useTranslation('resellerSetting');

    return <>

    <Grid sm={ 12 } xs={12}  item  >
        <FormControl fullWidth >
            <Field
                component={Selector}
                label={t('subscription.title')}
                name="subscriptionId,subscription"
                options = { SubscriptionsDialog}
                optionTitle= "customerName"
            />
        </FormControl>
    </Grid>
    </>

}


export const validate = (values, errors, t)=> {
    if (!hasValue(values.subscriptionId)) {
        errors.subscriptionId = t('subscription.required');
    }
    return errors;
}


export function SubscriptionView({ values, onRefresh }) {
    const { t } = useTranslation('resellerSetting');
    
    const update = async (values) =>{
        const {id,subscriptionId}=values;
        return await updateResellerSetting({id, subscriptionId});
    }

    const headline= (values) => {
        const { subscription = {} } = values;
        const headline = subscription?(subscription.customerName):'-';
        return headline;
    }

    const successMessage  = (values) => t('updated')

    return <ViewField
        successMessage={successMessage}
        title= { t('subscription.title')}
        headline  = { headline(values) }
        update ={update} 
        validate={validate}
        values={values}
        onRefresh={onRefresh}
        editComponent={ Subscription } />

}


