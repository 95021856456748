import React from 'react'
import Grid from '@material-ui/core/Grid'
import {VerticalTabs, Tab} from '../../../../Component/VerticalTabs'
import { useTranslation } from 'react-i18next';
import { Box, FormGroup, Divider } from '@material-ui/core'

import { LocationOn as LocationOnIcon, NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';
import { Region, RegionView, validate as validateRegion } from './Fields/Region'
import { ServerProvider, ServerProviderView, validate as validateServerProvider } from './Fields/ServerProvider'

import { Name, NameView, validate as validateName } from './Fields/Name'
import { Ip, IpView, validate as validateIp } from './Fields/Ip'
import { Sni, SniView  } from './Fields/Sni'

import { Domain, DomainView, validate as validateDomain } from './Fields/Domain'
import { HealthResult, HealthResultView } from './Fields/HealthResult'

import { Island, IslandView, validate as validateIsland } from './Fields/Island'
import { Enabled, EnabledView, validate as validateEnabled } from './Fields/Enabled'
import { YesterdayTraffic, YesterdayTrafficView, validate as validateYesterdayTraffic } from './Fields/YesterdayTraffic'
import { DailyAverageTraffic, DailyAverageTrafficView, validate as validateDailyAverageTraffic } from './Fields/DailyAverageTraffic'
import { Version, VersionView, validate as validateVersion } from './Fields/Version'
import { ServerQualityLevel, ServerQualityLevelView } from './Fields/ServerQualityLevel'
import { Leader, LeaderView } from './Fields/Leader'
import { Protocol, ProtocolView } from './Fields/Protocol'
import { NodeType, NodeTypeView } from './Fields/NodeType'


export const validators={
    validateRegion, validateServerProvider, validateIp, validateEnabled 
};
// , validateYesterdayTraffic, validateDailyAverageTraffic, validateVersion, validateIsland, validateName

export const validate =(values, errors, t) => {

    for (const [name, func] of Object.entries(validators)) {
        func(values, errors, t);
      }

}
export const FormFields = (props) =>
{
    const { values } = props;
    const { t } = useTranslation('server');
    return <>
<Grid container spacing={2} style={{padding:25, paddingTop:0}}>
        <Ip />
        <Leader />

        <Enabled />
        <Region />
        <ServerProvider />
        {/* <Name /> */}

        {/* <YesterdayTraffic /> */}

        {/* <DailyAverageTraffic /> */}
        {/* <Version /> */}
        <Domain />
        <Sni />
        <ServerQualityLevel />
        <NodeType />
        {/* <Island /> */}



</Grid>
</>
}

export const EditableViewFields = (props) =>
{
    const {values, onRefresh} = props;
    const { t } = useTranslation('server');

return <>
    <RegionView {...props} />
    <ServerProviderView {...props} />
    <NameView {...props} />
    <IpView {...props} />
    <SniView {...props}/>
    <DomainView {...props} />
    <IslandView {...props} />
    <EnabledView {...props} />
    <YesterdayTrafficView {...props} />
    <DailyAverageTrafficView {...props} />
    <VersionView {...props} />
    <ServerQualityLevelView {...props} />
    <LeaderView {...props} />
    <ProtocolView {...props} />
    <NodeTypeView {...props} />
    <HealthResultView {...props}/>

</>

}

