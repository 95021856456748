import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('identity');

export const insertRole = async (role) => {

    const resultEntity = await post(`role`,role,
    {
        errorMessage : t('common:insertError') + t('role:role')
    });

    if(!resultEntity) return null;

    return {...role, id:resultEntity.id};
}


export const updateRole = async (role) => {

    const result = await putFormData(`role/${ role.id }`,
        role,
        {
            errorMessage :  t('common:updateError') + t('role:role')
        });

    return result;
}

export const deleteRole = async (id) => {

    const result = await del(`role/${id}`,
        {
            errorMessage : t('common:deleteError') + t('role:role')
        });

    return result;
}

export const getRoles = async (params) => {

    const result = await get(`role`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('role:roles')

    });

    return result;
}



export const getRole = async (id) => {

    const result = await get(`role/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('role:role')
    });

    return result;
}



