import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button'
import { Grid} from '@material-ui/core'
import { withTranslation } from 'react-i18next';
import withForm from '../Contexts/withForm';
import { useTranslation } from 'react-i18next';
import { makeStyles, createStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => createStyles({

    root: {
        direction: "ltr"
      }
    
    }));


const FieldEditForm = (props) => {
    const { t } = useTranslation('common');
    // const form = useForm();  // This is an assumption based on the original HOC `withForm`
    const classes  = useStyles();

    const {
        title,
        values,
        reset,
        editComponent: EditComponent,
        submitting,
        handleSubmit,
        actions,
        cancelText,
        okText,
        onCancel,
        // ... otherProps if needed
    } = props;

    return (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Dialog
                className={classes.root}                
                fullWidth
                maxWidth={"xs"}
                open={true}
                onClose={() => onCancel && onCancel()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {title && (
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                )}
                <DialogContent>
                    <Grid container spacing={3}>
                        <EditComponent values={values} reset={reset} />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {actions || (
                        <>
                            <Button onClick={() => onCancel && onCancel()} color="primary">
                                {cancelText || t('cancel')}
                            </Button>
                            <Button color="primary" disabled={submitting} onClick={handleSubmit}>
                                {okText || t('ok')}
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </form>
    );
}

export default withForm(FieldEditForm);
// export default ({title, values, validate, children, actions, cancelText, okText, onOk, onCancel,t})=>

// <Form

// subscription={{ submitting: true, pristine: true }}
// onSubmit={onOk}
// initialValues={values}
// validate={validate}
// render={({ handleSubmit, reset, submitting, pristine, values }) => {
//     // this.resetForm = reset
//     return (<form onSubmit={handleSubmit} noValidate autoComplete="off" >

//     <Dialog
//         fullWidth
//         maxWidth={"xs"}
//         open={true}
//         onClose={()=>onCancel&&onCancel()}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//     >
//         {title&&
//         <DialogTitle 
//             id="alert-dialog-title" 
//             style={{ direction: 'rtl' }}>
//             {title}
//         </DialogTitle>}
//         <DialogContent >
//         <Grid container spacing={3} >

//                             {children}
//                             </Grid>
            
//         </DialogContent>
//         <DialogActions>
//             {actions|| <>
//             <Button onClick={()=>onCancel&&onCancel()} color="primary" >
//                 {cancelText|| 'انصراف'}
//             </Button>
//             <Button color="primary"  disabled={submitting} onClick={handleSubmit}>
//                 {okText|| 'تایید'}
//             </Button>
//             </>
//             }
//         </DialogActions>
//     </Dialog>
//     </form>)}}>
// </Form>


