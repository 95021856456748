import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Name, NameView, validate as validateName } from './Name'
import { DisplayName, DisplayNameView } from './DisplayName'


export const validators={
    validateName, 
};


export const validate =(values, errors, t) => {

    for (const [, func] of Object.entries(validators)) {
        func(values, errors, t);
      }

}
export const FormFields = (props) =>
{
    return <>
<Grid container spacing={2} style={{padding:25, paddingTop:0}}>

        <Name />
        <DisplayName />
</Grid>
</>
}

export const EditableViewFields = (props) =>
{
return <>

    <NameView {...props} />
    <DisplayNameView {...props} />

</>

}

