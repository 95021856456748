/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Route  } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useTheme } from '@material-ui/styles';


const breadcrumbNameMap = {
  '/superadmin': 'داشبورد',
  '/superadmin/inbox': 'پیام ها',
  '/superadmin/user': 'کاربران',
  '/superadmin/user/create': 'کاربر جدید',
  '/admin': 'داشبورد',
  '/admin/inbox': 'پیام ها',
  '/admin/user': 'کاربران',
  '/admin/user/create': 'کاربر جدید',
  '/admin/benefactor': 'گپ',
  '/admin/benefactor/create': 'مشتری  جدید',
};

function ListItemLink(props) {
  const { to, open, ...other } = props;
  const primary = breadcrumbNameMap[to];

  return (
    <li>
      <ListItem button component={RouterLink} to={to} {...other}>
        <ListItemText primary={primary} />
        {open != null ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>
    </li>
  );
}

ListItemLink.propTypes = {
  open: PropTypes.bool,
  to: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom:20,
    // width: 360,
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const LinkRouter = props => <Link {...props} component={RouterLink} />;

export default function RouterBreadcrumbs(props) {
  const classes = useStyles();
  // const [, setOpen] = React.useState(true);
  const {direction} = useTheme();
  const IconBasedOnLanguage = direction === 'rtl' ? ArrowForwardIcon : ArrowBackIcon;

  return (
    // <Router initialEntries={['']} initialIndex={0}>
      <div className={classes.root+ ' '+props.className}>
        <Route>
          {({ location }) => {
            const pathnames = location.pathname.split('/').filter(x => x);              

            if  (props.title )
            {
              var to = props.onBack?undefined:`/${pathnames.slice(0, -1).join('/')}`;
              return <><Typography  variant="h5">
                <IconButton  component={LinkRouter} onClick={props.onBack} to={to} ria-label="delete" className={classes.margin} size="medium">
                  <IconBasedOnLanguage  style={{color:'gray'}} fontSize="inherit" />
                </IconButton>{props.title}        
              </Typography>

              </>
            }
              

            return (
              <Breadcrumbs aria-label="breadcrumb" style={{paddingBottom:20}}>
                {/* <LinkRouter color="inherit" to="/">
                  Home
                </LinkRouter> */}
                {pathnames.map((value, index) => {
                  const last = index === pathnames.length - 1;
                  const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                  
                  return last ? (
                    <Typography color="textPrimary" key={to}>
                      {breadcrumbNameMap[to]}
                    </Typography>
                  ) : (
                    <LinkRouter color="inherit" to={to} key={to}>
                      {breadcrumbNameMap[to]}
                    </LinkRouter>
                  );
                })}
              </Breadcrumbs>
            );
          }}
        </Route>
        
      </div>
    // </Router>
  );
}