import React from 'react'
import Grid from '@material-ui/core/Grid'
import {VerticalTabs, Tab} from '../../../../Component/VerticalTabs'
import { useTranslation } from 'react-i18next';
import { Box, FormGroup, Divider } from '@material-ui/core'

import { NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';
import { Name, NameView } from './Fields/Name'
import { Code2, Code2View } from './Fields/Code2'
import { Code3, Code3View } from './Fields/Code3'


export const validators={
    
};


export const validate =(values, errors, t) => {

    for (const [name, func] of Object.entries(validators)) {
        func(values, errors, t);
      }

}
export const FormFields = (props) =>
{
    const { values } = props;
    const { t } = useTranslation('region');
    return <>
<Grid container spacing={2} style={{padding:25, paddingTop:0}}>

        <Name />
        <Code2 />
        <Code3 />
</Grid>
</>
}

export const EditableViewFields = (props) =>
{
    const {values, onRefresh} = props;
    const { t } = useTranslation('region');

return <>

    <NameView {...props} />
    <Code2View {...props} />
    <Code3View {...props} />

</>

}

