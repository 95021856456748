import React, { PureComponent } from 'react'
import { withRouter } from "react-router-dom"
// import 'react-table/react-table.css'
import { getSubscriptions, deleteSubscription } from '../api'
import { getSubscription, updateSubscription, insertSubscription } from '../api'
import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CopyIcon from "@material-ui/icons/FileCopy";
import FilterableGrid, { columnTypes } from '../../../../Component/FilterableGrid';
import ImportIcon from '@material-ui/icons/ImportExport';

import DeleteIcon from "@material-ui/icons/Delete";
import DialogWindow from '../../../../Component/DialogWindow'
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import { Link as RouterLink } from 'react-router-dom';
import {withLogin} from '../../../../Contexts/withLogin'
import EmptyList from '../../../../Component/EmptyList'
import {titleById as bundleTitleById, byId as BundleById, bundleStatus, allBundleStatuses} from '../../../../domain/bundleStatus'
import {titleById , byId, subscriptionStatus} from '../../../../domain/subscriptionStatus'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Typography from '@material-ui/core/Typography';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import {   bundleType } from '../../../../domain/bundleType';
import { Box, Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { withTheme } from '@material-ui/core/styles';
import { bundleToGb, toGb    } from '../../../../domain/bundleTraffic';
import { fromHours, toHumanText } from '../../../../domain/subscriptionPeroid';
import {numToFa} from '../../../../utils/persianUtils'; 
import ImportDialog from "../Create/CreatePageForImport"
import { hasRole } from '../../../../modules/Auth';
import { SubscriptionStatus } from '../Form/Fields/SubscriptionStatus'
import {allSubscriptionStatuses, getAllStatus} from '../../../../domain/subscriptionStatus'
import {allUserDevices, titleById as getByDeviceId} from '../../../../domain/userDevice';


const styles = {
    overflowEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '130px', // You can set this to any desired value or use other properties like flex, grid, etc.
    },
  }

const ddlColumns = [
    {  'id': {
        fa: false,
        sortable: false,
        searchable: true,
        editLink: true,
        title: 'Id',
        remote: true
    },
},
      {  'key': {
            fa: false,
            sortable: false,
            searchable: false,
            editLink: true,
            title: 'Key',
            remote: false
        },
    },
    {
        'customerName': {
            title: 'Customer Name',
            remote: true
        }
    }
];

function getClientName(text) {
    if (!text) return "";
    var first = text.split(';')[0]; // Get the text before the first semicolon

    if (first.length > 15) {
        first = first.split(' ')[0]; // Get the text before the first space
    }

    return first;
}

class DefaultList extends PureComponent {
     byPath = (obj, path) => {
        try {
          return path.split('.').reduce((o, key) => o[key], obj);
        } catch {
          return null;
        }
      };
    bundleStatus=(id)=>{
        if(!id) id=bundleStatus.NotUsed;
        var color= BundleById(id).color;
        const colorCode = this.byPath(this.props.theme.palette, color) || '';

        var icon = id === bundleStatus.Using? <DoneIcon style={{ color: colorCode }} /> : id === bundleStatus.Cancelled?<NotInterestedIcon style={{ color: colorCode }} />:<ErrorOutlineIcon style={{ color: colorCode }} />;

        return <Chip variant="outlined" color={colorCode} label={bundleTitleById(id)} style={{color:colorCode,borderColor:colorCode}} size="small" icon={icon} />
    }
    
    status=(id)=>{
        // if(!id) id=subscriptionStatus.Pending;
        var color= byId(id).color;
        const colorCode = this.byPath(this.props.theme.palette, color) || '';

        var icon = id === subscriptionStatus.Active? <DoneIcon style={{ color: colorCode }} /> : (id === subscriptionStatus.Trialing || id === subscriptionStatus.Pending) ?<NotInterestedIcon style={{ color: colorCode }} />:<ErrorOutlineIcon style={{ color: colorCode }} />;

        return <Chip variant="outlined" color={colorCode} label={titleById(id)} style={{color:colorCode,borderColor:colorCode}} size="small" icon={icon} />
    }

    type=(id)=>{
      
      var colorCode = this.props.theme.palette.text.secondary;
      

      if(id == bundleType.Reseller)
        return <Chip variant="outlined" color={colorCode} label={'فروشنده'} style={{borderColor: colorCode, color:colorCode}}
        size="small" />
      
        return '';
  }

    constructor(props) {
        super(props)
        this.state = {
            subscriptions: [],
            selection: [],
            selectAll: false,
            // subscriptionId: props.match.params.subscriptionId,
            openDeleteDialog:false

        }
    }
    area= this.props.match.path.split('/')[1]
    getColumns = (t)=>{
        var columns={};

        if(hasRole('SuperAdmin')){
            columns["reseller.id"] ={
                    role:'SuperAdmin',
                    format: (row) =>  <Tooltip title={`ID : ${row.reseller&&row.reseller.id}`}><div> <Box style={styles.overflowEllipsis}>{(row.reseller&&row.reseller.customerName)||'---'}</Box></div></Tooltip>,
                   
                    title: 'reseller',
                    remote: true
                };
        }
        columns = {...columns, ...{
        'key': {
            fa: false,
            sortable: false,
            searchable: false,
            editLink: true,
            title: t('titleKey'),
            remote: false
        },
        
        'customerName': {
            editLink: true,
            title: t('customerName.title'),
            remote: true
        },
        'customerPhone': {
            editLink: true,
            title: t('customerPhone.title'),
            remote: true
        },
        'userDevice': {
            title: t('userDevice.title'),
            format: (row) =>  <Tooltip title={(getByDeviceId(row.userDevice)||'') + " | " + row.userAgent}><div> <Box style={styles.overflowEllipsis}>{getClientName(row.userAgent)}</Box></div></Tooltip>,
            items: allUserDevices,
            remote: true
        },
        // 'activeBundle.firstConnectedTime': {
        //     title: t('currentBundle'),
        //     format: (row) => t('volume') + ' ' + toGb(row.activeBundle.availableTraffic) + ' / ' + ((row.activeBundle.availableHours > 0 ? toHumanText(row.activeBundle.availableHours) + ' ' + t('remaining') : t('ended')) ),
        //     remote: true
        // }, 
        'activeBundle.concurrencyLimit': {
            title: t('🔀'),
            remote: true
        }, 
        'activeBundle.traffic': {
            title: t('volume'),
            format: (row) =>    toGb(row.activeBundle.trafficUsed, 1) +' / ' + toGb(row.activeBundle.traffic,0) ,
            remote: true
        }, 
        'activeBundle.durationHours': {
            title: t('remainingTime'),
            format: (row) =>    (row.activeBundle.availableHours > 0 ? toHumanText(row.activeBundle.availableHours) : t('ended')),
            remote: true
        }, 

        'activeBundle.status': {
            title: t('bundleStatus'),
            format: (row) => this.bundleStatus(row.activeBundle.status),
            items: allBundleStatuses,
            remote: true
        },
        'subscriptionStartDate': {
            editLink: false,
            title: t('subscriptionStartDate.title'),
            type: columnTypes.dateTime,
            remote: true
        },
        
        'subscriptionStatus': {
            title: t('status'),
            format: (row) => this.status(row.subscriptionStatus),
            items: allSubscriptionStatuses,
            remote: true
        }
       
        
    }};
    
    return columns;
}

    async componentDidMount() {

        await this.loadSubscriptions()
    }

    refresh = async () => {
        await this.loadSubscriptions();
    }

    loadSubscriptions = async () => {
        var res = await getSubscriptions()
        if (res) {
            this.setState({ subscriptions: res.results,totalCount:res.totalCount, selection :[], selectAll:false, openDeleteDialog:false })
        }
    }

    onFilter = async (params) => {
        var res = await getSubscriptions({...params})

        if (res) {
            const isFiltered = Object.keys(params).length;
            this.setState({ subscriptions: res.results,totalCount:res.totalCount, openDeleteDialog:false, isFiltered })
        }
    }

    deleteSubscriptions = async () => {
        const res = await deleteSubscription(this.state.selection[0].id)
        if (res !== null){
            await this.loadSubscriptions()
        }

    }


    showDeleteDialog = (open, selected) => () => {
        this.setState({ selection : selected, openDeleteDialog: open });
    };

    showImportDialog = (open, selected) => () => {
        this.setState({  openImportDialog: open });
    };

    duplicate = (id) => async () => {
        var subscription = await getSubscription(id);
        if(subscription) {
            if( await insertSubscription({...subscription, id:undefined}))
                        await this.loadSubscriptions()
        }
    };

    onEditClick =(id)=>{
        const { match } = this.props;
        this.props.history.push(`${match.path}/${id}`);
    }
    onDeleteClick = (selected) => {
        this.setState({ selection : selected, openDeleteDialog: true });

    }
    selectionToolbar = (selected)=> {
        const {t}= this.props;
        return <>
{/* <Tooltip title={t('common:delete')}>
    <IconButton aria-label={t('common:delete')}>
        <DeleteIcon onClick={this.showDeleteDialog(true, selected)} />
    </IconButton>
</Tooltip> */}
{/* <Tooltip title={t('common:duplicate')}>
    <IconButton aria-label={t('common:duplicate')}>
        <CopyIcon onClick={this.duplicate(selected[0])} />
    </IconButton>
</Tooltip> */}



        </>
    }

    toolbar = (selected)=> {
        const { t, match }= this.props;
        return <>
<Tooltip title={t('common:new')}>
    <Button component={RouterLink} to={`/${this.area}/subscription/create`} color="primary">{t('new')}</Button>
</Tooltip>
<Tooltip title={t('common:filter')}>
    <IconButton aria-label={t('common:filter')}>
        <FilterListIcon />
    </IconButton>
</Tooltip>
{hasRole('SuperAdmin')&&

<Tooltip title={t('common:import')}>
        <IconButton aria-label={t('common:import')}>
            <ImportIcon onClick={this.showImportDialog(true)} />
        </IconButton>
        </Tooltip>
    }
        </>
    }


DeleteDialog = () => {
    const {t}= this.props;
    return <Dialog open={true}
                   onClose={this.handleCloseDeleteDialog}
                   aria-labelledby="alert-dialog-title"
                   aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title" style={{direction:'rtl'}}>{t('delete')}</DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {t('delete?')}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.showDeleteDialog(false)} color="primary">
            {t('common:cancel')}
        </Button>
        <Button onClick={this.deleteSubscriptions } color="primary" autoFocus>
            {t('common:ok')}
        </Button>
    </DialogActions>
</Dialog>
    }

    render() {
        const { subscriptions, openDeleteDialog,totalCount=0, openImportDialog, isFiltered } = this.state
        const {match, t} = this.props

        if (!isFiltered && !subscriptions.length) {
                  return (<EmptyList title={t('listIsEmpty')}
           entityTitle={t('subscription')}
           to={`/${this.area}/subscription/create`} />)
        }
        return (
        <>
            {openDeleteDialog&& this.DeleteDialog()}
            {openImportDialog&& <ImportDialog  onClose={this.showImportDialog(false)} onCancel={this.showImportDialog(false)} />}


<FilterableGrid data={ subscriptions }
                selectable={this.props.selectable || true}
                pagination={false}
                title={ t('subscriptions') +` - ${totalCount} ` + t('common:items') }
                selectionToolbar={this.selectionToolbar}
                toolbar={this.toolbar}
                header={this.props.columns ||this.getColumns(t)}
                onDelete={this.onDeleteClick}
                onEdit={this.onEditClick}
                onFilter={this.onFilter}
                onSelect={this.props.onSelect}></FilterableGrid>
        </>
        )
    }
}

export class ListDialog extends PureComponent {

    render() {
        return <DialogWindow open={this.props.open} onClose={this.props.onClose}>
    <List columns={ddlColumns}
          selectable={1}
          {...this.props} />
</DialogWindow>
    }
}


export const List = withTheme(withTranslation('subscription')(withLogin(withRouter(DefaultList))));
export default List;
