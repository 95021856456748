import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateResellerSetting } from '../../api'
import {hasValue} from '../../../../../utils/string'

import Switch from '../../../../../Component/Switch'

export const IsCardPaymentEnabled =  () => {
    const { t } = useTranslation('resellerSetting');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('isCardPaymentEnabled.title')}
                   name={"isCardPaymentEnabled"}
                   component={Switch}
                   type="text" />
        </FormControl>
    </Grid>
    </>
}

export const validate = (values, errors, t)=> {
    if ((values.isCardPaymentEnabled !== true && values.isCardPaymentEnabled !==false)) {
        errors.isCardPaymentEnabled = t('isCardPaymentEnabled.required');
    }
    return errors;
}


export function IsCardPaymentEnabledView({ values, onRefresh }) {
    const { t } = useTranslation('resellerSetting');

    const update = async (values) =>{
        const {id, isCardPaymentEnabled }=values;
        return await updateResellerSetting({id, isCardPaymentEnabled });
    }

    const successMessage  = (values) => t('updated')


    const { isCardPaymentEnabled=t("common:notEntered") } = values;

    return <ViewField successMessage={successMessage}
                        title={ t('isCardPaymentEnabled.title')}
                        headline={ isCardPaymentEnabled?t('common:yes'):t('common:no') }
                        changeTitle= {t('common:enable')}
                        update={update}
                        validate={validate}
                        values={values}
                        onRefresh={onRefresh}
                        editComponent={ IsCardPaymentEnabled }
                        />

}


