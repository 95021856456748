import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateResellerSetting } from '../../api'
import {hasValue} from '../../../../../utils/string'

import TextInput from '../../../../../Component/TextInput'

export const AdditionalDevicePriceRate =  () => {
    const { t } = useTranslation('resellerSetting');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('additionalDevicePriceRate.title')}
                   name={"additionalDevicePriceRate"}
                   component={TextInput}
                   number
                   adornment="%"
                   />
        </FormControl>
    </Grid>
    </>
}
export const validate = (values, errors, t)=> {
    if (!hasValue(values.additionalDevicePriceRate)) {
        errors.additionalDevicePriceRate = t('additionalDevicePriceRate.required');
    }
    return errors;
}


export function AdditionalDevicePriceRateView({ values, onRefresh, readonly }) {
    const { t } = useTranslation('resellerSetting');

    const update = async (values) =>{
        const {id, additionalDevicePriceRate }=values;
        return await updateResellerSetting({id, additionalDevicePriceRate });
    }

    const successMessage  = (values) => t('updated')

    const { additionalDevicePriceRate } = values;

    return <ViewField successMessage={successMessage}
                        title={ t('additionalDevicePriceRate.title')}
                        headline={ additionalDevicePriceRate+"%" }
                        update={update}
                        validate={validate}
                        values={values}
                        onRefresh={onRefresh}
                        readonly = {readonly}
                        editComponent={ AdditionalDevicePriceRate } />
    
}


