import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from '../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../../../Component/TextInput'
import Switch from '../../../../../Component/Switch'

export const GenerateRecurrentDonations =  ({values, t=useTranslation('setting').t}) =>
<>
<Grid item sm={ 6 } xs={12}>
    <FormControl fullWidth>
        <Field label={t('اشتراک‌های نمونه')}
               name={"generateRecurrentDonations"}
               component={Switch} />
    </FormControl>
</Grid>
{values.generateRecurrentDonations&&

<Grid item sm={ 6 } xs={12}>
<FormControl fullWidth>
        <Field placeholder={'تعداد'}
               name={"recurrentDonationCount"}
               number
               component={TextInput}
               adornment={'رکورد'} />
    </FormControl>
</Grid>
}
</>


export const validate = (values, errors)=> {
    if(values.generateRecurrentDonations && !values.recurrentDonationCount)
        errors.recurrentDonationCount = "تعداد نمونه باید بزرگتر از صفر باشد";
    return errors;
}
