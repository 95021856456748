import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateMimeType } from '../../api'
import TextInput from '../../../../../Component/TextInput'

export const Description =  ({t=useTranslation('mimeType').t}) =>
<>
<Grid item sm={ 12 } xs={12}>
    <FormControl fullWidth>
        <Field label={t('description.title')}
               name={"description"}
               component={TextInput}
               type="text" />
    </FormControl>
</Grid>
</>

export const validate = (values, errors, t)=> {
    if (!values.description) {
        errors.description = t('description.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, description }=values;
        return await updateMimeType({id, description });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { description } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('description.title')}
                          headline={ description }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ Description } />
    }
}


export const DescriptionView = withTranslation('mimeType')(View);