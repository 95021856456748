import React, { PureComponent } from 'react'
import { withRouter } from "react-router-dom"
// import 'react-table/react-table.css'
import { getTrafficTransactions, deleteTrafficTransaction } from '../api'
import { getTrafficTransaction, updateTrafficTransaction, insertTrafficTransaction } from '../api'
import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CopyIcon from "@material-ui/icons/FileCopy";
import FilterableGrid, { columnTypes } from '../../../../Component/FilterableGrid';
import DeleteIcon from "@material-ui/icons/Delete";
import DialogWindow from '../../../../Component/DialogWindow'
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import { Link as RouterLink } from 'react-router-dom';
import {withLogin} from '../../../../Contexts/withLogin'
import EmptyList from '../../../../Component/EmptyList'
import { Box, Chip } from '@material-ui/core';
import { hasRole } from '../../../../modules/Auth';
import SaveIcon from '@material-ui/icons/Save';


const styles = {
    negative: {
      color: 'red', // You can set this to any desired value or use other properties like flex, grid, etc.
    },
    positive: {
        color: 'green', // You can set this to any desired value or use other properties like flex, grid, etc.
      }
  }

  const formatAmountGB = (row) => {
    const amount = row.amountGB;
    const formattedAmount = amount + " GB";
    const style = amount >= 0 ? styles.positive : styles.negative;
    const sign = amount > 0 ? "+" : "";
  
    return (
      <Box style={style}>
        {sign}{formattedAmount}
      </Box>
    );
  };

const ddlColumns = [
    
    {
  
        'description': {
            title: 'description',
            remote: true
        }
    }
];


class DefaultList extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            trafficTransactions: [],
            selection: [],
            selectAll: false,
            // trafficTransactionId: props.match.params.trafficTransactionId,
            openDeleteDialog:false

        }
    }
    area= this.props.match.path.split('/')[1]
    getColumns = (t)=>{
        var columns={};
        if(hasRole('SuperAdmin')){
            columns["senderId"] ={
                    role:'SuperAdmin',
                    format: (row) =>  <Tooltip title={`ID : ${row.senderId}`}><div> <Box style={styles.overflowEllipsis}>{(row.senderName||row.senderId)||'---'}</Box></div></Tooltip>,
                   
                    title: 'Sender',
                    remote: true
                };
        }

        columns = {...columns, ...{
            'id': {
                fa: false,
                sortable: false,
                searchable: true,
                editLink: false,
                title: '#',
                remote: true
            },
            'transactionTime': {
                editLink: false,
                title: 'Date',
                type: columnTypes.dateTime,
                remote: true
            },
            'amountGB': {
                
                editLink : false,
                searchable: false,
                title: 'Amount(GB)',
                format:formatAmountGB,

                remote: true
            },
            'newBalance': {
                editLink : false,
                searchable: false,
                title: 'Reseller Balance',
                format: (row) => row.newBalance +" GB",

                remote: true
            },
            'receiver.customerName+receiver.customerPhone': {
                editLink : false,
                searchable: true,
                format: (row) => 
                    <Button component={RouterLink} to={`/${this.area}/subscription/`+row.receiverId} color="primary">{row.receiverName}</Button>,
                title: 'Subscriber',
                remote: true
            },

            'description': {
                fa: false,
                editLink : false,
                searchable: false,

                // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
                title: 'Description',
                remote: true
            }
            
            
        }}
        return columns;
    };

    async componentDidMount() {

        await this.loadTrafficTransactions()
    }

    refresh = async () => {
        await this.loadTrafficTransactions();
    }

    loadTrafficTransactions = async () => {
        var res = await getTrafficTransactions()
        if (res) {
            this.setState({ trafficTransactions: res, selection :[], selectAll:false, openDeleteDialog:false })
        }
    }

    onFilter = async (params) => {
        var res = await getTrafficTransactions({...params})

        if (res) {
            const isFiltered = Object.keys(params).length;
            this.setState({ trafficTransactions: res, openDeleteDialog:false, isFiltered })
        }
    }

    deleteTrafficTransactions = async () => {
        const res = await deleteTrafficTransaction(this.state.selection[0].id)
        if (res !== null){
            await this.loadTrafficTransactions()
        }

    }


    showDeleteDialog = (open, selected) => () => {
        this.setState({ selection : selected, openDeleteDialog: open });
    };

    duplicate = (id) => async () => {
        var trafficTransaction = await getTrafficTransaction(id);
        if(trafficTransaction) {
            if( await insertTrafficTransaction({...trafficTransaction, id:undefined}))
                        await this.loadTrafficTransactions()
        }
    };

    onEditClick =(id)=>{
        const { match } = this.props;
        this.props.history.push(`${match.path}/${id}`);
    }
    onDeleteClick = (selected) => {
        this.setState({ selection : selected, openDeleteDialog: true });

    }
    selectionToolbar = (selected)=> {
        const {t}= this.props;
        return <>
<Tooltip title={t('common:delete')}>
    <IconButton aria-label={t('common:delete')}>
        <DeleteIcon onClick={this.showDeleteDialog(true, selected)} />
    </IconButton>
</Tooltip>
<Tooltip title={t('common:duplicate')}>
    <IconButton aria-label={t('common:duplicate')}>
        <CopyIcon onClick={this.duplicate(selected[0])} />
    </IconButton>
</Tooltip>
        </>
    }

    exportExcel= async () => {
        
        await getTrafficTransactions({...{exportToExcel:true}, ...this.state.filterParams}, {download:true});

    }

    toolbar = (selected)=> {
        const { t, match }= this.props;
        return <>
{/* <Tooltip title={t('common:new')}>
    <Button component={RouterLink} to={`/${this.area}/trafficTransaction/create`} color="primary">{t('new')}</Button>
</Tooltip> */}
        <Tooltip title={t('common:exportToExcel')}>
            <IconButton onClick={this.exportExcel}>
                <SaveIcon />
            </IconButton>
        </Tooltip>
<Tooltip title={t('common:filter')}>
    <IconButton aria-label={t('common:filter')}>
        <FilterListIcon />
    </IconButton>
</Tooltip>
        </>
    }


DeleteDialog = () => {
    const {t}= this.props;
    return <Dialog open={true}
                   onClose={this.handleCloseDeleteDialog}
                   aria-labelledby="alert-dialog-title"
                   aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title" style={{direction:'rtl'}}>{t('delete')}</DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {t('delete?')}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.showDeleteDialog(false)} color="primary">
            {t('common:cancel')}
        </Button>
        <Button onClick={this.deleteTrafficTransactions } color="primary" autoFocus>
            {t('common:ok')}
        </Button>
    </DialogActions>
</Dialog>
    }

    render() {
        const { trafficTransactions, openDeleteDialog, isFiltered } = this.state
        const {match, t} = this.props

        if (!isFiltered && !trafficTransactions.length) {
                  return (<EmptyList title={t('listIsEmpty')}
           entityTitle={t('trafficTransaction')}
           to={`/${this.area}/trafficTransaction/create`} />)
        }
        return (
        <>
            {openDeleteDialog&& this.DeleteDialog()}
<FilterableGrid data={ trafficTransactions }
                selectable={this.props.selectable || true}
                pagination={false}
                title={t('trafficTransactions')}
                selectionToolbar={this.selectionToolbar}
                toolbar={this.toolbar}
                header={this.props.columns ||this.getColumns(t)}
                onDelete={this.onDeleteClick}
                onEdit={this.onEditClick}
                onFilter={this.onFilter}
                onSelect={this.props.onSelect}></FilterableGrid>
        </>
        )
    }
}

export class ListDialog extends PureComponent {

    render() {
        return <DialogWindow open={this.props.open} onClose={this.props.onClose}>
    <List columns={ddlColumns}
          selectable={1}
          {...this.props} />
</DialogWindow>
    }
}


export const List = withTranslation('trafficTransaction')(withLogin(withRouter(DefaultList)));
export default List;
