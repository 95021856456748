import React from 'react'
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import {byExp} from '../utils/objectHelper'
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import FormHelperText from '@material-ui/core/FormHelperText'
import { Box } from '@material-ui/core';
import InputLabel from "@material-ui/core/InputLabel";

var optionsDic={};

function getOptionsByString(str){
  var data=optionsDic[str];
  if (!data){
    data = optionsDic[str]=str.split('/').map((item)=>{
      return {id:item, name:item};
  
    });
  }
  
  return data;
}

class SelectorDDL extends React.PureComponent {

  constructor(props) {
      super(props)
      // this.handleModelChange = this.handleModelChange.bind(this);
      this.state = {
        ddlOpen : false,
        data : props.data 
      }
      
  }
  // componentWillReceiveProps(nextProps) {
  //   console.log('componentWillReceiveProps');
  //   // let newStates ;
  //   // if(data!)
  //   // console.log(nextProps);
  //   // this.setState({ value:nextProps.value });
  // }

  onCancel =()=> {
    this.setState({ddlOpen:false})

  }
  openDdl = ()=> {
    this.setState({ddlOpen:true})
  }
  onSelect = (id,data)=> {

    this.setState({ddlOpen:false}, ()=>{
      this.props.onChange&&this.props.onChange(id,data);
      setImmediate(()=>this.props.onSelect&&this.props.onSelect(id, data));
      

    })
  }
 
  
  render() {    
    var {options, selectable=1,withAll,value, exValue,objectValue, optionTitle, keyName="id", error, helperText, name, onChange,...rest} = this.props
    var {meta} = this.props;

    const isMultiSelect = selectable === true || selectable>1;

    if(isMultiSelect && !exValue && value){
      // it's server initial data
       exValue=value;
    }
    if(isMultiSelect && exValue && !value){
      // it's server initial data
       value=exValue;
    }

    // const titleByKey= (options,keyValue)=> {
    //   const option = (options).filter(o=>o[keyName]==keyValue)[0];
    //   return byExp( option, optionTitle);
    //   // return option&&option[optionTitle]
    // }
    // const byId=(options, id)=>{
    //   return (options||exValue).filter(o=>o[keyName]==id)[0];
    // }

    // console.log('exValue')
    // console.log(exValue)
    const DropDownList = options;
    // const data = this.state.data || objectValue ||{};
    const dataIsString = typeof options == "string";
    if(dataIsString){
      options = getOptionsByString(options);
    }
    const dataIsObject = typeof options == "object";

    return (<div>
  
      {!dataIsObject && isMultiSelect&&
      <>
      <InputLabel >{rest.label}</InputLabel>

        <Select
        readOnly={true}
        {...this.props}
        select
        fullWidth
        InputProps={{

          readOnly: true,
        }}
        helperText={meta.touched ? meta.error : undefined}

        value={(exValue||[]).length?'-':''}
        // type="text"
        onClick={this.openDdl}
        renderValue={() => (

        <Box display="flex" flexWrap="wrap" /*className={classes.chips}*/>
          {(exValue||[]).map(s => (
            <Chip
              key={s[keyName]}
              label={byExp( s, optionTitle)}
              /*className={classes.chip}*/
            />
          ))}
        </Box>
        )}
      >

      <MenuItem value={'-'}></MenuItem>

      </Select>
      {(meta.touched && meta.error) &&
  <FormHelperText style={{ color: '#f44336'}}>{meta.error}</FormHelperText>
  }
     </>


 
      }
 {!dataIsObject && !isMultiSelect&&
 <TextField
        {...this.props}
        select
        fullWidth
        InputProps={{
          readOnly: true,
        }}
        value={value===undefined?'':value}
        // type="text"
        onClick={this.openDdl}
      >
        <MenuItem value={value|''}>
            {byExp(exValue, optionTitle)}
        </MenuItem>
      </TextField>
}
      {!dataIsObject && this.state.ddlOpen&&
        <DropDownList withAll={withAll} selectable={selectable} {...rest} value = {value} exValue={exValue} selectData={exValue} open={this.state.ddlOpen} onSelect={this.onSelect} onClose={this.onCancel}/>
      }
      {dataIsObject &&
        <TextField
        {...this.props}
        select
        fullWidth
        InputProps={{
          readOnly: false,
        }}
        type="text"
      >
        {options.map(o=>
          <MenuItem value={o[keyName]}>
            {byExp(o, optionTitle)}
          </MenuItem>
        )
        }

      </TextField>
      } 

    </div>)
  }
}



export default ({
  input: { name, onChange, exValue,value, ...restInput }={},
  meta={},
  withAll,
  ...rest
}) => {


  return (
<SelectorDDL
  {...rest}  
  name={name}
  helperText={meta.touched ? meta.error : undefined}
  error={meta.error && meta.touched}
  inputProps={restInput}
  onChange={onChange}
  value={value}
  exValue={exValue}
  meta={meta}
  withAll={withAll}
/>)

}