import React, { Component } from 'react';
import './App.css';
import PrivateRoute from './Component/PrivateRoute'
import { MuiThemeProvider, createMuiTheme, createTheme } from '@material-ui/core/styles';
import {LoginContext} from './Contexts/LoginContext'
// import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
import { SnackbarProvider } from 'notistack';
import {
  HashRouter as Router,
  Route, Switch, Link} from "react-router-dom"
import './styles/fontiran.css'
import blue from '@material-ui/core/colors/blue'
import indigo from '@material-ui/core/colors/indigo'
import red from '@material-ui/core/colors/red'

import BenefactorPanel from './features/Benefactor'
import { switchUser, getProfile } from './modules/Auth'
import AdminPanel from './features/Admin'
import SuperAdminPanel from './features/SuperAdmin'
import Sign from './features/Anonymouse/Sign'
import Otp from './features/Anonymouse/Sign/Login/otp'
import OAuthCallback from './features/Anonymouse/Sign/Login/OAuthCallback'

import Pwd from './features/Anonymouse/Sign/Login/pwd'
import Auth from './Component/Auth'
import AjaxHandler from './modules/AjaxHandler';
import { ModalContainer } from 'react-router-modal';
import 'react-router-modal/css/react-router-modal.css'
import { green } from '@material-ui/core/colors';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

var _sideOpen = false;
const isDevelopment = process.env.NODE_ENV === 'development';

if (isDevelopment) {
  document.body.classList.add('dev-body');
}




const App = () => {
  var {t, i18n} = useTranslation('common');
  const direction = i18n.language=='fa'? 'rtl':'ltr';
  const [theme, setTheme] = useState(createTheme());
  const [profile, setProfile] = useState(getProfile());
  useEffect(() => {
    setTheme(getTheme(direction));

    
  }, []);  // empty dependency array means this effect runs once after initial render

  const switchUser = async (userId) => {
    await switchUser(userId);
    console.log('the user is switched');
    setProfile(getProfile());
  };

  const getTheme = (direction) => {
    return createTheme({
      sideOpen: (sideOpen)=> { if(sideOpen!==undefined) _sideOpen = sideOpen; return _sideOpen;},
      direction: direction,
      palette: {
        primary: isDevelopment ? green :  blue,
        secondary: isDevelopment ? indigo :indigo,

   

        // error: red,
        error: {
          main : "#ff3548",
        },
        success: {
          main : "#01c851",
        },
        warning: {
          main : "#ff8800",
        },
        info:{
          main:"#33b5e7"
        }
      },
      typography: {
        // Use the system font.
        fontFamily:
          'iransans, -apple-system,system-ui,BlinkMacSystemFont,' +
          '"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
      },
      overrides : {
        MuiDivider:{
          root:{
            width:'100%'
          }
        },
        MuiPaper:{
          root : {
            // backgroundColor: '#ffffff7d'
            backgroundColor:'#ffffffe0'
          }
        },
        MuiPopover: {
          paper : {
            backgroundColor: 'white'
          }
        },
        MuiMenu : {
          paper : {
            backgroundColor: 'white'
          }
        },
        MuiLink:{
          button:{
            fontSize:'0.875rem'
          }
        },
        MuiDialog:{
          paper : {
            backgroundColor: 'white'
          }
        },
      }
        });
  };

  return (
    <div dir={direction}>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <LoginContext.Provider value={{ switchUser, profile }}>
            <Router>
              <div style={styles.container}>
                <Auth />
                <AjaxHandler />
   
                <Switch>
                  {/* <Route exact path="/" component={Home} /> 
                  <Route path="/home" component={Home} />  */}
                                    <Route path="/auth-callback" component={OAuthCallback} />

                  <Route path="/sign" component={Sign} />
                  <Route path="/otp" component={Otp} />
                  <Route path="/pwd" component={Pwd} />
                  

                  <PrivateRoute requireRole='Subscriber,Administrator' path="/admin" component={AdminPanel} />
                  <PrivateRoute requireRole='SuperAdmin' path="/superadmin" component={SuperAdminPanel} />

                  <PrivateRoute  path="/benefactor" component={BenefactorPanel} />
                  {/* <PrivateRoute requireRole='Employee' path="/admin" component={AdminPanel} /> */}
                  <Route render={() => <center><h1>{t('pageNotFound')}</h1><br /><Link to='/' >{t('mainPage')}</Link></center>} />
                </Switch>
                <ModalContainer />              </div>
            </Router>
          </LoginContext.Provider>
        </SnackbarProvider>
      </MuiThemeProvider>
    </div>
  );
};

const styles={
    
  container:{
    // backgroundColor:'#f0f0f0',
    height:'100%',
    width:'100%',
    //position:'fixed',
    overflow:'auto'
  }
}

export default App;



// class App extends Component {

//   getTheme = (direction) => {
//     return createTheme({
//       sideOpen: (sideOpen)=> { if(sideOpen!==undefined) _sideOpen = sideOpen; return _sideOpen;},
//       direction: direction,
//       palette: {
//         primary: isDevelopment ? green :  blue,
//         secondary: isDevelopment ? indigo :indigo,

   

//         // error: red,
//         error: {
//           main : "#ff3548",
//         },
//         success: {
//           main : "#01c851",
//         },
//         warning: {
//           main : "#ff8800",
//         },
//         info:{
//           main:"#33b5e7"
//         }
//       },
//       typography: {
//         // Use the system font.
//         fontFamily:
//           'iransans, -apple-system,system-ui,BlinkMacSystemFont,' +
//           '"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
//       },
//       overrides : {
//         MuiDivider:{
//           root:{
//             width:'100%'
//           }
//         },
//         MuiPaper:{
//           root : {
//             // backgroundColor: '#ffffff7d'
//             backgroundColor:'#ffffffe0'
//           }
//         },
//         MuiPopover: {
//           paper : {
//             backgroundColor: 'white'
//           }
//         },
//         MuiMenu : {
//           paper : {
//             backgroundColor: 'white'
//           }
//         },
//         MuiLink:{
//           button:{
//             fontSize:'0.875rem'
//           }
//         },
//         MuiDialog:{
//           paper : {
//             backgroundColor: 'white'
//           }
//         },
//       }
    

//     })
//   }

//   componentWillMount() {
//     const direction = 'rtl';
//     const theme = this.getTheme(direction)
//     this.setState({
//       theme,
//       direction,
//       profile:getProfile()
//     })
//   }


//    switchUser=async (userId)=>{

//     await switchUser(userId);
//     console.log('the user is switched')
//     this.setState({profile:getProfile()});
 
//     // this.props.history.push("/")

//   //   return <Redirect
//   //       to={{
//   //         pathname: "/sign/login",
//   //         state: { from: this.getReturnUrl() }
//   //       }}
//   //     />
//   }


//   render() {
//     const { direction, theme } = this.state
//     return (
      
//       <div dir={direction}>
//         <MuiThemeProvider theme={theme}>
//         <SnackbarProvider maxSnack={3}>

//           <LoginContext.Provider value={{switchUser:this.switchUser, profile:this.state.profile}}>

//           <Router>
//             <div style={styles.container}>
//               <Auth />
//               <AjaxHandler />
              
//               <Switch>
//                   {/* <Route exact path="/" component={Home} /> 
//                   <Route path="/home" component={Home} />  */}
//                                     <Route path="/auth-callback" component={OAuthCallback} />

//                   <Route path="/sign" component={Sign} />
//                   <Route path="/otp" component={Otp} />
//                   <Route path="/pwd" component={Pwd} />
                  

//                   <PrivateRoute requireRole='Subscriber,Administrator' path="/admin" component={AdminPanel} />
//                   <PrivateRoute requireRole='SuperAdmin' path="/superadmin" component={SuperAdminPanel} />

//                   <PrivateRoute  path="/benefactor" component={BenefactorPanel} />
//                   {/* <PrivateRoute requireRole='Employee' path="/admin" component={AdminPanel} /> */}
//                   <Route render={() => <center><h1>{'صفحه مورد نظر یافت نشد'}</h1><br /><Link to='/' >{'صفحه اصلی'}</Link></center>} />
//                 </Switch>
//                 <ModalContainer />
//             </div>
//             </Router>
//             </LoginContext.Provider>
//         </SnackbarProvider>
//         </MuiThemeProvider>
//       </div>
//         );
//       }
//     }

//     const styles={
    
//       container:{
//         // backgroundColor:'#f0f0f0',
//         height:'100%',
//         width:'100%',
//         //position:'fixed',
//         overflow:'auto'
//       }
//     }
    
    // export default App;
