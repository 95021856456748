import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateResellerSetting } from '../../api'
import {hasValue} from '../../../../../utils/string'
import { currencyFormat } from '../../../../../utils/objectHelper';

import TextInput from '../../../../../Component/TextInput'

export const ResellerPricePerGb =  () => {
    const { t } = useTranslation('resellerSetting');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('resellerPricePerGb.title')}
                   name={"resellerPricePerGb"}
                   component={TextInput}
                   money
                   />
        </FormControl>
    </Grid>
    </>
}
export const validate = (values, errors, t)=> {
    return errors;
}


export function ResellerPricePerGbView({ values, onRefresh, readonly }) {
    const { t } = useTranslation('resellerSetting');

    const update = async (values) =>{
        const {id, resellerPricePerGb }=values;
        return await updateResellerSetting({id, resellerPricePerGb });
    }

    const successMessage  = (values) => t('updated')

    const { resellerPricePerGb } = values;

    return <ViewField successMessage={successMessage}
                        title={ t('resellerPricePerGb.title')}
                        headline={ resellerPricePerGb? currencyFormat(resellerPricePerGb,t('common:currency')) :t("common:notEntered") }
                        update={update}
                        validate={validate}
                        values={values}
                        onRefresh={onRefresh}
                        readonly = {readonly}
                        editComponent={ ResellerPricePerGb } />
    
}


