import React, { PureComponent } from 'react'
import { getUser } from '../api'
// import { getRecurrentDonations } from '../../RecurrentDonation/api'

import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import EditableViewForm from './_Component';

 class EditPage extends PureComponent {

    state = {
        user: {},
        // userId: this.props.match.params.id,
        recurrents:[],
        recurrentCounts:[]
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.loadData()
        }
    }
    async componentDidMount() {
        await this.loadData();
    }

    async loadData(){
        const userId = this.props.match.params.id;

        var user = await getUser(userId)
        // var recurrents = await getRecurrentDonations({benefactorId:userId});
        // var recurrents = await getRecurrentsWithDonationCount(this.state.userId)
        // var recurrentCounts = await getRecurrentCounts(this.state.userId)

        if (!user) return;

        this.setState({ user, userId:user.id })
    }

    refresh = async ()=>{
        await this.loadData();
    }

    render() {
        const {user, recurrents} = this.state;

        return <EditableViewForm onRefresh={this.refresh}
                  values={user }
                  recurrents = {recurrents}/>
    }
}


export default withSnackbar(withTranslation('user')(EditPage));