import React, { PureComponent } from 'react'
import Login from './Login/index'
import Register from './Register'
import { Route, Switch  } from "react-router-dom"
export default class Sign extends PureComponent {
    render() {
        return (
            <div>
                <Switch >
                    <Route path="/sign/register" component={Register} />
                    <Route path="/sign/login" component={Login} />
                </Switch>
            </div>
        )
    }
}
