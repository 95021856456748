import React from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import TextInput from '../../../../../Component/TextInput'
import { useTranslation } from 'react-i18next';
import { updateUser, exists } from '../../api'
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import {userTitle} from '../../../../../domain/extensions/userExtensions'
import { Link } from 'react-router-dom';

export const Name =  ({t=useTranslation('user').t}) =>
<>
<Grid item sm={6} xs={12}  >
    <FormControl fullWidth >
        <Field
            label={t('firstName.title')}
            name={"firstName"}
            component={TextInput}
            type="text"
        />
    </FormControl>
</Grid>
<Grid sm={6} xs={12}  item  >
    <FormControl fullWidth >
        <Field
            label={t('lastName.title')}
            name="lastName"
            component={TextInput}
            type="text"
        />
    </FormControl>
</Grid>
</>



export const validate = async (values, errors, t, warnings)=> {
    if (!values.firstName) {
        errors.firstName = t('firstName.required');
    }

    if (!values.lastName) {
        errors.lastName = t('lastName.required');
    }

    if(values.firstName && values.lastName && !values.id){ // only if it's create mode
        var found = await exists({firstName:values.firstName,lastName:values.lastName });
        if(found.id) warnings.lastName= <span>هم نام <Link to={`/admin/user/${found.id}`}>  {userTitle(found)}  </Link> از قبل ثبت شده است!</span>;

    }

    return errors;
}


class View extends React.Component {
    
    update = async (values) =>{
        const {id,firstName, lastName}=values;
        return await updateUser({id, firstName, lastName});
    }

    successMessage  = (values) => 
        values.firstName+ ' '+ values.lastName+ ' با موفقیت تغییر کرد!';

    render() {
        const { values, onRefresh, t } = this.props;
        const { firstName, lastName } = values;

        return <ViewField
            successMessage={this.successMessage}
            title= { t('firstName.title')}
            headline  = { firstName+ ' ' + lastName }
            update ={this.update} 
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            editComponent={Name}/>
    }
}


export const NameView = withTranslation('user')(View);