export const allGenders = [
    {
        "id": 0,
        "systemName": "male",
        "displayName" : "آقا"
    },
    {
        "id": 1,
        "systemName": "female",
        "displayName" : "خانم"
    }
];

export const byId = (id)=>{
    return allGenders.find(g=>g.id === id) || {}
}

export const titleById = (id)=>{
    return byId(id).displayName || "";
}