import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('beshkan');

export const insertServerProvider = async (serverProvider) => {

    const resultEntity = await post(`serverProvider`,serverProvider,
    {
        errorMessage : t('common:insertError') + t('serverProvider:serverProvider')
    });

    if(!resultEntity) return null;

    return {...serverProvider, id:resultEntity.id};
}


export const updateServerProvider = async (serverProvider) => {

    const result = await putFormData(`serverProvider/${ serverProvider.id }`,
        serverProvider,
        {
            errorMessage :  t('common:updateError') + t('serverProvider:serverProvider')
        });

    return result;
}

export const deleteServerProvider = async (id) => {

    const result = await del(`serverProvider/${id}`,
        {
            errorMessage : t('common:deleteError') + t('serverProvider:serverProvider')
        });

    return result;
}

export const getServerProviders = async (params) => {

    const result = await get(`serverProvider`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('serverProvider:serverProviders')

    });

    return result;
}



export const getServerProvider = async (id) => {

    const result = await get(`serverProvider/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('serverProvider:serverProvider')
    });

    return result;
}



