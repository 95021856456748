import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateOccasion } from '../../api'
import {allCalendarTypes, titleById} from '../../../../../domain/calendarType'
import Selector from '../../../../../Component/Selector'

export const CalenderType =  ({t=useTranslation('occasion').t }) =>{

    return <Grid item sm={ 6 } xs={12}>
    <FormControl fullWidth>
        <Field component={Selector}
               label={t('calenderType.title')}
               name="calenderType"
               options={allCalendarTypes }
               // keyName="id"
               optionTitle="displayName" />
    </FormControl>
</Grid>
}

export const validate = (values, errors, t)=> {
    if ((values.recurrentType === 0 || values.recurrentType === 2  || values.recurrentType === 4) && values.calenderType === undefined) {
        errors.calenderType = t('calenderType.required');
    }
    return errors;
}

class View extends React.Component {

    update = async (values) =>{
        const { id, calenderType }=values;
        return await updateOccasion({id, calenderType });
    }

    headline(values){
        const { calenderType } = values;
        return titleById(calenderType);
    }

    successMessage  = () => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;

        return <ViewField 
            successMessage={this.successMessage}
            title={ t('calenderType.title')}
            headline={ this.headline(values) }
            update={this.update}
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            editComponent={ CalenderType } />

    }
}

export const CalenderTypeView = withTranslation('occasion')(View);