import { createApi } from '../../utils/Ajax'
import {t} from '../../i18n';
const { post, del, get, putFormData, postFormData } = createApi('beshkan');



// export const getSubscriptions = async (params) => {

//     const result = await get(`subscription`,
//     { ...params }
//     ,
//     {
//         errorMessage : t('common:fetchError') + t('subscription:subscriptions')

//     });

//     return result;
// }

export const getDashboardData = async () => {

    const result = await get(`dash`,
    {},
    {
        errorMessage : t('common:fetchError') + t('subscription:subscription')
    });

    return result;
}




