import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateSubscription } from '../../api'
import {hasValue} from '../../../../../utils/string'
import { hasRole, getCurrentUser } from '../../../../../modules/Auth'

import {allSubscriptionStatuses, byId, getAllStatus, titleById} from '../../../../../domain/subscriptionStatus'
import Selector from '../../../../../Component/Selector'

export const SubscriptionStatus =  ({values}) =>{
    const { t } = useTranslation('subscription');

    return <Grid item sm={ 12 } xs={12}>
    <FormControl fullWidth>
        <Field component={Selector}
               label={t('subscriptionStatus.title')}
               name="subscriptionStatus"
               options={getAllStatus() }
               // keyName="id"
               optionTitle="displayName" />
    </FormControl>
</Grid>
}

export const validate = (values, errors, t)=> {
    if (values.subscriptionStatus === undefined) {
        errors.subscriptionStatus = t('subscriptionStatus.required');
    }
    return errors;
}

class View extends React.Component {

    update = async (values) =>{
        const { id, subscriptionStatus }=values;
        return await updateSubscription({id, subscriptionStatus });
    }

    headline(values){
        const { subscriptionStatus } = values;
        return titleById(subscriptionStatus);
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, readonly, t } = this.props;

        
        const currentUserId = getCurrentUser().id;
        const isSameUser = currentUserId === values.id;
        // const { subscriber: { id = currentUserId } = {}, t } = this.props;

        // if(!id) return <></>;
        // const subscriber = { ...this.props.subscriber, id };

        // const isSameUser = id == currentUserId;

        return <ViewField 
            successMessage={this.successMessage}
            title={ t('subscriptionStatus.title')}
            headline={ this.headline(values) }
            update={this.update}
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            readonly = {isSameUser?true:false}
            editComponent={ SubscriptionStatus } />

    }
}

export const SubscriptionStatusView = withTranslation('subscription')(View);