import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateBundle } from '../../api'
import {hasValue} from '../../../../../utils/string'

import {allBundleStatuses, byId, titleById} from '../../../../../domain/bundleStatus'
import Selector from '../../../../../Component/Selector'

export const Status =  ({values}) =>{
    const { t } = useTranslation('bundle');

    return <Grid item sm={ 12 } xs={12}>
    <FormControl fullWidth>
        <Field component={Selector}
               label={t('status.title')}
               name="status"
               options={allBundleStatuses }
               // keyName="id"
               optionTitle="displayName" />
    </FormControl>
</Grid>
}

export const validate = (values, errors, t)=> {
    if (values.status === undefined) {
        errors.status = t('status.required');
    }
    return errors;
}

class View extends React.Component {

    update = async (values) =>{
        const { id, status }=values;
        return await updateBundle({id, status });
    }

    headline(values){
        const { status } = values;
        return titleById(status);
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, readonly, t } = this.props;

        return <ViewField 
            successMessage={this.successMessage}
            title={ t('status.title')}
            headline={ this.headline(values) }
            update={this.update}
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            readonly = {readonly}
            editComponent={ Status } />

    }
}

export const StatusView = withTranslation('bundle')(View);