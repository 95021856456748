import React, { PureComponent } from 'react'
import { getOccasion } from '../api'
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import EditableViewForm from './EditableViewForm';

 class EditPage extends PureComponent {

    state = {
        occasion: {},
        occasionId: this.props.match.params.id
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData(){
        var occasion = await getOccasion(this.state.occasionId)

        if (!occasion) return;

        this.setState({ occasion })
    }

    refresh = async ()=>{
        await this.loadData();
    }

    render() {
        return <EditableViewForm onRefresh={this.refresh}
                  values={this.state.occasion } />
    }
}


export default withSnackbar(withTranslation('occasion')(EditPage));