import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from '../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import TextInput from '../../../../../Component/TextInput'
import Switch from '../../../../../Component/Switch'

export const GenerateDonations =  ({values, t=useTranslation('setting').t}) =>
<>
<Grid item sm={ 6 } xs={12}>
    <FormControl fullWidth>
        <Field label={t('نماینده ی های نمونه')}
               name={"generateDonations"}
               component={Switch} />
    </FormControl>
</Grid>
{values.generateDonations&&
<Grid item sm={ 6 } xs={12}>
<FormControl fullWidth>
        <Field placeholder={'تعداد'}
               name={"donationCount"}
               number
               component={TextInput}
               adornment={'رکورد'} />
    </FormControl>
</Grid>
}
</>

export const validate = (values, errors)=> {
    if(values.generateDonations && !values.donationCount)
        errors.donationCount = "تعداد نمونه باید بزرگتر از صفر باشد";
    return errors;
}
