import React from 'react';
import {LoginContext} from './LoginContext'


export function withLogin(Component) {
    return function withLoginComponent(props)  {
        return (<LoginContext.Consumer>
            {mainState => <Component {...props} 
                profile={mainState.profile} 
                switchUser={mainState.switchUser}
                />}
        </LoginContext.Consumer>)
    }
}

  
