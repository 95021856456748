import React from 'react';
import {FormContext} from '../Contexts/FormContext'
import {update as updateObject} from '../utils/objectHelper'

export default class Form extends React.Component {
    state={
        values:this.props.values || this.props.defaultValues||{},
        exValues:this.props.defaultExValues||{},
        errors:{},
        warnings:{},
        touches:{},
        allTouched: false,
        submitting:false,
        // errorFieldRenderd:false,
        // errorsOnSubmit:false
    }
    errorsOnSubmit = false;
    hasSubmitError= ()=>{
        return this.errorsOnSubmit;
        // const { errorsOnSubmit } = this.state;
        // if(errorsOnSubmit && this.errorsOnSubmit) return true;
    }
    errorFieldRenderd =false;
    /**
     * A field has error
     * 
     * Grouped forms need to know any error fields is rendered or not.
     * When submiting a form some error fields may not be rendered.
     */
    isErrorRendered=(name)=> {
        // let { errorFieldRenderd } = this;//this.state;
        if(!this.errorsOnSubmit) {
            // console.log('!this.hasSubmitError and isErrorRendered')
            return true;
        };

        if (!name){

            if(this.errorFieldRenderd) {
                // reset errorsOnSubmit
                this.errorsOnSubmit = false;
                // this.setState({errorsOnSubmit:false});
                // console.log('!name & errorFieldRenderd ')
                return true;
            }
            return false;
        }
        
        if (!this.errorFieldRenderd ) 
        {
            // || !errorFieldRenderd
            this.errorFieldRenderd = true;
            // this.setState({errorFieldRenderd:true});
        }
        
    }

    reset=(name)=>{
        var names=name.split(',');

        // const { [names[0]]: value, ...values } = this.state.values;
        
        var exValues= this.state.exValues;
        var values= this.state.values;
        
        names.forEach((n, i)=>{
            
            if(exValues.hasOwnProperty(n)) {
                delete exValues[n];
                exValues = {...exValues};
                
                // var { [n]: exValue, ...exValues } = exValues;

            } 
            if(values.hasOwnProperty(n)){
                delete values[n];
                values = {...values};
            }
        });
      

        this.setState({values, exValues});


    }
    resetOld=(name)=>{
        var names=name.split(',');

        const { [names[0]]: value, ...values } = this.state.values;
        names.forEach((n)=>{

        })
        ;
        if(names[1])
        {
            var { [names[1]]: exValue, ...exValues } = this.state.exValues;
        } else{
            exValues= this.state.exValues;

        }

        this.setState({values, exValues});


    }
    
    update=(obj, exObj, options, exOptions)=>{
        var values = this.state.values;
        var exValues = this.state.exValues;

        if(obj){
            values = {...values};
            updateObject(values, obj, options);
        }

        if(exObj){
            exValues = {...exValues};
            updateObject(exValues, exObj, exOptions);
        }
        
        this.setState({values, exValues});
    }

    set=(name, value)=>{
        this.setState({values:{...this.state.values, [name]:value}});
    }

    setEx=(name, value)=>{
        this.setState({exValues:{...this.state.exValues, [name]:value}});
    }
    handleSubmit= async () => {

        
        const {validate} = this.props;        

        const allValues = {...this.state.values, ...this.state.exValues};

        var errors = await validate(allValues);


        if(Object.keys(errors).length) {
            this.errorsOnSubmit = true;
            this.errorFieldRenderd=false;
            this.setState({ errors, allTouched:true });
            return;
        }
        
        
        this.setState({ submitting:true});
        var result = await this.props.onSubmit(this.state.values, this.state.exValues);
        if(!result)
            this.setState({ submitting:false});
        
    };

    onChange= (name, exName) => (e, exValue)=>{
        // const {validate} = this.props;        
        let value;
        
        if(e&&e.hasOwnProperty('target')){            
            value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        } else value=e||'';

        const values = {...this.state.values, [name]:value};
        
        
        let exValues = this.state.exValues;
        if(exName) {
            exValues= {...this.state.exValues, [exName]:exValue};
        }

  
        
        // const allValues = {...values, ...exValues};

        // var errors = validate(allValues);

        // clear error state of this field only, if is errored
        var  errors= this.state.errors;
        if (errors.hasOwnProperty(name)){
            const { [name]:ignored, ...otherErrors } = errors;
            errors = otherErrors;
        }

        this.setState({values, errors, exValues});
        
    };
    onBlur= name => async (e)=>{
        const { validateField, validate} = this.props;        

        const touches = {...this.state.touches, [name]:true};
        const allValues = {...this.state.values, ...this.state.exValues};


        
        if(validateField) {
            let errors = {...this.state.errors};
            let warnings = {...this.state.warnings};
            await validateField(name, allValues, errors, warnings);
            this.setState({touches, errors, warnings});

        } else {
            let errors = await validate(allValues);
            // console.log(errors);
            this.setState({touches, errors});

        }

        
    };
    render() {
        const {component : Component, ...props} = this.props;
        // const {errorsOnSubmit} = this.state;
        const values = this.state.values;
        const exValues = this.state.exValues;

        const allValues = {...this.state.exValues, ...this.state.values};


        return (
            <FormContext.Provider  value={{
                hasSubmitError:this.hasSubmitError, 
                isErrorRendered:this.isErrorRendered,
                handleSubmit:this.handleSubmit, 
                allTouched:this.state.allTouched, 
                submitting:this.state.submitting, 
                onChange:this.onChange,
                onBlur:this.onBlur, 
                values,
                exValues, 
                errors:this.state.errors,
                warnings:this.state.warnings, 
                touches:this.state.touches
            }}>
                {/* {this.props.children} */}
                <Component  
                    submitting={this.state.submitting} 
                    handleSubmit={this.handleSubmit}                    
                    {...props} 
                    values={allValues} 
                    exValues={exValues} 
                    form={this}/>
            </FormContext.Provider>
        );
        
      
    }
}