import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get,put, putFormData } = createApi('beshkan');

export const insertIsland = async (island) => {

    const resultEntity = await post(`island`,island,
    {
        errorMessage : t('common:insertError') + t('island:island')
    });

    if(!resultEntity) return null;

    return {...island, id:resultEntity.id};
}


export const updateIsland = async (island) => {

    const result = await putFormData(`island/${ island.id }`,
        island,
        {
            errorMessage :  t('common:updateError') + t('island:island')
        });

    return result;
}

export const setCurrentIsland = async (id) => {

    const result = await put(`island/${id}/set-current-island`,
        {
            errorMessage : t('common:updateError') + ': setCurrentIsland'
        });

    return result;
}

export const deleteIsland = async (id) => {

    const result = await del(`island/${id}`,
        {
            errorMessage : t('common:deleteError') + t('island:island')
        });

    return result;
}

export const getIslands = async (params) => {

    const result = await get(`island`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('island:islands')

    });

    return result;
}



export const getIsland = async (id) => {

    const result = await get(`island/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('island:island')
    });

    return result;
}



