import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateConnectionConfig } from '../../api'
import {hasValue} from '../../../../../utils/string'

import TextInput from '../../../../../Component/TextInput'

export const ClientConfig =  () => {
    const { t } = useTranslation('connectionConfig');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('clientConfig.title')}
                   name={"clientConfig"}
                   component={TextInput}
                   />
        </FormControl>
    </Grid>
    </>
}
export const validate = (values, errors, t)=> {
    if ( !hasValue(values.clientConfig)) {
        errors.clientConfig = t('clientConfig.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, clientConfig }=values;
        return await updateConnectionConfig({id, clientConfig });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { clientConfig } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('clientConfig.title')}
                          headline={ clientConfig }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ ClientConfig } />
    }
}


export const ClientConfigView = withTranslation('connectionConfig')(View);