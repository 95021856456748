import React, { PureComponent } from 'react'
import { getCity } from '../api'
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import EditableViewForm from './EditableViewForm';

 class EditPage extends PureComponent {

    state = {
        city: {},
        cityId: this.props.match.params.id
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData(){
        var city = await getCity(this.state.cityId)

        if (!city) return;

        this.setState({ city })
    }

    refresh = async ()=>{
        await this.loadData();
    }

    render() {
        return <EditableViewForm onRefresh={this.refresh}
                  values={this.state.city } />
    }
}


export default withSnackbar(withTranslation('city')(EditPage));