import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateRole } from '../../api'
import TextInput from '../../../../../Component/TextInput'

export const DisplayName =  ({t=useTranslation('role').t}) =>
<>
<Grid item sm={ 12 } xs={12}>
    <FormControl fullWidth>
        <Field label={t('displayName.title')}
               name={"displayName"}
               component={TextInput}
               type="text" />
    </FormControl>
</Grid>
</>

export const validate = (values, errors, t)=> {
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, displayName }=values;
        return await updateRole({id, displayName });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { displayName } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('displayName.title')}
                          headline={ displayName }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ DisplayName } />
    }
}


export const DisplayNameView = withTranslation('role')(View);