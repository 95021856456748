export const groupBy = (collection, property) => {

    var groups = {}
    collection.forEach(function (item) {
        var list = groups[item[property]]

        if (list) {
            list.push(item)
        } else {
            groups[item[property]] = [item]
        }
    })

    return groups
}


export const byPath = function(obj, path){
  console.log('byPath');

  console.log(path)
  console.log(obj)
  var i=0, len;  
  for (path=path.split('.'), len=path.length; i<len && obj; i++){
        obj = obj[path[i]];
    };
    return obj;
};


export const byExp=function (obj, expression){

    // optionTitle can be a function of + seperator text
    // ex. firstName+lastName
    // ex. p => p.firstName + p.LastName
    if(!obj) return "";
    if(typeof expression === "function")
      return expression(obj)
    if(expression.indexOf('+')>=0){
      var result="";
      expression.split('+').forEach(item=>result+=' '+ byPath(obj,item));
      return result;
    }
    return obj[expression]
  }

  export  function update(o, newValues, {excludeObjects=false, exclude=[], include=[]}={})
  {


    if(!newValues) return o;

    for (var member in newValues) {

      var newVal = newValues[member];

      var isObject = typeof newVal === 'object' && newVal !== null;
      
      if (excludeObjects && isObject && !include.indexOf(member)>=0)
        continue;
      if (exclude.indexOf(member)>=0)
        continue;

      o[member] = newVal;
    }

    return o;
  }

  export  function currencyFormat(num, unit='ریال') {
    if(!num) return num;
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ' '+unit
  }

  export function currencyBrief(num, unit, brief=true) {
    // num=num/10; // tomans
    if (num<=99999) return currencyFormat(num, unit);

    num=num/1000;
    if (num<999) return currencyFormat(num.toFixed(0) + (brief?'هـ':' هزار'), unit);

    num=num/1000;
    if 
    (num<999) return currencyFormat(num.toFixed(1) + (brief?'مـ':' میلیون'), unit);

    num=num/1000;
    if (num<999) return currencyFormat(num.toFixed(2) + (brief?'م‌د':' میلیارد'), unit);

    num=num/1000;
    if (num<999) return currencyFormat(num.toFixed(3) + (brief?'بـ':' بیلیون'), unit);

    num=num/1000;
    if (num<999) return currencyFormat(num.toFixed(4) + (brief?'ب‌د':' بیلیارد'), unit);

    num=num/1000;
    if (num<999) return currencyFormat(num.toFixed(4) + (brief?'تـ':' تریلیون'), unit);
    
    num=num/1000;
    if (num<999) return currencyFormat(num.toFixed(4) + (brief?'ت‌د':' تریلیارد'), unit);

    num=num/1000;
    if (num<999) return currencyFormat(num.toFixed(4) + (brief?'کـ':' کوآدریلیون'), unit);

    num=num/1000;
    if (num<999) return currencyFormat(num.toFixed(4) + (brief?'کد':' کادریلیارد'), unit);
}