import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateUser, exists } from '../../api'
import TextInput from '../../../../../Component/TextInput';
import {userTitle} from '../../../../../domain/extensions/userExtensions';
import { Link } from 'react-router-dom';

export const Phone =  ({t=useTranslation('user').t}) =>
<>
<Grid item xs={12}>
    <FormControl fullWidth>
        <Field label={t('phone.title')}
               name={"phone"}
               component={TextInput}
               type="text" />
    </FormControl>
</Grid>
</>

export const validate = async (values, errors, t)=> {
    if(values.phone){
        if(values.phone.length!==11 || values.phone.charAt(0)!=='0'){
            errors.phone = t('phone.invalid');
        } else {
            var found = await exists({excepId:values.id,phone:values.phone});
            if(found.id) errors.phone= <span>این تلفن همراه متعلق به <Link to={`/admin/user/${found.id}`}>  {userTitle(found)}  </Link> است!</span>;
        }
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, phone }=values;
        return await updateUser({id, phone });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { phone } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('phone.title')}
                          headline={ phone }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ Phone } />
    }
}


export const PhoneView = withTranslation('user')(View);