
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import {t} from '../i18n';

// import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

const styles = theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
});

const useUnderlineStyles = makeStyles(styles);

const useStyles = makeStyles(theme => ({
  ...styles(theme),
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  }
}));

export default function AutoComplete(props) {
  const classes = props.underline?useUnderlineStyles():useStyles();
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);


  const handleChange = event => {
    setInputValue(event.target.value);
  };

  const fetch = React.useMemo(
    () =>
      throttle(async (input, callback) => {
        var results = await props.onSearch(input);
        if(results) callback(results);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;



    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetch(inputValue, results => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  return (
    <Autocomplete
    classes={props.classes}
      id="acro-autocomple"

      getOptionLabel={option =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      freeSolo
      disableOpenOnFocus
      renderInput={params => (
        <TextField
        {...{...params, InputProps:{
          ...params.InputProps, classes, ...props.InputProps, className:classes.input}}}
          placeholder={props.placeholder || t("panel:search-subscriber")}
          fullWidth
          onChange={handleChange}
        />
      )}
      renderOption={option => {


        return props.children(option);
      }}
    />
  );
}


