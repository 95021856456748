
import { loggedIn, hasRole } from '../modules/Auth'
import React from "react"
import {
  Route,
  Redirect,
  
} from "react-router-dom"


const PrivateRoute = ({ component: Component, ...rest }) => {

    const UserHasRouteRole = () => (rest.requireRole === undefined || hasRole(rest.requireRole))
    return <Route
      {...rest}
      render={props =>
        {
          return (loggedIn() && UserHasRouteRole()) ? (
            <Component {...props} {...rest}/> 
          ) : (
            <Redirect
              to={{
                pathname: "/sign/login",
                state: { from: props.location }
              }}
            />
          )
        }
        
      }
    />
    }

  export default PrivateRoute