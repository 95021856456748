import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get,put, putFormData, postFormData } = createApi('beshkan');

export const getByTimes = async (params) => {

    const result = await get(`traffic/times`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('traffics:reportByTimes')

    });

    return result;
}

export const recoverBundleData = async () => {

    const result = await get(`traffic/recoverBundleData`,
    { }
    ,
    {
        errorMessage : t('common:fetchError') + t('traffics:reportByTimes')

    });

    return result;
}

export const insertServer = async (server) => {

    const resultEntity = await post(`server`,server,
    {
        errorMessage : t('common:insertError') + t('server:server')
    });

    if(!resultEntity) return null;

    return {...server, id:resultEntity.id};
}


export const updateServer = async (server) => {

    const result = await putFormData(`server/${ server.id }`,
        server,
        {
            errorMessage :  t('common:updateError') + t('server:server')
        });

    return result;
}

export const setMasterServer = async (id) => {

    const result = await put(`server/${id}/set-as-master`,
        {
            errorMessage : t('common:updateError') + ': setMasterServer'
        });

    return result;
}

export const updateNodeConfig = async (id="") => {
    var url = `server/${id}/update-config`;
    if(!id) url = `server/update-config`;

    const result = await put(url,
        {
            errorMessage : t('common:updateError') + ': updateNodeConfig'
        });

    return result;
}
export const updateNodeSoftware = async (id="") => {
    var url = `server/${id}/update-software`;
    if(!id) url = `server/update-software`;

    const result = await put(url,
        {
            errorMessage : t('common:updateError') + ': updateNodeSoftware'
        });

    return result;
}

export const sendCallback = async (query, id="") => {
    var url = `server/${id}/callback?`;
    if(!id) url = `server/callback?`;

    
    url+="query="+escape(query);

    const result = await put(url,
        {
            errorMessage : t('common:updateError') + ': sendCallback'
        });

    return result;
}




export const deleteServer = async (id) => {

    const result = await del(`server/${id}`,
        {
            errorMessage : t('common:deleteError') + t('server:server')
        });

    return result;
}

export const getServers = async (params) => {

    const result = await get(`server`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('server:servers')

    });

    return result;
}



export const getServer = async (id) => {

    const result = await get(`server/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('server:server')
    });

    return result;
}



