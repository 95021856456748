import React from 'react'
import Grid from '@material-ui/core/Grid'
import { MimeType, MimeTypeView, validate as validateMimeType } from './MimeType'
import { Filename, FilenameView, validate as validateFilename } from './Filename'
import { UpdatedOnUtc, UpdatedOnUtcView } from './UpdatedOnUtc'
import { IsTransient, IsTransientView } from './IsTransient'
import { Width, WidthView } from './Width'
import { Height, HeightView } from './Height'
import { Caption, CaptionView } from './Caption'
import { Description, DescriptionView } from './Description'
import { Size, SizeView } from './Size'
import { AltText, AltTextView } from './AltText'
import { CreatedOnView } from './CreatedOn'
import { UpdatedOnView } from './UpdatedOn'
import { CreatedByView } from './CreatedBy'
import { Duration, DurationView } from './Duration'
import { IsExternalUrl, IsExternalUrlView, validate as validateIsExternalUrl } from './IsExternalUrl'

export const validators={
    validateMimeType, validateFilename, validateIsExternalUrl 
};

export const validate =(values, errors, t) => {

    for (const [, func] of Object.entries(validators)) {
        func(values, errors, t);
      }
}

export const FormFields = (props) =>
{
    return <>
<Grid container spacing={2} style={{padding:25, paddingTop:0}}>

        <MimeType />
        <Filename />
        <UpdatedOnUtc />
        <IsTransient />
        <Width />
        <Height />
        <Caption />
        <Description />
        <Size />
        <AltText />
        <Duration />
        <IsExternalUrl />
</Grid>
</>
}

export const EditableViewFields = (props) =>
{
return <>

    <MimeTypeView {...props} />
    <FilenameView {...props} />
    <UpdatedOnUtcView {...props} />
    <IsTransientView {...props} />
    <WidthView {...props} />
    <HeightView {...props} />
    <CaptionView {...props} />
    <DescriptionView {...props} />
    <SizeView {...props} />
    <AltTextView {...props} />
    <CreatedOnView {...props} />
    <UpdatedOnView {...props} />
    <CreatedByView {...props} />
    <DurationView {...props} />
    <IsExternalUrlView {...props} />

</>

}

