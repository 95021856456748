import React from 'react'
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText  from '@material-ui/core/FormHelperText';

export default ({
  input: { name, onChange, value },
  label,
  defaultValue,
  money,
  number,
  meta,
  hint,
  adornment,
  ...rest
}) => {
if(money) adornment='تومان';
  return <>

 {/* <TextField
  {...rest}
  name={name}
  helperText={meta.touched&&meta.error ? meta.error : hint}
  error={meta.error && meta.touched}
  inputProps={restInput}
  onChange={onChange}

  value={value}
/> */}
  
  {label&&<InputLabel error={meta.error && meta.touched} htmlFor="adornment">{label}</InputLabel>}

  <Input
  id="adornment"
    {...rest}
    name={name}
    error={meta.error && meta.touched}
    onChange={function (e) { onChange(value, e.target.files[0])}}
    inputProps={{accept:rest.accept}}
    endAdornment={adornment&&<InputAdornment position="end">{adornment}</InputAdornment>}
    value={value || defaultValue}
  />
  {((meta.touched&&(meta.error || meta.warning)) || hint)&&
  <FormHelperText error={meta.error && meta.touched}>{(meta.touched && (meta.error || meta.warning)) || hint}</FormHelperText>
  }

</>
}
