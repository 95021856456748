import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('directories');

export const insertProvince = async (province) => {

    const resultEntity = await post(`province`,province,
    {
        errorMessage : t('common:insertError') + t('province:province')
    });

    if(!resultEntity) return null;

    return {...province, id:resultEntity.id};
}


export const updateProvince = async (province) => {

    const result = await putFormData(`province/${ province.id }`,
        province,
        {
            errorMessage :  t('common:updateError') + t('province:province')
        });

    return result;
}

export const deleteProvince = async (id) => {

    const result = await del(`province/${id}`,
        {
            errorMessage : t('common:deleteError') + t('province:province')
        });

    return result;
}

export const getProvinces = async (params) => {

    const result = await get(`province`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('province:provinces')

    });

    return result;
}



export const getProvince = async (id) => {

    const result = await get(`province/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('province:province')
    });

    return result;
}



