
import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import styled from 'styled-components'
const Wrapper = styled.div`
position: fixed;
top: 0;
left: 0;
right: 0;
z-index:9999999999999;
`;

const LinearProgressBar = () => <Wrapper><LinearProgress color={'secondary'}/></Wrapper>

export default LinearProgressBar