import React from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { updateUser } from '../../api'
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import {ListDialog as ProvincesDialog} from "../../../../Admin/Province/List"
import {ListDialog as CitiesDialog} from "../../../../Admin/City/List"
import Selector from '../../../../../Component/Selector'

export const City =  ({values={}, reset, t=useTranslation('user').t}) =>{
// const [provinceId, setProvinceId] = useState(values.provinceId);
return <>
<Grid item sm={values.provinceId?6:12} xs={12}  >
    <FormControl fullWidth >
        <Field
            component={Selector}
            label={t('province.title')}
            name="provinceId,province"
            onSelect={()=>reset('cityId,city')}
            options = {ProvincesDialog}
            // keyName="id"
            optionTitle="name"
            // objectValue = {values.province}
            // returnObjectKey= "roleId"
        />

    </FormControl>
</Grid>
{values.provinceId&&
<Grid sm={6} xs={12}  item  >
    <FormControl fullWidth >
        <Field
            component={Selector}
            label={t('city.title')}
            name="cityId,city"
            options = {CitiesDialog}
            provinceId={values.provinceId}
            filters={{provinceId : values.provinceId}}
            // keyName="id"
            optionTitle="name"
            // objectValue = {values.city}
            // returnObjectKey= "roleId"
        />
    </FormControl>
</Grid>}
</>

}


export const validate = (values, errors, t)=> {

    if (!values.provinceId) {
        errors.provinceId = t('province.required');
    }

    // if (!values.lastName) {
    //     errors.lastName = t('lastName.required');
    // }

    return errors;
}


class View extends React.Component {
    
    update = async (values) =>{
        const {id,cityId, provinceId}=values;
        return await updateUser({id, cityId, provinceId});
    }
    headline(values){
        const { province, city } = values;
        
        const headline = (province&&province.name) + ' / ' + (city?city.name:'-');
        return headline;
    }
    successMessage  = (values) => 
        'تغییر موقعیت '+(this.headline(values))+ ' با موفقیت انجتم شد!';

    render() {
        const { values, onRefresh, t } = this.props;
 
        return <ViewField
            successMessage={this.successMessage}
            title= { t('province.title')}
            headline  = { this.headline(values) }
            update ={this.update} 
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            editComponent={City}
            />
                
        
    }
}


export const CityView = withTranslation('user')(View);