import { Box, Button } from "@material-ui/core";
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default ({title, image, body, to, entityTitle, imageUrl})=> {
    const { t } = useTranslation('common');

    return (
        <Box display="flex" flexDirection="column" alignItems="center"  >
            <Box p={5} >
                {image || <img alt={t('isEmpty')} style={{width:100}} align="center" width src={imageUrl||"/content/images/empty.png"}/>}
            </Box>
            <Box p={1}><b>
                {title || t('emptyList')}
                </b>
            </Box>
            <Box>
                {body !== undefined || <span>{t('defineFirstEntity', { entityTitle })}</span>}
            </Box>
            <Box p={5}>
                {to && typeof(to) === "string" && <Button component={RouterLink} to={to} variant="contained" color="primary" >{t('newEntity', { entityTitle })}</Button>}
                {to && typeof(to) === "function" && <Button onClick={to} variant="contained" color="primary" >{t('newEntity', { entityTitle })}</Button>}
            </Box>
        </Box>
    );
};
