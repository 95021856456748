import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateSubscription } from '../../api'
import {hasValue} from '../../../../../utils/string'
import Selector from '../../../../../Component/Selector'
import {durationUnits,durationValues, fromHours, toHours, toHumanText} from '../../../../../domain/subscriptionPeroid'

import TextInput from '../../../../../Component/TextInput'

export const AvailableHours =  () => {
    const { t } = useTranslation('subscription');

    return (
      <>
        {/* <Grid item sm={12} xs={12}>
          <FormControl fullWidth>
            <Field
              label={t("availableHours.title")}
              name={"availableHours"}
              component={TextInput}
              number
            />
          </FormControl>
        </Grid> */}
        <Grid item sm={3} xs={6}>
          <Field
            component={Selector}
            label={"مدت اشتراک"}
            name="durationValue"
            adornment="ماه"
            options={durationValues}
            // keyName="id"
            optionTitle="displayName"
          />
        </Grid>
        <Grid item sm={3} xs={6}>
          <Field
            component={Selector}
            label={" "}
            name="durationUnit"
            // adornment=""
            options={durationUnits}
            // keyName="id"
            optionTitle="displayName"
          />
        </Grid>
      </>
    );
}
export const validate = (values, errors, t)=> {
    if (!hasValue(values.availableHours)) {
        errors.availableHours = t('availableHours.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, availableHours }=values;

        return await updateSubscription({id, availableHours });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh,readonly, t } = this.props;
        const { availableHours } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('availableHours.title')}
                          headline={ toHumanText(availableHours) || <Box color={'red'}>منقضی شده</Box> }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          readonly = {readonly}
                          editComponent={ AvailableHours } />
    }
}


export const AvailableHoursView = withTranslation('subscription')(View);