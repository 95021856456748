import React, { PureComponent } from 'react'
import {validate as validateImport } from '../Form/FieldsForImport'
import { withRouter } from "react-router-dom"
import CreateForm from './CreateFormForImport';
import { importFromJson } from '../api'
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {getProfile} from '../../../../modules/Auth' 
 class CreatePage extends PureComponent {
     state={
         
     }
    profile = getProfile() || {}
    defaultValues = {
        // parentId : 0,
        // isLeaf : true,
        // isEnabled:true,
        ...this.props.data,
        // roles: [{
        //     "id": -4,
        //     "name": "Customer",
        //     "displayName": "تست"
        // }],
        provinceId: this.profile.provinceId,
        province: this.profile.province || {},
    }
    onSubmit = async (values, valuesEx) => {
        console.log('valuesEx');
        console.log(valuesEx);
        const { enqueueSnackbar } = this.props;
        var res = await importFromJson({...values,  jsonFile:valuesEx.jsonFile})
        if(!res) {
            return false;
        }

        if (res) {
            if(res.errors){
                this.setState({'errors':res.errors});
            }
            else {
                if (this.state.errors&&this.state.errors.length>0)
                    this.setState({'errors':[]});
                this.props.onClose();
                this.props.history.push('/admin/subscription')
                enqueueSnackbar( 'مشتریکین با موفقیت وارد شدند!', { variant:'success' });
            }
        }

       //this.props.onSubmit(values)
    }

    validate = ( values ) => {
        const {t}= this.props;
        const errors = {}
        const warnings = {}
        validateImport(values, errors, t, warnings);
        this.forceUpdate();

        return errors
    }

    render() {
        const { onCancel, onClose, duplicates } = this.props
        // console.log('data in dialog')
        // console.log(data)
        return <>
        {<Dialog open={true}
                   fullWidth
                    onClose={onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" style={{direction:'rtl'}}></DialogTitle>
        <DialogContent>
            <CreateForm title={'درج دسته ای' }
                onCancel={onCancel || onClose}
                errors={this.state.errors}
                ref={this.form}
                duplicates = {duplicates}
                defaultValues={this.defaultValues}
                validate={this.validate}
                onSubmit={this.onSubmit} />
         
        </DialogContent> 
        

        
    </Dialog>
    }
      
        </>
    }
}

export default withSnackbar(withTranslation('benefactor')(withRouter(CreatePage)));