import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateSubscription } from '../../api'
import {hasValue} from '../../../../../utils/string'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

import Switch from '../../../../../Component/Switch'

export const IsYouth =  () => {
    const { t } = useTranslation('subscription');
    const [open, setOpen] = useState(false);
    const handleTouch = () => {
        setOpen(!open);
    };
    return <>
    <Grid item sm={ 12 } xs={12} style={{whiteSpace:"nowrap"}}>
        <FormControl >
            <Field label={t('isYouth.title')}
                   name={"isYouth"}
                   component={Switch}
                   type="text" />
        
        
        </FormControl>
        <Tooltip 
            title={<span dir="rtl" text >{t('isYouth.hint')}</span>}
            open={open}
            onMouseEnter= {handleTouch}
            onClose={() => setOpen(false)}
        >
        <IconButton onClick={handleTouch} style={{marginLeft:-20, marginRight:-20,marginTop:-4}} aria-label="info">    <InfoIcon /> </IconButton>
        </Tooltip>
    </Grid>
    </>
}

export const validate = (values, errors, t)=> {
    if ((values.isYouth !== true && values.isYouth !==false)) {
        errors.isYouth = t('isYouth.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, isYouth }=values;
        return await updateSubscription({id, isYouth });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values={}, onRefresh, t } = this.props;
        const { isYouth } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('isYouth.title')}
                          headline={ isYouth?t('common:yes'):t('common:no') }
                          update={this.update}
                          validate={validate}
                          values={values}
                          changeTitle= {t('common:enable')}

                          onRefresh={onRefresh}
                          editComponent={ IsYouth }
                          />

    }
}


export const IsYouthView = withTranslation('subscription')(View);
