import React, { PureComponent } from 'react'
import widthForm from '../../../../Contexts/withForm';
import { withTranslation } from 'react-i18next';
import {FormFields} from '../Form';
import FormActionBar from '../../../../Component/FormActionBar';
import RouterBreadcrumbs from '../../../../Component/RouterBreadcrumbs'

class CreateForm extends React.Component{
    render() {
        const {values, reset, t} = this.props;
        const isTrial = window.location.hash.includes("/create/trial");   
        const isReseller = window.location.hash.includes("/create/reseller"); 
        let title = '';

        if (isTrial) {
            title =  t("newTrialSubscription") ;
        } else if (isReseller) {
            title =  t("newReseller");
        }  else{
            title =  t("new") ;
        }
        return <form autocomplete="off">
    <RouterBreadcrumbs title={ title  } />
    <FormFields values={values} reset={reset} t={t} />
    <FormActionBar {...this.props} submitLabel={t('register')} />
</form>
    }
}


export default withTranslation('subscription')(widthForm(CreateForm));