export const allRecurrentTypes = [
    {
        "id": 0,
        "systemName": "specificDay",
        "displayName" : "روز مشخص"
    },
    {
        "id": 1,
        "systemName": "weekly",
        "displayName" : "هفتگی"
    },
    {
        "id": 2,
        "systemName": "monthly",
        "displayName" : "ماهیانه"
    },
    {
        "id": 3,
        "systemName": "seasonal",
        "displayName" : "فصلی"
    },
    {
        "id": 4,
        "systemName": "annually",
        "displayName" : "سالیانه"
    }
];
export const recurrentTypes ={
    specificDay:0,
    weekly:1,
    monthly:2,
    seasonal:3,
    annually:4
};

export const byId = (id)=>{
    return allRecurrentTypes.find(rt=>rt.id===id) || {}
}

export const titleById = (id)=>{
    return byId(id).displayName || "";
}