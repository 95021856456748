import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateResellerSetting } from '../../api'
import {hasValue} from '../../../../../utils/string'

import TextInput from '../../../../../Component/TextInput'

export const MonthlyDiscountRate =  () => {
    const { t } = useTranslation('resellerSetting');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('monthlyDiscountRate.title')}
                   name={"monthlyDiscountRate"}
                   component={TextInput}
                   number
                   adornment="%"
                   />
        </FormControl>
    </Grid>
    </>
}
export const validate = (values, errors, t)=> {
    if (!hasValue(values.monthlyDiscountRate)) {
        errors.monthlyDiscountRate = t('monthlyDiscountRate.required');
    }
    return errors;
}


export function MonthlyDiscountRateView({ values, onRefresh, readonly }) {
    const { t } = useTranslation('resellerSetting');

    const update = async (values) =>{
        const {id, monthlyDiscountRate }=values;
        return await updateResellerSetting({id, monthlyDiscountRate });
    }

    const successMessage  = (values) => t('updated')

    const { monthlyDiscountRate } = values;

    return <ViewField successMessage={successMessage}
                        title={ t('monthlyDiscountRate.title')}
                        headline={ monthlyDiscountRate+"%" }
                        update={update}
                        validate={validate}
                        values={values}
                        onRefresh={onRefresh}
                        readonly = {readonly}
                        editComponent={ MonthlyDiscountRate } />
    
}


