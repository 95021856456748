import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';

import ListPage from './List'
import CreatePage from './Create'
import EditPage from './Edit'
import { Route, Switch } from "react-router-dom";
import { Paper } from '@material-ui/core';



 class ResellerSettingManage extends PureComponent {
    
componentDidMount() {
    if( this.props.fixed !== false) {
      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
    }
  }
  
    
  resize() {
    // set the height of the table as the fixed hight of the content so it can be scrooled

    var wrapper = document.getElementsByClassName(this.props.classes.root)[0];
    if(wrapper && wrapper.style){
        wrapper.style.height = ((window.innerHeight-25) - wrapper.offsetTop)+"px"; 
    }
  }

  

    render() {
        const { match, classes } = this.props;
        return <Paper className={classes.root}>
    <Switch>
        {/* <Route path={`${match.path}/create`} component={CreatePage} /> */}
        <Route path={`${match.path}/:id`} component={EditPage} />
        {/* <Route path={`${match.path}`} exact component={ListPage} /> */}
    </Switch>
</Paper>
    }
}

const styles={
    root:{

        maxWidth: 650,
        padding:20,
        marginLeft:'auto',
        marginRight:'auto',

        overflowY:'scroll',
        scrollY:'auto'
        
        

    }
}

export default withStyles(styles)(ResellerSettingManage);