import React from 'react';
import {FormContext} from '../Contexts/FormContext'

export default class Field extends React.Component {

    // onChange= (e, exValue)=>{
    //     console.log(e.target.value);
    // }
    render() {
 
   

        const {name, component : Component,...rest} = this.props;
        const names = name.split(','); // name , exName ex: "employeeId,employee"
        // For the performance reasons its better to pass only the value and exValue
        //     currently we are passing the whole values and it causes extra rendering for all fields

        return <>
        
           <FormContext.Consumer>
             {({isErrorRendered, onChange, onBlur, values={}, exValues={}, errors,warnings, allTouched, touches})=>{
                let value = values[names[0]];
                let exValue = exValues[names[1]] || values[names[1]];
                const error = errors[names[0]];
                const warning = warnings[names[0]];
                if(error) isErrorRendered(name);
                if(value === undefined) value='';
                return <Component onBlur={onBlur(names[0])} meta={{error, warning,touched:(allTouched || touches[names[0]])}} {...rest} values={values} input={{value, exValue, name:names[0], onChange:onChange(names[0], names[1])}}/>
             }
            }
        </FormContext.Consumer>
        </>;
    }
}