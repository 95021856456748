import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateResellerSetting } from '../../api'
import {hasValue} from '../../../../../utils/string'

import Switch from '../../../../../Component/Switch'

export const IsEnabled =  () => {
    const { t } = useTranslation('resellerSetting');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('isBotEnabled.title')}
                   name={"isEnabled"}
                   component={Switch}
                   type="text" />
        </FormControl>
    </Grid>
    </>
}

export const validate = (values, errors, t)=> {
 
    return errors;
}



export function IsEnabledView({ values, onRefresh }) {
    const { t } = useTranslation('resellerSetting');

    const update = async (values) =>{
        const {id, isEnabled }=values;
        return await updateResellerSetting({id, isEnabled });
    }

    const successMessage  = (values) => t('updated')


    const { isEnabled } = values;

    return <ViewField successMessage={successMessage}
                        title={ t('isBotEnabled.title')}
                        headline={ isEnabled?t('common:active'):t('common:inactive') }
                        changeTitle= {t('common:enable')}

                        update={update}
                        validate={validate}
                        values={values}
                        onRefresh={onRefresh}
                        editComponent={ IsEnabled }
                        />

}


