import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import FormHelperText from '@material-ui/core/FormHelperText'
import {byExp} from '../utils/objectHelper'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

function getStyles(value, selectedValues, theme, keyName) {
  
  return {
    fontWeight:
    (selectedValues||[]).map(o=>o[keyName]).indexOf(value[keyName]) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}


export default ({
  input: { name, onChange, value, ...restInput },
  meta,
  ...rest
}) => {

  
  const classes = useStyles();
  const theme = useTheme();
  const keyName = rest.keyName || "id";
  const optionTitle = rest.optionTitle || "name";
  const options =  rest.options || [];
 
  const titleByKey= (keyValue)=> {
    const option = options.filter(o=>o[keyName] === keyValue)[0];
    return byExp(option, optionTitle);
    // return option&&option[optionTitle]
  }
  const byId=(id)=>{
    return options.filter(o=>o[keyName] === id)[0];
  }

  // select values from options if options is loaded :
  if (options.length)
  {
    value= (value||[]).map(o=>byId(o[keyName]));
  }


  //  return <div>Multi Select</div>
  return <>
<InputLabel >{rest.label}</InputLabel>

<Select  {...rest}
          multiple
          onChange={onChange}
          value={value}
          input={<Input  />}
          inputProps={restInput}
          helperText={meta.touched ? meta.error : undefined}

          renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(s => (
                <Chip
                  key={s[keyName]}
                  label={titleByKey(s[keyName])}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {options.map(o => (
            <MenuItem
              key={o[keyName]}
              value={o}
              style={getStyles(o, value, theme, keyName)}
            >
              {byExp(o, optionTitle)}
            </MenuItem>
          ))}
        </Select>



  {(meta.touched && meta.error) &&
  <FormHelperText style={{ color: '#f44336'}}>{meta.error}</FormHelperText>
  }
  </>
}

