import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('beshkan');

export const insertOccasion = async (occasion) => {

    const resultEntity = await post(`occasion`,occasion,
    {
        errorMessage : t('common:insertError') + t('occasion:occasion')
    });

    if(!resultEntity) return null;

    return {...occasion, id:resultEntity.id};
}


export const updateOccasion = async (occasion) => {

    const result = await putFormData(`occasion/${ occasion.id }`,
        occasion,
        {
            errorMessage :  t('common:updateError') + t('occasion:occasion')
        });

    return result;
}

export const deleteOccasion = async (id) => {

    const result = await del(`occasion/${id}`,
        {
            errorMessage : t('common:deleteError') + t('occasion:occasion')
        });

    return result;
}

export const getOccasions = async (params) => {

    const result = await get(`occasion`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('occasion:occasions')

    });

    return result;
}



export const getOccasion = async (id) => {

    const result = await get(`occasion/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('occasion:occasion')
    });

    return result;
}



