import React from 'react'
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from "@material-ui/core/styles";

const styles = {
  labelError: {color:'#f44336'},
};

const RadioGroupComponent =  ({
  input: { checked, value, name, onChange, ...restInput },
  meta,
  options,
  classes,
  ...rest
}) => 
{
  const hasError = (meta.error && meta.touched);
return (
  <React.Fragment>
  <RadioGroup row
            name={name}
            {...rest}
            inputProps={restInput}
            onChange={onChange}
            checked={!!checked} 
            value={value}
            // error={meta.error && meta.touched}
  >
    {options.map(option => (
      <FormControlLabel 
        value={option.value}
        label={option.label} 
        classes={{
          label:hasError&&classes.labelError,
        }}
        control={<Radio         
          className={
            hasError&&classes.labelError
          }
        
        
        />}
    />))}
  </RadioGroup>

  {hasError &&
    <FormHelperText style={{     marginTop: 0, color: '#f44336'}}>{meta.error}</FormHelperText>
  }
  </React.Fragment>
)
}
export default withStyles(styles)(RadioGroupComponent);

