import React, { PureComponent } from 'react'
import RouterBreadcrumbs from '../../../../Component/RouterBreadcrumbs'
import { withTranslation } from 'react-i18next';
import "filepond/dist/filepond.min.css";
import { EditableViewFields } from '../Components/Fields';

class EditableViewForm extends PureComponent {
    render() {
        const {t, values, onRefresh} = this.props

        return (<>
            <RouterBreadcrumbs title={ t('city') } />
            <EditableViewFields onRefresh={onRefresh} values={values} t={t} />
        </>)
    }
}

export default withTranslation('city')(EditableViewForm);