import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles';
// import DonationCountsWidget from '../Donation/Widgets/DonationCountsWidget'
// import DonationValuesWidget from '../Donation/Widgets/DonationValuesWidget'
// import UserCountsWidget from '../Benefactor/Widgets/UserCountsWidget';
// import ByProvincesWidget from '../Donation/Widgets/ByProvincesWidget';
// import BySubjectsWidget from '../Donation/Widgets/BySubjectsWidget';
// import UserCallsWidget from '../Benefactor/Widgets/UserCallsWidget';
// import ByTimesWidget from '../Donation/Widgets/ByTimesWidget';

export  class AdminHome extends PureComponent {
    render() {

        return (
                <>
                    <Grid container justify="center" spacing={2}>
                        <Grid item sm={4} xs={12}>
                            {/* <DonationCountsWidget /> */}
                        </Grid>

                        <Grid item sm={4} xs={12}>
                            {/* <DonationValuesWidget /> */}
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            {/* <UserCountsWidget /> */}
                        </Grid>
                    </Grid>

                    <Grid container justify="center" spacing={2} style={{marginTop:8}}>
                        <Grid item sm={4} xs={12} >
                            {/* <ByProvincesWidget /> */}
                        </Grid>

                        <Grid item sm={4} xs={12}>
                            {/* <UserCallsWidget /> */}

                        </Grid>
                        <Grid item sm={4} xs={12}>
                            {/* <BySubjectsWidget /> */}
                        </Grid>

                    </Grid>
                    <Grid container justify="center" spacing={2} style={{marginTop:8}}>
                        <Grid item sm={12} xs={12} >
                            {/* <ByTimesWidget /> */}
                        </Grid>
                    </Grid>

                </>
  
        )
    }
}


const styles = theme => ({
    
    root:{
        // padding :20,
        flexGrow: 1,
        // color:"#444444"
    },
    paper: {
        // borderRad
        borderRadius:0
    },
    control: {
        padding: theme.spacing(2),
    },
    link: {
        textDecoration: 'none'
    },
    container: {
        //paddingRight: 25,
        //paddingLeft: 25
    },
    header: {
        //paddingRight: 25
    },
    grid: {
        paddingTop: 10
    },
    
});


// const useStyles = makeStyles(theme => ({
//     root: {
//       flexGrow: 1,
//     },
//     paper: {
//       height: 140,
//       width: 100,
//     },
//     control: {
//       padding: theme.spacing(2),
//     },
//   }));

export default withStyles(styles, { withTheme: true })(AdminHome)