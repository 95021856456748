import React, { PureComponent } from 'react'
import { withRouter } from "react-router-dom"
import 'react-table/react-table.css'
import { getOccasions, deleteOccasion } from '../api'
import { getOccasion, insertOccasion } from '../api'
import FilterListIcon from "@material-ui/icons/FilterList";
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CopyIcon from "@material-ui/icons/FileCopy";
import FilterableGrid from '../../../../Component/FilterableGrid';
import DeleteIcon from "@material-ui/icons/Delete";
import DialogWindow from '../../../../Component/DialogWindow'
import { Link as RouterLink } from 'react-router-dom';
import {withLogin} from '../../../../Contexts/withLogin'
import EmptyList from '../../../../Component/EmptyList'

const ddlColumns = [
    {
        'name': {
            title: 'نام',
            remote: true
        }
    }
];

class DefaultList extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            occasions: [],
            selection: [],
            selectAll: false,
            // occasionId: props.match.params.occasionId,
            openDeleteDialog:false

        }
    }
    area = this.props.match.path.split('/')[1];
    columns = [
        {
            'name': {
                editLink : true,
                // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
                title: 'نام',
                remote: true
            }
        }
    ];

    async componentDidMount() {

        await this.loadOccasions()
    }

    refresh = async () => {
        await this.loadOccasions();
    }

    loadOccasions = async () => {
        var res = await getOccasions()
        if (res) {
            this.setState({ occasions: res, selection :[], selectAll:false, openDeleteDialog:false })
        }
    }

    onFilter = async (params) => {
        var res = await getOccasions({...params})
      
        if (res) {
            const isFiltered = Object.keys(params).length;
            this.setState({ occasions: res, openDeleteDialog:false, isFiltered })
        }
    }

    deleteOccasions = async () => {
        const res = await deleteOccasion(this.state.selection[0])
        if (res !== null){
            await this.loadOccasions()
        }

    }


    showDeleteDialog = (open, selected) => () => {
        this.setState({ selection : selected, openDeleteDialog: open });
    };

    duplicate = (id) => async () => {
        var occasion = await getOccasion(id);
        if(occasion) {
            if( await insertOccasion({...occasion, id:undefined}))
                        await this.loadOccasions()
        }
    };

    onEditClick =(id)=>{
        const { match } = this.props;
        this.props.history.push(`${match.path}/${id}`);
    }
    onDeleteClick = (selected) => {
        this.setState({ selection : selected, openDeleteDialog: true });

    }
    selectionToolbar = (selected)=> {
        const {t}= this.props;
        return <>
        <Tooltip title={t('common:delete')}>
        <IconButton aria-label={t('common:delete')}>
            <DeleteIcon onClick={this.showDeleteDialog(true, selected)} />
        </IconButton>
        </Tooltip>
        <Tooltip title={t('common:duplicate')}>
            <IconButton aria-label={t('common:duplicate')}>
            <CopyIcon onClick={this.duplicate(selected[0])} />
            </IconButton>
        </Tooltip>
        </>
    }

    toolbar = ()=> {
        const { t }= this.props;
        return <>
        <Tooltip title={t('common:new')}>
        <Button component={RouterLink} to={`/${this.area}/occasion/create`} color="primary" >{t('new')}</Button>
        </Tooltip>
        <Tooltip title={t('common:filter')}>
            <IconButton aria-label={t('common:filter')}>
                <FilterListIcon />
            </IconButton>
        </Tooltip>
        </>
    }


DeleteDialog = () => {
    const {t}= this.props;
    return <Dialog
        open={true}
        onClose={this.handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
    <DialogTitle id="alert-dialog-title" style={{direction:'rtl'}}>{t('delete')}</DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {t('delete?')}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.showDeleteDialog(false)} color="primary">
            {t('common:cancel')}
        </Button>
        <Button onClick={this.deleteOccasions } color="primary" autoFocus>
            {t('common:ok')}
        </Button>
    </DialogActions>
    </Dialog>
    }

    render() {
        const { occasions, openDeleteDialog, isFiltered } = this.state
        const {t} = this.props

        if (!isFiltered && !occasions.length) {
                  return <EmptyList 
                    title={t('listIsEmpty')}
                    entityTitle={t('occasion')}
                    to={`/${this.area}/occasion/create`}
                    />
        }
        return (
        <>
            {openDeleteDialog&& this.DeleteDialog()}
            <FilterableGrid 
                data={ occasions } 
                selectable = {this.props.selectable || true}
                pagination={false}
                title={t('occasions')}
                selectionToolbar = {this.selectionToolbar}   
                toolbar = {this.toolbar}                    
                header = {this.props.columns ||this.columns}  
                onDelete = {this.onDeleteClick}
                onEdit = {this.onEditClick}
                onFilter = {this.onFilter}
                onSelect={this.props.onSelect}
            ></FilterableGrid>

        </>
        )
    }
}

export class ListDialog extends PureComponent {

    render() {
        return <DialogWindow  open={this.props.open} onClose={this.props.onClose} > 
            <List  
                columns={ddlColumns}  
                selectable={1}  
                {...this.props} /> 
        </DialogWindow>
    }
}


export const List = withTranslation('occasion')(withLogin(withRouter(DefaultList)));
export default List;
