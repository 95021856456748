import React from 'react'
import Grid from '@material-ui/core/Grid'
import {VerticalTabs, Tab} from '../../../../Component/VerticalTabs'
import Divider from '../../../../Component/Divider'
import { ActiveBundle, ActiveBundleView } from './Fields/ActiveBundle'
import  { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Box, FormGroup, IconButton } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';
import { KeyView } from './Fields/Key'
import { CustomerName, CustomerNameView } from './Fields/CustomerName'
import { TelegramSupport, TelegramSupportView } from './Fields/TelegramSupport'

import { CustomerPhone, CustomerPhoneView } from './Fields/CustomerPhone'
import { MonthsRemaining, MonthsRemainingView, validate as validateMonthsRemaining } from './Fields/MonthsRemaining'
import { UseResellerCreditForRenewal, UseResellerCreditForRenewalView, validate as validateUseResellerCreditForRenewal } from './Fields/UseResellerCreditForRenewal'
import { MonthsConsumedView } from './Fields/MonthsConsumed'
import { NextRenewalDateView } from './Fields/NextRenewalDate'
import { SubscriptionStartDateView } from './Fields/SubscriptionStartDate'
import { SubscriptionType, SubscriptionTypeView, validate as validateSubscriptionType } from './Fields/SubscriptionType'
import { SubscriptionStatusView } from './Fields/SubscriptionStatus'
import {  } from './Fields/Island'
import { SelectedServersView } from './Fields/SelectedServers'
import { subscriptionType } from '../../../../domain/subscriptionType';
import { AvailableTraffic, AvailableTrafficView, validate as validateAvailableTraffic } from './Fields/AvailableTraffic'
import { DurationHours, DurationHoursView, validate as validateDurationHours } from './Fields/DurationHours'
import { PreferredRenewalTraffic, PreferredRenewalTrafficView } from './Fields/PreferredRenewalTraffic'
import { FirstConnectedTime, FirstConnectedTimeView } from './Fields/FirstConnectedTime'
import { LastSubscriptionUpdateView } from './Fields/LastSubscriptionUpdate'


import { AvailableHours, AvailableHoursView, validate as validateAvailableHours } from './Fields/AvailableHours'
import { ConcurrencyLimit, ConcurrencyLimitView, validate as validateConcurrencyLimit } from './Fields/ConcurrencyLimit'
import { Protocol, ProtocolView } from './Fields/Protocol'
import { UserAgent, UserAgentView } from './Fields/UserAgent'
import { UserDevice, UserDeviceView } from './Fields/UserDevice'
import { IsYouth, IsYouthView } from './Fields/IsYouth'
import { IsGovernment, IsGovernmentView } from './Fields/IsGovernment'

import Extensions from '../Extensions'
import QRCode from 'qrcode.react';
import { hasRole } from '../../../../modules/Auth'
import { Alert, AlertTitle } from '@material-ui/lab';
import Link from '@material-ui/core/Link';
import { allUserDevices, userDevice } from '../../../../domain/userDevice'
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  title:{
    margin:0,marginBottom:20,paddingLeft:20, fontWeight:500, fontSize:"1.25rem", color:"rgba(0, 0, 0, 0.54)"
  }
}));


function extractVersion(str) {
  const regex = /(\d+(?:\.\d+)+)/;
  const match = str.match(regex);
  return match ? match[1].split('.').map(Number) : [];
}

function isGreater(ver1, ver2) {
  const version1 = extractVersion(ver1);
  const version2 = extractVersion(ver2);
  const maxLength = Math.max(version1.length, version2.length);

  for (let i = 0; i < maxLength; i++) {
      const num1 = version1[i] || 0;
      const num2 = version2[i] || 0;

      if (num1 > num2) {
          return true;
      } else if (num1 < num2) {
          return false;
      }
  }

  return false;
}

function CopyToClipboardComponent({code}) {
  const [copySuccess, setCopySuccess] = useState('');
  const {t} = useTranslation()
  function copyToClipboard() {
    navigator.clipboard.writeText(code).then(
      function() {
        setCopySuccess(t('common:copied'));
      },
      function(err) {
        // setCopySuccess('Failed to copy text.');
      }
    );
  }

  return (
    <div style={{textAlign:'center'}}>


  
  <div style={{ color:'gray', overflowWrap: "break-word", marginLeft: "8px" }}>
    {code} <IconButton size='small' onClick={copyToClipboard} aria-label="copy">
    <FileCopyIcon />
  </IconButton>
  </div>



  {copySuccess && <span  style={{color:'green'}}>{copySuccess}</span>}
</div>

  );
}

const QRCodeWithBorder = ({ value, size = 200, borderColor = 'black', borderWidth = 4 }) => {
  return (
    <svg width={size} height={size}>
      {/* Border */}
      <rect
        x={borderWidth / 2}
        y={borderWidth / 2}
        width={size - borderWidth}
        height={size - borderWidth}
        fill="none"
        stroke={borderColor}
        strokeWidth={borderWidth}
      />
      {/* QR Code */}
      <QRCode
        value={value}
        size={size - borderWidth}
        renderAs="svg"
        includeMargin={false}
        x={borderWidth}
        y={borderWidth}
      />
    </svg>
  );
};

export default QRCodeWithBorder;
export const validators={
    validateUseResellerCreditForRenewal, validateSubscriptionType, validateConcurrencyLimit, validateAvailableTraffic, validateDurationHours, 
};



    
export const validate =(values, errors, t) => {

    for (const [name, func] of Object.entries(validators)) {
        func(values, errors, t);
      }

}
export const FormFields = (props) =>
{
    const { values } = props;
    const { t } = useTranslation('subscription');
 
    
    return <>
<Grid container spacing={2} style={{ padding:25, paddingTop:0}}>

   
        <CustomerName />
        <CustomerPhone />
        
        <Divider view />
        {!values.isTrial && !values.isReseller && <DurationHours values/>}

        {!values.isTrial && <AvailableTraffic values={values}/>}

        {/* <AvailableHours /> */}
        {!values.isTrial && !values.isReseller && <ConcurrencyLimit /> }

        {/* <FirstConnectedTime /> */}
        {!values.isTrial && values.isReseller && <Divider  />}
   

        {!values.isTrial && !values.isReseller && <Box height={ "80px"} />}

        {/* <SubscriptionType /> */}
        {!values.isReseller && <IsYouth /> }
        {!values.isReseller && <IsGovernment /> }

          {/* <SubscriptionType /> */}

        {/* <UseResellerCreditForRenewal /> */}

        {/* {!values.isReseller && <IsYouth /> } */}


</Grid>
</>
}

function peekMeaningPart(name) {
  var parts = (name||'').split(/[\b_\s\@]+/).filter(part => part.length > 0);
  var result = parts.reduce((a, b) => a.length >= b.length ? a : b, '');
  var all = parts.join('_');
  if (all.length < 10) {
    return all;
  }

  return result;
}
  
function getRemarkValue({ customerPhone='', customerName='', loginKey='' }) {
  customerPhone = customerPhone ||'';
  customerName = customerName || '';
  const lastName = peekMeaningPart(customerName.split('/').pop());
  let remark = customerPhone;

  if ((lastName && lastName.length < remark.length) || !remark) remark = lastName;
  remark = remark || loginKey.slice(0,6);

  return remark.substring(0, 13) ;

}




export const EditableViewFields = (props) =>
{
    const {values, onRefresh} = props;
    const { t } = useTranslation('subscription');
    const classes = useStyles();
    const remark = getRemarkValue(values)

return (
  <>
    <Grid container>
      <Grid item sm={8} xs={12}>
      {/* {props.values.userAgent&&
      props.values.userDevice == userDevice.iOS
      &&props.values.userAgent.toLowerCase().indexOf('streisand')<0&&  <Alert severity="warning">
            <AlertTitle>{t('important')}</AlertTitle>
            {t('recommendation')}
            <Link href="https://apps.apple.com/us/app/streisand/id6450534064" target="_blank" rel="noopener noreferrer"><strong>{t('updateLink')}</strong> </Link>

        </Alert>
    } */}
{/* {props.values.userAgent&&
      props.values.userDevice == userDevice.Android
      &&props.values.userAgent.toLowerCase().indexOf('v2rayng')>=0
      &&!isGreater(props.values.userAgent, 'v2rayNG/1.8.4')
      &&  <Alert severity="warning">
            <AlertTitle>{t('important')}</AlertTitle>
            {t('oldVersionWarning')} <Link href="https://play.google.com/store/apps/details?id=com.v2ray.ang" target="_blank" rel="noopener noreferrer"><strong>{t('updateLink')}</strong> </Link> 
        </Alert>
    } */}


{(!props.values.userAgent || (props.values.userAgent&&
      props.values.userDevice == userDevice.Android
      &&props.values.userAgent.toLowerCase().indexOf('v2ray')>=0 ) )&&  <Alert severity="error">
            <AlertTitle>{t('important')}</AlertTitle>
            {t('useFamilyNgWarning')} <Link href="https://github.com/nurlankusayinov/FamilyVPN/releases" target="_blank" rel="noopener noreferrer"><strong>{t('downloadFamily')}</strong> </Link> 
        </Alert>
    }

      </Grid>
      <Grid item sm={8} xs={12}>
        <KeyView readonly {...props} />
        <CustomerNameView {...props} />
        <CustomerPhoneView {...props} />
        <SubscriptionStartDateView readonly {...props} />
        {/* <SubscriptionTypeView readonly {...props} /> */}
        <SubscriptionStatusView readonly {...props} />
        {/* <ActiveBundleView {...props} /> */}

        {/* <UseResellerCreditForRenewalView {...props} /> */}
        <ProtocolView {...props} />
        { hasRole('SuperAdmin') && <SelectedServersView {...props} /> }
        {/* <UserAgentView {...props} /> */}
        {props.values.userAgent &&<UserDeviceView {...props} />}
        {props.values.userAgent && new Date(props.values.lastSubscriptionUpdate).getFullYear() !== 1 &&<LastSubscriptionUpdateView readonly {...props} />}
        
        <IsYouthView {...props} />

        {/* <IsGovernmentView {...props} /> */}


      </Grid>
      <Grid
        item
        sm={4}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div style={{ alignItems: "center", width: "100%", maxWidth: 200 }}>
          {/* <img
            width="100%"
            maxWidth={250}
            src="/beshkan/api/qr/key/1234567890987651"
            alt="QR Code"
          /> */}
          <div
  style={{ color: "green", textAlign: "center" }}
>{t('compatibleWithGapAndV2ray')}
  
</div>
<QRCode size={500} includeMargin={true}
  style={{ height: '100%', width: '100%', padding: 0, maxWidth: 200 }}
  value={`https://${values.subscriptionUpdateDomain}/sub/${values.loginKey}#${remark}`}  
/>

<CopyToClipboardComponent code= {`https://${values.subscriptionUpdateDomain}/sub/${values.loginKey}#${remark}`}/>



        </div>
      </Grid>
    </Grid>
    {/* { values.subscriptionType !==  subscriptionType.Lifetime&& <AvailableTrafficView {...props} /> } 
    <DurationHoursView {...props} />
    <AvailableHoursView readonly {...props} />
    <FirstConnectedTimeView readonly {...props} />
    <ConcurrencyLimitView {...props} />
    <Divider view />
    <SelectedServersView readonly {...props} /> */}
        {/* <Divider view /> */}

        {props.values.isReseller && <Divider view/> }
        {/* {props.values.isReseller && <h6 className={classes.title}>{t('resellerSettings')}</h6>  }
        {props.values.isReseller && <TelegramSupportView {...props}/> }
        {props.values.isReseller && <Divider view/> } */}

    {props.values.id &&
      Extensions({
        subscriber: props.values,
        newBundleMode: props.newBundleMode,
      })}


  </>
);

}

