import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('beshkan');

export const insertTrafficTransaction = async (trafficTransaction) => {

    const resultEntity = await post(`trafficTransaction`,trafficTransaction,
    {
        errorMessage : t('common:insertError') + t('trafficTransaction:trafficTransaction')
    });

    if(!resultEntity) return null;

    return {...trafficTransaction, id:resultEntity.id};
}


export const updateTrafficTransaction = async (trafficTransaction) => {

    const result = await putFormData(`trafficTransaction/${ trafficTransaction.id }`,
        trafficTransaction,
        {
            errorMessage :  t('common:updateError') + t('trafficTransaction:trafficTransaction')
        });

    return result;
}

export const deleteTrafficTransaction = async (id) => {

    const result = await del(`trafficTransaction/${id}`,
        {
            errorMessage : t('common:deleteError') + t('trafficTransaction:trafficTransaction')
        });

    return result;
}

export const getTrafficTransactions = async (params, options) => {

    const result = await get(`trafficTransaction`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('trafficTransaction:trafficTransactions'),
        ...options

    });

    return result;
}



export const getTrafficTransaction = async (id) => {

    const result = await get(`trafficTransaction/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('trafficTransaction:trafficTransaction')
    });

    return result;
}



