import React from 'react';
// import { FormContext } from './FormContext';
import Form from '../Component/Form';

const withForm = Component => props => <Form {...props} component={Component}></Form>;
export default withForm;




// <FormContext.Consumer>
//     {({values={}, handleSubmit})=> <>
//         <Component {...props} />
//     </>
//     }
// </FormContext.Consumer>

