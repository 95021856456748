import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateSubscription } from '../../api'
import {hasValue} from '../../../../../utils/string'

import {allUserDevices, byId, titleById} from '../../../../../domain/userDevice'
import Selector from '../../../../../Component/Selector'

export const UserDevice =  ({values}) =>{
    const { t } = useTranslation('subscription');

    return <Grid item sm={ 12 } xs={12}>
    <FormControl fullWidth>
        <Field component={Selector}
               label={t('userDevice.title')}
               name="userDevice"
               options={allUserDevices }
               // keyName="id"
               optionTitle="displayName" />
    </FormControl>
</Grid>
}

export const validate = (values, errors, t)=> {
    return errors;
}

class View extends React.Component {

    update = async (values) =>{
        const { id, userDevice }=values;
        return await updateSubscription({id, userDevice });
    }

    headline(values){
        const { userDevice, userAgent } = values;
        return titleById(userDevice) +' / ' + userAgent;
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, readonly, t } = this.props;

        return <ViewField 
            successMessage={this.successMessage}
            title={ t('userDevice.title')}
            headline={ this.headline(values) }
            update={this.update}
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            readonly = {readonly}
            editComponent={ UserDevice } />

    }
}

export const UserDeviceView = withTranslation('subscription')(View);