import React from 'react'
import Avatar from '@material-ui/core/Avatar';
import { getThumbLink } from '../modules/media'
import { withStyles } from '@material-ui/core/styles';
import { stringToHslColor } from '../utils/colorUtils';
import UserCircle from '../icons/user.svg'
const styles = () => ({
    avatar: {
        // margin: 10,
        color: '#fff',
        // display: 'inline-block',
        // verticalAlign: 'middle',
        lineHeight: '26pt',
        // fontSize: 13
      }
});

function toCapital(str)
{
    return str&&(str.replace('سید ','')[0]).toUpperCase()
}

function toCapitalNameFamily(firstName, lastName)
{
    var nullSpace = '‌';
    var capitalTitle  = toCapital(firstName);
    if(lastName) {
        if(firstName)
            capitalTitle += nullSpace;
            
        capitalTitle += toCapital(lastName)
    }

    return capitalTitle;
}

class AvatarComponent extends React.PureComponent {
    render() {
      var { color,size,fontSize, imageId, firstName, lastName, userInfo, classes } = this.props
      if(userInfo) {
         imageId= userInfo.imageId;
         firstName= userInfo.firstName;
         lastName= userInfo.lastName;
      }

      var capitalTitle = toCapitalNameFamily(firstName, lastName);
      const backgroundColor = color|| stringToHslColor(firstName+' '+lastName, 50, 50);
      let url = imageId?getThumbLink(imageId):'';
      fontSize = fontSize || 13

      // <img src="/icons/fa/user-circle.svg"/>
      const showUserIcon = !url && !firstName && !lastName;
      return (
        <Avatar src={url} style={{backgroundColor, fontSize, width:size, height:size}} className={classes.avatar}>{showUserIcon?<img alt="profile" src={UserCircle} width={"60%"} height={"60%"}/>:capitalTitle}</Avatar>

      )
    }
  }
  

export default withStyles(styles)(AvatarComponent)