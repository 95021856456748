import React, { PureComponent } from 'react';
import { signIn, signId, loggedIn, signByOidc,hasRole, getReturnUrl} from '../../../../modules/Auth'
import { Redirect } from "react-router-dom"
import {parse} from 'query-string'
import Loader from '../../../../Component/Loader'

// var mgr = new UserManager(config);

class Login extends PureComponent {
    constructor(props) {
        super(props)

        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.txtUsername = null
        this.txtPassword = null

        this.state = {
            showPassword: false,
            redirectToRegister: false,
            phoneEntered: false
        }
    }

    handleFormSubmit = async (pageName) => {

        this.setState({ submiting: true })
        const res = await signIn(this.txtUsername.value, this.txtPassword.value)

        if (res)
            this.props.history.push(pageName)
        else
            this.setState({ submiting: false })

    }

    btnClickHandle = async (e) => {
        e.preventDefault()
        if (loggedIn()) {
            this.props.history.push('./home');
        } else {

            const usernameRequired = this.txtUsername.value.length === 0,
                passRequired = this.txtPassword.value.length === 0;

            if (usernameRequired || passRequired) {
                this.setState({ usernameRequired, passRequired });

                return
            }

            const returnUrl = getReturnUrl()

            await this.handleFormSubmit(returnUrl)
        }
    }

    // getReturnUrl = () => {
    //     var state = this.props.location.state;
    //     var url = state && state.from && state.from.pathname;
    //     return url || '/'
    // }

    onRegisterClick = () => this.setState({ redirectToRegister: true })

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
        // setValues({ ...values, showPassword: !values.showPassword });
    };
    submitUsername = async () => {

        const hasPassword = await signId(this.txtUsername.value);
        if(hasPassword === null) {
            this.txtUsername.value = '';
            return;
        }
        this.setState({ phoneEntered: true, hasPassword });


        // setValues({ ...values, showPassword: !values.showPassword });
    };
    handleUsernameChanged = async (e) => {
        const value = e.target.value;
        const containsString = isNaN(value);


        if (containsString) {
            this.setState({ containsString: true });
            return;
        } else if (this.state.containsString) {
            this.setState({containsString:false});
        }

        if (!containsString && value.length === 11) {
            const hasPassword = await signId(this.txtUsername.value);
            if(hasPassword === null) {
                this.txtUsername.value = '';
                return;
            }
            this.setState({ phoneEntered: true, hasPassword });
        }

        // setValues({ ...values, showPassword: !values.showPassword });
    };


    componentWillMount() {
        var idToken = parse(this.props.location.search).id_token;

        this._asyncRequest = signByOidc(idToken).then( ()=> {
            this._asyncRequest = null;
            this.setState({ redirectToSign:true })
        });
    }

    componentWillUnmount() {
        if (this._asyncRequest) {
            // this._asyncRequest.cancel();
        }
    }

      
    render() {
        const { redirectToSign } = this.state
        if (redirectToSign) {

            console.log('has role')
            console.log(hasRole('SuperAdmin'));
            console.log('path from oauth-callback page : ');
            console.log(this.props.location.pathname)
       
            return <Redirect
              to={{
                pathname: getReturnUrl()
                // state: { from: getReturnUrl() }
              }}
            />
        }

        return <Loader/>
        
        // <div>call back is called</div>;


    
    }


}

export default (Login);