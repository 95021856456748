export const allCalendarTypes = [
    {
        "id": 0,
        "systemName": "persianCalendar",
        "displayName" : "تقویم فارسی"
    },
    {
        "id": 1,
        "systemName": "gregorianCalendar",
        "displayName" : "تقویم میلادی"
    },
    {
        "id": 2,
        "systemName": "hijriCalendar",
        "displayName" : "تقویم هجری"
    }
];
export const calendarTypes = {
        persianCalendar:0,
        gregorianCalendar:1,
        hijriCalendar: 2
};


export const byId = (id)=>{
    return allCalendarTypes.find(ct=>ct.id===id) || {}
}

export const titleById = (id)=>{
    return byId(id).displayName || "";
}