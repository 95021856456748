import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateUser } from '../../api'
import DateTimeInput from '../../../../../Component/DateTimeInput';
import moment from 'moment-jalaali';

export const UpdatedOn =  ({t=useTranslation('user').t}) =>
<>
<Grid item xs={12}>
    <FormControl fullWidth>
    <Field label={t('updatedOn.title')}
            name={"updatedOn"}
            date time
            component={DateTimeInput}
            />
    </FormControl>
</Grid>
</>

export const validate = (values, errors, t)=> {
    if (!values.updatedOn) {
        errors.updatedOn = t('updatedOn.required');
    }
    return errors;
}

class View extends React.Component {

    update = async (values) =>{
        const {id, updatedOn }=values;
        return await updateUser({id, updatedOn:moment(updatedOn).utc().format() });
    }

    successMessage  = (values) => this.props.t('updated')

    headline() {
        const { values={}} = this.props;
        const { updatedOn } = values;
        const format = 'jYYYY/jMM/jDD hh:mm A';
        
        return updatedOn?moment(updatedOn).format(format):'-';
    }

    render() {
        const { values, onRefresh, t } = this.props;

        return <ViewField 
            successMessage={this.successMessage}
            title={ t('updatedOn.title')}
            headline={ this.headline() }
            update={this.update}
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            readonly={true}
            editComponent={ UpdatedOn } />

    }
}


export const UpdatedOnView = withTranslation('user')(View);