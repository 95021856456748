import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ListPage from './List'
import CreatePage from '../../Admin/User/Create'
import EditPage from '../../Admin/User/Edit'
import { Route, Switch } from "react-router-dom";
import { Paper } from '@material-ui/core';
  
 class UserManage extends PureComponent {
    render() {
        const { match, classes } = this.props;
        return <Paper className={classes.root}> 
            <Switch >
                <Route path={`${match.path}/create`} component={CreatePage} />
                <Route path={`${match.path}/:id`} component={EditPage} />
                <Route path={`${match.path}`} exact component={ListPage} />
            </Switch>
        </Paper>
    }
}

const styles={
    root:{
        padding:20
    }
}

export default withStyles(styles)(UserManage);