import React, { PureComponent } from 'react'
import {validate as validateBundle } from '../Form'
import CreatePageComponent from './DefaultCreatePage.Component';
import { insertBundle } from '../api'
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import {bundleStatus} from '../../../../domain/bundleStatus'
import {durationUnits,durationValues, fromHours, toHours} from '../../../../domain/subscriptionPeroid'
import { bundleType } from '../../../../domain/bundleType';
var defaultDuration = 730;

 class DefaultCreatePage extends PureComponent {
    subscriberFromRoute()
    {
        const {location:{state:{subscriber={}}={}}={}}=this.props;
        // const {subscriber={}}=this.props;
        return subscriber;
    }
    defaultValues = { 
        traffic:50,
        concurrencyLimit:1,
        bundleType: bundleType.Consumer,
        status:bundleStatus.NotUsed,
        subscriberId:this.subscriberFromRoute().id,
        durationUnit:fromHours(defaultDuration).unitId,
        durationValue:fromHours(defaultDuration).valueId,
        ...this.props.defaultValues
        // roles: [{
        //     "id": -4,
        //     "name": "Customer",
        //     "displayName": "تست"
        // }],
        // provinceId: getProfile().provinceId,
        // province: getProfile().province,
    }
    defaultExValues = {
        subscriber:this.subscriberFromRoute(),
        ...this.props.defaultExValues
    }

    onSubmit = async (values, ex) => {
        const { t, enqueueSnackbar , onOk } = this.props;

        const { durationValue, durationUnit,
            concurrencyLimit,
            traffic,isResellerBundle,...rest } = values;

        const durationHours = Math.round(toHours(durationValue, durationUnit));
        if(isResellerBundle)
        {
            rest.bundleType = bundleType.Reseller;
        }
        if(!traffic)
        {
            rest.bundleType = bundleType.Infinite;
        }    

        // alert(values.durationHours );
        // return;
        var req = {...rest,
            concurrencyLimit,
            traffic:traffic * 1024, // in megabytes
            durationHours
        };



        var res = await insertBundle(req)
        if(!res) {
            return false;
        }
        

        if(onOk) onOk(res);
        else this.props.history.push('/admin/subscription/'+values.subscriberId)

        if (res) {
            enqueueSnackbar( t('saved'), { variant:'success' });
        }
    }

    validate = ( values ) => {
        const {t}= this.props;
        const errors = {}
        const warnings = {}

        validateBundle(values, errors, t, warnings);
        this.forceUpdate();
        console.log(errors);
        return errors
    }

    render() {
        const { formComponent, onCancel, t } = this.props
        return <>
            <CreatePageComponent submitLabel={t('register')}
                onCancel={onCancel}
                formComponent = {formComponent}
                defaultValues={this.defaultValues}
                defaultExValues = {this.defaultExValues}
                validate={this.validate}
                //validateField = {this.validateField}
                onSubmit={this.onSubmit} />
        </>
    }
}

export default withSnackbar(withTranslation('bundle')(DefaultCreatePage));