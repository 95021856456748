import common from './common.json'
import user from './user.json'
import benefactor from './benefactor.json'
import appuser from './appuser.json'
import role from './role.json'
import file from './file.json'
import mimeType from './mimeType.json'
import setting from './setting.json'
import settingType from './settingType.json'
import kindOfDoc from './kindOfDoc.json'
import city from './city.json'
import province from './province.json'
import donationSubject from './donationSubject.json'
import donation from './donation.json'
import recurrentDonation from './recurrentDonation.json'
import calendarType from './calendarType.json'
import occasion from './occasion.json'
import client from './client.json'
import personType from './personType.json'
import degreeLevel from './degreeLevel.json'
import donationSubjectType from './donationSubjectType.json'
import gender from './gender.json'
import call from './call.json'
import callReason from './callReason.json'
import callType from './callType.json'
import subscription from './subscription.json'
import island from './island.json'
import server from './server.json'
import region from './region.json'
import serverProvider from './serverProvider.json'
import bundle from './bundle.json'
import availableBundle from './availableBundle.json'
import panel from './panel.json'
import resellerSetting from './resellerSetting.json'
import trafficTransaction from './trafficTransaction.json'
export default {common, panel,bundle, availableBundle, region,serverProvider, subscription, trafficTransaction,island, server, appuser,user, role,benefactor, file, mimeType, setting, settingType, kindOfDoc, city, province, donationSubject,call, callReason, callType, donation, recurrentDonation,resellerSetting,  calendarType, occasion, client, personType, degreeLevel, donationSubjectType, gender  };
