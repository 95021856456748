import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateServer } from '../../api'
import {hasValue} from '../../../../../utils/string'

import TextInput from '../../../../../Component/TextInput'

export const DailyAverageTraffic =  () => {
    const { t } = useTranslation('server');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('dailyAverageTraffic.title')}
                   name={"dailyAverageTraffic"}
                   component={TextInput}
                   number
                   />
        </FormControl>
    </Grid>
    </>
}
export const validate = (values, errors, t)=> {
    if (!hasValue(values.dailyAverageTraffic)) {
        errors.dailyAverageTraffic = t('dailyAverageTraffic.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, dailyAverageTraffic }=values;
        return await updateServer({id, dailyAverageTraffic });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh,readonly, t } = this.props;
        const { dailyAverageTraffic } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('dailyAverageTraffic.title')}
                          headline={ dailyAverageTraffic }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          readonly = {readonly}
                          editComponent={ DailyAverageTraffic } />
    }
}


export const DailyAverageTrafficView = withTranslation('server')(View);