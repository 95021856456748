import React, {useState, useEffect } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateUser } from '../../api'
import MultiSelect from '../../../../../Component/MultiSelect'
import { getRoles } from "../../../../Admin/Role/api"
import Chip from "@material-ui/core/Chip";

let allRoles=null;

export const Roles =  (_values) =>{
    const t=useTranslation('user').t;
    const [options, setOptions] = useState(allRoles||[]);
    useEffect(()=> {loadOptions().then(res=>setOptions(res))}, options);

    return <Grid item xs={12}  >
        <FormControl fullWidth>
            <Field
                component={MultiSelect}
                label={t('roles.title')}
                name="roles"
                options = {options}
                // keyName="id"
                optionTitle="displayName"
                // returnObjectKey= "roleId"
            />
        </FormControl>
    </Grid>
}

export const validate = (values, errors, t)=> {
    if (!values.roles || !values.roles.length) {
        errors.roles = t('roles.required');
    }
    return errors;
}

export const loadOptions = async () =>
{
    return (allRoles=allRoles|| await getRoles())
}

class View extends React.Component {
    
    update = async (values) =>{
        const {id, roles }=values;
        return await updateUser({id, roles });
    }

    successMessage  = () => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { roles } = values;

        return <ViewField
            successMessage={this.successMessage}
            title= { t('roles.title')}
            headline  = {(roles||[]).map(r => (
                <Chip
                  key={ r.id }
                  label={ r.displayName }
                />
              ))}
            update ={this.update} 
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            editComponent={ Roles } />
    }
}

export const RolesView = withTranslation('user')(View);