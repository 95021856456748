import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateSubscription } from '../../api'
import {hasValue} from '../../../../../utils/string'

import DateTimeInput from '../../../../../Component/DateTimeInput';
import gmoment from 'moment';
import jmoment from 'moment-jalaali';

const lang = localStorage.getItem('language') || 'en';
const moment = lang=="fa"?jmoment : gmoment;


export const FirstConnectedTime =  () => {
    const { t } = useTranslation('subscription');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
        <Field label={t('firstConnectedTime.title')}
                name={"firstConnectedTime"}
                date time
                component={DateTimeInput}
                />
        </FormControl>
    </Grid>
    </>
}

export const validate = (values, errors, t)=> {
    return errors;
}

class View extends React.Component {

    update = async (values) =>{
        const {id, firstConnectedTime }=values;
        return await updateSubscription({id, firstConnectedTime:moment(firstConnectedTime).utc().format() });
    }

    successMessage  = (values) => this.props.t('updated')

    headline() {
        const { values={},t} = this.props;
        const { firstConnectedTime } = values;
        const format = lang=="fa"?'jYYYY/jMM/jDD hh:mm A' : 'YYYY/MM/DD HH:MM';

        return firstConnectedTime?moment(firstConnectedTime).format(format):t('notUsedYet');
    }


    render() {
        const { values, onRefresh,readonly, t } = this.props;
        const { firstConnectedTime } = values;

        return <ViewField 
            successMessage={this.successMessage}
            title={ t('firstConnectedTime.title')}
            headline={ this.headline() }
            update={this.update}
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            readonly = {readonly}
            editComponent={ FirstConnectedTime } />

    }
}


export const FirstConnectedTimeView = withTranslation('subscription')(View);