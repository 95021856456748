import React, { PureComponent } from 'react';
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles';
// import DonationCountsWidget from '../../Admin/Donation/Widgets/DonationCountsWidget';
// import DonationValuesWidget from '../../Admin/Donation/Widgets/DonationValuesWidget';
// import ByProvincesWidget from '../../Admin/Donation/Widgets/ByProvincesWidget';
// import BySubjectsWidget from '../../Admin/Donation/Widgets/BySubjectsWidget';

// import UserCountsWidget from '../../Admin/Benefactor/Widgets/UserCountsWidget';
// import UserCallsWidget from '../../Admin/Benefactor/Widgets/UserCallsWidget';
// import ByTimesWidget from '../../Admin/Server/Widgets/ByTimesWidget';
import { Button } from '@material-ui/core';
import { recoverBundleData } from '../../Admin/Server/api'
export  class AdminHome extends PureComponent {
    render() {
        // const {classes, theme} = this.props;
        return (
                <>
                    <Grid container justify="center" spacing={2}>
                        <Grid item sm={4} xs={12}>
                            {/* <DonationCountsWidget allTenants={true}/> */}
                        </Grid>

                        <Grid item sm={4} xs={12}>
                            {/* <DonationValuesWidget allTenants={true}/> */}
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            {/* <UserCountsWidget allTenants={true}/> */}
                        </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={2} style={{marginTop:8}}>
                        <Grid item sm={4} xs={12} >
                            {/* <ByProvincesWidget allTenants={true}/> */}
                        </Grid>

                        <Grid item sm={4} xs={12}>
                            {/* <UserCallsWidget allTenants={true}/> */}

                        </Grid>
                        <Grid item sm={4} xs={12}>
                            {/* <BySubjectsWidget allTenants={true}/> */}
                        </Grid>

                    </Grid>
                    <Grid container justify="center" spacing={2} style={{marginTop:8}}>
                        <Grid item sm={12} xs={12} >
                            {/* <ByTimesWidget allTenants={true}/> */}
                        </Grid>
                    </Grid>

                    <Grid container justify="center" spacing={2} style={{marginTop:8}}>
                        <Grid item sm={12} xs={12} >
                            widgets_placeholder
                            {/* <Button variant="contained" color="primary" onClick={() =>recoverBundleData()}>RECOVER</Button> */}
                        </Grid>
                    </Grid>

                </>
  
        )
    }
}


const styles = theme => ({
    
    root:{
        // padding :20,
        flexGrow: 1,
        // color:"#444444"
    },
    paper: {
        // borderRad
        borderRadius:0
    },
    control: {
        padding: theme.spacing(2),
    },
    link: {
        textDecoration: 'none'
    },
    container: {
        //paddingRight: 25,
        //paddingLeft: 25
    },
    header: {
        //paddingRight: 25
    },
    grid: {
        paddingTop: 10
    },
    
});


// const useStyles = makeStyles(theme => ({
//     root: {
//       flexGrow: 1,
//     },
//     paper: {
//       height: 140,
//       width: 100,
//     },
//     control: {
//       padding: theme.spacing(2),
//     },
//   }));

export default withStyles(styles, { withTheme: true })(AdminHome)