import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateSubscription } from '../../api'
import {hasValue} from '../../../../../utils/string'

import TextInput from '../../../../../Component/TextInput'

export const ConcurrencyLimit =  () => {
    const { t } = useTranslation('subscription');

    return <>
    <Grid item sm={ 3 } xs={12}>
        <FormControl fullWidth>
            <Field label={' '} //t('concurrencyLimit.title')
                   name={"activeBundle.concurrencyLimit"}
                   component={TextInput}
                   adornment ={t('concurrencyLimit.adornment')}
                //    value={concurrencyLimit}

                   />
        </FormControl>
    </Grid>
    </>
}
export const validate = (values, errors, t)=> {
    const field= "activeBundle.concurrencyLimit";
    const value = values[field]
    if (!hasValue(value) || parseInt(value)<=0) {
        errors[field] = t('concurrencyLimit.required');
    }
    if(parseInt(value)>3) {
        errors[field] = t('bundle:concurrencyLimit.max3');
    }
   
    

    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, concurrencyLimit }=values;
        return await updateSubscription({id, concurrencyLimit });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh,readonly, t } = this.props;
        const { concurrencyLimit } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('concurrencyLimit.title')}
                          headline={ concurrencyLimit }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          readonly = {readonly}
                          editComponent={ ConcurrencyLimit } />
    }
}


export const ConcurrencyLimitView = withTranslation('subscription')(View);