import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from '../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateUser } from '../../api'
import TextInput from '../../../../../Component/TextInput'

export const Address =  ({t=useTranslation('user').t}) =>
<>
<Grid item xs={12}>
    <FormControl fullWidth>
        <Field label={t('address.title')}
               name={"address"}
               component={TextInput}
               type="text" />
    </FormControl>
</Grid>
</>

export const validate = (values, errors, t)=> {
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, address }=values;
        return await updateUser({id, address });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { address } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('address.title')}
                          headline={ address }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ Address } />
    }
}


export const AddressView = withTranslation('user')(View);