import jMoment from "moment-jalaali";
import moment from "moment-jalaali";

import DefaultMomentUtils from "@date-io/moment";
import {numToFa} from './persianUtils'
var symbolMap = {
    1: "۱",
    2: "۲",
    3: "۳",
    4: "۴",
    5: "۵",
    6: "۶",
    7: "۷",
    8: "۸",
    9: "۹",
    0: "۰"
};
const defaultFormats = {
    fullDate: "YYYY, jMMMM Do",
    normalDate: "dddd, jD jMMM",
    shortDate: "jD jMMM",
    monthAndDate: "jD jMMMM",
    dayOfMonth: "jD",
    year: "jYYYY",
    month: "jMMMM",
    monthShort: "jMMM",
    monthAndYear: "jMMMM jYYYY",
    minutes: "mm",
    hours12h: "hh",
    hours24h: "HH",
    seconds: "ss",
    fullTime12h: "hh:mm A",
    fullTime24h: "HH:mm",
    fullDateTime12h: "jD jMMMM hh:mm A",
    fullDateTime24h: "jD jMMMM HH:mm",
    keyboardDate: "jYYYY/jMM/dd",
    keyboardDateTime12h: "jYYYY/jMM/jDD hh:mm A",
    keyboardDateTime24h: "jYYYY/jMM/jDD HH:mm"
};
export default class MomentUtils extends DefaultMomentUtils {
    constructor({ formats, instance } = {}) {
        super({ locale: "fa", instance });
        this.moment = instance || jMoment;
        this.locale = "fa";
        this.formats = Object.assign(Object.assign({}, defaultFormats), formats);
    }
    toJMoment(date) {
        return this.moment(date ? date.clone() : undefined).locale("fa");
    }
    parse(value, format) {
        if (value === "") {
            return null;
        }
        return this.moment(value, format, true).locale("fa");
    }
    date(value) {
        
        if (value === null) {
            return null;
        }
        return this.moment(value).locale("fa");
    }
    isBeforeYear(date, value) {
        return date.jYear() < value.jYear();
    }
    isAfterYear(date, value) {
        return date.jYear() > value.jYear();
    }
    getMonth(date) {
        return date.jMonth();
    }
    // getDayText (date) {
    //     return date.jDate();
    // }
    // getYearText (date) {
    //     return date.jYear();
    // }
    // getMonthText (date) {
    //     return date.jMonth();
    // }
    startOfMonth(date) {
        return date.clone().startOf("jMonth");
    }
    endOfMonth(date) {
        return date.clone().endOf("jMonth");
    }
    getNextMonth(date) {
        return date.clone().add(1, "jMonth");
    }
    getPreviousMonth(date) {
        return date.clone().subtract(1, "jMonth");
    }
    getYear(date) {
        return date.jYear();
    }
    setYear(date, year) {
        return date.clone().jYear(year);
    }
    getMeridiemText(ampm) {
        return ampm === "am"
            ? this.toJMoment()
                .hours(2)
                .format("A")
            : this.toJMoment()
                .hours(14)
                .format("A");
    }
    getWeekdays() {
        return [0, 1, 2, 3, 4, 5, 6].map(dayOfWeek => {
            return this.toJMoment()
                .weekday(dayOfWeek)
                .format("dd");
        });
    }
    isEqual(value, comparing) {
        if (value === null && comparing === null) {
            return true;
        }
        return this.moment(value).isSame(comparing);
    }
    formatNumber(num) {
        return num.replace(/\d/g, match => symbolMap[match]).replace(/,/g, "،");
    }
    getWeekArray(date) {
        const start = date
            .clone()
            .startOf("jMonth")
            .startOf("week");
        const end = date
            .clone()
            .endOf("jMonth")
            .endOf("week");
        let count = 0;
        let current = start;
        const nestedWeeks = [];
        while (current.isBefore(end)) {
            const weekNumber = Math.floor(count / 7);
            nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
            nestedWeeks[weekNumber].push(current);
            current = current.clone().add(1, "day");
            count += 1;
        }
        return nestedWeeks;
    }
    getYearRange(start, end) {
        const startDate = this.moment(start).startOf("jYear");
        const endDate = this.moment(end).endOf("jYear");
        const years = [];
        let current = startDate;
        while (current.isBefore(endDate)) {
            years.push(current);
            current = current.clone().add(1, "jYear");
        }
        return years;
    }
    getCalendarHeaderText(date) {
        return this.formatNumber(this.format(date, 'jMMMM jYYYY'));
    }
    getYearText(date) {
        return this.formatNumber(this.format(date, "jYYYY"));
    }
    getDatePickerHeaderText(date) {
        return this.formatNumber(this.format(date, "ddd, jMMM jD"));
    }
    getDateTimePickerHeaderText(date) {
        return this.formatNumber(this.format(date, "jMMM jD"));
    }
    getMonthText(date) {
        return this.formatNumber(this.format(date, "jMMMM"));
    }
    getDayText(date) {
        return this.formatNumber(this.format(date, "jD"));
    }
    getHourText(date, ampm) {
        return this.formatNumber(this.format(date, ampm ? "hh" : "HH"));
    }
    getMinuteText(date) {
        return this.formatNumber(this.format(date, "mm"));
    }
    getSecondText(date) {
        return this.formatNumber(this.format(date, "ss"));
    };
}



const jMonths= [
    'فروردین',
    'اردیبهشت',
    'خرداد',
    'تیر',
    'مرداد',
    'شهریور',
    'مهر',
    'آبان',
    'آذر',
    'دی',
    'بهمن',
    'اسفند'
]


// const jUtils = new MomentUtils();
const jSeasons = [
    'بهار',
    'تابستان',
    'پاییز',
    'زمستان',
];
const lang = localStorage.getItem('language') || 'en';

function getGregorianDateTitle(dateString) {
    let date = new Date(dateString);
    
    let day = date.getDate();
    let month = date.getMonth() + 1; // months are 0-based in JavaScript
    let year = date.getFullYear();
  
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
  }
  
  function getGregorianTimeTitle(dateString, twelveHourFormat = true) {
    let date = new Date(dateString);
    
    let hours = date.getHours();
    let minutes = date.getMinutes();
  
    if(twelveHourFormat) {
      let period = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
  
      return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes} ${period}`;
    } else {
      return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    }
  }
  
  

export const getDateTitle = (date) => {
    if(lang!='fa')
        return getGregorianDateTitle(date);
    var d= moment(date);
    var now = moment(new Date());
    if(d.year() <= 1971 || !d.year()) return "";
    return d.jDate()+' '+ getMonthTitle(d.jYear(), d.jMonth()+1, now.jYear());
}
export const getTimeTitle = (date) => {
    if(lang!='fa')
        return getGregorianTimeTitle(date);
    var d= moment(date);
    if(d.year() <= 1971 || !d.year()) return "";
    return d.format("hh:mm A");
}
export const getMonthTitleByDate = (date, relYear) => {
    var d= moment(date);
    if(d.year() <= 1997) return "";

    return getMonthTitle(d.jYear(), d.jMonth()+1, relYear);
}

export const getMonthTitle = (jYear, jMonthNumber, relYear) => {
    // if(typeof(jYear)=='object')
    if(!jMonthNumber)
        return getMonthTitleByDate(jYear, relYear);

    if(jYear<1000 && jYear >90 )  jYear+=1300;
    if(relYear<1000 && relYear >90 )  relYear+=1300;
    const sameYear = jYear === relYear;
    if(jYear<1400) jYear=jYear-1300;

    var title=jMonths[jMonthNumber-1];
    if(!sameYear) {
        title += ' '+ numToFa(jYear.toString());
    }

    return title;
}

export const getYearTitle = (date) => {
    var d= moment(date);
    return numToFa(d.jYear().toString());
}
export const getSeasonTitle = (date) => {
    var d= moment(date);
    
    var seasonNumber = (Math.ceil((d.jMonth()+1) / 3.0)-1);
    return jSeasons[seasonNumber] + ' ' + numToFa(d.jYear().toString());
}

export const getWeekTitle = (date) => {
    var d= moment(date);
    
    return numToFa('هفته ' + d.jWeekYear());
}