import React from 'react'
import Grid from '@material-ui/core/Grid'
import {VerticalTabs, Tab} from '../../../../Component/VerticalTabs'
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import HistoryIcon from '@material-ui/icons/History';
import { Gender, GenderView } from './Fields/Gender'
import { Name, NameView, validate as validateName } from './Fields/Name'
import { Birthday, BirthdayView } from './Fields/Birthday'
import { NatId, NatIdView, validate as validateNatId } from './Fields/NatId'
import { City, CityView, validate as validateCity } from './Fields/City'
import { Phone, PhoneView, validate as validatePhone } from './Fields/Phone'
import { WorkPhone, WorkPhoneView } from './Fields/WorkPhone'
import { Fax, FaxView } from './Fields/Fax'
import { Email, EmailView } from './Fields/Email'
import { Address, AddressView } from './Fields/Address'
import { IdView } from './Fields/Id'

import { Postcode, PostcodeView } from './Fields/Postcode'
import { UserName, UserNameView, validate as validateUserName } from './Fields/UserName'
import { Password, PasswordView, validate as validatePassword } from './Fields/Password'
import { HasPassword, HasPasswordView } from './Fields/HasPassword'
import { Roles, RolesView, validate as validateRoles } from './Fields/Roles'
import { IsEmailValid, IsEmailValidView } from './Fields/IsEmailValid'
import { IsPhoneValid, IsPhoneValidView } from './Fields/IsPhoneValid'
import { CreatedOnView } from './Fields/CreatedOn'
import { UpdatedOnView } from './Fields/UpdatedOn'
import { CreatedByView } from './Fields/CreatedBy'
import { hasRole } from '../../../../modules/Auth'
// import Extensions from '../Extensions'

export const validators={
    validateName,validateNatId,validatePhone, validateCity, validateUserName, validatePassword, validateRoles, 
};

export const validate = async (values, errors, t, warnings) => {

    for (const [, func] of Object.entries(validators)) {
        await func(values, errors, t, warnings);
      }

}

export const FormFields = ({values, reset, t=useTranslation('user').t}) =>
{
return <>
<VerticalTabs >
<Tab title={<><AccountCircleIcon/><Box>{t('basic.title')}</Box></>}>
    <Grid container spacing={2} >
        <NatId/>
        <Gender/>
        <Name/>
        <Birthday/>
    </Grid>
</Tab>
<Tab title={<><ContactPhoneIcon/><Box>{t('contact.title')}</Box></>}>
    <Grid container spacing={2} >
        <City reset={reset} values={values}/>
        <Phone/>
        <WorkPhone/>
        <Address/>
        <Fax/>
        <Email/>
        <Postcode/>
    </Grid>
</Tab>
{hasRole('Administrator,SuperAdmin')&&<Tab title={<><VerifiedUserIcon/><Box>{t('security.title')}</Box></>}>
    <Grid container spacing={2} >
        <HasPassword/>
        { values.hasPassword&& <UserName/> } 
        { values.hasPassword&& <Password/> } 
        <Roles/>
        <IsEmailValid/>
        <IsPhoneValid/>
    </Grid>
</Tab>}
</VerticalTabs>
</>
}

export const EditableViewFields = (props) =>
{
    const {values,t=useTranslation('user').t} = props;
return <>
<VerticalTabs >
<Tab title={<><AccountCircleIcon/><Box>{t('basic.title')}</Box></>}>
    <IdView {...props} />

    <GenderView {...props} />
    <NameView {...props} />
    <BirthdayView {...props} />
    <NatIdView {...props} />
</Tab>
<Tab title={<><ContactPhoneIcon/><Box>{t('contact.title')}</Box></>}>
    <CityView {...props} />
    <PhoneView {...props} />
    <WorkPhoneView {...props} />
    <FaxView {...props} />
    <EmailView {...props} />
    <AddressView {...props} />
    <PostcodeView {...props} />
</Tab>
{hasRole('Administrator,SuperAdmin')&&<Tab title={<><VerifiedUserIcon/><Box>{t('security.title')}</Box></>}>
    <HasPasswordView {...props}/>
    { values.hasPassword&& <UserNameView {...props} />}
    { values.hasPassword&& <PasswordView {...props} />}
    <RolesView {...props} />
    <IsEmailValidView {...props} />
    <IsPhoneValidView {...props} />
</Tab>}
<Tab title={<><HistoryIcon/><Box>{t('record.title')}</Box></>}>
    <CreatedOnView {...props} />
    <UpdatedOnView {...props} />
    <CreatedByView {...props} />
</Tab>
</VerticalTabs>
</>

}

