import React from 'react'
import Grid from '@material-ui/core/Grid'
import {VerticalTabs, Tab} from '../../../../Component/VerticalTabs'
import { useTranslation } from 'react-i18next';
import { Box, FormGroup, Divider } from '@material-ui/core'

import { Settings as SettingsIcon, NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';
import { SettingType, SettingTypeView } from './Fields/SettingType'
import { Name, NameView } from './Fields/Name'
import { StringValue, StringValueView } from './Fields/StringValue'
import { NumberValue, NumberValueView } from './Fields/NumberValue'


export const validators={
    
};


export const validate =(values, errors, t) => {

    for (const [name, func] of Object.entries(validators)) {
        func(values, errors, t);
      }

}
export const FormFields = (props) =>
{
    const { values } = props;
    const { t } = useTranslation('setting');
    return <>
<Grid container spacing={2} style={{padding:25, paddingTop:0}}>

        <SettingType />
        <Name />
        <StringValue />
        <NumberValue />
</Grid>
</>
}

export const EditableViewFields = (props) =>
{
    const {values, onRefresh} = props;
    const { t } = useTranslation('setting');

return <>

    <SettingTypeView {...props} />
    <NameView {...props} />
    <StringValueView {...props} />
    <NumberValueView {...props} />

</>

}

