import React from 'react';
import { useTranslation, i18n } from 'react-i18next';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { makeStyles } from '@material-ui/core/styles';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import StoreIcon from '@material-ui/icons/StoreTwoTone';
import Backdrop from '@material-ui/core/Backdrop';
import TimerIcon from '@material-ui/icons/Timer';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root:{
    position: 'fixed', bottom: 10, right: 10 
 }
}));

const LanguageSwitcher = () => {
  const classes = useStyles();
  const { t,i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };


  const navigateToUrl=(url)=>{    
    history.push(url);
  }
 

  const actions = [
    { icon:  <PersonAddIcon /> , url:'/admin/subscription/create', title: t('subscription:new') },
    { icon: <StoreIcon />, url:'/admin/subscription/create/reseller', title:  t('subscription:newReseller') },
    { icon: <TimerIcon />, url:'/admin/subscription/create/trial', title: t('subscription:newTrialSubscription') },
  ];


  return (
    <div className={classes.root}>
      <Backdrop open={open} />

    <SpeedDial

className={classes.speedDialRoot}

  ariaLabel="SpeedDial tooltip example"

  // className={classes.speedDial}
  hidden={false}
  icon={<SpeedDialIcon  />}

  onClose={handleClose}
  onOpen={handleOpen}
  open={open}
>
   {actions.map(action => (
    <SpeedDialAction
      key={action.name}
      icon={action.icon}
      tooltipTitle={action.title}
      tooltipOpen={true} // Always show the tooltip
      tooltipPlacement="left"
      
      onClick={()=> navigateToUrl(action.url)}
    />
  ))} 
</SpeedDial>  
    </div>
  );
};

export default LanguageSwitcher;
