import React, { PureComponent } from 'react'
import { withRouter } from "react-router-dom"
// import 'react-table/react-table.css'
import { getRegions, deleteRegion } from '../api'
import { getRegion, updateRegion, insertRegion } from '../api'
import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CopyIcon from "@material-ui/icons/FileCopy";
import FilterableGrid from '../../../../Component/FilterableGrid';
import DeleteIcon from "@material-ui/icons/Delete";
import DialogWindow from '../../../../Component/DialogWindow'
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import { Link as RouterLink } from 'react-router-dom';
import {withLogin} from '../../../../Contexts/withLogin'
import EmptyList from '../../../../Component/EmptyList'



const ddlColumns = [
    {
        'name': {
            title: 'نام',
            remote: true
        }
    }
];


class DefaultList extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            regions: [],
            selection: [],
            selectAll: false,
            // regionId: props.match.params.regionId,
            openDeleteDialog:false

        }
    }
    area= this.props.match.path.split('/')[1]
    columns = {
        'name': {
            editLink : true,
            // format: (row) => <RouterLink component="button" to={this.props.match.path+`/${row.id}`}>{row.Name}</RouterLink>,
            title: 'نام',
            remote: true
        }
        
    };

    async componentDidMount() {

        await this.loadRegions()
    }

    refresh = async () => {
        await this.loadRegions();
    }

    loadRegions = async () => {
        var res = await getRegions()
        if (res) {
            this.setState({ regions: res, selection :[], selectAll:false, openDeleteDialog:false })
        }
    }

    onFilter = async (params) => {
        var res = await getRegions({...params})

        if (res) {
            const isFiltered = Object.keys(params).length;
            this.setState({ regions: res, openDeleteDialog:false, isFiltered })
        }
    }

    deleteRegions = async () => {
        const res = await deleteRegion(this.state.selection[0].id)
        if (res !== null){
            await this.loadRegions()
        }

    }


    showDeleteDialog = (open, selected) => () => {
        this.setState({ selection : selected, openDeleteDialog: open });
    };

    duplicate = (id) => async () => {
        var region = await getRegion(id);
        if(region) {
            if( await insertRegion({...region, id:undefined}))
                        await this.loadRegions()
        }
    };

    onEditClick =(id)=>{
        const { match } = this.props;
        this.props.history.push(`${match.path}/${id}`);
    }
    onDeleteClick = (selected) => {
        this.setState({ selection : selected, openDeleteDialog: true });

    }
    selectionToolbar = (selected)=> {
        const {t}= this.props;
        return <>
<Tooltip title={t('common:delete')}>
    <IconButton aria-label={t('common:delete')}>
        <DeleteIcon onClick={this.showDeleteDialog(true, selected)} />
    </IconButton>
</Tooltip>
<Tooltip title={t('common:duplicate')}>
    <IconButton aria-label={t('common:duplicate')}>
        <CopyIcon onClick={this.duplicate(selected[0])} />
    </IconButton>
</Tooltip>
        </>
    }

    toolbar = (selected)=> {
        const { t, match }= this.props;
        return <>
<Tooltip title={t('common:new')}>
    <Button component={RouterLink} to={`/${this.area}/region/create`} color="primary">{t('new')}</Button>
</Tooltip>
<Tooltip title={t('common:filter')}>
    <IconButton aria-label={t('common:filter')}>
        <FilterListIcon />
    </IconButton>
</Tooltip>
        </>
    }


DeleteDialog = () => {
    const {t}= this.props;
    return <Dialog open={true}
                   onClose={this.handleCloseDeleteDialog}
                   aria-labelledby="alert-dialog-title"
                   aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title" style={{direction:'rtl'}}>{t('delete')}</DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {t('delete?')}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.showDeleteDialog(false)} color="primary">
            {t('common:cancel')}
        </Button>
        <Button onClick={this.deleteRegions } color="primary" autoFocus>
            {t('common:ok')}
        </Button>
    </DialogActions>
</Dialog>
    }

    render() {
        const { regions, openDeleteDialog, isFiltered } = this.state
        const {match, t} = this.props

        if (!isFiltered && !regions.length) {
                  return (<EmptyList title={t('listIsEmpty')}
           entityTitle={t('region')}
           to={`/${this.area}/region/create`} />)
        }
        return (
        <>
            {openDeleteDialog&& this.DeleteDialog()}
<FilterableGrid data={ regions }
                selectable={this.props.selectable || true}
                pagination={false}
                title={t('regions')}
                selectionToolbar={this.selectionToolbar}
                toolbar={this.toolbar}
                header={this.props.columns ||this.columns}
                onDelete={this.onDeleteClick}
                onEdit={this.onEditClick}
                onFilter={this.onFilter}
                onSelect={this.props.onSelect}></FilterableGrid>
        </>
        )
    }
}

export class ListDialog extends PureComponent {

    render() {
        return <DialogWindow open={this.props.open} onClose={this.props.onClose}>
    <List columns={ddlColumns}
          selectable={1}
          {...this.props} />
</DialogWindow>
    }
}


export const List = withTranslation('region')(withLogin(withRouter(DefaultList)));
export default List;
