import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateIsland } from '../../api'
import {hasValue} from '../../../../../utils/string'

import Switch from '../../../../../Component/Switch'

export const Enabled =  () => {
    const { t } = useTranslation('island');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('enabled.title')}
                   name={"enabled"}
                   component={Switch}
                   type="text" />
        </FormControl>
    </Grid>
    </>
}

export const validate = (values, errors, t)=> {
    if ((values.enabled !== true && values.enabled !==false)) {
        errors.enabled = t('enabled.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, enabled }=values;
        return await updateIsland({id, enabled });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { enabled } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('enabled.title')}
                          headline={ enabled?t('common:yes'):t('common:no') }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ Enabled }
                          />

    }
}


export const EnabledView = withTranslation('island')(View);
