import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateTrafficTransaction } from '../../api'
import {hasValue} from '../../../../../utils/string'

import TextInput from '../../../../../Component/TextInput'

export const SenderNewBalance =  () => {
    const { t } = useTranslation('trafficTransaction');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('senderNewBalance.title')}
                   name={"senderNewBalance"}
                   component={TextInput}
                   number
                   />
        </FormControl>
    </Grid>
    </>
}
export const validate = (values, errors, t)=> {
    if (!hasValue(values.senderNewBalance)) {
        errors.senderNewBalance = t('senderNewBalance.required');
    }
    return errors;
}


export function SenderNewBalanceView({ values, onRefresh, readonly }) {
    const { t } = useTranslation('trafficTransaction');

    const update = async (values) =>{
        const {id, senderNewBalance }=values;
        return await updateTrafficTransaction({id, senderNewBalance });
    }

    const successMessage  = (values) => t('updated')

    const { senderNewBalance } = values;

    return <ViewField successMessage={successMessage}
                        title={ t('senderNewBalance.title')}
                        headline={ senderNewBalance }
                        update={update}
                        validate={validate}
                        values={values}
                        onRefresh={onRefresh}
                        readonly = {readonly}
                        editComponent={ SenderNewBalance } />
    
}


