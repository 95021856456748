import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateResellerSetting } from '../../api'
import {hasValue} from '../../../../../utils/string'

import TextInput from '../../../../../Component/TextInput'

export const Name =  () => {
    const { t } = useTranslation('resellerSetting');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('name.title')}
                   name={"name"}
                   component={TextInput}
                   />
        </FormControl>
    </Grid>
    </>
}
export const validate = (values, errors, t)=> {
    if ( !hasValue(values.name)) {
        errors.name = t('name.required');
    }
    return errors;
}


export function NameView({ values, onRefresh }) {
    const { t } = useTranslation('resellerSetting');

    const update = async (values) =>{
        const {id, name }=values;
        return await updateResellerSetting({id, name });
    }

    const successMessage  = (values) => t('updated')

    const { name } = values;

    return <ViewField successMessage={successMessage}
                        title={ t('name.title')}
                        headline={ name||t('common:notEntered') }
                        update={update}
                        validate={validate}
                        values={values}
                        onRefresh={onRefresh}
                        editComponent={ Name } />

}