import React, { PureComponent } from 'react'
import RouterBreadcrumbs from '../../../../Component/RouterBreadcrumbs'
import { withTranslation } from 'react-i18next';
import "filepond/dist/filepond.min.css";
import { EditableViewFields } from '../Form';
import Button from '@material-ui/core/Button'
import { hasRole, getCurrentUser } from '../../../../modules/Auth'

class EditableViewForm extends PureComponent {
  title() {
    const {t, values:{id,customerName}, onRefresh} = this.props

    var currentId = getCurrentUser().id;
    if(id == currentId) return t("my-subscription");

    return customerName || t('subscriber');
  } 
  render() {
        const {t, values, onRefresh} = this.props

        return (<>
<RouterBreadcrumbs title={ this.title()  } />
{/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
<RouterBreadcrumbs title={ t('subscription') } />
  <div>
    <Button variant="contained" color="primary">بسته‌ی جدید</Button>
  </div>
</div> */}

            <EditableViewFields onRefresh={onRefresh} values={values} t={t} />
        </>)
    }
}

export default withTranslation('subscription')(EditableViewForm);