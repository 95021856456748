import React, { PureComponent } from 'react'
import { withRouter } from "react-router-dom"
// import 'react-table/react-table.css'
import { getSubscriberBundles, deleteBundle } from '../api'
import { getBundle, updateBundle, insertBundle } from '../api'
import FilterListIcon from "@material-ui/icons/FilterList";
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CopyIcon from "@material-ui/icons/FileCopy";
import FilterableGrid from '../../../../Component/FilterableGrid';
import DeleteIcon from "@material-ui/icons/Delete";
import DialogWindow from '../../../../Component/DialogWindow'
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import { Link as RouterLink } from 'react-router-dom';
import {withLogin} from '../../../../Contexts/withLogin'
import EmptyList from '../../../../Component/EmptyList'



const ddlColumns = [
];


class DefaultList extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            bundles: [],
            selection: [],
            selectAll: false,
            // bundleId: props.match.params.bundleId,
            openDeleteDialog:false

        }
    }
    area= this.props.match.path.split('/')[1]
    columns = {
    };

    async componentDidMount() {
        await this.loadBundles();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.refresh !== this.props.refresh || prevProps.filters !== this.props.filters) {
            await this.loadBundles();
        }
    }

    loadBundles = async () => {
        console.log('filter: ');
        console.log(this.props.filters);
        var res = await getSubscriberBundles({...this.props.filters});
        if (res) {
            this.setState({ bundles: res, selection: [], selectAll: false, openDeleteDialog: false });
        }
    };

    onFilter = async (params) => {
        const filterParams = {...params, ...this.props.filters};

        var res = await getSubscriberBundles({...filterParams})

        if (res) {
            const isFiltered = Object.keys(params).length;
            this.setState({ bundles: res, openDeleteDialog:false, isFiltered, filterParams })
        }
    }

    deleteBundles = async () => {
        const res = await deleteBundle(this.state.selection[0].id)
        if (res !== null){
            await this.loadBundles()
        }

    }


    showDeleteDialog = (open, selected) => () => {
        this.setState({ selection : selected, openDeleteDialog: open });
    };

    duplicate = (id) => async () => {
        var bundle = await getBundle(id);
        if(bundle) {
            if( await insertBundle({...bundle, id:undefined}))
                        await this.loadBundles()
        }
    };

    // onEditClick =(id)=>{
    //     const { match } = this.props;
    //     this.props.history.push(`${match.path}/${id}`);
    // }
    // onDeleteClick = (selected) => {
    //     this.setState({ selection : selected, openDeleteDialog: true });

    // }
    selectionToolbar = (selected)=> {
        const {t}= this.props;
        return <>
<Tooltip title={t('common:delete')}>
    <IconButton aria-label={t('common:delete')}>
        <DeleteIcon onClick={this.showDeleteDialog(true, selected)} />
    </IconButton>
</Tooltip>
<Tooltip title={t('common:duplicate')}>
    <IconButton aria-label={t('common:duplicate')}>
        <CopyIcon onClick={this.duplicate(selected[0])} />
    </IconButton>
</Tooltip>
        </>
    }

    toolbar = ()=>(selected)=> {
        const { t, createButton:CreateButton }= this.props;
        return <>
<Tooltip title={t('common:new')}>
{CreateButton ||<Button component={RouterLink} to={`/${this.area}/bundle/create`} color="primary">{t('new')}</Button>}
</Tooltip>
{/* <Tooltip title={t('common:filter')}>
    <IconButton aria-label={t('common:filter')}>
        <FilterListIcon />
    </IconButton>
</Tooltip> */}
        </>
    }


DeleteDialog = () => {
    const {t}= this.props;
    return <Dialog open={true}
                   onClose={this.handleCloseDeleteDialog}
                   aria-labelledby="alert-dialog-title"
                   aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title" style={{direction:'rtl'}}>{t('delete')}</DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {t('delete?')}
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={this.showDeleteDialog(false)} color="primary">
            {t('common:cancel')}
        </Button>
        <Button onClick={this.deleteBundles } color="primary" autoFocus>
            {t('common:ok')}
        </Button>
    </DialogActions>
</Dialog>
    }

    render() {
        const { bundles, openDeleteDialog, isFiltered } = this.state
        const {fixed, match,onEditClick,onDeleteClick, onSelect,t }= this.props

        if (!isFiltered && !bundles.length) {
                  return (<EmptyList title={t('listIsEmpty')}
           entityTitle={t('bundle')}
           to={`/${this.area}/bundle/create`} />)
        }
        return (
        <>
            {openDeleteDialog&& this.DeleteDialog()}
<FilterableGrid 
                fixed = {fixed}
                data={ bundles }
                selectable={this.props.selectable || true}
                pagination={false}
                title={this.props.title|| t('bundles')}
                selectionToolbar={this.selectionToolbar}
                toolbar={this.toolbar()}
                header={this.props.columns ||this.columns}
                showHeader = {false}
                onEdit={onEditClick}
                onDelete={onDeleteClick}
                // onDelete={this.onDeleteClick}
                // onEdit={this.onEditClick}
                onFilter={this.onFilter}
                onSelect={this.props.onSelect}
                >
                    
                </FilterableGrid>
        </>
        )
    }
}

export class ListDialog extends PureComponent {

    render() {
        return <DialogWindow open={this.props.open} onClose={this.props.onClose}>
    <List columns={ddlColumns}
          selectable={1}
          {...this.props} />
</DialogWindow>
    }
}


export const List = withTranslation('bundle')(withLogin(withRouter(DefaultList)));
export default List;
