import React, { } from 'react'
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';



class View extends React.Component {


    render() {
        const { values, onRefresh } = this.props;
        const { id } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ 'کد مشتری '}
                          headline={ id }
                          values={values}
                          onRefresh={onRefresh}
                          readonly={true}
                           />
    }
}


export const IdView = withTranslation('user')(View);