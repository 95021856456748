import React from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import TextInput from '../../../../../Component/TextInput'
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateUser } from '../../api'

export const Password = ({t=useTranslation('user').t}) =>
<>
<Grid item sm={6} xs={12}   >
    <FormControl fullWidth>
        <Field
            label={t('password.title')}
            name="password"
            component={TextInput}
            type="password"
        />
    </FormControl>
</Grid>
<Grid item sm={6} xs={12}   >
    <FormControl fullWidth >
        <Field
            label={t('repeatPassword.title')}
            name="repeatPassword"
            component={TextInput}
            type="password"
        />
    </FormControl>
</Grid>
</>
function scorePassword(pass) {
    var score = 0;
    if (!pass)
        return score;

    // award every unique letter until 5 repetitions
    var letters = {};//new Object();
    for (var i=0; i<pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    var variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    }

    var variationCount = 0;
    for (var check in variations) {
        variationCount += (variations[check] === true) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return parseInt(score);
}
export const validate = (values, errors, t)=> {

    if (!values.hasPassword)
        return errors;

    if (!values.password) {
        errors.password = t('password.required');
    }

    if (!values.repeatPassword) {
        errors.repeatPassword = t('repeatPassword.required')
    }

    if (values.password && values.repeatPassword
        && (values.password !== values.repeatPassword)) {
        errors.repeatPassword = t('repeatPassword.invalid')
    }

    if(scorePassword(values.password) < 40) {
        errors.password = t('password.invalid')
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, password }=values;
        return await updateUser({id, password });
    }

    successMessage  = (/*values*/) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { password } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('password.title')}
                          headline={ password }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={Password}
                          />
    }
}


export const PasswordView = withTranslation('user')(View);