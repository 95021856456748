import React, { PureComponent } from 'react'
import { withTranslation, useTranslation } from 'react-i18next';
import List from './DefaultList'
import {ActiveBundleCreateDialog} from '../Create/Dialogs'
import {ActiveBundleEditDialog} from '../Edit/Dialogs'
import { withTheme } from '@material-ui/core/styles';
import { bundleToGb, toGb    } from '../../../../domain/bundleTraffic';
import {   bundleType } from '../../../../domain/bundleType';

import { hasRole, getCurrentUser } from '../../../../modules/Auth'

import {columnTypes} from '../../../../Component/FilterableGrid' 
import Button from '@material-ui/core/Button'
import { Box, Chip } from '@material-ui/core';
import EmptyList from '../../../../Component/EmptyList'
// import {userTitle} from '../../../../domain/extensions/userExtensions'
// import {dueTitle} from '../utils/recurrentUtils'
import {titleById, byId, bundleStatus} from '../../../../domain/bundleStatus'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Grid, LinearProgress } from '@material-ui/core';
import {toHumanText, durationUnits,durationValues, fromHours, toHours} from '../../../../domain/subscriptionPeroid'

import CircularProgress from '@material-ui/core/CircularProgress';
  import Typography from '@material-ui/core/Typography';
  
  
import NotInterestedIcon from '@material-ui/icons/NotInterested';

 const byPath = (obj, path) => {
    try {
      return path.split('.').reduce((o, key) => o[key], obj);
    } catch {
      return null;
    }
  };

  
  
function LinearProgressWithLabel(props) {
    return (
      <Box position="relative" display="block" width="100%">
        <LinearProgress variant="determinate" {...props} style={{minWidth:40, maxWidth:100,height:16, borderRadius: '5px' }} />
        <Box 
          position="absolute" 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
          minWidth={40}
           maxWidth={100}
          width="100%" 
          top={3} 
          left={0} 
          bottom={0} 
          right={0}
        >
          <Typography variant="caption" component="div" style={{ color: props.value >= 60 ? '#fff' : '#000' }}>
            {`${Math.round(props.value)}%`}
            {props.label && <Typography variant="caption" component="span" style={{ paddingLeft:10, color: props.value >= 60 ? '#fff' : '#000' }}>
                        { `${props.label}`}

                        
                      </Typography>
            }
          </Typography>
        </Box>
      </Box>
    );
  }
  function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }
export class ActiveBundleList extends PureComponent {
    state={
        new:this.props.newBundleMode || false,
        edit:false,
        selectedBundleId:0 
    }
    // area = this.props.match.path.split('/')[1];
    status=(id)=>{
        if(!id) id=bundleStatus.NotUsed;
        var color= byId(id).color;
        const colorCode = byPath(this.props.theme.palette, color) || '';

        var icon = id === bundleStatus.Using? <DoneIcon style={{ color: colorCode }} /> : id === bundleStatus.Cancelled?<NotInterestedIcon style={{ color: colorCode }} />:<ErrorOutlineIcon style={{ color: colorCode }} />;

        return <Chip variant="outlined" color={colorCode} label={titleById(id)} style={{color:colorCode,borderColor:colorCode}} size="small" icon={icon} />
    }
    type=(id)=>{
      
      var colorCode = this.props.theme.palette.text.secondary;
      

      if(id == bundleType.Reseller)
        return <Chip variant="outlined" color={colorCode} label={'reseller'} style={{borderColor: colorCode, color:colorCode}}
        size="small" />
      
        return '';
  }

  getColumns = (t) => ({
     
    'createdTime': {
      editLink: false,
      title: 'Date',
      type: columnTypes.date,
      remote: true
    },
    'traffic': {
        title: t('trafficVolume'),
        type: columnTypes.number,
        format: (row) => <span> <span>{ toGb(row.traffic,0)}</span> <span> {(row.bundleType !== bundleType.Reseller ? ' / ' + toHumanText(row.durationHours)+ ' / x'+row.concurrencyLimit:this.type(row.bundleType) )}</span> </span>,
        filter: false,
        editLink: true,
        remote: true
    },
    // 'concurrencyLimit': {
    //     title: t('🔀'),
    //     format: (row) => 'x'+row.concurrencyLimit,
    //     editLink: true,

    //     remote: true
    // }, 
    'trafficUsed': {
        title: t('remaining'),
        type: columnTypes.number,
        format: (row) => row.status !== bundleStatus.Over 
                         ? <LinearProgressWithLabel value={(((row.traffic - row.trafficUsed) / row.traffic) * 100) || 0} label={bundleToGb(row)} />
                         : '',
        filter: false,
        remote: false
    },
    'remainingHours': {
        title: t('duration'),
        type: columnTypes.number,
        format: (row) => <span>
          <span>{(row.bundleType === bundleType.Infinite ? t('unlimited') : toGb(row.availableTraffic, 1, true))} 
          </span>
          
          
          <span>{(row.bundleType === bundleType.Reseller 
                         ? '' 
                         : ' / ' +(row.remainingHours > 0 ? toHumanText(row.remainingHours) + ' ' : t('ended')))
          }</span> 
           </span>,
        remote: true
    },
    'status': {
        title: t('status.title'),
        format: (row) => this.status(row.status),
        remote: true
    }
  })

    handleDialogClose=()=>{
        this.setState({new:false});
    }

    handleDialogOk=()=>{
        this.setState({new:false, edit:false});
    }

    handleNewButtonClick=()=>{
        this.setState({new:true});
    }

    handleBundleClick=(bundleId)=>{
        if(!bundleId) { return;}
        this.setState({edit:true, selectedBundleId:bundleId});
    }
    handleEditDialogClose=()=>{
        this.setState({edit:false});
    }
    EmptyListContent = () => { 
      const { t } = this.props;
  
      return (
          <EmptyList 
              title={t('noActivePackageForSubscriber')}
              entityTitle={t('bundle')}
              to={this.handleNewButtonClick}
          />
      );
  }
  
  onEditClick =(id)=>{
    const { match } = this.props;
    alert('asdfasdf' + id);
    this.props.history.push(`${match.path}/${id}`);
  }
  onDeleteClick = (selected) => {
      this.setState({ selection : selected, openDeleteDialog: true });

  } 
  render() {
    const currentUserId = getCurrentUser().id;
    const { subscriber: { id = currentUserId } = {}, t } = this.props;

    if(!id) return <></>;
    const subscriber = { ...this.props.subscriber, id };
    
    const isSameUser = id == currentUserId;


    var newButton = <></>;
    if(subscriber.isReseller || !isSameUser) {
        newButton = <Button 
        variant="contained"
        color="primary" 
        style={{ backgroundColor: this.props.theme.palette.success.dark }}
        startIcon={<AddCircleOutlineIcon />}
        onClick={this.handleNewButtonClick} >{!isSameUser? t('new'): t('myNew')}</Button>
    }
    return (
        <>
            <List  
                fixed={false}
                createButton={newButton}
                columns={this.getColumns(t)}
                refresh={this.state.new || this.state.edit} 
                selectable={1}  
                onSelect={this.handleBundleClick}
                filters={{subscriberId : id}}
                title={t('bundles')}
                onEdit={this.onEditClick}
                onDelete={this.onDeleteClick}

                emptyList={this.EmptyListContent()}
                {...this.props} 
                subscriber={subscriber}
            /> 
            
            {this.state.new &&
                <ActiveBundleCreateDialog 
                    onOk={this.handleDialogOk} 
                    onClose={this.handleDialogClose}
                    subscriber={subscriber}
                />
            }

            {this.state.edit &&
                <ActiveBundleEditDialog 
                    onClose={this.handleEditDialogClose}
                    id={this.state.selectedBundleId}
                    subscriber={subscriber}
                />
            }
        </>
    );
  }
}
       
export default withTheme(withTranslation('bundle')(ActiveBundleList));
