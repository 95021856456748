import {t} from '../i18n';
import { getCurrentUser, getProfile, hasRole } from '../modules/Auth';

export const subscriptionStatus = {
    Active:0,
    Pending: 1,
    Expired:2,
    Cancelled:3,
    Suspended:4,
    Trialing:4,
}

export const allSubscriptionStatuses = [
    {
        "id": 0,
        "systemName": "active",
        "displayName" : t('subscription:active'),
        "color" : "success.dark"
    },
    {
        "id": 1,
        "systemName": "trialing",
        "displayName" : t('subscription:trialing'),
        "color" : "primary.dark",
        // "admin-access" : true
    },
    {
        "id": 2,
        "systemName": "pending",
        "displayName" : t('subscription:pending'),
        "color" : "success.dark",

    },
    {
        "id": 3,
        "systemName": "expired",
        "displayName" : t('subscription:expired'),
        "color" : "error.dark",
        "admin-access" : true
    },
    {
        "id": 4,
        "systemName": "cancelled",
        "displayName" : t('subscription:cancelled'),
        "color" : "error.dark",
    },
    {
        "id": 5,
        "systemName": "suspended",
        "displayName" : t('subscription:suspended'),
        "color" : "text.secondary",
        // "admin-access" : true

    },
    {
        "id": 6,
        "systemName": "concurrencyExceeded",
        "displayName" : t('subscription:concurrencyExceeded'),
        "color" : "error.dark",
        "admin-access" : true

        // "admin-access" : true

    },
];

const userAccessStatuses = allSubscriptionStatuses.filter(s=>!s["admin-access"])

export const getAllStatus = ()=>{
    const  isSuperAdmin = hasRole("SuperAdmin");
    if(!isSuperAdmin) return userAccessStatuses;
    return allSubscriptionStatuses;
}
export const byId = (id)=>{
    return allSubscriptionStatuses.find(ss=>ss.id===id) || {}
}

export const titleById = (id)=>{
    return byId(id).displayName || "";
}