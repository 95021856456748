import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateSubscription } from '../../api'
import {hasValue} from '../../../../../utils/string'

import DateTimeInput from '../../../../../Component/DateTimeInput';
import gmoment from 'moment';
import jmoment from 'moment-jalaali';

const lang = localStorage.getItem('language') || 'en';
const moment = lang=="fa"?jmoment : gmoment;

export const LastSubscriptionUpdate =  () => {
    const { t } = useTranslation('subscription');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
        <Field label={t('lastSubscriptionUpdate.title')}
                name={"lastSubscriptionUpdate"}
                date time
                component={DateTimeInput}
                />
        </FormControl>
    </Grid>
    </>
}

export const validate = (values, errors, t)=> {
    if (!hasValue(values.lastSubscriptionUpdate)) {
        errors.lastSubscriptionUpdate = t('lastSubscriptionUpdate.required');
    }
    return errors;
}

class View extends React.Component {

    update = async (values) =>{
        const {id, lastSubscriptionUpdate }=values;
        return await updateSubscription({id, lastSubscriptionUpdate:moment(lastSubscriptionUpdate).utc().format() });
    }

    successMessage  = (values) => this.props.t('updated')

    headline() {
        const { values={}, t} = this.props;
        const { lastSubscriptionUpdate } = values;
        const format = lang=="fa"?'jYYYY/jMM/jDD hh:mm A' : 'YYYY/MM/DD HH:MM';
        console.log('lastSubscriptionUpdate')
        console.log(moment(lastSubscriptionUpdate));
        return lastSubscriptionUpdate?moment(lastSubscriptionUpdate).format(format):'';
    }

    render() {
        const { values, onRefresh,readonly, t } = this.props;
        const { lastSubscriptionUpdate } = values;

        return <ViewField 
            successMessage={this.successMessage}
            title={ t('lastSubscriptionUpdate.title')}
            headline={ this.headline() }
            update={this.update}
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            readonly = {readonly}
            editComponent={ LastSubscriptionUpdate } />

    }
}


export const LastSubscriptionUpdateView = withTranslation('subscription')(View);