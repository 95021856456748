
import { makeStyles } from '@material-ui/core/styles';

export const useStyles =makeStyles((theme) => ({
    
    root:{
        // padding :20,
        
        // color:"#444444"
    },
    paper: {
        // borderRad
        borderRadius:0,
        maxHeight: '300px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        minHeight:'300px'
    },
    control: {
        padding: theme.spacing(2),
    },
    link: {
        textDecoration: 'none'
    },
    container: {
        //paddingRight: 25,
        //paddingLeft: 25
    },
    header: {
        //paddingRight: 25
    },
    grid: {
        paddingTop: 10
    },
    
    "a":{
        maxWidth: 290,
        /* padding-right: 100px; */
        border: "1px solid rgba(11, 105, 161, 0.627)",
        display: "inline-block",
        borderRadius: 10,
        height: 70,
        borderTopLeftRadius: 50,
        borderBottomLeftRadius: 50,
        padding: 10,
        paddingLeft: 80,
        paddingRight:20,
        margin: 20,
        color: "rgb(11, 105, 161)",
        textAlign: 'left',
        "background": "rgba(255, 255, 255, 0.5)",
        '& div':{
            "background": "rgba(255, 255, 255, 0)",
            "WebkitTransition": "background 0.2s, color 0.2s, left 0.2s",
            "MozTransition": "background 0.2s, color 0.2s, left 0.2s",
            "transition": "background 0.2s, color 0.2s, left 0.2s",
            "left": "4px",
        },
        '&:hover':{
            "background": "rgba(255, 255, 255, 1)"
        },
        // overflow: "hidden",
        // textOverflow: "ellipsis"
        '&:hover div' : {
                // "top": "-7px",
                "left": "-10px",
                // "padding": "7px",
                // "boxShadow": "0 0 0 4px #fff",
                "background": "rgba(255, 255, 255, 1)",
                "WebkitTransition": "-webkit-transform 0.2s, opacity 0.2s, left 0.2s",
                "WebkitTransform": "scale(1.3)",
                "MozTransition": "-moz-transform 0.2s, opacity 0.2s, left 0.2s",
                "MozTransform": "scale(1.3)",
                "MsTransform": "scale(1.3)",
                "transition": "transform 0.2s, opacity 0.2s, left 0.2s",
                "transform": "scale(1.3)",
                // "opacity": "0"          
        },
    },
    serviceTitle:{
        fontSize:'130%',
        padding:20,
        fontWeight: "bold",
        color: "#1e938e"
    },
    icon : {
        position:"absolute",
        width: 60,
        height:60,
        left: 4,
        top: 4,
        border:"2px solid",
        borderRadius: 100,
        padding: 10,
        overflow: "hidden"



    },
    hovicon: {
        display: 'inline-block',
        // fontSize: 45,
        // lineHeight: 90,
        cursor: "pointer",
        // margin: 20,
        // width: 90,
        // height: 90,
        // borderRadius: '50%',
        textAlign: "center",
        // position: "relative",
        textDecoration: "none",
        zXndex: 1,
        // color: "#fff",
        "&::after": 
            {
                "pointerEvents": "none",
                "position": "absolute",
                "width": "100%",
                "height": "100%",
                "borderRadius": "50%",
                "content": "''",
                "WebkitBoxSizing": "content-box",
                "MozBoxSizing": "content-box",
                "boxSizing": "content-box"
              },
            "&::before": 
              {
                "speak": "none",
                "fontSize": "48px",
                "lineHeight": "90px",
                "fontStyle": "normal",
                "fontWeight": "normal",
                "fontVariant": "normal",
                "textTransform": "none",
                "display": "block",
                "WebkitFontSmoothing": "antialiased"
              },

             
          
    },

}));



