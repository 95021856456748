import React, { PureComponent } from 'react'
import { getIsland } from '../api'
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import EditableViewForm from './EditForm';
import { withRouter } from "react-router-dom"

class EditPage extends PureComponent {

    state = {
        island: {},
        //islandId: this.props.match.params.id
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.loadData()
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData(){
        const islandId = this.props.match.params.id;
        var island = await getIsland(islandId)

        if (!island) return;

        this.setState({ island })
    }

    refresh = async ()=>{
        await this.loadData();
    }

    render() {
        return <EditableViewForm onRefresh={this.refresh}
                  values={this.state.island } />
    }
}


export default withSnackbar(withTranslation('island')(withRouter(EditPage)));