import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateSubscription } from '../../api'
import {hasValue} from '../../../../../utils/string'
import { subscriptionType } from '../../../../../domain/subscriptionType';
import TextInput from '../../../../../Component/TextInput'

export const MonthsRemaining =  () => {
    const { t } = useTranslation('subscription');

    return <>
    <Grid item sm={ 3 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('monthsRemaining.title')}
                   name={"monthsRemaining"}
                   component={TextInput}
                   number
                   adornment ="ماه"
                   />
        </FormControl>
    </Grid>
    </>
}
export const validate = (values, errors, t)=> {
    if (values.subscriptionType !==  subscriptionType.Lifetime && !hasValue(values.monthsRemaining)) {
        errors.monthsRemaining = t('monthsRemaining.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, monthsRemaining }=values;
        return await updateSubscription({id, monthsRemaining });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh,readonly, t } = this.props;
        const { monthsRemaining } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('monthsRemaining.title')}
                          headline={ monthsRemaining + ' ماه' }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          readonly = {readonly}
                          editComponent={ MonthsRemaining } />
    }
}


export const MonthsRemainingView = withTranslation('subscription')(View);