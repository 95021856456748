import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('beshkan');

export const insertSampleData = async (model) => {

    const resultEntity = await post(`setting/GenerateSampleData`,model,
    {
        errorMessage : t('common:insertError') + t('setting:setting')
    });

    if(!resultEntity) return null;

    return {...model, id:resultEntity.id};
}


export const insertSetting = async (setting) => {

    const resultEntity = await post(`setting`,setting,
    {
        errorMessage : t('common:insertError') + t('setting:setting')
    });

    if(!resultEntity) return null;

    return {...setting, id:resultEntity.id};
}


export const updateSetting = async (setting) => {

    const result = await putFormData(`setting/${ setting.id }`,
        setting,
        {
            errorMessage :  t('common:updateError') + t('setting:setting')
        });

    return result;
}

export const deleteSetting = async (id) => {

    const result = await del(`setting/${id}`,
        {
            errorMessage : t('common:deleteError') + t('setting:setting')
        });

    return result;
}

export const getSettings = async (params) => {

    const result = await get(`setting`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('setting:settings')

    });

    return result;
}



export const getSetting = async (id) => {

    const result = await get(`setting/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('setting:setting')
    });

    return result;
}



