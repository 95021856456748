import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('beshkan');

export const insertMimeType = async (mimeType) => {

    const resultEntity = await post(`mimeType`,mimeType,
    {
        errorMessage : t('common:insertError') + t('mimeType:mimeType')
    });

    if(!resultEntity) return null;

    return {...mimeType, id:resultEntity.id};
}


export const updateMimeType = async (mimeType) => {

    const result = await putFormData(`mimeType/${ mimeType.id }`,
        mimeType,
        {
            errorMessage :  t('common:updateError') + t('mimeType:mimeType')
        });

    return result;
}

export const deleteMimeType = async (id) => {

    const result = await del(`mimeType/${id}`,
        {
            errorMessage : t('common:deleteError') + t('mimeType:mimeType')
        });

    return result;
}

export const getMimeTypes = async (params) => {

    const result = await get(`mimeType`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('mimeType:mimeTypes')

    });

    return result;
}



export const getMimeType = async (id) => {

    const result = await get(`mimeType/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('mimeType:mimeType')
    });

    return result;
}



