import React, { PureComponent } from 'react'
class Logo extends PureComponent {
  render() {
    const { style } = this.props
    return (
        <img alt="logo" style={style} src={"/content/images/logo.png"}></img>
    )
  }
}

export default Logo
