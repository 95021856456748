import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from '../../../../../Component/Field';
import TextInput from '../../../../../Component/TextInput'
import Switch from '../../../../../Component/Switch'

export const GenerateBeshkan =  ({values}) =>
<>
<Grid item sm={ 6 } xs={12}>
    <FormControl fullWidth>
        <Field label={'نمونه‌ی گپ'}
               name={"generateBeshkan"}
               component={Switch} />
    </FormControl>
</Grid>
{values.generateBeshkan&&
<Grid item sm={ 6 } xs={12}>
<FormControl fullWidth>
        <Field placeholder={'تعداد'}
               name={"benefactorCount"}
               number
               component={TextInput}
               adornment={'رکورد'} />
    </FormControl>
</Grid>
}
</>

export const validate = (values, errors)=> {
    if(values.generateBeshkan && !values.benefactorCount)
        errors.benefactorCount = "تعداد نمونه باید بزرگتر از صفر باشد";
    return errors;
}