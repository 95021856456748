import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateOccasion } from '../../api'
import DateTimeInput from '../../../../../Component/DateTimeInput';
import moment from 'moment-jalaali';

export const ReminderAt =  ({t=useTranslation('occasion').t}) =>
<>
<Grid item sm={ 12 } xs={12}>
    <FormControl fullWidth>
    <Field label={t('reminderAt.title')}
            name={"reminderAt"}
            time
            component={DateTimeInput}
            />
    </FormControl>
</Grid>
</>

export const validate = (values, errors, t)=> {
    if (values.reminderEnabled && !values.reminderAt) {
        errors.reminderAt = t('reminderAt.required');
    }
    return errors;
}

class View extends React.Component {

    update = async (values) =>{
        const {id, reminderAt }=values;
        return await updateOccasion({id, reminderAt:moment(reminderAt).utc().format() });
    }

    successMessage  = (values) => this.props.t('updated')

    headline() {
        const { values={}} = this.props;
        const { reminderAt } = values;
        const format = 'hh:mm A';
        
        return reminderAt?moment(reminderAt).format(format):'-';
    }

    render() {
        const { values, onRefresh, t } = this.props;

        return <ViewField 
            successMessage={this.successMessage}
            title={ t('reminderAt.title')}
            headline={ this.headline() }
            update={this.update}
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            editComponent={ ReminderAt } />

    }
}


export const ReminderAtView = withTranslation('occasion')(View);