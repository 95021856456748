import React, { PureComponent } from 'react'
import widthForm from '../../../../Contexts/withForm';
import { withTranslation } from 'react-i18next';
import {FormFields} from '../Form';
import FormActionBar from '../../../../Component/FormActionBar';
import RouterBreadcrumbs from '../../../../Component/RouterBreadcrumbs'
import {userTitle} from '../../../../domain/extensions/userExtensions'

class CreateForm extends PureComponent{
    title(){ 
        return this.props.t("new") + ' ' + userTitle(this.props.values);
    }
    render() {
        const {values, reset, t} = this.props;
        return <form autocomplete="off">
            <RouterBreadcrumbs  />
            <FormFields values={values} reset={reset} t={t} />
            <FormActionBar {...this.props} submitLabel={t('register')} />
        </form>
    }
}

export default withTranslation('user')(widthForm(CreateForm));