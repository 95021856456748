import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData, postFormData } = createApi('beshkan');

export const importFromJson = async (data) => {

    const result = await postFormData(`subscription/importfromjson`,
    data,
    {
        errorMessage :  t('common:updateError') + t('server:server')
    });

    return result;
} 


export const insertSubscription = async (subscription) => {
    var idempotencyKey = subscription.idempotencyKey;
    delete subscription.idempotencyKey;

    subscription = fixModel(subscription);
    const resultEntity = await post(`subscription`,subscription,
    {

        headers:{"Idempotency-Key" :idempotencyKey},        
        errorMessage : t('common:insertError') + t('subscription:subscription')
    });

    if(!resultEntity) return null;

    return {...subscription, id:resultEntity.id};
}


export const updateSubscription = async (subscription) => {
    subscription = fixModel(subscription);

    console.log(subscription);
    const result = await putFormData(`subscription/${ subscription.id }`,
        subscription,
        {
            errorMessage :  t('common:updateError') + t('subscription:subscription')
        });

    return result;
}

export const deleteSubscription = async (id) => {

    const result = await del(`subscription/${id}`,
        {
            errorMessage : t('common:deleteError') + t('subscription:subscription')
        });

    return result;
}

export const getSubscriptions = async (params) => {

    const result = await get(`subscription`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('subscription:subscriptions')

    });

    return result;
}
export const getSubscriptionsByKey = async (key) => {

    const result = await get(`subscription/key/`+key,
    {  }
    ,
    {
        errorMessage : t('common:fetchError') + t('subscription:subscriptions')

    });

    return result;
}


export const getSubscription = async (id) => {

    const result = await get(`subscription/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('subscription:subscription')
    });

    return result;
}



function fixModel(sub){
    sub = {...sub};

    if(sub.selectedServers){
        sub.subscriptionSelectedServers = sub.selectedServers.map(s=> ({serverId:s.id}));
        sub.selectedServers = undefined;
    }

    return sub;
}

