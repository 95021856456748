//// import config from '../config.json'
var IDENTITY_URL= /*"https://localhost:5000/auth" ||*/ process.env.REACT_APP_IDENTITY_URL;
var CRM_URL= /*"https://localhost:59797" ||*/ process.env.REACT_APP_CRM_URL;
var DIRECTORIES_URL=  process.env.REACT_APP_DIRECTORIES_URL;
var BENEFACTORS_URL=  process.env.REACT_APP_BENEFACTORS_URL;
var CLIENTS_URL= process.env.REACT_APP_CLIENTS_URL;
var SPA_URL= process.env.REACT_APP_SPA_URL;

// var IDENTITY_URL= "https://localhost:5000/auth";
// // var CRM_URL= "https://localhost:59797";
// var DIRECTORIES_URL=  "https://localhost:57797";
// var BENEFACTORS_URL=  "https://localhost:56797";
// // var CLIENTS_URL= "https://localhost:58797";
// var SPA_URL= "http://localhost:3000";

export default {
    // serverUrl: process.env.SERVER_URL || window.location.origin,
    // serverOrigin: `${process.env.SERVER_URL}/api` || window.location.origin,
    crmUrl: CRM_URL?`${CRM_URL}/api` : `${window.location.origin}/crm/api`,
    directoriesUrl: DIRECTORIES_URL?`${DIRECTORIES_URL}/api` : `${window.location.origin}/directories/api`,
    beshkanUrl: BENEFACTORS_URL?`${BENEFACTORS_URL}/api` : `${window.location.origin}/beshkan/api`,
    clientsUrl: CLIENTS_URL?`${CLIENTS_URL}/api` : `${window.location.origin}/clients/api`,
    identityUrl: IDENTITY_URL?`${IDENTITY_URL}/api` : `${window.location.origin}/auth/api`,
    authorityUrl: IDENTITY_URL?`${IDENTITY_URL}` : `${window.location.origin}/auth`,
    spaClientUrl: SPA_URL || `${window.location.origin}`
    // todoUrl: `https://localhost:45318`

}
