import React from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '../../../../../Component/Divider'
import { GenerateDonations, validate as validateGenerateDonations } from './GenerateDonations';
import { GenerateRecurrentDonations, validate as validateGenerateRecurrentDonations } from './GenerateRecurrentDonations';
import { GenerateClients, validate as validateGenerateClients } from './GenerateClients';
import { GenerateBeshkan, validate as validateGenerateBeshkan } from './GenerateBeshkan';

export const validators={
    validateGenerateDonations, validateGenerateRecurrentDonations   , validateGenerateClients ,validateGenerateBeshkan
};

export const validate =(values, errors, t) => {

    for (const [, func] of Object.entries(validators)) {
        func(values, errors, t);
      }
}
export const FormFields = (props) =>
{
    const { values} = props;
    return <>
        <Grid container spacing={2} style={{padding:25, paddingTop:0}}>
                <GenerateClients values={values}/>
                <Divider/>
                <GenerateBeshkan values={values}/>
                <Divider/>
                <GenerateDonations values={values}/>
                <Divider/>
                <GenerateRecurrentDonations values={values}/>


        </Grid>
    </>
}

