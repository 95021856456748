import React, { PureComponent } from 'react'
import DialogWindow from '../../../../Component/DialogWindow'

import ActiveBundleEditPage from './ActiveBundleEditPage'

import DefaultEditPage from './DefaultEditPage'


export class DefaultEditDialog extends PureComponent {
    render() {
        return <DialogWindow  open={this.props.open} onClose={this.props.onClose} > 
            <DefaultEditPage {...this.props} />
        </DialogWindow>
    }
}

export class ActiveBundleEditDialog extends PureComponent {
    render() {
        return <DialogWindow  open={this.props.open} onClose={this.props.onClose} > 
            <ActiveBundleEditPage {...this.props}/>
        </DialogWindow>
    }
}
