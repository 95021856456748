import { createApi } from '../../../utils/Ajax'
// import { create } from 'filepond'
const { post, put, del, get } = createApi('beshkan');



export const insertCustomer = async (customer) => {

    const resultEntity = await post(`customer`,
        customer,
        {
            errorMessage : `خطا در درج کاربر`
        });
    
    if(!resultEntity) return null;

    return {...customer, id:resultEntity.id};
}


export const updateCustomer = async (customer) => {

    const result = await put(`customer`,
        customer,
        {
            errorMessage : `خطا در اصلاح کاربر`
        });

    return result;
}

export const deleteCustomer = async (id) => {

    const result = await del(`customer/${id}`,
        {
            errorMessage : `خطا در حذف کاربر`
        });

    return result;
}

export const getCustomers = async (p) => {

    const result = await get(`customer`,{...p},
    {
        errorMessage : `خطا در دریافت لیست کاربر`
    });

    return result;
}


export const getCustomer = async (id) => {

    const result = await get(`customer/`+id,
    {},
    {
        errorMessage : `خطا در دریافت کاربر`
    });

    return result;
}




//
// Roles
//

export const deleteRole = async (customerId, id) => {

    const result = await del(`customer/${customerId}/DeleteRole/${id}`,
        {
            errorMessage : `خطا در حذف نقش ها`
        });
    
    return result;
}

export const deleteRoles = async (customerId, ids) => {

    const result = await del(`customer/`+customerId + "/DeleteRoles",
        ids,
        {
            errorMessage : `خطا در حذف نقش ها`
        });
    
    return result;
}

export const getRoles = async () => {

    const result = await get('role',
        
        {
            errorMessage : `خطا در دریافت نقش ها`
        });
    
    return result;
}

export const addRoles = async (customerId, ids) => {

    const result = await post(`customer/`+customerId + "/PostRoles",
        ids,
        {
            errorMessage : `خطا در درج نقش ها`
        });
    
    return result;
}
