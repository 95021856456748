import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateServer } from '../../api'
import {hasValue} from '../../../../../utils/string'

import {ListDialog as ServerProvidersDialog} from "../../../../Admin/ServerProvider/List"
import Selector from '../../../../../Component/Selector'


export const ServerProvider =  ({values={ serverProvider:{} }}) =>{
    const { t } = useTranslation('server');

    return <>

    <Grid sm={ 6 } xs={12}  item  >
        <FormControl fullWidth >
            <Field
                component={Selector}
                label={t('serverProvider.title')}
                name="serverProviderId,serverProvider"
                options = { ServerProvidersDialog}
                optionTitle= "name"
            />
        </FormControl>
    </Grid>
    </>

}


export const validate = (values, errors, t)=> {
    if (!hasValue(values.leaderId) && !hasValue(values.serverProviderId)) {
        errors.serverProviderId = t('serverProvider.required');
    }
    return errors;
}


class View extends React.Component {
    
    update = async (values) =>{
        const {id,serverProviderId}=values;
        return await updateServer({id, serverProviderId});
    }

    headline(values){
        const { serverProvider = {} } = values;
        const headline = serverProvider?(serverProvider.name):'-';
        return headline;
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
 
        return <ViewField
            successMessage={this.successMessage}
            title= { t('serverProvider.title')}
            headline  = { this.headline(values) }
            update ={this.update} 
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            editComponent={ ServerProvider } />
        
    }
}


export const ServerProviderView = withTranslation('server')(View);