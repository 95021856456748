let apiErrorhandler = (exception, customMessage)=> {
    console.log(customMessage);
    console.log(exception);
    console.log(exception.response)
};


export const setApiErrorHandler = (handler) => {
    apiErrorhandler = handler;
};

export const onApiError = (exception, customMessage) => {

    console.log(exception.response)
        window.LastErrorMessage = exception.response.data;

    if(apiErrorhandler == null) {
        console.log('apiErrorhandler is null');
        return;
    }

    apiErrorhandler(exception, customMessage)
};

