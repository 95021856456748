import { createApi } from '../../../utils/Ajax'
const { post, put, del, get } = createApi('beshkan');

export const addCustomer = async (user) => {

    const resultEntity = await post(`user`,
        user,
        {
            errorMessage : `خطا در درج کاربر`
        });
    
    if(!resultEntity) return null;

    return {...user, id:resultEntity.id};
}

// export const addCustomer = async (user) => {

//     const resultEntity = await post(`user`,
//         user,
//         {
//             errorMessage : `خطا در درج کاربر`
//         });
    
//     if(!resultEntity) return null;

//     return {...user, id:resultEntity.id};
// }

export const updateUser = async (user) => {

    const result = await put(`user`,
        user,
        {
            errorMessage : `خطا در اصلاح کاربر`
        });

    return result;
}

export const deleteUser = async (id) => {

    const result = await del(`user/${id}`,null,
        {
            errorMessage : `خطا در حذف کاربر`
        });

    return result;
}

export const getAll = async (pageIndex, pageSize) => {

    const result = await get(`user`,
    { pageIndex, pageSize }
    ,
    {
        errorMessage : `خطا در دریافت لیست کاربر`
    });

    return result;
}


export const getUser = async (id) => {

    const result = await get(`user`,
    {
        id
    },
    {
        errorMessage : `خطا در دریافت کاربر`
    });

    return result;
}




//
// Roles
//

export const deleteRoles = async (userId, ids) => {

    const result = await del(`user/`+userId,
        ids,
        {
            errorMessage : `خطا در حذف نقش ها`
        });
    
    return result;
}

export const addRoles = async (userId, ids) => {

    const result = await post(`user/`+userId,
        ids,
        {
            errorMessage : `خطا در درج نقش ها`
        });
    
    return result;
}
