import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateUser } from '../../api'
import TextInput from '../../../../../Component/TextInput'

export const UserName =  ({t=useTranslation('user').t}) =>
<>
<Grid item xs={12}>
    <FormControl fullWidth>
        <Field label={t('userName.title')}
               name={"userName"}
               component={TextInput}
               type="text" />
    </FormControl>
</Grid>
</>

export const validate = (values, errors, t)=> {
    if (values.hasPassword &&  !values.userName) {
        errors.userName = t('userName.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, userName }=values;
        return await updateUser({id, userName });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { userName } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('userName.title')}
                          headline={ userName }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ UserName } />
    }
}


export const UserNameView = withTranslation('user')(View);