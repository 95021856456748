import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('beshkan');

export const insertBundle = async (bundle) => {

    const resultEntity = await post(`bundle`,bundle,
    {
        errorMessage : t('common:insertError') + t('bundle:bundle')
    });

    if(!resultEntity) return null;

    return {...bundle, id:resultEntity.id};
}


export const updateBundle = async (bundle) => {

    const result = await putFormData(`bundle/${ bundle.id }`,
        bundle,
        {
            errorMessage :  t('common:updateError') + t('bundle:bundle')
        });

    return result;
}

export const deleteBundle = async (id) => {

    const result = await del(`bundle/${id}`,
        {
            errorMessage : t('common:deleteError') + t('bundle:bundle')
        });

    return result;
}

export const getBundles = async (params) => {

    const result = await get(`bundle`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('bundle:bundles')

    });

    return result;
}

export const getSubscriberBundles = async (params) => {

    const result = await get(`bundle/subscriber`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('bundle:bundles')

    });

    return result;
}


export const getBundle = async (id) => {

    const result = await get(`bundle/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('bundle:bundle')
    });

    return result;
}



