import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateServer } from '../../api'
import {hasValue} from '../../../../../utils/string'

import {ListDialog as IslandsDialog} from "../../../../Admin/Island/List"
import Selector from '../../../../../Component/Selector'


export const Island =  ({values={ island:{} }}) =>{
    const { t } = useTranslation('server');

    return <>

    <Grid sm={ 12 } xs={12}  item  >
        <FormControl fullWidth >
            <Field
                component={Selector}
                label={t('island.title')}
                name="islandId,island"
                options = { IslandsDialog}
                optionTitle= "name"
            />
        </FormControl>
    </Grid>
    </>

}


export const validate = (values, errors, t)=> {
    if (!hasValue(values.islandId)) {
        errors.islandId = t('island.required');
    }
    return errors;
}


class View extends React.Component {
    
    update = async (values) =>{
        const {id,islandId}=values;
        return await updateServer({id, islandId});
    }

    headline(values){
        const { island = {} } = values;
        const headline = island?(island.name):'-';
        return headline;
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
 
        return <ViewField
            successMessage={this.successMessage}
            title= { t('island.title')}
            headline  = { this.headline(values) }
            update ={this.update} 
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            editComponent={ Island } />
        
    }
}


export const IslandView = withTranslation('server')(View);