import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateOccasion } from '../../api'
import Switch from '../../../../../Component/Switch'

export const DueMessageEnabled =  ({t=useTranslation('occasion').t}) =>
<>
<Grid item sm={ 12 } xs={12}>
    <FormControl fullWidth>
        <Field label={t('dueMessageEnabled.title')}
               name={"dueMessageEnabled"}
               component={Switch}
               type="text" />
    </FormControl>
</Grid>
</>

export const validate = (values, errors, t)=> {
    if ((values.dueMessageEnabled !== true && values.dueMessageEnabled !==false)) {
        errors.dueMessageEnabled = t('dueMessageEnabled.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, dueMessageEnabled }=values;
        return await updateOccasion({id, dueMessageEnabled });
    }

    successMessage  = () => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { dueMessageEnabled } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('dueMessageEnabled.title')}
                          headline={ dueMessageEnabled?t('common:yes'):t('common:no') }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ DueMessageEnabled }
                          />

    }
}


export const DueMessageEnabledView = withTranslation('occasion')(View);
