import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('beshkan');

export const insertAvailableBundle = async (availableBundle) => {

    const resultEntity = await post(`availableBundle`,availableBundle,
    {
        errorMessage : t('common:insertError') + t('availableBundle:availableBundle')
    });

    if(!resultEntity) return null;

    return {...availableBundle, id:resultEntity.id};
}


export const updateAvailableBundle = async (availableBundle) => {

    const result = await putFormData(`availableBundle/${ availableBundle.id }`,
        availableBundle,
        {
            errorMessage :  t('common:updateError') + t('availableBundle:availableBundle')
        });

    return result;
}

export const deleteAvailableBundle = async (id) => {

    const result = await del(`availableBundle/${id}`,
        {
            errorMessage : t('common:deleteError') + t('availableBundle:availableBundle')
        });

    return result;
}

export const getAvailableBundles = async (params) => {

    const result = await get(`availableBundle`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('availableBundle:availableBundles')

    });

    return result;
}



export const getAvailableBundle = async (id) => {

    const result = await get(`availableBundle/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('availableBundle:availableBundle')
    });

    return result;
}



