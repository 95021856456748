import React, { PureComponent } from 'react'
import { getSubscription } from '../api'
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import EditableViewForm from './EditForm';
import { withRouter } from "react-router-dom"
import {durationUnits,durationValues, fromHours, toHours} from '../../../../domain/subscriptionPeroid'

class EditPage extends PureComponent {

    state = {
        subscription: {},
        //subscriptionId: this.props.match.params.id
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.loadData()
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData(){
        const subscriptionId = this.props.match.params.id;
        var subscription = await getSubscription(subscriptionId);
        subscription.durationUnit=fromHours(subscription.durationHours).unitId;
        subscription.durationValue=fromHours(subscription.durationHours).valueId;

        if (!subscription) return;

        this.setState({ subscription })
    }

    refresh = async ()=>{
        await this.loadData();
    }

    render() {
        return <EditableViewForm onRefresh={this.refresh}
                  values={this.state.subscription } />
    }
}


export default withSnackbar(withTranslation('subscription')(withRouter(EditPage)));