export const allNodeTypes = [
    {
        "id": 0,
        "systemName": "server",
        "displayName" : "server"
    },
    {
        "id": 1,
        "systemName": "dnsBridge",
        "displayName" : "dns Bridge"
    }
];

export const byId = (id)=>{
    return allNodeTypes.find(nt=>nt.id==id) || {}
}

export const titleById = (id)=>{
    return byId(id).displayName || "";
}