import React from 'react'
import Grid from '@material-ui/core/Grid'
import {VerticalTabs, Tab} from '../../../../Component/VerticalTabs'
import { useTranslation } from 'react-i18next';
import { Box, FormGroup, Divider } from '@material-ui/core'

import { NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';
import { Name, NameView, validate as validateName } from './Fields/Name'
import { Priority, PriorityView, validate as validatePriority } from './Fields/Priority'
import { ServerConfig, ServerConfigView, validate as validateServerConfig } from './Fields/ServerConfig'
import { ClientConfig, ClientConfigView, validate as validateClientConfig } from './Fields/ClientConfig'
import { ClientJsonConfig, ClientJsonConfigView, validate as validateClientJsonConfig } from './Fields/ClientJsonConfig'

import { IsDefault, IsDefaultView, validate as validateIsDefault } from './Fields/IsDefault'
import { IsSystemConfig, IsSystemConfigView, validate as validateIsSystemConfig } from './Fields/IsSystemConfig'
import { IsEnabled, IsEnabledView, validate as validateIsEnabled } from './Fields/IsEnabled'


export const validators={
    validateName, validatePriority, validateServerConfig, validateClientJsonConfig, validateClientConfig, validateIsDefault, validateIsSystemConfig, validateIsEnabled 
};


export const validate =(values, errors, t) => {

    for (const [name, func] of Object.entries(validators)) {
        func(values, errors, t);
      }

}
export const FormFields = (props) =>
{
    const { values } = props;
    const { t } = useTranslation('connectionConfig');
    return <>
<Grid container spacing={2} style={{padding:25, paddingTop:0}}>

        <Name />
        <Priority />
        <ServerConfig />
        <ClientConfig />
        <ClientJsonConfig />
        <IsDefault />
        <IsSystemConfig />
        <IsEnabled />
</Grid>
</>
}

export const EditableViewFields = (props) =>
{
    const {values, onRefresh} = props;
    const { t } = useTranslation('connectionConfig');

return <>

    <NameView {...props} />
    <PriorityView {...props} />
    <ServerConfigView {...props} />
    <ClientConfigView {...props} />
    <ClientJsonConfigView {...props} />
    <IsDefaultView {...props} />
    <IsSystemConfigView {...props} />
    <IsEnabledView {...props} />

</>

}

