import React, { PureComponent } from 'react'
import { getBundle } from '../api'
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import EditPageComponent from './DefaultEditPage.Component';
import { withRouter } from "react-router-dom"
import {durationUnits,durationValues, fromHours, toHours} from '../../../../domain/subscriptionPeroid'

class DefaultEditPage extends PureComponent {

    state = {
        bundle: {},
        //bundleId: this.props.match.params.id
    }

    componentDidUpdate(prevProps) {
        const id = this.props.id || this.props.match.params.id;
        const prevId = prevProps.id || prevProps.match.params.id;
        
        if (prevId !== id) {
            this.loadData()
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData(){
        const bundleId = this.props.id || this.props.match.params.id;
        var bundle = await getBundle(bundleId)
        bundle.durationUnit=fromHours(bundle.durationHours).unitId;
        bundle.durationValue=fromHours(bundle.durationHours).valueId;
        bundle.traffic=bundle.traffic/1024;
        if (!bundle) return;

        this.setState({ bundle })
    }

    refresh = async ()=>{
        await this.loadData();
    }

    render() {
        const {form} = this.props;

        return <EditPageComponent 
            onRefresh={this.refresh}
            values={this.state.bundle }
            form = {form}
            onClose = {this.props.onClose}
        />
    }
}


export default withSnackbar(withTranslation('bundle')(withRouter(DefaultEditPage)));