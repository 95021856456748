import React from 'react'
import  * as ActiveBundles from './ActiveBundles'
import { Tab } from './../../../../Component/VerticalTabs';
import { Box } from '@material-ui/core';
var components = [ActiveBundles];

export default (props)=>
components.map(({Icon, getName, Extension})=><Tab title={<><Icon/><Box>{getName(props)}</Box></>}>
        <Extension {...props}/>
    </Tab>)
    
