import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData, postFormData } = createApi('beshkan');

export const importFromJson = async (data) => {

    const result = await postFormData(`subscription/importfromjson`,
    data,
    {
        errorMessage :  t('common:updateError') + t('server:server')
    });

    return result;
} 


export const getDashboard = async (params) => {

    const result = await get(`dashboard`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('subscription:subscriptions')

    });

    return result;
}
export const getSubscriptionsByKey = async (key) => {

    const result = await get(`subscription/key/`+key,
    {  }
    ,
    {
        errorMessage : t('common:fetchError') + t('subscription:subscriptions')

    });

    return result;
}


export const getSubscription = async (id) => {

    const result = await get(`subscription/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('subscription:subscription')
    });

    return result;
}



function fixModel(sub){
    sub = {...sub};

    if(sub.selectedServers){
        sub.subscriptionSelectedServers = sub.selectedServers.map(s=> ({serverId:s.id}));
        sub.selectedServers = undefined;
    }

    return sub;
}

