export const allDegreeLevels = [
    {
        "id": 0,
        "systemName": "unknown",
        "displayName" : "نامشخص"
    },
    {
        "id": 1,
        "systemName": "basic",
        "displayName" : "پایه"
    },
    {
        "id": 2,
        "systemName": "diploma",
        "displayName" : "دیپلم"
    },
    {
        "id": 3,
        "systemName": "associateDegree",
        "displayName" : "فوق دیپلم"
    },
    {
        "id": 4,
        "systemName": "bachelor",
        "displayName" : "لیسانس"
    },
    {
        "id": 5,
        "systemName": "engineeringBachelor",
        "displayName" : "لیسانس مهندسی"
    },
    {
        "id": 6,
        "systemName": "master",
        "displayName" : "فوق لیسانس"
    },
    {
        "id": 7,
        "systemName": "enginneringMaster",
        "displayName" : "فوق لیسانس مهندسی"
    },
    {
        "id": 8,
        "systemName": "doctoral",
        "displayName" : "دکتری"
    },
    {
        "id": 9,
        "systemName": "seminary",
        "displayName" : "حوزوی"
    },
    {
        "id": 10,
        "systemName": "nonDegree",
        "displayName" : "بی سواد"
    }

];

export const degreeLevels = {
    unknown : 0,
    basic: 1,
    diploma: 2,
    associateDegree: 3,
    bachelor: 4,
    bachelorOfEngineering: 5,
    master: 6,
    masterOfEngineering: 7,
    doctoral: 8,
    seminary : 9,
    nonDegree: 10,
}

export const byId = (id)=>{
    return allDegreeLevels.find(dl=>dl.id===id) || {}
}

export const titleById = (id)=>{
    return byId(id).displayName || "";
}