import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateUser } from '../../api'
import {allGenders, titleById} from '../../../../../domain/gender'
import Selector from '../../../../../Component/Selector'

export const Gender =  ({t=useTranslation('user').t }) =>{

    return <Grid item xs={12}>
    <FormControl fullWidth>
        <Field component={Selector}
               label={t('gender.title')}
               name="gender"
               options={allGenders }
               // keyName="id"
               optionTitle="displayName" />
    </FormControl>
</Grid>
}

export const validate = (values, errors)=> {
    return errors;
}

class View extends React.Component {

    update = async (values) =>{
        const { id, gender }=values;
        return await updateUser({id, gender });
    }

    headline(values){
        const { gender } = values;
        return titleById(gender);
    }

    successMessage  = () => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;

        return <ViewField 
            successMessage={this.successMessage}
            title={ t('gender.title')}
            headline={ this.headline(values) }
            update={this.update}
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            editComponent={ Gender } />

    }
}

export const GenderView = withTranslation('user')(View);