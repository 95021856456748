import React, { PureComponent } from 'react'
import { Form as ActiveBundleForm } from '../Form/ActiveBundleForm'
import DefaultCreatePage from './DefaultCreatePage'

export default class ActiveBundleCreatePage extends PureComponent {
    subscriber = this.props.subscriber||{};
    defaultValues={
        subscriberId:this.subscriber.id
    }
    defaultExValues={
        subscriber:{...this.props.subscriber}
    }
    render() {

        return <DefaultCreatePage 
                onOk={this.props.onOk}
                formComponent = {ActiveBundleForm}
                defaultValues ={this.defaultValues}
                defaultExValues = {this.defaultExValues}
        />
    }
}

