import React, { PureComponent } from 'react'
import {validate as validateUser, validators } from '../Form'
import CreateForm from './_Component';
import { insertUser } from '../api'
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { getProfile } from '../../../../modules/Auth'
import  {upperFirst} from "lodash";

 class CreatePage extends PureComponent {

    defaultValues = {
        roles: [{
            "id": -3,
            "name": "Employee",
            "displayName": "کارمند"
        }],
        provinceId: getProfile().provinceId,
        province: getProfile().province,
        isInternetUser:false
    }

    onSubmit = async (values) => {
        
        const { t, enqueueSnackbar  } = this.props;
        var res = await insertUser({...values, city:null, province:null})
        if(!res) {
            return false;
        }
        // this.props.history.push('/admin/user')
        this.props.history.goBack();


        if (res) {
            enqueueSnackbar( t('saved'), { variant:'success' });
        }
    }

    // Just validate a specific field not whole form, why? as the 'validate' function validates the whole form, we might have many ajax validations within this form 
    // We prevent unnessasary ajax calls by implementing validateField
    // If we have any ajax validation we shoyld implement it, if not then the form will do ajax call by each normal validation
    // TODO : this function can be automatically implemented by the validator later
    validateField = async ( name, values, errors, warnings ) => {

        if(name === 'firstName' || name ==='lastName')
            name = 'name';

        const  validatorName = 'validate'+upperFirst(name);
            
        if(!validators[validatorName]) return errors;

        const {t}= this.props;
        errors = errors||{};
    
        await validators[validatorName](values, errors, t, warnings);
        this.forceUpdate();
        return errors
    }

    // 'validate' function will be called before submiting the form (and by each 'Blur' event if we haven't implemented validateField)
    validate = async ( values ) => {
        const {t}= this.props;
        const errors = {}
        const warnings = {}
        await validateUser(values, errors, t, warnings);
        this.forceUpdate();
        return errors
    }

    render() {
        const { onCancel, t } = this.props
        
        return <>
            <CreateForm submitLabel={t('register')}
                onCancel={onCancel}
                ref={this.form}
                defaultValues={this.defaultValues}
                validate={this.validate}
                validateField = {this.validateField}
                onSubmit={this.onSubmit} />
        </>
    }
}

export default withSnackbar(withTranslation('user')(CreatePage));