import React, { PureComponent } from 'react'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Field, Form } from 'react-final-form'
import TextInput from '../../../../Component/TextInput'
import FormControl from '@material-ui/core/FormControl'
import "filepond/dist/filepond.min.css";
import RadioGroupComponent from '../../../../Component/RadioGroup'

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'

// import CoreSelect from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
const genderOptions = [
    {label : 'آقا', value : 'False' },
    {label : 'خانم', value : 'True' },
];

class CreateForm extends PureComponent {
    state = {

    }
    // constructor(props) {
    //     super(props)
    //     this.state = {
    //         roles:[]

    //     }
    // }

    onSubmit = (values) => {
        this.props.onSubmit(values)
    }

    async componentDidMount() {
        // await this.loadRoles()
    }

    // loadRoles = async () => {
    //     var res = await getRoles()
    //     if (res) {
    //         this.setState({ roles: res })
    //     }
    // }

    render() {
        let {
            
            customer,
            validate,
            classes
        } = this.props
        
        customer = customer || {user:{}};

        console.log('the customer : ' )
        console.log(customer )
        return (<Form

            subscription={{ submitting: true, pristine: true }}
            onSubmit={this.onSubmit}
            initialValues={customer}
            validate={validate}
            render={({ handleSubmit, reset, submitting }) => {
                this.resetForm = reset
                return (<form onSubmit={handleSubmit} noValidate autoComplete="off" >

                            <Grid container direction="column" 
                              alignItems="center"
                              justify="center"
                              // style={{ minHeight: '100vh' }}
                  
                              // justify="space-around" 
                              style={{ maxWidth: '100%',padding: 15 }}
                            // style={{ padding: 15 }}
                            >
                            <Paper style={{  paddingTop:30, maxWidth: 400, minWidth: 400 }}>

                    <Grid item container direction="column">
                        
                                         <Grid item style={styles.row} >
                                         <Typography 
                            style={{
                                paddingBottom: 5
                            }}
                            variant="h6" color="inherit" noWrap>
                            تکمیل مشخصات
                        </Typography>
                        <Typography style={{color:'rgba(0, 0, 0, 0.7)'}}>
                        مشخصات شما در سامانه گپ موجود نمی‌باشد لطفا برای یک بار آن را وارد نمایید
                        </Typography>
                                    </Grid>
<Grid item style={styles.genderRow}>
<FormControl component="fieldset" fullWidth>
{/* <FormLabel component="legend">Gender</FormLabel> */}
    <Field
        options= {genderOptions}
        name="user.gender"
        component={RadioGroupComponent}
    />
</FormControl>
</Grid>


{/* <Grid item style={styles.genderRow}>
  
  <FormControl component="fieldset">
  <FormLabel component="legend">جنسیت</FormLabel>
      <RadioGroup row>
          <FormControlLabel
              label="آقا"
              control={
              <Field
                  name="gender"
                  component={Radio}
                  type="radio"
                  value="0"
              />
              }
          />
          
          <FormControlLabel
              label="خانم"
              control={
              <Field
                  name="gender"
                  component={Radio}
                  type="radio"
                  value="1"
              />
              }
          />
      </RadioGroup>
  </FormControl>
</Grid> */}
                                <Grid container className={classes.row} fullWidth style={{paddingTop:0}} >
                                    <Grid item xs={6} style={{    paddingLeft: 20}}>

                                        <FormControl fullWidth  style={{ direction: 'rtl' }}>
                                            <Field
                                                label={'نام'}
                                                name="user.firstName"
                                                component={TextInput}
                                                type="text"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item  xs={6}>
                                        <FormControl fullWidth style={{ direction: 'rtl' }}>
                                            <Field
                                                label={'نام خانوادگی'}
                                                name="user.lastName"
                                                component={TextInput}
                                                type="text"
                                            />
                                        </FormControl>
                                    </Grid>
                                    </Grid>

                                   <Grid item style={styles.row}>
                                        <FormControl fullWidth >
                                            <Field
                                                label={'کد ملی'}
                                                name="natId"
                                                component={TextInput}
                                                type="text"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item  className={classes.row} >

                 
                                        
                                        {/* <FormControl fullWidth>
                                        <Field
                                                ////component={MultiSelect}
                                                name="userRoles"
                                                label="نقش ها"
                                                buttonLabel= {'انتخاب نقش...'}
                                                
                                                component={Selector}
                                                options = {allRoles}
                                                optionKey="id"
                                                optionTitle="displayName"
                                                
                                                returnObjectKey= "roleId"
                                                multiselect
                                            />
                                        </FormControl> */}



                                        {/* <FormControl fullWidth style={{ direction: 'rtl' }}>
                                            <Field
                                                name="cityId"
                                                label='شهر'
                                                buttonLabel= {'انتخاب شهر...'}

                                                component={Selector}
                                                options = {CitiesDialog}
                                                optionKey = "id"
                                                optionTitle = "name" // { city => city.name }

                                                valueObject = {data&&data.city} // if options are array data then we can ignore this prop                                               
                                                
                                                multiselect
                                                mode = "simple" // lookup // dropdown
                                                // multiSelect="lookup" // checkbox // dropdown
                                                //singleSelect ="lookup" // radio // dropdown
                                                //// selectorTitle = { city => city.Name }
                                                //// DropDownList = {CitiesDialog}
                                            />
                                        </FormControl> */}

                               
                                    </Grid>
<Grid item style={styles.actionRow}>
{/* <Button onClick={onCancel} color="primary" >
                                انصراف
                            </Button> */}
                            <Button color="primary" autoFocus disabled={submitting} onClick={handleSubmit}>
                                تایید
                            </Button>
</Grid>
                                    {/* <Grid item style={styles.row} >
                                        <FormControl fullWidth>
                                            <Field
                                                label={'ایمیل'}
                                                name="email"
                                                component={TextInput}
                                                type="text"
                                            />
                                        </FormControl>
                                    </Grid> */}
                                    {/* <Grid item style={styles.row}>
                                        <FormControl fullWidth >
                                            <Field
                                                label={'موبایل'}
                                                name="phone"
                                                component={TextInput}
                                                type="number"
                                            />
                                        </FormControl>
                                    </Grid> */}
                                    {/* <Grid item style={styles.row}>
                                        <FormControl fullWidth >
                                            <Field
                                                label={'تلفن'}
                                                name="workPhone"
                                                component={TextInput}
                                                type="number"
                                            />
                                        </FormControl>
                                    </Grid> */}
                                   
                                </Grid>
                            
                            </Paper>
                            </Grid>


                    
                          


                </form>)
            }
            } />



        )
    }
}

const styles = {
    root : {
        paddingTop:30
    },
    row: {
        padding: '10px 50px'

    },
    ddl : {
        minWidth:160
    },
    genderRow : {
        padding: '10px 50px',
        paddingBottom : 0
    },
    actionRow : {
        backgroundColor: '#c3c3c321',
        borderTop: '1px solid #8080801f',
        height:60,
        textAlign: 'left',
        paddingTop: 10,
        paddingLeft: 10,
        marginTop: 20
    }
}

export default withStyles(styles)(CreateForm)