import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from '../../../../../Component/Field';
import TextInput from '../../../../../Component/TextInput'
import Switch from '../../../../../Component/Switch'

export const GenerateClients =  ({values}) =>
<>
<Grid item sm={ 6 } xs={12}>
    <FormControl fullWidth>
        <Field label={'نمونه‌ی مددجویان'}
               name={"generateClients"}
               component={Switch} />
    </FormControl>
</Grid>
{values.generateClients&&
<Grid item sm={ 6 } xs={12}>
<FormControl fullWidth>
        <Field placeholder={'تعداد'}
               name={"clientCount"}
               number
               component={TextInput}
               adornment={'رکورد'} />
    </FormControl>
</Grid>
}
</>

export const validate = (values, errors)=> {
    if(values.generateClients && !values.clientCount)
        errors.clientCount = "تعداد نمونه باید بزرگتر از صفر باشد";
    return errors;
}
