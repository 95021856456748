import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateResellerSetting } from '../../api'
import {hasValue} from '../../../../../utils/string'

import TextInput from '../../../../../Component/TextInput'

export const BotToken =  () => {
    const { t } = useTranslation('resellerSetting');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('botToken.title')}
                   name={"botToken"}
                   component={TextInput}
                   dir="ltr"
                   />
        </FormControl>
    </Grid>
    </>
}
export const validate = (values, errors, t)=> {    
    const regex = /^\d+:[\w-]{35}$/;
    if(!regex.test(values.botToken)) {
        errors.botToken = t('botToken.invalid');
    }

    return errors;
}

function maskString(t, str) {
    if (!str || str.length <= 8) {
        return str;
    }
    return <div dir="ltr">{ str.substring(0, 4) + '*******' + str.substring(str.length - 4) ||t('common:notEntered') }</div>;
}
export function BotTokenView({ values, onRefresh }) {
    const { t } = useTranslation('resellerSetting');

    const update = async (values) =>{
        const {id, botToken=t("common:notEntered") }=values;
        return await updateResellerSetting({id, botToken });
    }

    const successMessage  = (values) => t('updated')

    const { botToken } = values;

    return <ViewField successMessage={successMessage}
                        title={ t('botToken.title')}
                        headline={ maskString(t, botToken)}
                        update={update}
                        validate={validate}
                        values={values}
                        onRefresh={onRefresh}
                        editComponent={ BotToken } />

}