export const userDevice = 
{
    Android:0,
    iOS:1,
    Windows:2,
    MacOS:3
}
    

export const allUserDevices = [
    {
        "id": 0,
        "systemName": "android",
        "displayName" : "Android"
    },
    {
        "id": 1,
        "systemName": "ios",
        "displayName" : "iOS"
    },
    {
        "id": 2,
        "systemName": "windows",
        "displayName" : "Windows"
    },
    {
        "id": 3,
        "systemName": "macOS",
        "displayName" : "MacOS"
    }
];

export const byId = (id)=>{
    return allUserDevices.find(ud=>ud.id==id) || {}
}

export const titleById = (id)=>{
    return byId(id).displayName || "";
}