import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateTrafficTransaction } from '../../api'
import {hasValue} from '../../../../../utils/string'

import DateTimeInput from '../../../../../Component/DateTimeInput';
import moment from 'moment-jalaali';

export const TransactionTime =  () => {
    const { t } = useTranslation('trafficTransaction');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
        <Field label={t('transactionTime.title')}
                name={"transactionTime"}
                date time
                component={DateTimeInput}
                />
        </FormControl>
    </Grid>
    </>
}

export const validate = (values, errors, t)=> {
    if (!hasValue(values.transactionTime)) {
        errors.transactionTime = t('transactionTime.required');
    }
    return errors;
}

export function TransactionTimeView({ values, onRefresh, readonly }) {
    const { t } = useTranslation('trafficTransaction');

    const update = async (values) => {
        const { id, transactionTime } = values;
        return await updateTrafficTransaction({ id, transactionTime: moment(transactionTime).utc().format() });
    };

    const successMessage  = (values) => t('updated')

    const headline = () => {
        const { values={}} = this.props;
        const { transactionTime } = values;
        const format = 'jYYYY/jMM/jDD hh:mm A';
        
        return transactionTime?moment(transactionTime).format(format):'-';
    }

    const { transactionTime } = values;

    return <ViewField 
        successMessage={this.successMessage}
        title={ t('transactionTime.title')}
        headline={ this.headline() }
        update={this.update}
        validate={validate}
        values={values}
        onRefresh={onRefresh}
        readonly = {readonly}
        editComponent={ TransactionTime } />

}