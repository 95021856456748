import jMoment from "moment-jalaali";
import React from "react";
import JalaliAdapter from "@date-io/jalaali";

import { TextField } from "@material-ui/core";
import {
  TimePicker,
  DateTimePicker,
  LocalizationProvider,
  DatePicker,
} from "@material-ui/pickers";

jMoment.loadPersian({ dialect: "persian-modern", usePersianDigits: false });

class JalaliDateTimePicker extends React.PureComponent{

  render(){

    const {time, date,error,helperText,placeholder, ...other } = this.props;
    const Control = date&&time?DateTimePicker :(time?TimePicker:DatePicker) ;
    const format = date&&time?'jYYYY/jMM/jDD': (time?'hh:mm A':'jYYYY/jMM/jDD') 
    return <>
    <LocalizationProvider dateAdapter={JalaliAdapter} locale={"fa"}>
    <Control 

     {...other }
      renderInput={props => <TextField          
        // onKeyDown={function(e) { e.target.value='1300/10/1'} }
        // onChange={function(v) {console.log(numToEn(moment(v.target.value).locale('us').utc().format())) ;other.onChange({...v, target:{value:numToEn(moment(v.target.value).utc().format()) }});}}

        // onBlur={function(e) {var t = e.target; other.onChange(e.target.value);}}
        // onFocus={function(e) {var t = e.target; setTimeout(function(){
        //   var pos = doGetCaretPosition(t);

        //   t.value=numToEn(t.value);
        //   setCaretPosition(t, pos)
        
        // },100)} }
             
              inputProps={{
      
                  style:{direction:"ltr"},
                  

              }}  
              
              {...props} error={error}  helperText={helperText} />}
      clearable
        okText="قبول"
        cancelText="انصراف"
        clearText="خالی کن"
        mask="____/__/__"
        inputFormat={format}
        // onChange={function(e){ }}
    />
 
      </LocalizationProvider></>

  }
}
export default ({
  date,time,
  input: { name, onChange,  value },
  meta={},
  hint,
  ...rest
}) => (
  
<JalaliDateTimePicker
    date={date}
    time={time}
    name={name}
    autocomplete="none"
    label={rest.label}
    helperText={meta.touched ? meta.error : hint}
    error={meta.error && meta.touched}
    onChange={onChange}
    value={value||""}
  />

  )






  


