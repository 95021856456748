export const allServerQualities = [
    {
        "id": 0,
        "systemName": "VIP",
        "displayName" : "VIP"
    },
    {
        "id": 1,
        "systemName": "premium",
        "displayName" : "پرمیوم"
    },
    {
        "id": 2,
        "systemName": "standard",
        "displayName" : "استاندارد"
    },
    {
        "id": 3,
        "systemName": "basic",
        "displayName" : "پایه"
    }
];

export const byId = (id)=>{
    return allServerQualities.find(sq=>sq.id==id) || {}
}

export const titleById = (id)=>{
    return byId(id).displayName || "";
}