
/*global ArrayBuffer, Uint16Array*/
var size = 2000 * 2;
var ArToPersianBuffer = new ArrayBuffer(size);
var ArabicToPersianMap = new Uint16Array(ArToPersianBuffer);
ArabicToPersianMap['ي'.charCodeAt(0)] = 'ی'.charCodeAt(0);
//ArabicToPersianMap['ئ'.charCodeAt(0)] = 'ی'.charCodeAt(0);
ArabicToPersianMap['ك'.charCodeAt(0)] = 'ک'.charCodeAt(0);

ArabicToPersianMap['٠'.charCodeAt(0)] = '۰'.charCodeAt(0);
ArabicToPersianMap['١'.charCodeAt(0)] = '۱'.charCodeAt(0);
ArabicToPersianMap['٢'.charCodeAt(0)] = '۲'.charCodeAt(0);
ArabicToPersianMap['٣'.charCodeAt(0)] = '۳'.charCodeAt(0);
ArabicToPersianMap['٤'.charCodeAt(0)] = '۴'.charCodeAt(0);
ArabicToPersianMap['٥'.charCodeAt(0)] = '۵'.charCodeAt(0);
ArabicToPersianMap['٦'.charCodeAt(0)] = '۶'.charCodeAt(0);
ArabicToPersianMap['٧'.charCodeAt(0)] = '۷'.charCodeAt(0);
ArabicToPersianMap['٨'.charCodeAt(0)] = '۸'.charCodeAt(0);
ArabicToPersianMap['٩'.charCodeAt(0)] = '۹'.charCodeAt(0);

var NumToPersianBuffer = new ArrayBuffer(size);
var NumToPersianMap = new Uint16Array(NumToPersianBuffer);
NumToPersianMap['٠'.charCodeAt(0)] = '۰'.charCodeAt(0);
NumToPersianMap['١'.charCodeAt(0)] = '۱'.charCodeAt(0);
NumToPersianMap['٢'.charCodeAt(0)] = '۲'.charCodeAt(0);
NumToPersianMap['٣'.charCodeAt(0)] = '۳'.charCodeAt(0);
NumToPersianMap['٤'.charCodeAt(0)] = '۴'.charCodeAt(0);
NumToPersianMap['٥'.charCodeAt(0)] = '۵'.charCodeAt(0);
NumToPersianMap['٦'.charCodeAt(0)] = '۶'.charCodeAt(0);
NumToPersianMap['٨'.charCodeAt(0)] = '۸'.charCodeAt(0);
NumToPersianMap['٩'.charCodeAt(0)] = '۹'.charCodeAt(0);
NumToPersianMap['0'.charCodeAt(0)] = '۰'.charCodeAt(0);
NumToPersianMap['1'.charCodeAt(0)] = '۱'.charCodeAt(0);
NumToPersianMap['2'.charCodeAt(0)] = '۲'.charCodeAt(0);
NumToPersianMap['3'.charCodeAt(0)] = '۳'.charCodeAt(0);
NumToPersianMap['4'.charCodeAt(0)] = '۴'.charCodeAt(0);
NumToPersianMap['5'.charCodeAt(0)] = '۵'.charCodeAt(0);
NumToPersianMap['6'.charCodeAt(0)] = '۶'.charCodeAt(0);
NumToPersianMap['7'.charCodeAt(0)] = '۷'.charCodeAt(0);
NumToPersianMap['8'.charCodeAt(0)] = '۸'.charCodeAt(0);
NumToPersianMap['9'.charCodeAt(0)] = '۹'.charCodeAt(0);

var NumToEnglishBuffer = new ArrayBuffer(size);
var NumToEnglishMap = new Uint16Array(NumToEnglishBuffer);
NumToEnglishMap['٠'.charCodeAt(0)] = '0'.charCodeAt(0);
NumToEnglishMap['١'.charCodeAt(0)] = '1'.charCodeAt(0);
NumToEnglishMap['٢'.charCodeAt(0)] = '2'.charCodeAt(0);
NumToEnglishMap['٣'.charCodeAt(0)] = '3'.charCodeAt(0);
NumToEnglishMap['٤'.charCodeAt(0)] = '4'.charCodeAt(0);
NumToEnglishMap['٥'.charCodeAt(0)] = '5'.charCodeAt(0);
NumToEnglishMap['٦'.charCodeAt(0)] = '6'.charCodeAt(0);
NumToEnglishMap['٧'.charCodeAt(0)] = '7'.charCodeAt(0);
NumToEnglishMap['٨'.charCodeAt(0)] = '8'.charCodeAt(0);
NumToEnglishMap['٩'.charCodeAt(0)] = '9'.charCodeAt(0);
NumToEnglishMap['۰'.charCodeAt(0)] = '0'.charCodeAt(0);
NumToEnglishMap['۱'.charCodeAt(0)] = '1'.charCodeAt(0);
NumToEnglishMap['۲'.charCodeAt(0)] = '2'.charCodeAt(0);
NumToEnglishMap['۳'.charCodeAt(0)] = '3'.charCodeAt(0);
NumToEnglishMap['۴'.charCodeAt(0)] = '4'.charCodeAt(0);
NumToEnglishMap['۵'.charCodeAt(0)] = '5'.charCodeAt(0);
NumToEnglishMap['۶'.charCodeAt(0)] = '6'.charCodeAt(0);
NumToEnglishMap['۷'.charCodeAt(0)] = '7'.charCodeAt(0);
NumToEnglishMap['۸'.charCodeAt(0)] = '8'.charCodeAt(0);
NumToEnglishMap['۹'.charCodeAt(0)] = '9'.charCodeAt(0);


/*
    Fixes dome persian character mistakes
*/
export const fixFa = function (str) {

    str=str.toString();

    var len = str.length;
    var buffer = new ArrayBuffer(2 * len);
    var bufferView = new Uint16Array(buffer);
    var i, code, targetCode;

    for (i = 0; i < len; i++) {
        code = str.charCodeAt(i);

        if (code < 2000) { targetCode = ArabicToPersianMap[code];
            } else { targetCode = code; }

        if (targetCode === 0) { ArabicToPersianMap[code] = code;
            } else { code = targetCode; }

        bufferView[i] = code;
    }

    return String.fromCharCode.apply(null, bufferView);//arr.join('');
};
var lang = localStorage.getItem('language') || 'en'
// Converts english number and arabic numbers to persian number
export const numToFa = function (str) {
    if(str===undefined || str===null || lang!="fa")
        return str;

    str=(str||0).toString();

    var len = str.length;
    var buffer = new ArrayBuffer(2 * len);
    var bufferView = new Uint16Array(buffer);
    var i, code, targetCode;

    for (i = 0; i < len; i++) {
        code = str.charCodeAt(i);

        if (code < 2000) { targetCode = NumToPersianMap[code];
            } else { targetCode = code; }

        if (targetCode === 0) { NumToPersianMap[code] = code;
            } else { code = targetCode; }

        bufferView[i] = code;
    }

    return String.fromCharCode.apply(null, bufferView);//arr.join('');
};

// Converts persian number and arabic numbers to English number
export const numToEn = function (str) {
    str=str.toString();

    var len = str.length;
    var buffer = new ArrayBuffer(2 * len);
    var bufferView = new Uint16Array(buffer);
    var i, targetCode, code;

    for (i = 0; i < len; i++) {
        code = str.charCodeAt(i);

        if (code < 2000) { targetCode = NumToEnglishMap[code];
            } else { targetCode = code; }

        if (targetCode === 0) { NumToEnglishMap[code] = code;
            } else { code = targetCode; }

        bufferView[i] = code;
    }

    return String.fromCharCode.apply(null, bufferView); //arr.join('');
};

// module.exports =  persian;
/*
persian.parseJalali = function (jalaliString) {

};*/
