import React, { PureComponent } from 'react'
import DialogWindow from '../../../../Component/DialogWindow'

import ActiveBundleCreatePage from './ActiveBundleCreatePage'

import DefaultCreatePage from './DefaultCreatePage'


export class DefaultCreateDialog extends PureComponent {
    render() {
        return <DialogWindow  open={this.props.open} onClose={this.props.onClose} > 
            <DefaultCreatePage {...this.props}/>
        </DialogWindow>
    }
}

export class ActiveBundleCreateDialog extends PureComponent {
    render() {
        return <DialogWindow  open={this.props.open} onClose={this.props.onClose} > 
            <ActiveBundleCreatePage {...this.props}/>
        </DialogWindow>
    }
}
