import { bundleType } from './bundleType';
import {titleById, byId, bundleStatus} from './bundleStatus'
import {t} from '../i18n';



export const toGb = (mb, fixed = 1, onlyGB =false ) => {
  const gb = mb / (1024 );
  if (gb >= 1024 && !onlyGB) {
    const tb = gb / 1024;
    fixed=1;
    return tb.toFixed(fixed) + t('bundle:tb');
  }
  return gb.toFixed(fixed) + t('bundle:gb');
};

export const bundleToGb=(bundle, fixed=1, onlyGB =false)=>{
   var val = bundle.traffic - bundle.trafficUsed;
   if(val<=0 ||bundle.bundleType == bundleType.Infinite) return "";

   return toGb(val,fixed, onlyGB );
}




