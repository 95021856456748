import React, { PureComponent } from 'react';
import { signIn, loggedIn } from '../../../../modules/Auth'
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid'
import Logo from '../../../../Component/Logo';
import { Redirect } from "react-router-dom"
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';

import {numToFa} from '../../../../utils/persianUtils';

class Otp extends PureComponent {
    constructor(props) {
        super(props)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.txtUsername = null
        this.txtPassword = null

        this.state = {
            showPassword: false,
            redirectToRegister: false,
            phoneEntered: false
        }
    }
    getReturnUrl = () => {
        var state = this.props.location.state;
        var url = state && state.from && state.from.pathname;
        return url || '/'
    }
    handleFormSubmit = async (pageName) => {

        this.setState({ submiting: true })
        const res = await signIn(this.getPhoneNumber(), this.txtPassword.value)

        if (res)
            this.props.history.push(pageName)
        else
            this.setState({ submiting: false })

    }

    btnClickHandle = async (e) => {
        e.preventDefault()
        if (loggedIn()) {
            this.props.history.push('./home');
        } else {

            const //usernameRequired = this.txtUsername.value.length === 0,
                passRequired = this.txtPassword.value.length === 0;

            if ( passRequired) {
                this.setState({  passRequired })
                return
            }

            const returnUrl = this.getReturnUrl()

            await this.handleFormSubmit(returnUrl)
        }
    }


    onRegisterClick = () => this.setState({ redirectToRegister: true })

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });

        // setValues({ ...values, showPassword: !values.showPassword });
    };
    handleUsernameChanged = (e) => {
        if (e.target.value.length === 11)
            this.setState({ phoneEntered: true });

        // setValues({ ...values, showPassword: !values.showPassword });
    };

    getPhoneNumberFa() {
        var state = this.props.location.state;
        return state ? numToFa(state.phoneNumber) : '';
    }

    getPhoneNumber() {
        var state = this.props.location.state;
        return state ? state.phoneNumber : '';
    }

    render() {
        const { submiting/*, message, showMessage, phoneEntered*/, redirectToRegister } = this.state
        // const phoneNumber = this.getPhoneNumber();

        if (redirectToRegister) {
            return <Redirect
                to={{
                    pathname: "/sign",
                    state: {  from: this.getReturnUrl() }
                }}
            />
        }
        return (
            <form onSubmit={this.handleFormSubmit} formNoValidate autoComplete="off">

            <Slide direction="left" in={true} mountOnEnter unmountOnExit> 

            <Grid container spacing={0} direction="row" alignContent='center' justify="space-around" align="center" style={{ maxWidth: '100%' }}>

                <Grid style={{ padding: '40px', marginTop: '50px' }} xs={12} /*xs={12} sm={4}*/>
                    <Paper alignContent='center' align="center" style={{ padding: 50, paddingTop: 35, maxWidth: 400 }}>

                        {/* <Slide direction="left" in={phoneEntered} mountOnEnter unmountOnExit> */}
                        <Logo style={{ width: '120px' }} />
                        <Typography align="center" alignContent='center'
                            style={{
                                marginBottom: '20px',
                                marginTop: "-15px"
                            }}
                            variant="h6" color="inherit" noWrap>
                            خوش آمدید
                        </Typography>
                        <Typography align="center" alignContent='center'
                            style={{
                                marginBottom: '50px',
                                marginTop: "0px"
                            }}
                            variant="body1" color="inherit">
                            لطفا رمز عبور را وارد نمایید


                        </Typography>

                        <FormControl fullWidth textAlign="right" align="right" style={{ marginBottom: 45 }}>

                            <TextField
                                error={this.state.passRequired}
                                id="password"
                                // className={clsx(classes.margin, classes.textField)}
                                variant="outlined"
                                autoComplete='new-password'
                                type={this.state.showPassword ? 'text' : 'password'}
                                label="رمز"
                                // value={values.password}
                                inputRef={el => this.txtPassword = el}
                                // onChange={handleChange('password')}
                                // inputProps={{ style: { textAlign: 'center', fontSize: "1.3em" } }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>


                        <Grid container spacing={0} direction="row" alignContent='center' justify="space-around" align="center" style={{ maxWidth: '100%' }}>
                        <Grid spacing={0} xs={6} /*xs={12} sm={4}*/>

                            <FormControl fullWidth textAlign="right" align="right" >
                                <Button style={{ maxWidth: 130 }} 
                                    disabled={submiting}
                                    onClick={this.btnClickHandle}

                                    variant="contained" color="primary" >
                                        ورود
                                    </Button>
                            </FormControl>
                        </Grid>
                            <Grid spacing={0} xs={6} /*xs={12} sm={4}*/>


                                <FormControl style={{display:'block'}} fullWidth textAlign="left" align="left" >

                                    <Button style={{ textAlign: 'left' }} align="left" textAlign="left" component="span" color="primary" >
                                        فراموش کردم
                                    </Button>

                                </FormControl>    
                            </Grid>
   
                        </Grid>





                        {/* </Slide> */}

                    </Paper>
                </Grid>
            </Grid>
</Slide>
</form>
        );
    }


}

export default (Otp);