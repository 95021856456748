import React from 'react'
import { Box, Button} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( ()=>createStyles({
 
    root: {
        padding:20,
        paddingLeft:0
    },
    submit:{
        minWidth:110,
    },
    delete:{
        minWidth:110,
    },
    back :{
        paddingLeft:10

    }
}))

export default ({submitting, showSubmitButton, showDeleteButton, submitLabel, handleSubmit, handleDelete})=> {
    const classes = useStyles()
    return submitLabel && <Box display="flex" className={classes.root} flexDirection="row-reverse" justifyContent="space-between" >
         {showSubmitButton !==false && <Button color="primary" 
            className={classes.submit} 
            variant="contained" 
            // autoFocus 
            disabled={submitting} 
            onClick={handleSubmit}>
            {submitLabel}
        </Button>}


        {showDeleteButton && <Button 
            className={classes.delete} 
            // variant="contained" 
            variant="outlined"
            // autoFocus 
            disabled={submitting} 
            onClick={handleDelete}>
            {'حذف'}
        </Button>}
        {/* <Button color="primary" ><ArrowForwardIcon  style={{color:'gray'}} fontSize="inherit" /> <Box className={classes.back}> بازگشت</Box></Button> */}


        
    </Box>
}