import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('identity');


export const reportCounts = async (params) => {

    const result = await get(`user/reportCounts`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('user:users')

    });

    return result;
}
export const reportCalls = async (params) => {

    const result = await get(`user/reportCalls`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('user:users')

    });

    return result;
}


export const insertUser = async (user) => {

    user = fixModel(user);

    const resultEntity = await post(`user`,user,
    {
        errorMessage : t('common:insertError') + t('user:user')
    });

    if(!resultEntity) return null;

    return {...user, id:resultEntity.id};
}


export const updateUser = async (user) => {

    user = fixModel(user);

    const result = await putFormData(`user/${ user.id }`,
        user,
        {
            errorMessage :  t('common:updateError') + t('user:user')
        });

    return result;
}

export const deleteUser = async (id) => {

    const result = await del(`user/${id}`,
        {
            errorMessage : t('common:deleteError') + t('user:user')
        });

    return result;
}

export const getUsers = async (params, options) => {

    const result = await get(`user`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('user:users'),
        ...options
    });

    return result;
}

export const exists = async ({excepId,...params}, options) => {

    
    const result = await get(`user/exists`+(excepId?'?excepId='+excepId:''),
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('user:users'),
        ...options
    });

    return result;
}

export const getUser = async (id) => {

    const result = await get(`user/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('user:user')
    });

    return result;
}


export const getToken = async (subscriptionKey) => {


    const resultEntity = await post(`token?subscriptionKey=`+subscriptionKey,
    {},
    {
        errorMessage : t('common:insertError') + t('user:user')
    });

    if(!resultEntity) return null;

    return resultEntity;
}

function fixModel(user){
    user = {...user};

    if(user.roles){
        user.userRoles = user.roles.map(r=> ({roleId:r.id}));
        user.roles = undefined;
    }

    return user;
}





























// export const insertUser = async (user) => {

//     user = fixModel(user);
  
//     const resultEntity = await post(`user`,
//         user,
//         {
//             errorMessage : t('common:insertError') + t('user:user')
//         });
    
//     if(!resultEntity) return null;

//     return {...user, id:resultEntity.id};
// }


// export const updateUser = async (user) => {

//     user = fixModel(user);

//     const result = await putFormData(`user/${user.id}`,
//         user,
//         {
//             errorMessage :  t('common:editError') + t('user:user')
//         });

//     return result;
// }

// export const deleteUser = async (id) => {

//     const result = await del(`user/${id}`,
//         {
//             errorMessage : t('common:deleteError') + t('user:user')
//         });

//     return result;
// }

// // export const getUsers = async (p) => {

// //     const result = await get(`user`,{...p},
// //     {
// //         errorMessage : `خطا در دریافت لیست کاربر`
// //     });

// //     return result;
// // }
// export const getUsers = async (p, onlyEmployees) => {
//     const action = onlyEmployees? `user\\employee` : `user`;

//     const result = await get(action,{...p},
//     {
//         errorMessage : t('common:fetchError') + t('user:users')
//     });

//     return result;
// }


// export const getUser = async (id) => {

//     const result = await get(`user/`+id,
//     {},
//     {
//         errorMessage : t('common:fetchError') + t('user:user')
//     });

//     return result;
// }




// //
// // Roles
// //

// export const deleteRole = async (userId, id) => {

//     const result = await del(`user/${userId}/DeleteRole/${id}`,
//         {
//             errorMessage : t('common:deleteError') + t('user:roles.title')
//         });
    
//     return result;
// }

// export const deleteRoles = async (userId, ids) => {

//     const result = await del(`user/`+userId + "/DeleteRoles",
//         ids,
//         {
//             errorMessage : t('common:deleteError') + t('user:roles.title')
//         });
    
//     return result;
// }

// export const getRoles = async () => {

//     const result = await get('role',
        
//         {
//             errorMessage : t('common:fetchError') + t('user:roles.title')
//         });
    
//     return result;
// }

// export const addRoles = async (userId, ids) => {

//     const result = await post(`user/`+userId + "/PostRoles",
//         ids,
//         {
//             errorMessage : t('common:insertError') + t('user:roles.title')
//         });
    
//     return result;
// }


// function fixModel(user){
//     user = {...user};

//     if(user.roles){
//         user.userRoles = user.roles.map(r=> ({roleId:r.id}));
//         user.roles = undefined;
//     }

//     return user;
// }
