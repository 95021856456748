import React, { PureComponent } from 'react'
import RouterBreadcrumbs from '../../../../Component/RouterBreadcrumbs'
import { withTranslation } from 'react-i18next';
import "filepond/dist/filepond.min.css";
import { EditableViewFields } from '../Form';
import {userTitle} from '../../../../domain/extensions/userExtensions'

class EditableViewForm extends PureComponent {

    render() {
        const {t, values,recurrents, onRefresh} = this.props

        return (<>
            <RouterBreadcrumbs title={ userTitle(values) } />
            <EditableViewFields recurrents={recurrents} onRefresh={onRefresh} values={values} t={t} />
        </>)
    }
}

export default withTranslation('user')(EditableViewForm);