import React from "react";
import Tabs from "@material-ui/core/Tabs";
import TabTitle from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withStyles } from '@material-ui/core/styles';
import { FormContext } from "../Contexts/FormContext";
import Divider from './Divider';

class TabPanel extends React.PureComponent {

render(props) {
  const { hasSubmitError, isErrorRendered, changeValue, children, value, index, ...other } = this.props;
  let onlyHideIt = false
  if( hasSubmitError()){
    onlyHideIt = true; // So the tab is rendered but is hidden. An eroor field needs to be rendered, so it tells the form provider that i'm rendered  
    if(isErrorRendered()) {
      changeValue(index-1);
      return <></>;
    }
  }

  if(!onlyHideIt && value !== index) return <></>;

  return ( <Typography
    style={{overflow: 'auto', width:'100%'}}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      <Box p={3}  style={{paddingTop:0}}>{children}</Box>

      {/* {value === index && <Box p={3} style={{paddingTop:0}}>{children}</Box>} */}
    </Typography>

  );
}
}
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}


const styles = theme => ({
  root: {


    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    display: "flex",
    // height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: 180,
  },
  tabPanel:{
    padding:'0!important',
    border:'1px solid red'
  },
  tabItem:{
    "& >svg":{
      marginRight:15
    },
    textAlign:'left',
    // alignItems:'end',
    // textAlign:'left',
    fontSize:'120%',
    // display: 'flex',
    // flexDirection: 'row'
    flexDirection: 'row',
    justifyContent: 'flex-start'
  }
});


class TabComponent extends React.PureComponent {

  render()
  {

    // React.Children.forEach(this.props.children, x =>{ console.log(x.children.);console.log(x.props)})
   return this.props.children;
  }
}

class VerticalTabsComponent extends React.PureComponent {

  state = {
    value: this.props.currentTabIndex || 0
  }

  setValue(value) {
    this.setState({
      value
    });
  }

  handleChange = (_event, newValue) => {
    this.setValue(newValue);
  };

  changeValue = (newValue) => {
    this.setValue(newValue);
  };
  async componentWillReceiveProps(nextProps) {
    if(nextProps.currentTabIndex !==undefined && nextProps.currentTabIndex !== this.state.value) {
      this.setValue(nextProps.currentTabIndex);
    }
  }
  /*
  scan = (children)=>{
    if(!children)
      return;
      // console.log(children)
      React.Children.map(children, 
        c=> {if(c) {if(c.type) {var C=c; console.log(<C/>)}}if(c&&c.props&&c.props.children) this.scan(c.props.children)});
  }
  */

  render() {
    const { children, classes } = this.props;
    const { value } = this.state;
    var flatternChildren = [[],...children].reduce((fl, item)=> Array.isArray(item)? [...fl,...item]:[...fl,item]);
    flatternChildren = flatternChildren.filter(ch=>ch.props);
      // flatternChildren.reverse();
      // this.scan(this.props.children);

      return <FormContext.Consumer>
        {({hasSubmitError, isErrorRendered, isRendered, onChange, onBlur, values={}, exValues={}, errors, allTouched, touches})=>{
    

        return <div className={classes.root}>

        <Tabs
        style={{}}
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={this.handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          {flatternChildren.map((tab, i)=>(tab.props.seperator?<Divider/>:<TabTitle key={i} classes={{wrapper:classes.tabItem}} label={tab.props.title} {...a11yProps(i)} />))}

        </Tabs>

        {
            flatternChildren.map((tab, i)=><TabPanel key={i} hasSubmitError={hasSubmitError} isErrorRendered={isErrorRendered} changeValue ={this.changeValue} value={value} index={i}>{tab}</TabPanel>)
        }
        </div>
        }}
      </FormContext.Consumer>
  }
}

export const Tab = TabComponent;
export const VerticalTabs = withStyles(styles)(VerticalTabsComponent);
