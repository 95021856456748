import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateSubscription } from '../../api'
import {hasValue} from '../../../../../utils/string'

import {allSubscriptionTypes, byId, titleById} from '../../../../../domain/subscriptionType'
import Selector from '../../../../../Component/Selector'

export const SubscriptionType =  ({values}) =>{
    const { t } = useTranslation('subscription');

    return <Grid item sm={ 12 } xs={12}>
    <FormControl fullWidth>
        <Field component={Selector}
               label={t('subscriptionType.title')}
               name="subscriptionType"
               options={allSubscriptionTypes }
               // keyName="id"
               optionTitle="displayName" />
    </FormControl>
</Grid>
}

export const validate = (values, errors, t)=> {
    if (values.subscriptionType === undefined) {
        errors.subscriptionType = t('subscriptionType.required');
    }
    return errors;
}

class View extends React.Component {

    update = async (values) =>{
        const { id, subscriptionType }=values;
        return await updateSubscription({id, subscriptionType });
    }

    headline(values){
        const { subscriptionType } = values;
        return titleById(subscriptionType);
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, readonly, t } = this.props;

        return <ViewField 
            successMessage={this.successMessage}
            title={ t('subscriptionType.title')}
            headline={ this.headline(values) }
            update={this.update}
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            readonly = {readonly}
            editComponent={ SubscriptionType } />

    }
}

export const SubscriptionTypeView = withTranslation('subscription')(View);