import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateBundle } from '../../api'
import {hasValue} from '../../../../../utils/string'

import DateTimeInput from '../../../../../Component/DateTimeInput';
import moment from 'moment-jalaali';

export const CreatedTime =  () => {
    const { t } = useTranslation('bundle');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
        <Field label={t('createdTime.title')}
                name={"createdTime"}
                date time
                component={DateTimeInput}
                />
        </FormControl>
    </Grid>
    </>
}

export const validate = (values, errors, t)=> {
    if (!hasValue(values.createdTime)) {
        errors.createdTime = t('createdTime.required');
    }
    return errors;
}

class View extends React.Component {

    update = async (values) =>{
        const {id, createdTime }=values;
        return await updateBundle({id, createdTime:moment(createdTime).utc().format() });
    }

    successMessage  = (values) => this.props.t('updated')

    headline() {
        const { values={}} = this.props;
        const { createdTime } = values;
        const format = 'jYYYY/jMM/jDD hh:mm A';
        
        return createdTime?moment(createdTime).format(format):'-';
    }

    render() {
        const { values, onRefresh,readonly, t } = this.props;
        const { createdTime } = values;

        return <ViewField 
            successMessage={this.successMessage}
            title={ t('createdTime.title')}
            headline={ this.headline() }
            update={this.update}
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            readonly = {readonly}
            editComponent={ CreatedTime } />

    }
}


export const CreatedTimeView = withTranslation('bundle')(View);