import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Name, NameView, validate as validateName } from './Name'
import { Extension, ExtensionView, validate as validateExtension } from './Extension'
import { Description, DescriptionView, validate as validateDescription } from './Description'
import { KindOfDoc, KindOfDocView, validate as validateKindOfDoc } from './KindOfDoc'

export const validators={
    validateName, validateExtension, validateDescription, validateKindOfDoc 
};

export const validate =(values, errors, t) => {

    for (const [, func] of Object.entries(validators)) {
        func(values, errors, t);
      }

}

export const FormFields = (props) =>
{
    return <>
<Grid container spacing={2} style={{padding:25, paddingTop:0}}>

        <Name />
        <Extension />
        <Description />
        <KindOfDoc />
</Grid>
</>
}

export const EditableViewFields = (props) =>
{
return <>

    <NameView {...props} />
    <ExtensionView {...props} />
    <DescriptionView {...props} />
    <KindOfDocView {...props} />

</>

}

