import { Box, Button } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import {getToken} from '../User/api';
import { hasRole, getCurrentUser } from '../../../modules/Auth'
import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useStyles } from './EmployeeHome.styles';
import FilterableGrid, { columnTypes } from '../../../Component/FilterableGrid';
import { useTranslation } from 'react-i18next';
import { getDashboard } from './api'
import { Paper } from '@material-ui/core';
import { Grid} from '@material-ui/core'
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { Chip } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import {titleById as bundleTitleById, byId as BundleById, bundleStatus, allBundleStatuses} from '../../../domain/bundleStatus'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
// import { useTheme } from '@material-ui/core';
import { fromHours, toHumanText } from '../../../domain/subscriptionPeroid';
import { bundleToGb, toGb    } from '../../../domain/bundleTraffic';


function getFullName({ customerPhone='', customerName='', id:loginKey='' }) {
  customerPhone = customerPhone ||'';
  customerName = customerName || '';
  let remark = customerPhone + ' ' + customerName;


  remark = (!customerName && !customerPhone) ? "ID: "+loginKey.toString(): remark;

  return remark ;

}
const EmployeeHome = (props) => {
    const history = useHistory();
    const match = useRouteMatch();
    const classes = useStyles();
    const {t} = useTranslation('subscription');
    const [dashboard, setDashboard] = useState({
      approachingExpiration:[],
      approachingTrafficLimit:[],
      expired:[],
      trafficExceeded:[]
    });
    // const theme = useTheme();
    const byPath = (obj, path) => {
      try {
        return path.split('.').reduce((o, key) => o[key], obj);
      } catch {
        return null;
      }
    };
    // const bundleStatus=(id)=>{
    //   if(!id) id=bundleStatus.NotUsed;
    //   var color= BundleById(id).color;
    //   const colorCode = byPath(theme.palette, color) || '';
    
    //   var icon = id === bundleStatus.Using? <DoneIcon style={{ color: colorCode }} /> : id === bundleStatus.Cancelled?<NotInterestedIcon style={{ color: colorCode }} />:<ErrorOutlineIcon style={{ color: colorCode }} />;
    
    //   return <Chip variant="outlined" color={colorCode} label={bundleTitleById(id)} style={{color:colorCode,borderColor:colorCode}} size="small" icon={icon} />
    // }
    const bundleExpirationDate=(row)=>{
      if(!row.activeBundle)
        return "-";
      let text =  toHumanText(row.activeBundle.availableHours);    
      if(row.activeBundle.availableHours>0)
        text+= ' ' + t('subscription:units.next');
      return <Box color="red">{text}</Box>;
    }
    const getExpirationColums = (t) => ({
  
      'customerName': {
        editLink: true,
          searchable: false,
          format:(row) =>  getFullName(row),
          sortable: false,
          remote: true
      },
      'activeBundle.durationHours': {
        title: t('remainingTime'),
        format: (row) => bundleExpirationDate(row),
        items: allBundleStatuses,
        remote: true
    
      }
    
  });
  const getColums = (t) => ({
    'customerName': {
      editLink: true,
      format:(row) =>  getFullName(row),

        searchable: false,
        sortable: false,
        remote: true
    },

  'activeBundle.traffic': {
    title: t('volume'),
    format: (row) =>  row.activeBundle? <Box color="red">{ toGb(row.activeBundle.trafficUsed, 1) +' / ' + toGb(row.activeBundle.traffic,0) }</Box>:<Box>-</Box>,
    remote: true
  }, 
  });
  const onEditClick =(id)=>{
    const { match } = props;
    // props.history.push(`${match.path}/${id}`);
    gotoPage(`/admin/subscription/${id}`)
  }
    useEffect(() => {
      const loadDashboardData = async () => {
          const res = await getDashboard();
          if (res) {
              setDashboard(res);
          }
          const allLengthsZero = Object.values(res).every(arr => arr.length === 0);
          if(allLengthsZero) gotoPage(`/admin/subscription/${getCurrentUser().id}`);

          
          return res;
      };

      loadDashboardData();

  
      
    }, [match.path, history]);
  
    const gotoPage = (path) => {
      history.push(path);
    };
    // if (!dashboard.approachingExpiration) {
      
    //     return <div>Loading...</div>;
    // }
    return (
      <>
  <Box sx={{ flexGrow: 1, p: 2 }}>
  <Grid container spacing={2}>
  <Grid item xs={12} container spacing={2}>
    <Grid item xs={12} sm={6} md={6}>
      <Paper className={classes.paper}>
        <FilterableGrid
          data={dashboard.approachingExpiration}
          fixed={false}
          selectable={false}
          pagination={false}
          showHeader={false}
          onEdit={onEditClick}
          title={t("approachingExpiration")}
          header={getExpirationColums(t)}
          
        />
      </Paper>
    </Grid>
    <Grid item xs={12} sm={6} md={6}>
      <Paper className={classes.paper}>
        <FilterableGrid
          data={dashboard.expired}
          fixed={false}
          selectable={false}
          pagination={false}
          showHeader={false}
          onEdit={onEditClick}

          title={t("expired")}
          header={getExpirationColums(t)}          
        />
      </Paper>
    </Grid>
    </Grid>


    <Grid item xs={12} container  spacing={2}>
    <Grid item xs={12} sm={6} md={6}>
      <Paper className={classes.paper}>
        <FilterableGrid
          data={dashboard.approachingTrafficLimit}
          fixed={false}
          selectable={false}
          pagination={false}
          showHeader={false}
          title={t("approachingTrafficLimit")}
          emptyList={<Box>Empty</Box>}
          onEdit={onEditClick}

          header={getColums(t)}
        />
      </Paper>
    </Grid>
    <Grid item xs={12} sm={6} md={6}>
      <Paper className={classes.paper}>
        <FilterableGrid
          data={dashboard.trafficExceeded}
          fixed={false}
          selectable={false}
          pagination={false}
          showHeader={false}
          title={t("trafficExceeded")}
          onEdit={onEditClick}

          header={getColums(t)}
        />
      </Paper>
    </Grid>
  </Grid>
  </Grid>
</Box>

      </>
    );
  };
  
  
// export  class EmployeeHome extends PureComponent {
//     // async testAjaxToken() {
//     //     var result = await getToken('6E539503B68814A7');
//     //     console.log(result);
//     // }
//     constructor(props) {
//         super(props);
//         this.gotoPage('/admin/subscription/' + getCurrentUser().id)();
//     }
//     gotoPage = (path) => () => {
//         const { match, history } = this.props
//         history.push(`${path}`);
//     }

    
//     render() {

//         const {classes} = this.props;
//         return (
//                 <>

//                 <Box display="flex" flexWrap="wrap" justifyContent="center">
//                     <Box >
//                         <Button className={classes.a} button variant="outlined" color="primary" label={'ٔNew Subscriber'} icon={<FaceIcon />}
//                             onClick={() =>     this.props.history.push(`/admin/subscription/create`)}
//                         >
//                             <Box className={classes.icon + ' icon'}><img alt=""  src={'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><defs><style>.fa-primary{fill:rgb(11, 105, 161)}  .fa-secondary{fill:rgb(11, 105, 161); opacity:.4}</style></defs><path d="M0 128v304a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48V128zm176 64a64 64 0 1 1-64 64 64 64 0 0 1 64-64zm93.3 224H82.7c-10.4 0-18.8-10-15.6-19.8A64.09 64.09 0 0 1 128 352h8.2a103 103 0 0 0 79.6 0h8.2a64.09 64.09 0 0 1 60.9 44.2c3.2 9.9-5.2 19.8-15.6 19.8zM512 344a8 8 0 0 1-8 8H360a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8zm0-64a8 8 0 0 1-8 8H360a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8zm0-64a8 8 0 0 1-8 8H360a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h144a8 8 0 0 1 8 8z" class="fa-secondary"/><path d="M224 352h-8.2a103 103 0 0 1-79.6 0H128a64.09 64.09 0 0 0-60.9 44.2C63.9 406 72.3 416 82.7 416h186.6c10.4 0 18.8-9.9 15.6-19.8A64.09 64.09 0 0 0 224 352zM528 32H48A48 48 0 0 0 0 80v48h576V80a48 48 0 0 0-48-48zM176 320a64 64 0 1 0-64-64 64 64 0 0 0 64 64z" class="fa-primary"/></svg>'} />
//                             </Box>
//                            New Subscriber
//                         </Button >
//                     </Box>
//                 </Box>
//                 </>
  
//         )
//     }
// }







export default EmployeeHome;


// export default withStyles(styles, { withTheme: true })(EmployeeHome)