import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateUser } from '../../api'
import Switch from '../../../../../Component/Switch'

export const IsPhoneValid =  ({t=useTranslation('user').t}) =>
<>
<Grid item xs={12}>
    <FormControl fullWidth>
        <Field label={t('isPhoneValid.title')}
               name={"isPhoneValid"}
               component={Switch}
               type="text" />
    </FormControl>
</Grid>
</>

export const validate = (values, errors, t)=> {
    if (values.isPhoneValid !== true && values.isPhoneValid !==false) {
        errors.isPhoneValid = t('isPhoneValid.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, isPhoneValid }=values;
        return await updateUser({id, isPhoneValid });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { isPhoneValid } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('isPhoneValid.title')}
                          headline={ isPhoneValid?t('common:yes'):t('common:no') }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ IsPhoneValid }
                          />

    }
}


export const IsPhoneValidView = withTranslation('user')(View);
