import React from 'react'
import Grid from '@material-ui/core/Grid'
import {VerticalTabs, Tab} from '../../../../Component/VerticalTabs';
import Divider from '../../../../Component/Divider';
import { useTranslation } from 'react-i18next';
import { Box, FormGroup } from '@material-ui/core'

import { NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';
import { Subscriber, SubscriberView, validate as validateSubscriber } from './Fields/Subscriber'
import { Traffic, TrafficView, validate as validateTraffic } from './Fields/Traffic'
import { ConcurrencyLimit, ConcurrencyLimitView, validate as validateConcurrencyLimit } from './Fields/ConcurrencyLimit'
import { TrafficUsed, TrafficUsedView } from './Fields/TrafficUsed'
import { DurationHours, DurationHoursView, validate as validateDurationHours } from './Fields/DurationHours'
import { FirstConnectedTime, FirstConnectedTimeView } from './Fields/FirstConnectedTime'
import { CreatedTime, CreatedTimeView, validate as validateCreatedTime } from './Fields/CreatedTime'
import { Status, StatusView, validate as validateStatus } from './Fields/Status'
import { BundleType,BundleTypeView, validate as validateBundleType } from './Fields/BundleType';
import { IsResellerBundle } from './Fields/IsResellerBundle';
import { bundleType } from '../../../../domain/bundleType';


export const validators={
    validateSubscriber, validateTraffic, validateConcurrencyLimit, validateDurationHours 
};


export const validate =(values, errors, t) => {

    for (const [name, func] of Object.entries(validators)) {
        func(values, errors, t);
      }

}
export const Form = (props) =>
{
    const { values } = props;
    const { t } = useTranslation('bundle');
    return <>
<Grid container spacing={2} style={{padding:25, paddingTop:0}}>

        {/* <Subscriber /> */}
        <Traffic values={values}/>
        { !values.isResellerBundle && <ConcurrencyLimit/> }
        {/* <TrafficUsed /> */}
        { !values.isResellerBundle && <DurationHours />}
        <IsResellerBundle/>

        {/* <FirstConnectedTime /> */}
        {/* <CreatedTime /> */}
        {/* <Status /> */}
        {/* <OrderType /> */}
</Grid>
</>
}

export const ViewForm = (props) =>
{
    const {values, onRefresh} = props;
    const { t } = useTranslation('bundle');
if(!values.bundleType) return <></>
return <>
    {/* <SubscriberView {...props} /> */}
    <TrafficView {...props} />
    {values.bundleType !== bundleType.Reseller && <ConcurrencyLimitView {...props} /> }
    {/* <TrafficUsedView {...props} /> */}
    {values.bundleType !== bundleType.Reseller  && <DurationHoursView {...props} /> }
    <FirstConnectedTimeView readonly {...props} />
    {/* <CreatedTimeView {...props} /> */}
    {/* <StatusView {...props} /> */}
    {/* <BundleTypeView {...props} /> */}

</>

}

