import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateResellerSetting } from '../../api'
import {hasValue} from '../../../../../utils/string'
import { makeStyles } from "@material-ui/core/styles";

import TextInput from '../../../../../Component/TextInput'
const useStyles = makeStyles(() => ({
    indentTitle:{
      paddingLeft:20
    }
  }));
export const CardNumbers =  () => {
    const { t } = useTranslation('resellerSetting');

    return <>
    <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('cardNumbers.title')}
                   name={"cardNumbers"}
                   component={TextInput}
                   multiline
                   dir="ltr"
                   />
        </FormControl>
    </Grid>
    </>
}
export const validate = (values, errors, t)=> {
    return errors;
}


export function CardNumbersView({ values, onRefresh }) {
    const { t } = useTranslation('resellerSetting');
    const classes = useStyles();
    const update = async (values) =>{
        const {id, cardNumbers }=values;
        return await updateResellerSetting({id, cardNumbers });
    }

    const successMessage  = (values) => t('updated')

    const { cardNumbers } = values;

    return  <div >
        <ViewField successMessage={successMessage}
                            title={ t('cardNumbers.title')  }
                            headline={ cardNumbers || t("common:notEntered") }
                            update={update}
                            validate={validate}
                            values={values}
                            onRefresh={onRefresh}
                            editComponent={ CardNumbers } />

    </div>

}