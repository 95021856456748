import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton, TextField } from '@material-ui/core';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { AddBox, Delete, DragIndicator } from '@material-ui/icons';
import { arrayMoveImmutable as arrayMove } from 'array-move';
import TextInput from '../../../../Component/TextInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import NumberFormat from 'react-number-format';
import { styled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { currencyFormat } from '../../../../utils/objectHelper';

const  CustomTableCell= styled(TableCell)({
  padding: '5px',
  paddingLeft: '16px',
});

const useStyles = makeStyles({
  tableRow:{
    zIndex:9000,
    direction:'ltr'
  }
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix=""
    />
  );
}

var uuidCounter = new Date().getTime();
function uuid() {
  return uuidCounter++;
}

const DragHandle = SortableHandle(() => (
  <IconButton>
    <DragIndicator />
  </IconButton>
));
const endAdor = (label) =>

      <InputAdornment position="end">              
      {label}
      </InputAdornment>

  
const SortableItem = SortableElement(({ t,row, onRowDelete, onRowChange }) => {
  const classes = useStyles();
  
  return <TableRow className = {classes.tableRow }>
    <CustomTableCell>
      <DragHandle    />
    </CustomTableCell>
    <CustomTableCell>
      <TextField
        value={row.traffic}
        placeholder={t("ٰtraffic")}
     
        InputProps={{endAdornment: endAdor(t("bundle:gb")), inputComponent: NumberFormatCustom}}
        onChange={(e) => onRowChange(row.id, 'traffic', e.target.value)}
      />
    </CustomTableCell>
    {/* <CustomTableCell>
      <TextField
        placeholder="Duration"
        value={row.duration}
        InputProps={endAdor("Month")}
        onChange={(e) => onRowChange(row.id, 'duration', e.target.value)}
      />
    </CustomTableCell> */}
    <CustomTableCell>
      <TextField
        placeholder={t("price")}
        InputProps={{endAdornment: endAdor(t("common:currency")), inputComponent: NumberFormatCustom}}
        value={row.price}
        onChange={(e) => onRowChange(row.id, 'price', e.target.value)}
      />
    </CustomTableCell>
    <CustomTableCell >
      <IconButton  onClick={() => onRowDelete(row.id)}>
        <Delete  />
      </IconButton>
    </CustomTableCell>
  </TableRow>
}
);


const SortableList = SortableContainer(({t, items, onRowDelete, onRowChange }) => {
  return (
    <TableBody  >
      {items.map((row, index) => (
        <SortableItem
          key={`item-${row.id}`}
          index={index}
          row={row}
          t={t}
          onRowDelete={onRowDelete}
          onRowChange={onRowChange}
          value={row.id}
        />
      ))}
    </TableBody>
  );
});

export const ViewPriceTable = ({ data }) => {
  const {t} = useTranslation('resellerSetting');
  return ( <div style={{paddingBottom:20}}>
    <Table  >
      <TableHead>
        <TableRow>
          <CustomTableCell>{t('volume')}</CustomTableCell>
          <CustomTableCell>{t('price')}</CustomTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            <CustomTableCell> { currencyFormat(row.traffic,t('bundle:gb'))} </CustomTableCell>
            <CustomTableCell> { currencyFormat(row.price,t('common:currency'))}</CustomTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
   </div>
  );
};
export const EditablePriceTable = ({input: { name, onChange, value }={}}) => {
  const [rows, setRows] = useState(JSON.parse(value||'[]'));
  
  
  const setChanges = (newRows) =>{
    setRows(newRows);
    onChange({
      target: {
        value: JSON.stringify(newRows),
      },
    });
  }
  const handleAddRow = () => {
    setRows([...rows, { id: uuid(), traffic: '', duration: '', price: '' }]);
    // setChanges(rows.filter(row => row.id !== id));

  };

  const handleDeleteRow = (id) => {    
    setChanges(rows.filter(row => row.id !== id));

  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setChanges(arrayMove(rows, oldIndex, newIndex));
    
  };

  const handleChange = (id, field, value) => {
    const newRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    
    setChanges(newRows);
  };
  const {t} = useTranslation('resellerSetting'); 
  return (
    <div>

      <Table >
        <TableHead>
          <TableRow>
            <CustomTableCell></CustomTableCell>
            <CustomTableCell style={{width:'40%'}}>{t('volume')}</CustomTableCell>
            <CustomTableCell style={{width:'60%'}}>{t('price')}</CustomTableCell>
            <CustomTableCell> </CustomTableCell>
          </TableRow>
        </TableHead>
        <SortableList  
          useDragHandle={true}
          lockAxis="y" 
          t={t}
          items={rows}
          onSortEnd={onSortEnd}
          onRowDelete={handleDeleteRow}
          onRowChange={handleChange}
        />
      </Table>
      <div style={{ textAlign: 'center' }}>
      <IconButton onClick={handleAddRow}  >
        <AddBox />
      </IconButton>
      </div>
    </div>
  );
};

