import React from 'react'
import { loggedIn, returnUser,getUserTitle, isSwitched, signOut, getProfile,hasRole, panels, getPanelTitle, getDefaultPanel, getCurrentArea } from '../modules/Auth'
import { withRouter } from "react-router-dom"
import IconButton from '@material-ui/core/IconButton'
import Avatar from './Avatar'
import Popover from '@material-ui/core/Popover';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {Redirect} from "react-router-dom"
import {t} from '../i18n';

const styles = () => ({
  root: {
      flexGrow: 1,
  }, 
  card :{
    direction:'ltr',

    width:330,
  },
  otherAreaButton : {
    width:'100%',
    minWidth: 170,
    marginTop: 20,
  },
  authBox:{
    right:'5px',position:"fixed", top:'0px', zIndex:9998, color:'white'
  },
  body:{padding:10, paddingRight: 30}

});
class Auth extends React.Component{
  constructor(props){
    super(props)

    this.state = {
      anchorEl: null
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  onLogoutClick = async ()=>{
    await signOut()
    this.props.history.push("/")
    this.setState({ anchorEl: null })
  }
  onReturnClick = ()=>{
    returnUser();
  }
  
  gotoPath = (path) => () => {
    this.props.history.push(path)
    this.setState({ anchorEl: null })
  }

  getOtherPanelButtons()
  {
    const currentArea = getCurrentArea(this.props.location.pathname);
    const {classes} = this.props;
    
    const otherPanels = panels.filter(p=>p.path !== currentArea && hasRole(p.roles));

    console.log(getProfile());
    // const tenantTitle = getProfile().tenantTitle;

    return otherPanels.map(p=>
      <Button className={classes.otherAreaButton} color="primary" variant="outlined" onClick={ this.gotoPath('/'+p.path) } size="small">{getPanelTitle(p)}</Button>
    );

  }

  getReturnUrl = () => {
      var state = this.props.location.state;
      var url = state && state.from && state.from.pathname;
      return url || '/'
  }
  
  render(){
    const path = 
      this.props.location.pathname,
      { anchorEl } = this.state,
      isLoggedIn = loggedIn(),
      profile = isLoggedIn && getProfile()
    const currentArea = getCurrentArea(this.props.location.pathname);
    // alert(currentArea);
    const { classes } = this.props;
   
    // If it's the auth-callback page, do nothing
    const isCallbackPage = this.props.location.pathname.indexOf('auth-callback')>=0;
    if(isCallbackPage) 
      return <div></div>;

    if (!isLoggedIn) {
      return <Redirect
        to={{
          pathname: "/sign/login",
          state: { from: this.getReturnUrl() }
        }}
      />
    } else if(!currentArea) {
      return <Redirect
        to={{
          pathname: getDefaultPanel().path,
          state: { from: this.getReturnUrl() }
        }}
      />
    }
    
    return(
      <div className="account-icon">
         <div className={classes.authBox}>
         {!isLoggedIn && !isCallbackPage &&
          <Button  color="primary" style={{marginTop:15}} variant="contained" onClick={ this.gotoPath('/sign/login') } size="small">ورود به سیستم</Button>
          }

        <IconButton
          aria-label="More"
          aria-owns='long-menu'
          aria-haspopup="true"
          onClick={this.handleClick}>
          {isLoggedIn && 
          <Avatar color="#1074c3"  firstName={profile.given_name} lastName={profile.family_name} />
          }
         
           {/* <AccountCircle style={{color:'white'}}/> */}
        </IconButton>
        
         {isLoggedIn &&
         <Popover
        // id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={this.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}

      >

      <Card className={classes.card}>
      <CardContent>

<Grid container spacing={4} >
  <Grid item   xs={4} style={{paddingLeft:50, paddingRight:50}}>
  {isLoggedIn && 
<IconButton
          style={{padding:0,width: '100%'}}
          aria-label="More"
          aria-owns='long-menu'
          aria-haspopup="true"
          // onClick={this.handleClick}
          
          >


          <Avatar size={100}  firstName={profile.given_name} lastName={profile.family_name} />
        

        </IconButton>
  }
  </Grid>
  <Grid item className={classes.body}   xs={8}>
  {isLoggedIn && 

        <Typography variant="b" component="b">
          {getUserTitle(profile)}
        </Typography> }
        {this.getOtherPanelButtons()}
 
  </Grid>
</Grid>


{(!isLoggedIn && path !== '/sign/login') &&<Button  color="primary" variant="contained" onClick={ this.gotoPath('/sign/login') } size="small">{t('panel:login')}</Button>}

      </CardContent>
      <CardActions style={{borderTop: '1px solid #a7a7a796',
    backgroundColor: '#80808017'}}>
          {isLoggedIn && !isSwitched() &&<Button onClick={ this.onLogoutClick } size="small">{t('panel:logout')}</Button>}
          {isLoggedIn && isSwitched() &&<Button onClick={ this.onReturnClick } size="small">{t('panel:return')}</Button>}

      </CardActions>
    </Card>


      </Popover>
         }

      </div>
      </div>
    )
  }
}


  export default withStyles(styles)(withRouter(Auth))

