import {t} from '../i18n';

export const bundleStatus = {
    Cancelled:0,
    NotUsed: 1,
    Using:2,
    Expired:3,
    Over:4
}

export const allBundleStatuses = [
    {
        "id": 0,
        "systemName": "cancelled",
        "displayName" : t('bundle:bundleStatus.cancelled'),
        "color" : "text.secondary"
    },
    {
        "id": 1,
        "systemName": "notUsed",
        "displayName" : t('bundle:bundleStatus.notUsed'),
        "color" : "primary.dark"
    },
    {
        "id": 2,
        "systemName": "using",
        "displayName" : t('bundle:bundleStatus.using'),
        "color" : "success.dark"

    },
    {
        "id": 3,
        "systemName": "expired",
        "displayName" : t('bundle:bundleStatus.expired'),
        "color" : "error.dark"
    },
    {
        "id": 4,
        "systemName": "over",
        "displayName" : t('bundle:bundleStatus.over'),
        "color" : "error.dark"
    }
];

export const byId = (id)=>{
    return allBundleStatuses.find(bs=>bs.id==id) || {}
}

export const titleById = (id)=>{
    return byId(id).displayName || "";
}