import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateMimeType } from '../../api'
import {allKindOfDocs, titleById} from '../../../../../domain/kindOfDoc'
import Selector from '../../../../../Component/Selector'

export const KindOfDoc =  ({t=useTranslation('mimeType').t }) =>{

    return <Grid item sm={ 12 } xs={12}>
    <FormControl fullWidth>
        <Field component={Selector}
               label={t('kindOfDoc.title')}
               name="kindOfDoc"
               options={allKindOfDocs }
               // keyName="id"
               optionTitle="displayName" />
    </FormControl>
</Grid>
}

export const validate = (values, errors, t)=> {
    if (values.kindOfDoc === undefined) {
        errors.kindOfDoc = t('kindOfDoc.required');
    }
    return errors;
}

class View extends React.Component {

    update = async (values) =>{
        const { id, kindOfDoc }=values;
        return await updateMimeType({id, kindOfDoc });
    }

    headline(values){
        const { kindOfDoc } = values;
        return titleById(kindOfDoc);
    }

    successMessage  = () => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;

        return <ViewField 
            successMessage={this.successMessage}
            title={ t('kindOfDoc.title')}
            headline={ this.headline(values) }
            update={this.update}
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            editComponent={ KindOfDoc } />

    }
}

export const KindOfDocView = withTranslation('mimeType')(View);