import React, { PureComponent } from 'react'
import {Redirect} from "react-router-dom"
import CreateForm from './Form'
import { insertCustomer } from '../api'
import { setProfile, getProfile } from '../../../../modules/Auth'

export default class Create extends PureComponent {

    state={

    }
    constructor(props) {
        super(props)
        this.form = React.createRef()
    }

    onSubmit = async (values) => {
        console.log('values');
        console.log(values);
        console.log(values);
        // values.user
        var res = await insertCustomer(values);

        if(res) {
            console.log(res);
            let profile = getProfile();
            profile.firstName = values.user.firstName;
            profile.lastName = values.user.lastName;
            profile.gender = values.user.gender;
            console.log('the profile before setProfile')
            console.log(profile)

            setProfile(profile);
            console.log('the profile after setProfile')
            console.log(profile)

            this.setState({hasProfile:true});
            console.log('the setState')

        }
        return;

        // if (res) {
        //     if (values.role && values.role.id) {
        //         await addRoles(res.id, [values.role.id])
        //     }

        //     this.props.onFinish(res.id)
        // }
    }

    

    validate = (values) => {
        const errors = {}
//         console.log('Errorrrr')
// console.log(values);
        if (!values.user || !values.user.firstName) {
            errors.user = errors.user || {};
            errors.user.firstName = 'نام خود را وارد نمایید'
        }

        if (!values.user || !values.user.lastName) {
            errors.user = errors.user || {};

            errors.user.lastName = 'نام خانوادگی را وارد نمایید'
        }

   

        if (!values.user || !values.user.gender) {
            errors.user = errors.user || {};

            errors.user.gender = 'جنسیت خود را وارد نمایید'
        }

        if (!values.natId) {
            errors.natId = 'کد ملی خود را وارد نمایید'
        }

        if (!values.cityId) {
            errors.cityId = 'محل فعالیت را وارد نمایید'
        }
     



        return errors
    }



    render() {
        if (this.state.hasProfile) {
            return <Redirect
                to={{
                    pathname: "/",
                    // state: { ...selectedIssue }
                }}
            />
        }

        const { onCancel } = this.props
        return <CreateForm
            onCancel={onCancel}
            ref={this.form}
            validate={this.validate}
            onSubmit={this.onSubmit} />
    }
}
