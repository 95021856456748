import React, { PureComponent } from 'react';
import { redirectToOidcLogin, signIn, signId, loggedIn } from '../../../../modules/Auth'
import Loader from '../../../../Component/Loader'
class Login extends PureComponent {
    constructor(props) {
        super(props)

        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.txtUsername = null
        this.txtPassword = null

        this.state = {
            showPassword: false,
            redirectToRegister: false,
            phoneEntered: false
        }
    }

    handleFormSubmit = async (pageName) => {

        this.setState({ submiting: true })
        const res = await signIn(this.txtUsername.value, this.txtPassword.value)

        if (res)
            this.props.history.push(pageName)
        else
            this.setState({ submiting: false })

    }

    btnClickHandle = async (e) => {
        e.preventDefault()
        if (loggedIn()) {
            this.props.history.push('./home');
        } else {

            const usernameRequired = this.txtUsername.value.length === 0,
                passRequired = this.txtPassword.value.length === 0;

            if (usernameRequired || passRequired) {
                this.setState({ usernameRequired, passRequired });

                return
            }

            const returnUrl = this.getReturnUrl()

            await this.handleFormSubmit(returnUrl)
        }
    }

    getReturnUrl = () => {
        var state = this.props.location.state;
        var url = state && state.from && state.from.pathname;
        return url || '/'
    }

    onRegisterClick = () => this.setState({ redirectToRegister: true })

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
        // setValues({ ...values, showPassword: !values.showPassword });
    };
    submitUsername = async () => {

        const hasPassword = await signId(this.txtUsername.value);
        if(hasPassword === null) {
            this.txtUsername.value = '';
            return;
        }
        this.setState({ phoneEntered: true, hasPassword });


        // setValues({ ...values, showPassword: !values.showPassword });
    };
    handleUsernameChanged = async (e) => {
        const value = e.target.value;
        const containsString = isNaN(value);
  

        if (containsString) {
            this.setState({ containsString: true });
            return;
        } else if (this.state.containsString) {
            this.setState({containsString:false});
        }
        
        if (!containsString && value.length === 11) {
            const hasPassword = await signId(this.txtUsername.value);
            if(hasPassword === null) {
                this.txtUsername.value = '';
                return;
            }
            this.setState({ phoneEntered: true, hasPassword });
        }

        // setValues({ ...values, showPassword: !values.showPassword });
    };



    render() {
        const from = this.getReturnUrl();
        const returnUrl = from;
        redirectToOidcLogin(returnUrl);
        return  <Loader/>
        
        // <div>redirecting to identity server to sign-in</div>;
    }


}

export default (Login);