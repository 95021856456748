
import { createApi } from '../utils/Ajax'
const { post, apiUrl : serverUrl   } = createApi('beshkan');

const uploadUrl = 'Media/Upload'
const imageUrl = 'Media/Image'

export const UPLOAD_URL = serverUrl + uploadUrl
export const IMAGE_URL = serverUrl + imageUrl

export const upload = async (formData, config) => await post(uploadUrl, formData, config)

export const getImageDownloadLink = (imageId) => {
    var imageUrl = serverUrl + `Media/Image/${imageId}`;
    return imageUrl;
}

export const getImageLink = (imageId, size) => {
    var imageUrl = serverUrl + `Media/Image/${imageId}`;
    if(size) {
        imageUrl+='?size='+size;
    }

    return imageUrl;
}

export const getThumbLink = (imageId, size) => {
    var imageUrl = serverUrl + `Media/Image/${imageId}?size=`+100;
    if(size) {
        imageUrl+='?size='+size;
    }

    return imageUrl;
}
