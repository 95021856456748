import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from '../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
// import {ListDialog as ImagesDialog} from "../../../../Admin/Image/List"
// import FileUpload from '../../../../../Component/FileUpload'
import FileInput from '../../../../../Component/FileInput'

// import { post, serverUrl } from '../utils/Ajax'

// const uploadUrl = 'Media/Upload'


export const JsonFile =  ({t=useTranslation('category').t}) =>{
return <>

<Grid sm={ 12 } xs={12}  item  >

          <FormControl fullWidth >

<Field
            label={t('jsonFile.title')}
            name="jsonFilePath,jsonFile"
        component={FileInput}

        accept=".json, application/json"
        // uploadUrl={uploadURL}
        // browseUrl={browseUrl}
        // token={token}
        // onCancel = {onCancel}
        // onUploadError = {onUploadError}
        type="file"
/>
   
</FormControl>
</Grid>
</>

}


export const validate = (values, errors)=> {
    return errors;
}





