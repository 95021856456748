import React from 'react'
import Grid from '@material-ui/core/Grid'
import {VerticalTabs, Tab} from '../../../../Component/VerticalTabs'
import { useTranslation } from 'react-i18next';
import { Box, FormGroup, Divider } from '@material-ui/core'

import { NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';
import { Sender, SenderView, validate as validateSender } from './Fields/Sender'
import { Receiver, ReceiverView, validate as validateReceiver } from './Fields/Receiver'
import { AmountGB, AmountGBView, validate as validateAmountGB } from './Fields/AmountGB'
import { Description, DescriptionView } from './Fields/Description'
import { SenderNewBalance, SenderNewBalanceView, validate as validateSenderNewBalance } from './Fields/SenderNewBalance'
import { TransactionTime, TransactionTimeView, validate as validateTransactionTime } from './Fields/TransactionTime'
import { ReceiverNewBalance, ReceiverNewBalanceView } from './Fields/ReceiverNewBalance'


export const validators={
    validateSender, validateReceiver, validateAmountGB, validateSenderNewBalance, validateTransactionTime, 
};


export const validate =(values, errors, t) => {

    for (const [name, func] of Object.entries(validators)) {
        func(values, errors, t);
      }

}
export const FormFields = (props) =>
{
    const { values } = props;
    const { t } = useTranslation('trafficTransaction');
    return <>
<Grid container spacing={2} style={{padding:25, paddingTop:0}}>

        <Sender />
        <Receiver />
        <AmountGB />
        <Description />
        <SenderNewBalance />
        <TransactionTime />
        <ReceiverNewBalance />
</Grid>
</>
}

export const EditableViewFields = (props) =>
{
    const {values, onRefresh} = props;
    const { t } = useTranslation('trafficTransaction');

return <>

    <SenderView {...props} />
    <ReceiverView {...props} />
    <AmountGBView {...props} />
    <DescriptionView {...props} />
    <SenderNewBalanceView {...props} />
    <TransactionTimeView {...props} />
    <ReceiverNewBalanceView {...props} />

</>

}

