import React from 'react'
import Grid from '@material-ui/core/Grid'
// import { useTranslation } from 'react-i18next';
// import { Province, validate as validateProvince } from './Fields/Province'
import { JsonFile, validate as validateJsonFile } from './Fields/JsonFile'



export const validators={
    validateJsonFile 
};

export const validate = async (values, errors, t, warnings) => {

    for (const [, func] of Object.entries(validators)) {
        await func(values, errors, t, warnings);
      }

}

export const FormFields = (props) => {
    
    const {values} = props;
    
    console.log(values)
    return (
    <Grid container spacing={2} style={{padding:25, paddingTop:0}}>
        {/* <Province /> */}
        <JsonFile />
        <ErrorsView {...props} />

    </Grid>
    )
}


const ErrorsView = ({errors=[]}) =>
{
return <>
    {errors.length&& <div>
        <div style={{color:'red'}}> خطا، لطفا موارد زیر را رفع کنید و دوباره تلاش کنید :</div> <br/> {
        errors.map(e=> <div> {'*'} {e}</div>)
        }
    {/* <IdView {...props} /> */}
    </div>
    }
</>
}

