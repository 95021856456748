import React from 'react'
import Grid from '@material-ui/core/Grid'
import {VerticalTabs, Tab} from '../../../../Component/VerticalTabs'
import { useTranslation } from 'react-i18next';
import { Box, FormGroup, Divider } from '@material-ui/core'

import { NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';
import { Name, NameView, validate as validateName } from './Fields/Name'


export const validators={
    validateName 
};


export const validate =(values, errors, t) => {

    for (const [name, func] of Object.entries(validators)) {
        func(values, errors, t);
      }

}
export const FormFields = (props) =>
{
    const { values } = props;
    const { t } = useTranslation('serverProvider');
    return <>
<Grid container spacing={2} style={{padding:25, paddingTop:0}}>

        <Name />
</Grid>
</>
}

export const EditableViewFields = (props) =>
{
    const {values, onRefresh} = props;
    const { t } = useTranslation('serverProvider');

return <>

    <NameView {...props} />

</>

}

