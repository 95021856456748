import React, { PureComponent } from 'react'
import {PulseLoader} from 'react-spinners'

class Loader extends PureComponent {
  render() {
    return (
<div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '50vh'  // adjust the height as per your requirements
          }}>
        <PulseLoader loading={true} size={20} color="#7336d6" />
          </div>    )
  }
}

export default Loader
