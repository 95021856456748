import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateCity } from '../../api'
import {ListDialog as ProvincesDialog} from "../../../../Admin/Province/List"
import Selector from '../../../../../Component/Selector'


export const Province =  ({values={ province:{} },t=useTranslation('city').t}) =>{
return <>

<Grid sm={ 12 } xs={12}  item  >
    <FormControl fullWidth >
        <Field
            component={Selector}
            label={t('province.title')}
            name="provinceId,province"
            options = { ProvincesDialog}
            optionTitle= "name"
        />
    </FormControl>
</Grid>
</>

}


export const validate = (values, errors, t)=> {
    if (!values.provinceId) {
        errors.provinceId = t('province.required');
    }
    return errors;
}


class View extends React.Component {
    
    update = async (values) =>{
        const {id,provinceId}=values;
        return await updateCity({id, provinceId});
    }

    headline(values){
        const { province = {} } = values;
        const headline = province?(province.name):'-';
        return headline;
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
 
        return <ViewField
            successMessage={this.successMessage}
            title= { t('province.title')}
            headline  = { this.headline(values) }
            update ={this.update} 
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            editComponent={ Province } />
        
    }
}


export const ProvinceView = withTranslation('city')(View);