import i18n from 'i18next';
// import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import fa from './locales/fa';
import en from './locales/en';
import ru from './locales/ru';
import vi from './locales/vi';
import cn from './locales/cn';
import ko from './locales/ko';


i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
//   .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
//   .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // resGetPath: '/locales/__lng__/__ns__.json',
    ns: ['common'],
    defaultNs:'common',
    fallbackLng: 'en',
    lng: localStorage.getItem('language') || 'en',
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en,
      ru,
      vi,
      cn,
      ko,
      fa
    }
  }, (err, t) => {
    console.log('err')
    console.log(err)
    // i18next.t('myKey'); // key in moduleA namespace (defined default)
    // i18next.t('common:myKey'); // key in common namespace
  });

  // i18next.loadNamespaces('anotherNamespace', (err, t) => { /* ... */ });
export const t =i18n.t.bind(i18n);
export default i18n;