import React, { PureComponent } from 'react'
import { ViewForm as ActiveBundleForm } from '../Form/ActiveBundleForm'
import DefaultEditPage from './DefaultEditPage'

export default class ByRecurrentEditPage extends PureComponent {
    // recurrent = this.props.recurrentDonation||{};
    // defaultValues={
    //     donationSubjectId:this.recurrent.donationSubjectId,
    //     benefactorId:this.recurrent.benefactorId,
    //     occasionId:this.recurrent.occasionId,
    //     recurrentId:this.recurrent.id,
    //     price:this.recurrent.price,
    //     value:this.recurrent.value,
    //     unitName:this.recurrent.unitName,
    //     typeName:this.recurrent.typeName,
    //     qty:this.recurrent.qty,
    //     extraFieldValue:this.recurrent.extraFieldValue
        
    // }
    // defaultExValues={
    //     ...this.recurrent
    // }
    render() {
        return <DefaultEditPage 
                onOk={this.props.onOk}
                form = {ActiveBundleForm}
                onClose = {this.props.onClose}
                id={this.props.id}
                // defaultValues ={this.defaultValues}
                // defaultExValues = {this.defaultExValues}
        />
    }
}

