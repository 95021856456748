import React, { PureComponent } from 'react'
import widthForm from '../../../../Contexts/withForm';
import { withTranslation } from 'react-i18next';
import {Form as DefaultCreateForm} from '../Form';
import FormActionBar from '../../../../Component/FormActionBar';
import RouterBreadcrumbs from '../../../../Component/RouterBreadcrumbs'

class CreateForm extends PureComponent{
    render() {
        const {values, t, form, formComponent, classes } = this.props;
        const FormComponent = formComponent || DefaultCreateForm;
        return <form autocomplete="off">
    <RouterBreadcrumbs title={ t("new")  } />
    <FormComponent values={values}  t={t} form={form}  />
    <FormActionBar {...this.props} submitLabel={t('register')} />
</form>
    }
}


export default withTranslation('bundle')(widthForm(CreateForm));