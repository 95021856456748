import React from 'react'
import {Switch, FormControlLabel} from "@material-ui/core";
import FormHelperText  from '@material-ui/core/FormHelperText';


export default ({
  input: { value, name, onChange, ...restInput },
  meta,
  hint,
  ...rest
}) => 
<>
  <FormControlLabel
  style={meta.error && meta.touched && {color:'red'}}
        control={
          <Switch 
            // checked={state.checkedA} 
            // onChange={handleChange('checkedA')} 
            // value={value} 

            defaultChecked={!!value}
            {...rest}
            name={name}
            helperText={meta.touched ? meta.error : undefined}
            error={meta.error && meta.touched}
            inputProps={restInput}
            onChange={(e)=>{onChange(e)}}
            checked={!!value}

            type="checkbox"
            />}
            label={rest.label}
    />
    {((meta.touched&&meta.error) || hint)&&
      <FormHelperText error={meta.error && meta.touched}>{(meta.touched && meta.error) || hint}</FormHelperText>
      }
   
  </>

