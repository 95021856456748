import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import FilterableGrid from './Component/FilterableGrid';
import './i18n';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import { create } from 'jss';
import rtl from 'jss-rtl';

// import {JssProvider} from 'react-jss';
// import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { jssPreset, StylesProvider,createGenerateClassName } from '@material-ui/styles';

import './index.css';
// import TestComponent from './TestComponent';
// import TestRoute from './TestRoute';
// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

// Custom Material-UI class name generator.
const generateClassName = createGenerateClassName()

// const sheetsManager = new Map();

ReactDOM.render(<StylesProvider generateClassName={generateClassName} jss={jss} /*flip*/ >
    {/* <TestComponent /> */}
                {/* <App></App> */}

    <React.Fragment>
        <CssBaseline />
        {/* <TestComponent /> */}
        <App></App>
        {/* <TestRoute /> */}
    </React.Fragment>
</StylesProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
