import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateUser } from '../../api'
import TextInput from '../../../../../Component/TextInput'

export const Postcode =  ({t=useTranslation('user').t}) =>
<>
<Grid item xs={12}>
    <FormControl fullWidth>
        <Field label={t('postcode.title')}
               name={"postcode"}
               component={TextInput}
               type="text" />
    </FormControl>
</Grid>
</>

export const validate = (values, errors, t)=> {
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, postcode }=values;
        return await updateUser({id, postcode });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { postcode } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('postcode.title')}
                          headline={ postcode }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ Postcode } />
    }
}


export const PostcodeView = withTranslation('user')(View);