import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('beshkan');

export const insertResellerSetting = async (resellerSetting) => {

    const resultEntity = await post(`resellerSetting`,resellerSetting,
    {
        errorMessage : t('common:insertError') + t('resellerSetting:resellerSetting')
    });

    if(!resultEntity) return null;

    return {...resellerSetting, id:resultEntity.id};
}


export const updateResellerSetting = async (resellerSetting) => {

    const result = await putFormData(`resellerSetting/${ resellerSetting.id }`,
        resellerSetting,
        {
            errorMessage :  t('common:updateError') + t('resellerSetting:resellerSetting')
        });

    return result;
}

export const deleteResellerSetting = async (id) => {

    const result = await del(`resellerSetting/${id}`,
        {
            errorMessage : t('common:deleteError') + t('resellerSetting:resellerSetting')
        });

    return result;
}

export const getResellerSettings = async (params) => {

    const result = await get(`resellerSetting`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('resellerSetting:resellerSettings')

    });

    return result;
}



export const getResellerSetting = async (id) => {

    const result = await get(`resellerSetting/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('resellerSetting:resellerSetting')
    });

    return result;
}



