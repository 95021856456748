import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateFile } from '../../api'
import {ListDialog as MimeTypesDialog} from "../../../../Admin/MimeType/List"
import Selector from '../../../../../Component/Selector'


export const MimeType =  ({values={ mimeType:{} },t=useTranslation('file').t}) =>{
return <>

<Grid sm={ 12 } xs={12}  item  >
    <FormControl fullWidth >
        <Field
            component={Selector}
            label={t('mimeType.title')}
            name="mimeTypeId,mimeType"
            options = { MimeTypesDialog}
            optionTitle= "name"
        />
    </FormControl>
</Grid>
</>

}


export const validate = (values, errors, t)=> {
    if (!values.mimeTypeId) {
        errors.mimeTypeId = t('mimeType.required');
    }
    return errors;
}


class View extends React.Component {
    
    update = async (values) =>{
        const {id,mimeTypeId}=values;
        return await updateFile({id, mimeTypeId});
    }

    headline(values){
        const { mimeType = {} } = values;
        const headline = mimeType?(mimeType.name):'-';
        return headline;
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
 
        return <ViewField
            successMessage={this.successMessage}
            title= { t('mimeType.title')}
            headline  = { this.headline(values) }
            update ={this.update} 
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            editComponent={ MimeType } />
        
    }
}


export const MimeTypeView = withTranslation('file')(View);