import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('beshkan');

export const insertRegion = async (region) => {

    const resultEntity = await post(`region`,region,
    {
        errorMessage : t('common:insertError') + t('region:region')
    });

    if(!resultEntity) return null;

    return {...region, id:resultEntity.id};
}


export const updateRegion = async (region) => {

    const result = await putFormData(`region/${ region.id }`,
        region,
        {
            errorMessage :  t('common:updateError') + t('region:region')
        });

    return result;
}

export const deleteRegion = async (id) => {

    const result = await del(`region/${id}`,
        {
            errorMessage : t('common:deleteError') + t('region:region')
        });

    return result;
}

export const getRegions = async (params) => {

    const result = await get(`region`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('region:regions')

    });

    return result;
}



export const getRegion = async (id) => {

    const result = await get(`region/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('region:region')
    });

    return result;
}



