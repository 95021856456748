import React, { PureComponent } from 'react'
import {validate as validateIsland } from '../Form'
import CreateForm from './CreateForm';
import { insertIsland } from '../api'
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';

 class CreatePage extends PureComponent {
    defaultValues = { 
        enabled:true
        // roles: [{
        //     "id": -4,
        //     "name": "Customer",
        //     "displayName": "تست"
        // }],
        // provinceId: getProfile().provinceId,
        // province: getProfile().province,
    }
    onSubmit = async (values) => {
        const { t, enqueueSnackbar  } = this.props;
        var res = await insertIsland(values)
        if(!res) {
            return false;
        }
        
        this.props.history.goBack();

        if (res) {
            enqueueSnackbar( t('saved'), { variant:'success' });
        }
    }

    validate = ( values ) => {
        const {t}= this.props;
        const errors = {}
        const warnings = {}

        validateIsland(values, errors, t, warnings);
        this.forceUpdate();

        return errors
    }

    render() {
        const { onCancel, t } = this.props
        return <>
            <CreateForm submitLabel={t('register')}
                onCancel={onCancel}
                ref={this.form}
                defaultValues={this.defaultValues}
                validate={this.validate}
                //validateField = {this.validateField}
                onSubmit={this.onSubmit} />
        </>
    }
}

export default withSnackbar(withTranslation('island')(CreatePage));