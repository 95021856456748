import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateBundle } from '../../api'
import {hasValue} from '../../../../../utils/string'
import {durationUnits,durationValues, fromHours, toHours, toText} from '../../../../../domain/subscriptionPeroid'
import Selector from '../../../../../Component/Selector'

import TextInput from '../../../../../Component/TextInput'

export const DurationHours =  () => {
    const { t } = useTranslation('bundle');

    return <>
    {/* <Grid item sm={ 12 } xs={12}>
        <FormControl fullWidth>
            <Field label={t('durationHours.title')}
                   name={"durationHours"}
                   component={TextInput}
                   number
                   />
        </FormControl>
    </Grid> */}

<Grid item sm={6} xs={6}>
          <Field
            component={Selector}
            label={t("subscriptionDuration")}
            name="durationValue"
            adornment="ماه"
            options={durationValues}
            // keyName="id"
            optionTitle="displayName"
          />
        </Grid>
        <Grid item sm={6} xs={6}>
          <Field
            component={Selector}
            label={" "}
            name="durationUnit"
            // adornment=""
            options={durationUnits}
            // keyName="id"
            optionTitle="displayName"
          />
        </Grid>

    </>
}
export const validate = (values, errors, t)=> {
    if (!hasValue(values.durationUnit)) {
        errors.durationHours = t('durationHours.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        values.durationHours = Math.round(toHours(values.durationValue, values.durationUnit));

        const {id, durationHours }=values;

        return await updateBundle({id, durationHours });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh,readonly, t } = this.props;
        const { durationHours } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('durationHours.title')}
                          headline={ toText(durationHours) }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          readonly = {readonly}
                          editComponent={ DurationHours } />
    }
}


export const DurationHoursView = withTranslation('bundle')(View);