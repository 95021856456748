import React from 'react'
import Grid from '@material-ui/core/Grid'
import {VerticalTabs, Tab} from '../../../../Component/VerticalTabs'
import { useTranslation } from 'react-i18next';
import { Box, FormGroup, Divider } from '@material-ui/core'

import { NavigateBefore as NavigateBeforeIcon } from '@material-ui/icons';
import { Name, NameView, validate as validateName } from './Fields/Name'
import { Traffic, TrafficView, validate as validateTraffic } from './Fields/Traffic'
import { Duration, DurationView, validate as validateDuration } from './Fields/Duration'
import { IsResellerPackage, IsResellerPackageView, validate as validateIsResellerPackage } from './Fields/IsResellerPackage'
import { Enable, EnableView, validate as validateEnable } from './Fields/Enable'


export const validators={
    validateName, validateTraffic, validateDuration, validateIsResellerPackage, validateEnable 
};


export const validate =(values, errors, t) => {

    for (const [name, func] of Object.entries(validators)) {
        func(values, errors, t);
      }

}
export const FormFields = (props) =>
{
    const { values } = props;
    const { t } = useTranslation('availableBundle');
    return <>
<Grid container spacing={2} style={{padding:25, paddingTop:0}}>

        <Name />
        <Traffic />
        <Duration />
        <IsResellerPackage />
        <Enable />
</Grid>
</>
}

export const EditableViewFields = (props) =>
{
    const {values, onRefresh} = props;
    const { t } = useTranslation('availableBundle');

return <>

    <NameView {...props} />
    <TrafficView {...props} />
    <DurationView {...props} />
    <IsResellerPackageView {...props} />
    <EnableView {...props} />

</>

}

