import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import TextField from "@material-ui/core/TextField";
import SortDirectionIcon from '@material-ui/icons/ArrowDownward';
import { debounce } from "throttle-debounce";
import { Box } from "@material-ui/core";
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';


const HeaderControl = ({ className, label, items, onChange }) => {
  const [value, setValue] = React.useState('');
  const theme = useTheme();
  const minWidth = value?'':label.length * 6.7 + 40; // 7 is approximate width per character, 40 is extra padding
  const byPath = (obj, path) => {
    try {
      return path.split('.').reduce((o, key) => o[key], obj);
    } catch {
      return null;
    }
  };
  const handleChange = (event) => {
    setValue(event.target.value);
    onChange(event);
  };
  const colorCode = (color) =>{
    return byPath(theme.palette, color) || '';

  }
  return (
    <FormControl className={className} fullWidth >

      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
      
        style={{minWidth:minWidth, marginBottom:-7}}
        labelId={`${label}-label`}
        id={`${label}-select`}
        value={value}
        onChange={handleChange}
        label={label}
        sx={{ '& fieldset': { border: 'none' } }} // Remove border

      >
        {items.map((item) => (
          <MenuItem key={item.id} value={item.systemName} style={{ color: colorCode(item.color) || 'inherit' }}>
            {item.displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
class EnhancedTableHead extends React.PureComponent {
  constructor(props) {
    super(props);
    // this.requestFilterThrottled = throttle(500, props.onRequestFilter);
    this.requestFilterDebounced = debounce(500, props.onRequestFilter);
  }
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };
  createFilterHandler = property => event => {
    var value = event.target.value;
    this.requestFilterDebounced(value, property);
  };
  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      header,
      displayFilterIcon
    } = this.props;

    return (

      <TableHead>
        <TableRow>
          {this.props.showCheckbox && <TableCell padding="checkbox">
            {!parseInt(this.props.selectable) &&
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            }
          </TableCell>}
          {header.map(headerColumn => {
            return (
              <TableCell
                key={headerColumn.name}
                // numeric={true}
                padding={true ? "none" : "default"}
                sortDirection={orderBy === headerColumn.name ? order : false}
              >
                {/* <Tooltip
                  title="Sort"
                  placement={true ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                > */}

                  <div className={this.props.classes.filterControl}  >
                    {headerColumn.title&&headerColumn.sortable!==false &&
                      <TableSortLabel
                        className={this.props.classes.sortLabel}
                        active={orderBy === headerColumn.name}
                        direction={order}
                        style={{ whiteSpace: "nowrap" }}
                        onClick={this.createSortHandler(headerColumn.name)}
                      >
                        <div className='ctrl' onClick={this.createSortHandler(headerColumn.name)} style={{
                          display: orderBy === headerColumn.name ? "none" : "",
                          bottom: "33px",
                        }}><SortDirectionIcon style={{ transform: "scale(0.7)" }} />
                        </div>
                        {displayFilterIcon&& <div className='ctrl' onClick={(e) => { e.stopPropagation() }} style={{
                          //  display: orderBy === row.id?"none":"",
                          bottom: "50px",
                        }}><FilterListIcon style={{ transform: "scale(0.7)" }} />
                        </div>}
                      </TableSortLabel>
                    }
                    {headerColumn.items?
                     <HeaderControl 
                          label={headerColumn.title}
                          className = {this.props.classes.headerInput}
                          items={headerColumn.items}
                          onChange={this.createFilterHandler(headerColumn.name)}
                          />
                      :
                    headerColumn.title&&
                      <TextField
                        
                        label={headerColumn.title}
                        className = {this.props.classes.headerInput}
                        margin="normal"
                        onChange={this.createFilterHandler(headerColumn.name)}
                        InputLabelProps={{
                          className: this.props.classes.headerInputLabel,
                          align: 'left',
                          // onClick:this.createSortHandler(row.id)
                        }}
                         InputProps={{
                          readOnly: headerColumn.searchable===false
                        }}
                        // readOnly={true}
                      />}
                    

                  </div>
                {/* </Tooltip> */}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};


export default EnhancedTableHead;
