import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateUser } from '../../api'
import Switch from '../../../../../Component/Switch'

export const HasPassword =  ({t=useTranslation('user').t}) =>
<>
<Grid item sm={ 12 } xs={12}>
    <FormControl fullWidth>
        <Field label={t('hasPassword.title')}
               name={"hasPassword"}
               component={Switch}
               type="text" />
    </FormControl>
</Grid>
</>

export const validate = (values, errors, t)=> {
    if ((values.hasPassword !== true && values.hasPassword !==false)) {
        errors.hasPassword = t('hasPassword.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, hasPassword }=values;
        return await updateUser({id, hasPassword });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { hasPassword } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('hasPassword.title')}
                          headline={ hasPassword?t('common:yes'):t('common:no') }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ HasPassword }
                          />

    }
}


export const HasPasswordView = withTranslation('user')(View);
