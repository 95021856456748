import { createApi } from '../../../utils/Ajax'
import {t} from '../../../i18n';
const { post, del, get, putFormData } = createApi('beshkan');

export const insertConnectionConfig = async (connectionConfig) => {

    const resultEntity = await post(`connectionConfig`,connectionConfig,
    {
        errorMessage : t('common:insertError') + t('connectionConfig:connectionConfig')
    });

    if(!resultEntity) return null;

    return {...connectionConfig, id:resultEntity.id};
}


export const updateConnectionConfig = async (connectionConfig) => {

    const result = await putFormData(`connectionConfig/${ connectionConfig.id }`,
        connectionConfig,
        {
            errorMessage :  t('common:updateError') + t('connectionConfig:connectionConfig')
        });

    return result;
}

export const deleteConnectionConfig = async (id) => {

    const result = await del(`connectionConfig/${id}`,
        {
            errorMessage : t('common:deleteError') + t('connectionConfig:connectionConfig')
        });

    return result;
}

export const getConnectionConfigs = async (params) => {

    const result = await get(`connectionConfig`,
    { ...params }
    ,
    {
        errorMessage : t('common:fetchError') + t('connectionConfig:connectionConfigs')

    });

    return result;
}



export const getConnectionConfig = async (id) => {

    const result = await get(`connectionConfig/`+id,
    {},
    {
        errorMessage : t('common:fetchError') + t('connectionConfig:connectionConfig')
    });

    return result;
}



