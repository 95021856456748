import React, { } from 'react'
import { Grid, FormControl} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateFile } from '../../api'
import Switch from '../../../../../Component/Switch'

export const IsTransient =  ({t=useTranslation('file').t}) =>
<>
<Grid item sm={ 12 } xs={12}>
    <FormControl fullWidth>
        <Field label={t('isTransient.title')}
               name={"isTransient"}
               component={Switch}
               type="text" />
    </FormControl>
</Grid>
</>

export const validate = (values, errors, t)=> {
    if (values.isTransient !== true && values.isTransient !==false) {
        errors.isTransient = t('isTransient.required');
    }
    return errors;
}


class View extends React.Component {

    update = async (values) =>{
        const {id, isTransient }=values;
        return await updateFile({id, isTransient });
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
        const { isTransient } = values;

        return <ViewField successMessage={this.successMessage}
                          title={ t('isTransient.title')}
                          headline={ isTransient?t('common:yes'):t('common:no') }
                          update={this.update}
                          validate={validate}
                          values={values}
                          onRefresh={onRefresh}
                          editComponent={ IsTransient }
                          />

    }
}


export const IsTransientView = withTranslation('file')(View);
