import React, { PureComponent } from 'react';
import { signByOtp } from '../../../../modules/Auth'
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid'
import Logo from '../../../../Component/Logo';
import {Redirect} from "react-router-dom"
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import FormHelperText from '@material-ui/core/FormHelperText';
import {numToFa} from '../../../../utils/persianUtils';

class Otp extends PureComponent {
    constructor(props) {
        super(props)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.txtUsername = null
        this.txtPassword = null

        this.state = {
            showPassword: false,
            redirectToRegister:false,
            phoneEntered : false
        }
    }

    handleFormSubmit = async (pageName) => {
        this.setState({ submiting: true })
        const res = await signByOtp(this.getPhoneNumber(), this.txtPassword.value)
        let errorMessage="";

        if(res.retryIsOver){
            console.log('res.wait');

            console.log(res.wait);
            
            errorMessage = 'دوباره تلاش کنید';
            if((res.wait+1) >0) 
                errorMessage = 'لطفا پس از گذشت ' + (res.wait+1) + ' دقیقه دوباره تلاش کنید';
            else {
                this.setState({ redirectToSign:true })
            }

            this.setState({ submiting: true, passRequired:true, errorMessage })
            this.txtPassword.value = '';
            setTimeout(()=>{
                this.setState({ redirectToSign:true })
            }, 5000);

            return;
        }
        
        if(res.incorrectPassword){

            errorMessage = 'رمز اشتباه است';
            this.setState({ passRequired:true, submiting: false, errorMessage })
            this.txtPassword.value = '';

            return;
        }

        if(res.token) {
            this.props.history.push(pageName);

        } else {
            this.setState({ submiting: false }); // retry
            this.txtPassword.value = '';
            return;
        }


        

            // if(hasPassword === null) {
            //     this.txtUsername.value = '';
            //     return;
            // }
            // this.setState({ phoneEntered: true, hasPassword });

    }

    

    getReturnUrl = () => {
        var state = this.props.location.state;
        var url = state && state.from && state.from.pathname;
        return url || '/'
    }
    onRegisterClick = () => this.setState({redirectToRegister:true})

    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword});

        // setValues({ ...values, showPassword: !values.showPassword });
      };
    handleUsernameChanged = (e) => {
        if(e.target.value.length === 11)
            this.setState({phoneEntered: true});

        // setValues({ ...values, showPassword: !values.showPassword });
      };
      

    getPhoneNumberFa() {
        var state = this.props.location.state;
        return state ? numToFa(state.phoneNumber) : '';
    }

    getPhoneNumber() {
        var state = this.props.location.state;
        return state ? state.phoneNumber : '';
    }

    handlePasswordChanged = async (e) => {
        if (e.target.value.length === 6) {


            const returnUrl = this.getReturnUrl()

            await this.handleFormSubmit(returnUrl)


          
        }

        // setValues({ ...values, showPassword: !values.showPassword });
    };

    render() {
        const { errorMessage, submiting, redirectToSign } = this.state
        const phoneNumber = this.getPhoneNumberFa();

        
        console.log('STATES AND PROPS')
        console.log(this.state)
        console.log(this.props)

        if (redirectToSign) {
            console.log('pat from otp : ');
            console.log(this.props.location.pathname)
            return <Redirect
              to={{
                pathname: "sign/login",
                state: { from: this.getReturnUrl() }
              }}
            />
        }
        return (
            <Slide direction="left" in={true} mountOnEnter unmountOnExit> 

                <Grid container spacing={0} direction="row" alignContent='center' justify="space-around" align="center" style={{ maxWidth: '100%' }}>

<Grid  style={{ padding: '40px', marginTop: '50px' }}  xs={12} /*xs={12} sm={4}*/>
    <Paper alignContent='center' align="center" style={{padding:50, paddingTop: 35, maxWidth:400 }}>

    <Logo style={{ width: '120px' }} />
                        <Typography align="center" alignContent='center'
                            style={{
                                marginBottom: '20px',
                                marginTop: "-15px"
                            }}
                            variant="h6" color="inherit" noWrap>
                            خوش آمدید
                        </Typography>
                        <Typography align="center" alignContent='center'
                            style={{
                                marginBottom: '50px',
                                marginTop: "0px"
                            }}
                            variant="body1" color="inherit">
                            لطفا رمز ارسال شده به شماره <b>{phoneNumber}</b> را در جعبه زیر وارد نمایید
                            
                              
                        </Typography>

<FormControl textAlign="right" align="right" style={{width:220}}>

<TextField
                            error={this.state.passRequired}
                            disabled={submiting}
                            id="password"
                            // className={clsx(classes.margin, classes.textField)}
                            variant="outlined"
                            autoComplete='new-password'
                            type={this.state.showPassword ? 'text' : 'password'}
                            label="رمز"
                            // value={values.password}
                            inputRef={el => this.txtPassword = el}
                            aria-describedby="component-error-text"
                            onChange={this.handlePasswordChanged}
                            // inputProps={{ style: { textAlign:'center'}}}
                            InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    aria-label="Toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                >
                                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                </InputAdornment>
                            ),
                            }}
                        />
                        {errorMessage&&
                                  <FormHelperText color="red" style={{color:'red'}} id="component-error-text">{errorMessage}</FormHelperText>
                        }
                                                                    </FormControl>
         

</Paper>
                    </Grid>
                    {/* <Hidden xsDown>
                        <Grid item xs={8}>
                            <img
                                src='/content/images/loginBg.jpg'
                                alt='login'
                                className="backCover"
                            />
                        </Grid>
                    </Hidden> */}
                </Grid>
</Slide>
        );
    }


}

export default (Otp);