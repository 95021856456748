import React, { PureComponent } from 'react'
import { Route, Switch } from "react-router-dom";
import EditProfile from './Profile/Edit'
import Home from './Home'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LanguageIcon from '@material-ui/icons/Language';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import MailIcon from '@material-ui/icons/Mail';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Collapse from '@material-ui/core/Collapse';
import HandIcon from '@material-ui/icons/EmojiPeople';


const serviceItems = [{
  title: "درخواست سرور اختصاصی",
  link: "http://google.com/"
},{
  title: "شرکت در وبینار",
  link: "http://google.com/"
},
{
  title: "ترفه ی ویژه",
  link: "http://google.com/"
},
{
  title: "درخواست نمایندگی",
  link: "http://google.com/"
},{
  title: "وب‌سایت گپ",
  link: "http://google.com/"
}]
  ;

const drawerWidth = 300;
const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  root: {
    display: 'flex',
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    //marginTop:24,
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    //marginTop:24,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 7 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    //marginTop:24,
    //marginRight:5
    padding: theme.spacing.unit * 3,
  },
});

class ManagePanel extends PureComponent {

  state = {
    left: true,
    openSettingMenu: false,
    openServicesMenu: false,
  }

  gotoPage = (path) => () => {
    const { match, history } = this.props
    history.push(`${match.path}/${path}`);
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
      isSmall: false

    })
  }

  toggleMenu = (name) => () => {
    this.setState({
      [name]: !this.state[name],
      isSmall: false
    })
  }
  handleServicesMenuItemClick = () => {
    this.setState({
      openServicesMenu: !this.state.openServicesMenu
    })
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    let isSmall = (window.innerWidth <= 760);
    if(this.state.isSmall !== isSmall)  
      this.setState({isSmall:isSmall});
  }


  render() {
    const { match, classes, theme } = this.props
    const { openServicesMenu, isSmall } = this.state
    let {left} = this.state;
    const { gotoPage, toggleDrawer } = this
    left = left && !isSmall;
    return (
      <div className={classes.root}>
        <CssBaseline />

        <AppBar position="fixed" className={classNames(classes.appBar, {
          [classes.appBarShift]: left,
        })}>
          <Toolbar disableGutters={!left}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={toggleDrawer('left', true)}
              className={classNames(classes.menuButton, {
                [classes.hide]: left,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              پنل نماینده ی من
            </Typography>

          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: left,
            [classes.drawerClose]: !left,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: left,
              [classes.drawerClose]: !left,
            }),
          }}
          open={left}
          onClose={toggleDrawer('left', false)}
        >
          <div className={classes.toolbar}>

            <IconButton onClick={toggleDrawer('left', false)}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>

          </div>
          <Divider />
          <List >



<ListItem button selected key={'خانه'} onClick={gotoPage('home')}>
              <ListItemIcon><AccountCircleIcon /></ListItemIcon>
              <ListItemText
                className={classNames({
                  [classes.hide]: !left,
                })}
                primary={'خانه'} />

            </ListItem>
<ListItem button  key={'کمک های من'} onClick={gotoPage('donations')}>
              <ListItemIcon><CardGiftcardIcon /></ListItemIcon>
              <ListItemText
                className={classNames({
                  [classes.hide]: !left,
                })}
                primary={'کمک های من'} />

            </ListItem>
            <ListItem button  key={' سلامت مددجوبان من'} onClick={gotoPage('donations')}>
              <ListItemIcon><HandIcon /></ListItemIcon>
              <ListItemText
                className={classNames({
                  [classes.hide]: !left,
                })}
                primary={'مددجوبان من'} />

            </ListItem>
            <ListItem button key={'نامه‌های من'} onClick={gotoPage('inbox')}>
              <ListItemIcon><MailIcon /></ListItemIcon>
              <ListItemText
                className={classNames({
                  [classes.hide]: !left,
                })}
                primary={'نامه‌های من'} />

            </ListItem>
   
            <Divider />
            <ListItem button onClick={this.handleServicesMenuItemClick}>
              <ListItemIcon>
                <LanguageIcon />
              </ListItemIcon>
              <ListItemText primary="سایر خدمات" />
              {openServicesMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openServicesMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {serviceItems.map(item=><ListItem button className={classes.nested} onClick={() => window.location.href =item.link }>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText secondary={item.title} />
                </ListItem>)}
                
              </List>
            </Collapse>
            <Divider />
            <ListItem button key={'پایگاه دانش'} onClick={() => alert('بزودی اطلاعات پایگاه دانش در دسترس شما عزیزان قرار خواهد گرفت') }>
              <ListItemIcon><LocalLibraryIcon /></ListItemIcon>
              <ListItemText
                primary={'پایگاه دانش'} />

            </ListItem>
            {/* <Collapse in={openSettingMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={gotoPage('setting/homePage')}>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText inset primary="صفحه اول" />
                </ListItem>
              </List>
            </Collapse> */}


          </List>
        </Drawer>

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch >
            <Route path={`${match.path}/profile`} component={EditProfile} />
            <Route path={`${match.path}/`} component={Home} />

     
          </Switch>
        </main>
      </div>
    )
  }
}
export default withStyles(styles, { withTheme: true })(ManagePanel);
