
import React from 'react'
import { Grid, Box} from '@material-ui/core'
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import FieldEditDialog from './FieldEditDialog'
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
const useStyles = makeStyles(() => createStyles({
  root: {
    padding: 10,
    paddingLeft: 50,
    borderBottom: '1px solid #dedede',
    
    "&:hover": {
        backgroundColor: '#00000010',
    }
    
  },
  title : {
    padding: 0,
    margin: 0,
    paddingRight: 12,
    fontSize: '100%',
    color: 'gray'
  },
  content:{
    padding: 0,
    paddingRight: 12
  },
  contentBody :{
    padding:2, 
    color:'#5f6368', 
    fontSize:'.875rem'
  },
  contentHeadline:{
    padding:2
  },
  arrowIcon:{
    color: 'rgb(95, 99, 104)',
    fontSize: '1.0rem'
  }
}));

const ViewFieldTemplate =({readonly, showTitle, title, headline, body, onClick}) =>{
    const classes  = useStyles();
    const {direction} = useTheme();
    const IconBasedOnLanguage = direction === 'rtl' ? ArrowBackIosIcon : ArrowForwardIosIcon;
  
    return <Grid onClick={onClick} className = {classes.root} style={{cursor:(!readonly)?'pointer':'default'}} container alignItems="center">
       {showTitle!==false && <Grid className={classes.title} item sm={5} xs={12} alignItems="center" >
            {title}
        </Grid>}
        <Grid className={classes.content} item sm={showTitle!==false?7:12} xs={12}  >
            <Box display="flex"  justifyContent="space-between" alignItems="center" >
               
                <Box>
                    {headline&& <Box className={classes.contentHeadline}>{headline}</Box>}
                    {body&& <Box  className={classes.contentBody} >{body}</Box>}
                </Box>
                {!readonly && <IconBasedOnLanguage className={classes.arrowIcon}/>}
            </Box>
        </Grid>
        </Grid>
} 





 class ViewField extends React.PureComponent{
  state={
      editing:false
  }

  validate=(values, errors)=>this.props.validate(values,errors||{},this.props.t)
  validateField=(name, values, errors)=>this.props.validate(values,errors||{},this.props.t)

  update = async (vals) =>
  {
      const {enqueueSnackbar, update, successMessage, onRefresh}=this.props;
      // const {id,firstName, lastName}=vals;
      // var res = await updateUser({id, firstName, lastName});
      var res= await update(vals);
      if (res) {
          enqueueSnackbar(successMessage(vals), { variant:'success' });
      }
      this.cancelEdit()
      onRefresh();
  }
  showEdit = ()=> this.setState({editing:true});
  cancelEdit = ()=> this.setState({editing:false});

render() {
  const {readonly, title,showTitle, changeTitle,headline,body, values, editComponent, t} = this.props;
  const {editing} = this.state;
  // const [editing, setEditing] = useState(false)
  // const {enqueueSnackbar  } = useSnackbar();
  return <>
  <ViewFieldTemplate 
      title={title}
      showTitle={showTitle}
      headline={headline}
      body={body}
      readonly={readonly}
      onClick={!readonly && this.showEdit}
  />
  {editing&&

     <FieldEditDialog 
          title={`${changeTitle||t('change')} ${title}`}
          values={values}
          validate={this.validate}
          validateField = {this.validateField}
          onCancel={this.cancelEdit}
          onSubmit={this.update}
          t={t}
          editComponent = {editComponent}
      />
        

  }
  </>
}
}


export default withSnackbar(withTranslation('common')(ViewField))
