import React, { PureComponent } from 'react'
import { getServerProvider } from '../api'
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import EditableViewForm from './EditForm';
import { withRouter } from "react-router-dom"

class EditPage extends PureComponent {

    state = {
        serverProvider: {},
        //serverProviderId: this.props.match.params.id
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.loadData()
        }
    }

    async componentDidMount() {
        await this.loadData();
    }

    async loadData(){
        const serverProviderId = this.props.match.params.id;
        var serverProvider = await getServerProvider(serverProviderId)

        if (!serverProvider) return;

        this.setState({ serverProvider })
    }

    refresh = async ()=>{
        await this.loadData();
    }

    render() {
        return <EditableViewForm onRefresh={this.refresh}
                  values={this.state.serverProvider } />
    }
}


export default withSnackbar(withTranslation('serverProvider')(withRouter(EditPage)));