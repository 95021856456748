import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateSubscription } from '../../api'
import {hasValue} from '../../../../../utils/string'

import {ListDialog as BundlesDialog} from "../../../../Admin/Bundle/List"
import Selector from '../../../../../Component/Selector'


class View extends React.Component {
    
    update = async (values) =>{
        const {id,activeBundleId}=values;
        return await updateSubscription({id, activeBundleId});
    }

    headline(values){
        const { activeBundle = {} } = values;
        const headline = activeBundle?activeBundle.traffic:'-';
        return headline;
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, t } = this.props;
 
        return <ViewField
            successMessage={this.successMessage}
            title= { t('activeBundle.title')}
            headline  = { this.headline(values) }
            update ={this.update} 
            readonly = {true}
            values={values}
            onRefresh={onRefresh}
          />
        
    }
}


export const ActiveBundleView = withTranslation('subscription')(View);