import React, {useState, useEffect } from 'react'
import { Grid, FormControl, Box} from '@material-ui/core'
import Field from './../../../../../Component/Field';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import ViewField from '../../../../../Component/ViewField';
import { updateBundle } from '../../api'
import {hasValue} from '../../../../../utils/string'

import {allBundleTypes, byId, titleById} from '../../../../../domain/bundleType'
import Selector from '../../../../../Component/Selector'

export const BundleType =  ({values}) =>{
    const { t } = useTranslation('bundle');

    return <Grid item sm={ 12 } xs={12}>
    <FormControl fullWidth>
        <Field component={Selector}
               label={t('bundleType.title')}
               name="bundleType"
               options={allBundleTypes }
               // keyName="id"
               optionTitle="displayName" />
    </FormControl>
</Grid>
}

export const validate = (values, errors, t)=> {
    if (values.bundleType === undefined) {
        errors.bundleType = t('bundleType.required');
    }
    return errors;
}

class View extends React.Component {

    update = async (values) =>{
        const { id, bundleType }=values;
        return await updateBundle({id, bundleType });
    }

    headline(values){
        const { bundleType } = values;
        return titleById(bundleType);
    }

    successMessage  = (values) => this.props.t('updated')

    render() {
        const { values, onRefresh, readonly, t } = this.props;

        return <ViewField 
            successMessage={this.successMessage}
            title={ t('bundleType.title')}
            headline={ this.headline(values) }
            update={this.update}
            validate={validate}
            values={values}
            onRefresh={onRefresh}
            readonly = {readonly}
            editComponent={ BundleType } />

    }
}

export const BundleTypeView = withTranslation('bundle')(View);