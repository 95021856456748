import React, { useState, useRef  } from 'react'
import {validate as validateSubscription } from '../Form'
import CreateForm from './CreateForm';
import { insertSubscription } from '../api'
import { useTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { subscriptionType } from '../../../../domain/subscriptionType';
import { bundleType } from '../../../../domain/bundleType';

import {durationUnits,durationValues, fromHours, toHours} from '../../../../domain/subscriptionPeroid'
import { generateIdempotencyKey } from '../../../../utils/Ajax';


const CreatePage = ({  enqueueSnackbar, onCancel, history }) => {
    const {t} = useTranslation('subscription')
    const isTrial = window.location.hash.includes("/create/trial");   
    const isReseller = window.location.hash.includes("/create/reseller"); 
    var defaultDuration = isTrial?24:730;

    const defaultValues = { 
        subscriptionType: subscriptionType.Basic,
        useResellerCreditForRenewal: false,
        idempotencyKey: generateIdempotencyKey(),
        "activeBundle.concurrencyLimit":isTrial?2: 1,
        "activeBundle.traffic": isTrial?1:isReseller?500:50,
        "isTrial": isTrial,
        "isReseller": isReseller,
        "isYouth": isTrial?true:false,
        "subscriptionType": isTrial ? subscriptionType.Trial : subscriptionType.Basic,
        durationUnit: fromHours(defaultDuration).unitId,
        durationValue: fromHours(defaultDuration).valueId
    }

    const form = useRef(null);

    const onSubmit = async (values) => {
        const { durationValue, durationUnit,
            "activeBundle.concurrencyLimit": concurrencyLimit,
            "activeBundle.traffic": traffic,
            
            ...rest } = values;

        const durationHours = Math.round(toHours(durationValue, durationUnit));

        rest.activeBundle = {
            concurrencyLimit,
            traffic: traffic * 1024, // in megabytes
            durationHours,
            bundleType: traffic===0? bundleType.Infinite:values.isReseller? bundleType.Reseller : bundleType.Consumer,
        };

        const res = await insertSubscription(rest);
        if (!res) return false;

        history.push('/admin/subscription/' + res.id);
        enqueueSnackbar(t('saved'), { variant: 'success' });
    }

    const validate = (values) => {
        const errors = {}
        const warnings = {}

        validateSubscription(values, errors, t, warnings);
        console.log('warnings');
        console.log(warnings);
        console.log(errors);

        // This might need another approach in functional components
        // Consider using state to re-render

        return errors
    }

    return (
        <>
            <CreateForm 
                submitLabel={t('register')}
                onCancel={onCancel}
                ref={form}
                defaultValues={defaultValues}
                validate={validate}
                onSubmit={onSubmit} 
            />
        </>
    );
}

export default withSnackbar(CreatePage);


    



//  class CreatePage extends React.Component {
//     constructor(props) {
//         super(props);
        
//         const isTrial = window.location.hash.includes("/create/trial");   
//         const  isReseller = window.location.hash.includes("/create/reseller"); 

//         this.defaultValues = { 
//             subscriptionType:subscriptionType.Basic,
//             useResellerCreditForRenewal:false,
//             idempotencyKey : generateIdempotencyKey(),
//             "activeBundle.concurrencyLimit" :1,
//             "activeBundle.traffic" :50,
//             "isTrial" : isTrial,
//             "isReseller" : isReseller,
//             "subscriptionType" :isTrial? subscriptionType.Trial: SubscriptionType.Basic,
    
//             durationUnit:fromHours(defaultDuration).unitId,
//             durationValue:fromHours(defaultDuration).valueId
    
//         }
//     }  
    
//     onSubmit = async (values) => {

//         const { t, enqueueSnackbar  } = this.props;

//         const { durationValue, durationUnit,
//             "activeBundle.concurrencyLimit":concurrencyLimit,
//             "activeBundle.traffic" : traffic,

//             ...rest } = values;

//         const durationHours = Math.round(toHours(durationValue, durationUnit));

//         rest.activeBundle = {
//             concurrencyLimit,
//             traffic:traffic * 1024, // in megabytes
//             durationHours
//         };
        
//         var res = await insertSubscription(rest);
//         if(!res) {
//             return false;
//         }
//         this.props.history.push('/admin/subscription/'+res.id);
//         if (res) {
//             enqueueSnackbar( t('saved'), { variant:'success' });
//         }
//     }

//     validate = ( values ) => {
//         const {t}= this.props;
//         const errors = {}
//         const warnings = {}

//         validateSubscription(values, errors, t, warnings);
//         console.log(warnings);
//         console.log(errors);

//         this.forceUpdate();

//         return errors
//     }

//     render() {
//         const { onCancel, t } = this.props


//         return <>
//             <CreateForm submitLabel={t('register')}
//                 onCancel={onCancel}
//                 ref={this.form}
//                 defaultValues={this.defaultValues}
//                 validate={this.validate}
//                 //validateField = {this.validateField}
//                 onSubmit={this.onSubmit} />
//         </>
//     }
// }

// export default withSnackbar(withTranslation('subscription')(CreatePage));